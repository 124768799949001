import React, { useEffect } from 'react';
import { LOGIN_REDIRECT_URL } from '@/api/util/httpErrorHandler.util.ts';

export const LogoutController: React.FC = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    if (pathname === '/logout') {
      window.location.href = LOGIN_REDIRECT_URL;
    }
  }, [pathname]);

  return null;
};
