import React from 'react';
import { AiSearchChatBubble } from '@/views/aiSearch/components/AiSearchChatBubble.comp.tsx';
import { AiSearchResultItem } from '@/views/aiSearch/components/AiSearchResultItem.comp.tsx';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import { AIUserProfileSearchResultItemDto } from '@/api/generated';
import { UserProfileChatInputModel } from '@/views/userProfile/components/sections/careerPath/model/UserProfileChatInput.model.ts';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';

type Props = {
  results: Record<string, AIUserProfileSearchResultItemDto[]>;
  isSearching: boolean;
};

export const AiSearchHistory: React.FC<Props> = props => {
  const { results, isSearching } = props;

  const handleResultClick = (userProfileId: string) => () => {
    const chatInput = JSON.stringify(UserProfileChatInputModel.value);
    const win = window.open(
      window.location.origin +
        RouteEnum.USER_PROFILE +
        `?${queryString.stringify({ userProfileId, chatInput })}`,
      '_blank',
    );
    win?.focus();
  };

  const resultsKeys = Object.keys(results);

  return (
    <>
      <Box sx={AiSearchBoxStyles}>
        {resultsKeys.map((key, index) => (
          <Box sx={AiSearchHistoryBoxStyles} key={key}>
            <Box sx={AiSearchBubblesBoxStyles}>
              <Box display="flex" flexDirection="column">
                <AiSearchChatBubble text={key} isUser />
                <AiSearchChatBubble text="Please wait while we're getting your results" />
                {(index < resultsKeys.length - 1 || !isSearching) && (
                  <AiSearchChatBubble text="Here are the results:" />
                )}
              </Box>
            </Box>
            {results[key].length > 0 && (
              <Grid sx={AiSearchResultsGridStyles} container spacing={2}>
                {results[key].map(resultInner => (
                  <AiSearchResultItem
                    key={resultInner.profile.userProfileId}
                    result={resultInner}
                    handleResultClick={handleResultClick}
                  />
                ))}
              </Grid>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

const AiSearchBoxStyles: SxProps = {
  background: '#101010',
  width: '100%',
  textAlign: 'center',
};

const AiSearchHistoryBoxStyles: SxProps = {
  background: '#101010',
  textAlign: 'center',
};

const AiSearchBubblesBoxStyles: SxProps = {
  padding: '20px 0',
  width: '600px',
  margin: 'auto',
};

const AiSearchResultsGridStyles: SxProps = {
  width: '900px',
  margin: 'auto',
};
