import { colors } from '@/utils/colors.ts';
import { typography } from '@/utils/typography.ts';
import { SxProps, Theme } from '@mui/material';

export const FormFieldLabelBasicStyles = (isError: boolean): SxProps<Theme> => ({
  ...typography.f12Regular,
  fontFamily: typography.fontFamily,
  color: isError ? colors.red : colors.darkGrey,
});

export const FormFieldLabelStyles = (isError: boolean): SxProps<Theme> => ({
  ...FormFieldLabelBasicStyles(isError),
  position: 'absolute',
  top: -20,
  left: 0,
});
