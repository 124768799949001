import React from 'react';
import { FieldError } from 'react-hook-form';
import Typography from '@mui/material/Typography';

interface Props {
  error: FieldError | undefined;
}

interface ErrorModel extends FieldError {
  value?: FieldError;
}

export const TextError: React.FC<Props> = props => {
  const { error } = props;

  if (!error) {
    return null;
  }

  const localError = error as ErrorModel;

  return (
    <>
      <Typography component="div" color="error" variant="f12Regular" sx={{ pt: 1 }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*  @ts-ignore */}
        {localError.value?.message ?? localError.message}
      </Typography>
    </>
  );
};
