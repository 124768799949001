import React, { LazyExoticComponent, Suspense } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { RouteEnum } from '../layouts/routes/Route.model';
import Layout from '../layouts/private/Layout.tsx';
import { RoleTypeDto } from '@/api/generated';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { RouteStateModel } from '@/model/RouteState.model.ts';
import { PATHS_NOT_REQUIRING_AUTHORIZATION } from '@/api/util/httpErrorHandler.util.ts';
import { PageLoading } from '@/routes/PageLoading.comp.tsx';

interface Props {
  rolesWithAccess: RoleTypeDto[];
  children:
    | React.FC<{ route: RouteStateModel }>
    | LazyExoticComponent<React.FC<{ route: RouteStateModel }>>;
}

export const RouteGuard: React.FC<Props> = props => {
  const { rolesWithAccess } = props;
  const { value } = useUserContext();
  const { role } = value;
  const params = useParams();
  const location = useLocation();

  const route: RouteStateModel = {
    location,
    params,
  };

  if (PATHS_NOT_REQUIRING_AUTHORIZATION.some(path => path === location.pathname)) {
    return (
      <Suspense fallback={<PageLoading />}>
        <Layout component={props.children} route={route} />
      </Suspense>
    );
  }

  if (role === undefined) {
    return <Navigate to={RouteEnum.LOGIN} replace />; // TODO @IGOR role undefined - co jest do chuja???
  }

  const roleAccepted = rolesWithAccess.includes(role);

  if (rolesWithAccess.length > 0 && !roleAccepted) {
    return <Navigate to={RouteEnum.FORBIDDEN} replace />;
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Layout component={props.children} route={route} />
    </Suspense>
  );
};
