/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OcdCvSectionDto = {
    Awards: 'AWARDS',
    Certificates: 'CERTIFICATES',
    Contact: 'CONTACT',
    Courses: 'COURSES',
    Description: 'DESCRIPTION',
    Education: 'EDUCATION',
    Experience: 'EXPERIENCE',
    Languages: 'LANGUAGES',
    Projects: 'PROJECTS',
    Skills: 'SKILLS'
} as const;

export type OcdCvSectionDto = typeof OcdCvSectionDto[keyof typeof OcdCvSectionDto];



