/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CompanyEmployeeDetailsDto } from '../models';
// @ts-ignore
import type { UpdateEmployeeRequestDto } from '../models';
/**
 * CompanyEmployeesApi - axios parameter creator
 * @export
 */
export const CompanyEmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployee: async (companyId: string, employeeId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyEmployee', 'companyId', companyId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCompanyEmployee', 'employeeId', employeeId)
            const localVarPath = `/api/v1/companies/{companyId}/employees/{employeeId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyEmployee: async (companyId: string, employeeId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyEmployee', 'companyId', companyId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateCompanyEmployee', 'employeeId', employeeId)
            // verify required parameter 'updateEmployeeRequestDto' is not null or undefined
            assertParamExists('updateCompanyEmployee', 'updateEmployeeRequestDto', updateEmployeeRequestDto)
            const localVarPath = `/api/v1/companies/{companyId}/employees/{employeeId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyEmployeesApi - functional programming interface
 * @export
 */
export const CompanyEmployeesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyEmployeesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyEmployee(companyId: string, employeeId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEmployeeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEmployee(companyId, employeeId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyEmployeesApi.getCompanyEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyEmployee(companyId: string, employeeId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyEmployee(companyId, employeeId, updateEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyEmployeesApi.updateCompanyEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyEmployeesApi - factory interface
 * @export
 */
export const CompanyEmployeesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyEmployeesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployee(companyId: string, employeeId: string, options?: any): AxiosPromise<CompanyEmployeeDetailsDto> {
            return localVarFp.getCompanyEmployee(companyId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {string} employeeId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyEmployee(companyId: string, employeeId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompanyEmployee(companyId, employeeId, updateEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyEmployeesApi - interface
 * @export
 * @interface CompanyEmployeesApi
 */
export interface CompanyEmployeesApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyEmployeesApiInterface
     */
    getCompanyEmployee(companyId: string, employeeId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyEmployeeDetailsDto>;

    /**
     * 
     * @param {string} companyId 
     * @param {string} employeeId 
     * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyEmployeesApiInterface
     */
    updateCompanyEmployee(companyId: string, employeeId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CompanyEmployeesApi - object-oriented interface
 * @export
 * @class CompanyEmployeesApi
 * @extends {BaseAPI}
 */
export class CompanyEmployeesApi extends BaseAPI implements CompanyEmployeesApiInterface {
    /**
     * 
     * @param {string} companyId 
     * @param {string} employeeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyEmployeesApi
     */
    public getCompanyEmployee(companyId: string, employeeId: string, options?: RawAxiosRequestConfig) {
        return CompanyEmployeesApiFp(this.configuration).getCompanyEmployee(companyId, employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {string} employeeId 
     * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyEmployeesApi
     */
    public updateCompanyEmployee(companyId: string, employeeId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return CompanyEmployeesApiFp(this.configuration).updateCompanyEmployee(companyId, employeeId, updateEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

