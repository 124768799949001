import { AIUserProfileSearchWeightDto } from '@/api/generated';

export type AiSearchParametersModel = Record<keyof AIUserProfileSearchWeightDto, number>;

export enum AiSearchParameterControlDirection {
  increase = 'increase',
  decrease = 'decrease',
}

export const normalAiSearchParameters: AiSearchParametersModel = {
  positionWeight: 0.6,
  skillWeight: 0.1,
  languagesWeight: 0.05,
  experienceWeight: 0.15,
  certificatesWeight: 0.1,
};

export const upSkillAiSearchParameters: AiSearchParametersModel = {
  positionWeight: 0.0,
  skillWeight: 0.7,
  languagesWeight: 0.05,
  experienceWeight: 0.15,
  certificatesWeight: 0.1,
};
