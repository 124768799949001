import { useTranslation } from 'react-i18next';
import { KeyPrefix, TFunction } from 'i18next';
import { NAMESPACE } from '@/assets/locales/en.ts';

export const useTranslationWithPrefix = (
  prefix: KeyPrefix<typeof NAMESPACE>,
): ReturnType<typeof useTranslation<typeof NAMESPACE, KeyPrefix<typeof NAMESPACE>>> => {
  return useTranslation(NAMESPACE, { keyPrefix: prefix });
};

export type PrivalleyTFunction = TFunction<typeof NAMESPACE, KeyPrefix<typeof NAMESPACE>>;
