import axios from 'axios';
import { decodeStringOutput } from '@/utils/String.util';
import config from '@/config/config';
import { DataOperationsError } from '@/errorHandling/DataOperationsError.util';
import { httpErrorHandler } from '@/api/util/httpErrorHandler.util';
import { getCsrfToken } from '@/api/candidates-api';
import { HTTP_STATUS } from '@/api/util/ErrorCode.model';
import { HTTP_HEADER } from '@/api/util/httpHeaders.model';
import { HttpMethod } from '@/api/util/HttpMethod.model';

class HttpService {
  _defaultMsg = 'Unknown error occurred';

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }

  _getUrl = (endpoint, queryParams = '', local = false) => {
    const queryString = queryParams ? this._getQueryParams(queryParams) : '';
    const path = endpoint + queryString;
    if (local) {
      return path;
    }

    return this._getHost() + path;
  };

  _getHost = () => {
    return config.HOST;
  };

  _getQueryParams = queryParams => {
    let query = '?';
    Object.keys(queryParams).forEach((key, index) => {
      const formatParam = `${index !== 0 ? '&' : ''}${key}=${queryParams[key]}`;
      query = query.concat(formatParam);
    });
    return query;
  };

  _resolveHeaders = (customHeaders = {}) => {
    return {
      ...this.headers,
      ...customHeaders,
    };
  };

  _responseHandler = (response, options) => {
    const { status } = response;
    if (status >= 200 && status < 300) {
      options.onSuccess(response.data);
    } else {
      throw new DataOperationsError(this._defaultMsg, response);
    }
  };

  _parseAxiosConfig = (options, method) => {
    return {
      headers: this._resolveHeaders(options.headers),
      data: options.payload ? removeWhiteSpace(options.payload) : {},
      method,
      url: this._getUrl(options.endpoint, options.queryParams, options.local),
      withCredentials: true,
    };
  };

  _parseError = (error, options) => {
    if (error.response?.status) {
      const { status } = error.response;
      if (status === HTTP_STATUS.UNAUTHORIZED || status === HTTP_STATUS.FORBIDDEN) {
        return;
      }
    }

    options.onError(
      error instanceof DataOperationsError
        ? error
        : new DataOperationsError(
            error.response?.data?.message ?? this._defaultMsg,
            error.response,
          ),
    );
  };

  _request = async (options, method) => {
    axios({
      ...this._parseAxiosConfig(options, method),
    })
      .then(res => {
        this._responseHandler(res, options);
      })
      .catch(error => {
        this._parseError(error, options);
      });
  };

  post = async options => {
    await this._request(options, HttpMethod.POST);
  };

  delete = options => this._request(options, HttpMethod.DELETE);

  put = options => this._request(options, HttpMethod.PUT);

  patch = options => this._request(options, HttpMethod.PATCH);

  get = options =>
    axios({
      ...this._parseAxiosConfig(options, HttpMethod.GET),
    })
      .then(response => {
        const { status, data, headers } = response;
        if (status >= 200 && status < 300) {
          let parsedData;
          if (typeof data === 'object') {
            const stringified = JSON.stringify(data);
            parsedData = JSON.parse(decodeStringOutput(stringified));
          } else if (typeof data === 'string') {
            parsedData = decodeStringOutput(data);
          } else {
            parsedData = data;
          }
          options.onSuccess(parsedData, headers);
        } else if (status !== HTTP_STATUS.UNAUTHORIZED && status !== HTTP_STATUS.FORBIDDEN) {
          throw new DataOperationsError(this._defaultMsg, response);
        }
      })
      .catch(error => {
        this._parseError(error, options);
      });
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return httpErrorHandler(error, () => new DataOperationsError(error.message, error.response));
  },
);

axios.interceptors.request.use(
  async config => {
    if (
      config.method === HttpMethod.PUT ||
      config.method === HttpMethod.POST ||
      config.method === HttpMethod.PATCH ||
      config.method === HttpMethod.DELETE
    ) {
      config.headers[HTTP_HEADER['X-CSRF-Token']] = await getCsrfToken();
    } else {
      delete config.headers[HTTP_HEADER['X-CSRF-Token']];
    }
    return config;
  },
  error => Promise.reject(error),
);

export default HttpService;

export const removeWhiteSpace = payload => {
  if (typeof payload === 'object') {
    return Object.entries(payload).reduce((a, [key, value]) => {
      a[key] = typeof value === 'string' ? value.trim() : value;
      return a;
    }, {});
  }
  if (typeof payload === 'string') {
    return payload.trim();
  }
  return payload;
};
