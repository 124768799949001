import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function InfoIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-2 -2 29 29">
      <g fill="none">
        <path
          stroke={props.htmlColor || '#7c878e'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,21v0c-4.971,0 -9,-4.029 -9,-9v0c0,-4.971 4.029,-9 9,-9v0c4.971,0 9,4.029 9,9v0c0,4.971 -4.029,9 -9,9Z"
        ></path>
        <path
          stroke={props.htmlColor || '#7c878e'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12,17v-5h-1"
        ></path>
        <path
          stroke={props.htmlColor || '#7c878e'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M11.749,8c-0.138,0 -0.25,0.112 -0.249,0.25c0,0.138 0.112,0.25 0.25,0.25c0.138,0 0.25,-0.112 0.25,-0.25c0,-0.138 -0.112,-0.25 -0.251,-0.25"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default InfoIcon;
