import { VacancyDetailsForSearchDto } from '@/api/generated';
import { useQuery } from '@tanstack/react-query';
import { useVacancyApiClient } from '@/api/util/useVacancyApiClient.util.ts';
import { AxiosError } from 'axios';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';
import { VacancyDetailsForSearch } from '../model/vacancyDetailsForSearch.model';

type Return = {
  vacancyDetailsForSearch: VacancyDetailsForSearchDto | undefined | null;
  isGettingVacancyDetailsForSearch: boolean;
  isErrorVacancyDetailsForSearch: boolean;
};

type Params = {
  vacancyId: string | null;
  onSuccess: (data: VacancyDetailsForSearchDto | undefined | null) => Promise<void>;
};

export const useGetVacancyDetailsForSearch = (params: Params): Return => {
  const { vacancyId, onSuccess } = params;
  const { vacancyApi } = useVacancyApiClient();
  const { data, isLoading, isError } = useQuery<VacancyDetailsForSearchDto | null, AxiosError>({
    queryFn: async () => {
      if (!vacancyId) {
        return null;
      }
      const res = await vacancyApi.getVacancyDetailsForSearch(vacancyId);
      return res.data;
    },
    queryKey: [QueryKeyEnum.VACANCY_DETAILS_GET, vacancyId],
    onSuccess: async data => {
      VacancyDetailsForSearch.value = data;
      await onSuccess(data);
    },
  });

  return {
    vacancyDetailsForSearch: data,
    isGettingVacancyDetailsForSearch: isLoading,
    isErrorVacancyDetailsForSearch: isError,
  };
};
