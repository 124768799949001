import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { GeneralError } from '@/views/generalError/GeneralError.view.tsx';

export const NotFound = () => {
  const { t } = useTranslationWithPrefix('generalError.notFound');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== `/${RouteEnum.NOT_FOUND}`) {
      navigate(RouteEnum.NOT_FOUND);
    }
  }, []);

  return <GeneralError message={t('message')} />;
};
