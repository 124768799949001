import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileSkillDto } from '@/api/generated-user-profile';

type SkillDescription = {
  title: string;
  descriptions: string[];
};

export type SkillsDescriptions = {
  [0]: SkillDescription;
  [1]: SkillDescription;
  [2]: SkillDescription;
  [3]: SkillDescription;
  [4]: SkillDescription;
};

export const skillsDescriptions: SkillsDescriptions = {
  0: {
    title: 'No knowledge',
    descriptions: [],
  },
  1: {
    title: 'Level 1 - Novice',
    descriptions: [
      "Experience gained in a classroom and/or lab scenario's or as a trainee on-the-job guided by a senior consultant",
      'Assistance is expected when performing the install',
      'Understanding and the ability to discuss terminology, concepts, principles, and issues.',
      'The full range of reference and resource materials can be used',
    ],
  },
  2: {
    title: 'Level 2 - Intermediate',
    descriptions: [
      'Able to successfully complete tasks in this competency as requested',
      'Help from an expert may be required from time to time, but they can perform the skill independently',
      'Focus is on installation and configuration knowledge/skill',
      'Understanding and the ability to discuss the application and implications of changes to installation',
      'Focus on in depth troubleshooting product issuese',
    ],
  },
  3: {
    title: 'Level 3 - Advanced',
    descriptions: [
      'Performed the actions associated with this skill without assistance',
      'Recognize within their immediate organization as "a subject matter expert" when difficult questions arise regarding this product',
      'Capable of coaching others in the product and handling complex design',
    ],
  },
  4: {
    title: 'Level 4 - Expert',
    descriptions: [
      'Known as an expert in this product',
      'Able to provide guidance, troubleshoot and answer questions related to this area of expertise',
      'Able to demonstrate consistent excellence in applying this competency across multiple projects and complex designs',
      'Recognized externally and by product vendors as "an absolute subject matter expert"',
      'Considered an authority on the product',
    ],
  },
};

export type UserProfileSkillsSectionTranslation = SectionTranslationType<
  Omit<UserProfileSkillDto, 'userProfileId' | 'skillId' | 'contentLanguage'>
> & {
  content: {
    tableHeader: {
      skill: string;
      level: string;
      experienceInYears: string;
      actions: string;
    };
    experienceMonths: string;
    experienceYears: string;
    skillsDescriptions: SkillsDescriptions;
  };
  form: {
    skillsDescriptions: SkillsDescriptions;
  };
};

export const userProfileSkillsSectionTranslation: UserProfileSkillsSectionTranslation = {
  headerTitle: 'Skills',
  content: {
    emptyItemsLabel: 'skills',
    tableHeader: {
      skill: 'Skill',
      level: 'Level',
      experienceInYears: 'Experience',
      actions: 'Actions',
    },
    experienceMonths: 'mon',
    experienceYears: 'yrs',
    skillsDescriptions,
    button: {
      edit: 'Edit',
    },
  },
  form: {
    actions: 'Actions',
    experienceInMonths: 'Experience',
    level: 'Adjust your level',
    nonDictionarySkillValue: 'Non dictionary skill value',
    skill: 'Skill',
    skillsDescriptions,
    button: {
      save: 'Save',
    },
  },
  modal: {
    title: 'Add skill',
  },
};
