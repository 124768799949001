import { useSearchUserProfile } from '@/views/profileSearch/util/useSearchUserProfile.util.ts';
import { useSearchUserProfileFullText } from '@/views/profileSearch/util/useSearchUserProfileFullText.util.ts';
import {
  PROFILE_SEARCH_MODE,
  ProfileSearchModeEnum,
} from '@/views/profileSearch/model/profileSearchMode.model.ts';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { ProfileSearchResultsModel } from '@/views/profileSearch/model/ProfileSearchResults.model.ts';

type Params = {
  form: ProfileSearchFormModel;
  initializeValues?: boolean;
};

export type HandleSubmitSearchType = (params: Params) => Promise<void>;

type Return = {
  handleSubmitSearch: HandleSubmitSearchType;
  isSearching: boolean;
  isSearchDone: boolean;
};

export const useHandleSearchProfile = (): Return => {
  const { searchUserProfile, searchUserProfileLoading, searchUserProfileDone } =
    useSearchUserProfile();
  const {
    searchUserProfileFullText,
    searchUserProfileFullTextLoading,
    searchUserProfileFullTextDone,
  } = useSearchUserProfileFullText();

  return {
    handleSubmitSearch: async params => {
      const { form, initializeValues } = params;
      const { addType, fullTextSearch, section, availableOnly, ...regularRequest } = form;
      if (PROFILE_SEARCH_MODE.value === ProfileSearchModeEnum.REGULAR) {
        const parsedReqularSearch = {
          ...regularRequest,
          firstName: regularRequest.firstName?.trim(),
          lastName: regularRequest.lastName?.trim(),
        };
        ProfileSearchResultsModel.value.profiles = await searchUserProfile({
          ...parsedReqularSearch,
          availableOnly,
        });
      } else if (PROFILE_SEARCH_MODE.value === ProfileSearchModeEnum.FULL_TEXT) {
        const parsedSection = initializeValues ? undefined : section;
        ProfileSearchResultsModel.value = await searchUserProfileFullText({
          inputs: [{ value: fullTextSearch ?? '', section: parsedSection }],
          availableUsers: availableOnly ? 'AVAILABLE_ONLY' : 'ALL_USERS',
        });
      } else {
        throw new Error('Unknown profile search mode');
      }
    },
    isSearching: searchUserProfileLoading || searchUserProfileFullTextLoading,
    isSearchDone: searchUserProfileFullTextDone || searchUserProfileDone,
  };
};
