import { PageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.en.ts';

export const pageTitlesTranslation: PageTitlesTranslation = {
  addVacancy: 'Ressource anfordern',
  aiSearch: 'AI search',
  candidateDetails: 'Informationen zu den Kandidaten',
  candidates: 'Kandidaten',
  companies: 'Unternehmen',
  companyDetails: 'Informationen zum Unternehmen',
  companyEmployee: 'Informationen für Arbeitnehmer',
  copyVacancy: 'Anfrage kopieren',
  editVacancy: 'Anfrage bearbeiten',
  eraCandidate: 'Neuer Kandidat',
  initializeAccount: 'Ihr Konto initialisieren',
  knowMe: 'KnowMe',
  profileSearch: 'Profil suchen',
  projects: 'Projekte',
  recruitmentBoard: 'Anfrage-Tracker',
  recruitmentCandidates: 'Kandidaten',
  reports: 'Meldungen',
  settingsDictionaries: 'Wörterbücher',
  userProfile: 'Profil',
  vacancyCandidateTimeline: 'Zeitplan für die Kandidaten',
  vacancyDetails: 'Details anfordern',
  signPrivacyPolicy: 'Datenschutzerklärung unterschreiben',
  signUpdatedPrivacyPolicy: 'Unterschreiben Sie die aktualisierte Datenschutzerklärung',
};
