import { SignPrivacyPolicyTranslation } from '@/assets/locales/views/signPrivacyPolicy/signPrivacyPolicy.translation.en';

export const signPrivacyPolicyTranslation: SignPrivacyPolicyTranslation = {
  form: {
    buttons: {
      submit: 'Einreichen',
    },
    tooltips: {
      agreeToPrivacyPolicy:
        'Bitte öffnen Sie den Link zur Datenschutzerklärung und klicken Sie auf das Kontrollkästchen, um das Formular abzuschicken',
    },
    fields: {
      agreeToPrivacyPolicy1:
        'Wenn Sie dieses System betreten, stimmen Sie der Datenschutzrichtlinie von Orange Cyberdefense KnowMe zu, die Sie hier finden: ',
      agreeToPrivacyPolicy2: 'hier klicken',
      agreeToPrivacyPolicy3:
        'Bitte wenden Sie sich an Ihr lokales SPOC, wenn Sie Fragen dazu haben, wie Orange Cyberdefense den Datenschutz für das KnowMe-Portal behandelt.',
    },
    error: {
      signingPrivacyPolicy:
        'Fehler beim Unterschreiben der Datenschutzerklärung. Bitte versuchen Sie es später noch einmal.',
    },
  },
};
