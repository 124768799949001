import { SignPrivacyPolicyTranslation } from '@/assets/locales/views/signPrivacyPolicy/signPrivacyPolicy.translation.en';

export const signPrivacyPolicyTranslation: SignPrivacyPolicyTranslation = {
  form: {
    buttons: {
      submit: 'Soumettre',
    },
    tooltips: {
      agreeToPrivacyPolicy:
        'Veuillez ouvrir le lien de la politique de confidentialité et cliquer sur la case pour soumettre le formulaire',
    },
    fields: {
      agreeToPrivacyPolicy1:
        "En accédant à ce système, vous acceptez la politique de confidentialité de KnowMe d'Orange Cyberdefense, que vous pouvez consulter ici : ",
      agreeToPrivacyPolicy2: 'cliquez ici',
      agreeToPrivacyPolicy3:
        'Veuillez contacter votre SPOC local si vous avez des questions sur la façon dont Orange Cyberdefense gère la confidentialité des données pour le portail KnowMe',
    },
    error: {
      signingPrivacyPolicy:
        'Erreur lors de la signature de la politique de confidentialité. Veuillez réessayer plus tard.',
    },
  },
};
