import React, { useState } from 'react';
import { UserProfileItemDto } from '@/api/generated-user-profile';
import Grid from '@mui/material/Grid';
import {
  ProfileSearchResultItemLocationStyles,
  ProfileSearchResultItemStyles,
  ProfileSearchResultNameStyles,
} from '@/views/profileSearch/styles/profileSearch.styles.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import Box from '@mui/material/Box';
import { colors } from '@/utils/colors.ts';
import IconButton from '@mui/material/IconButton';

type Props = {
  item: UserProfileItemDto;
};

export const ProfileSearchResultItem: React.FC<Props> = props => {
  const { item } = props;
  const { push } = useOcdHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslationWithPrefix('views.profileSearch.content.results.menu');

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenProfile = () => {
    push({
      pathname: RouteEnum.USER_PROFILE,
      params: { userProfileId: item.userProfileId },
    });
    handleCloseMenu();
  };
  const handleOpenProfileInNewTab = () => {
    const win = window.open(
      window.location.origin + RouteEnum.USER_PROFILE + `?userProfileId=${item.userProfileId}`,
      '_blank',
    );
    win?.focus();
    handleCloseMenu();
  };

  return (
    <>
      <Grid item>
        <Box sx={ProfileSearchResultNameStyles}>
          <Box onClick={handleOpenMenu}>
            {item.firstName} {item.lastName}
          </Box>
          <IconButton onClick={handleOpenMenu}>
            <OpenInNewIcon htmlColor={colors.blue} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ ...ProfileSearchResultItemStyles, pb: 1 }}> {item.position}</Box>
        <Box sx={ProfileSearchResultItemLocationStyles}>{item.divisionLocation}</Box>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleOpenProfile}>{t('open')}</MenuItem>
        <MenuItem onClick={handleOpenProfileInNewTab}>{t('openInNewTab')}</MenuItem>
      </Menu>
    </>
  );
};
