import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { RouteStateModel } from '@/model/RouteState.model.ts';
import React from 'react';

export const DRAWER_WIDTH = 272;

type Props = {
  component: React.FC<{ route: RouteStateModel }>;
  route: RouteStateModel;
};

const Layout: React.FC<Props> = ({ component: Component, route }) => (
  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
    <CssBaseline />
    <Component route={route} />
  </LocalizationProvider>
);

export default Layout;
