/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileEducation1Dto } from '../models';
/**
 * UserProfileEducationApi - axios parameter creator
 * @export
 */
export const UserProfileEducationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileEducation: async (userProfileId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileEducation', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileEducation', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/educations`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileEducation1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileEducation: async (userProfileId: string, userProfileEducationId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileEducation', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileEducationId' is not null or undefined
            assertParamExists('deleteUserProfileEducation', 'userProfileEducationId', userProfileEducationId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileEducation', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/educations/{userProfileEducationId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileEducationId"}}`, encodeURIComponent(String(userProfileEducationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileEducation: async (userProfileId: string, userProfileEducationId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileEducation', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileEducationId' is not null or undefined
            assertParamExists('updateUserProfileEducation', 'userProfileEducationId', userProfileEducationId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileEducation', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/educations/{userProfileEducationId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileEducationId"}}`, encodeURIComponent(String(userProfileEducationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileEducation1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileEducationApi - functional programming interface
 * @export
 */
export const UserProfileEducationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileEducationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileEducation(userProfileId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileEducation(userProfileId, lang, userProfileEducation1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileEducationApi.createUserProfileEducation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileEducation(userProfileId, userProfileEducationId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileEducationApi.deleteUserProfileEducation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileEducation(userProfileId, userProfileEducationId, lang, userProfileEducation1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileEducationApi.updateUserProfileEducation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileEducationApi - factory interface
 * @export
 */
export const UserProfileEducationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileEducationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileEducation(userProfileId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileEducation(userProfileId, lang, userProfileEducation1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileEducation(userProfileId, userProfileEducationId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileEducationId 
         * @param {LangDto} lang 
         * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileEducation(userProfileId, userProfileEducationId, lang, userProfileEducation1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileEducationApi - interface
 * @export
 * @interface UserProfileEducationApi
 */
export interface UserProfileEducationApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApiInterface
     */
    createUserProfileEducation(userProfileId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileEducationId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApiInterface
     */
    deleteUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileEducationId 
     * @param {LangDto} lang 
     * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApiInterface
     */
    updateUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileEducationApi - object-oriented interface
 * @export
 * @class UserProfileEducationApi
 * @extends {BaseAPI}
 */
export class UserProfileEducationApi extends BaseAPI implements UserProfileEducationApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApi
     */
    public createUserProfileEducation(userProfileId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileEducationApiFp(this.configuration).createUserProfileEducation(userProfileId, lang, userProfileEducation1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileEducationId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApi
     */
    public deleteUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileEducationApiFp(this.configuration).deleteUserProfileEducation(userProfileId, userProfileEducationId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileEducationId 
     * @param {LangDto} lang 
     * @param {UserProfileEducation1Dto} [userProfileEducation1Dto] User Profile Education update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileEducationApi
     */
    public updateUserProfileEducation(userProfileId: string, userProfileEducationId: string, lang: LangDto, userProfileEducation1Dto?: UserProfileEducation1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileEducationApiFp(this.configuration).updateUserProfileEducation(userProfileId, userProfileEducationId, lang, userProfileEducation1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

