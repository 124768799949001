import { ViewsTranslation } from '@/assets/locales/views/views.translation.en.ts';

import { profileSearchTranslation } from '@/assets/locales/views/profileSearch/profileSearch.translation.fr.ts';
import { vacancyDetailsTranslation } from '@/assets/locales/views/vacancyDetails/vacancyDetails.translation.fr.ts';
import { projectsTranslation } from '@/assets/locales/views/projects/projects.translation.fr.ts';
import { userProfileTranslation } from '@/assets/locales/views/userProfile/userProfile.translation.fr.ts';
import { recruitmentBoardTranslation } from '@/assets/locales/views/recruitmentBoard/recruitmentBoard.translation.fr.ts';
import { reportsTranslation } from '@/assets/locales/views/reports/reports.translation.fr.ts';
import { signPrivacyPolicyTranslation } from '@/assets/locales/views/signPrivacyPolicy/signPrivacyPolicy.translation.fr';
import { aiSearchTranslation } from '@/assets/locales/views/aiSearch/aiSearch.translation.fr.ts';

export const viewsTranslation: ViewsTranslation = {
  aiSearch: aiSearchTranslation,
  profileSearch: profileSearchTranslation,
  projects: projectsTranslation,
  recruitmentBoard: recruitmentBoardTranslation,
  reports: reportsTranslation,
  userProfile: userProfileTranslation,
  vacancyDetails: vacancyDetailsTranslation,
  signPrivacyPolicy: signPrivacyPolicyTranslation,
};
