import {
  Configuration,
  VacancyApiFactory,
  VacancyCandidateApiFactory,
  VacancyCandidateUserProfileApiFactory,
  VacancyCompaniesApiFactory,
  VacancyProjectsApiFactory,
  VacancyProjectsListApiFactory,
} from '@/api/generated';
import { useMemo } from 'react';
import { useAxiosInstance } from './useAxiosInstance.util';
import CONFIG from '@/config/config';

interface Return {
  vacancyApi: ReturnType<typeof VacancyApiFactory>;
  vacancyCandidateApi: ReturnType<typeof VacancyCandidateApiFactory>;
  vacancyCandidateUserProfileApi: ReturnType<typeof VacancyCandidateUserProfileApiFactory>;
  vacancyCompaniesApi: ReturnType<typeof VacancyCompaniesApiFactory>;
  vacancyProjectsApi: ReturnType<typeof VacancyProjectsApiFactory>;
  vacancyProjectsListApi: ReturnType<typeof VacancyProjectsListApiFactory>;
}

export const useVacancyApiClient = (): Return => {
  const basePath = CONFIG.HOST;
  const configuration = getConfiguration(basePath);
  const axiosInstance = useAxiosInstance();

  return useMemo(() => {
    return {
      vacancyApi: VacancyApiFactory(configuration, undefined, axiosInstance),
      vacancyCandidateApi: VacancyCandidateApiFactory(configuration, undefined, axiosInstance),
      vacancyCandidateUserProfileApi: VacancyCandidateUserProfileApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
      vacancyCompaniesApi: VacancyCompaniesApiFactory(configuration, undefined, axiosInstance),
      vacancyProjectsApi: VacancyProjectsApiFactory(configuration, undefined, axiosInstance),
      vacancyProjectsListApi: VacancyProjectsListApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
    };
  }, [axiosInstance]);
};

const getConfiguration = (basePath: string): Configuration => ({
  basePath,
  isJsonMime(_mime: string): boolean {
    return true;
  },
});
