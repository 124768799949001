import React from 'react';
import { colors } from '@/utils/colors.ts';
import Checkbox from '@mui/material/Checkbox';
import { SxProps } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
  value?: boolean;
  invalid?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const KnowMeCheckbox: React.FC<Props> = props => {
  const { value, invalid, sx, disabled, onChange } = props;

  return (
    <>
      <Checkbox
        value={value}
        checked={value}
        sx={{ ...sx, svg: { fill: invalid ? colors.red : colors.primary } }}
        disabled={disabled}
        onChange={onChange}
        icon={
          <CheckBoxOutlineBlankIcon
            style={{
              fill: colors.grey,
              height: '20px',
              width: '20px',
            }}
          />
        }
        checkedIcon={
          <CheckBoxIcon
            style={{
              fill: colors.blue,
              height: '20px',
              width: '20px',
            }}
          />
        }
      />
    </>
  );
};
