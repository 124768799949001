import {
  vacancyDetailsContentTranslation,
  VacancyDetailsContentTranslation,
} from '@/assets/locales/views/vacancyDetails/content/vacancyDetails.content.translation.en.ts';
import {
  vacancyDetailsFooterTranslation,
  VacancyDetailsFooterTranslation,
} from '@/assets/locales/views/vacancyDetails/footer/vacancyDetails.footer.translation.en.ts';
import {
  vacancyDetailsHeaderTranslation,
  VacancyDetailsHeaderTranslation,
} from '@/assets/locales/views/vacancyDetails/header/vacancyDetails.header.translation.en.ts';

export type VacancyDetailsTranslation = {
  content: VacancyDetailsContentTranslation;
  footer: VacancyDetailsFooterTranslation;
  header: VacancyDetailsHeaderTranslation;
};

export const vacancyDetailsTranslation: VacancyDetailsTranslation = {
  content: vacancyDetailsContentTranslation,
  footer: vacancyDetailsFooterTranslation,
  header: vacancyDetailsHeaderTranslation,
};
