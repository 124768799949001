import { VacancyStatusEnumDto } from '@/api/generated';

import { VacanciesTranslation } from '@/assets/locales/vacancies/vacancies.translation.en.ts';

export const vacanciesTranslation: VacanciesTranslation = {
  DELIVERY: 'Lieferung',
  HYBRID: 'Hybride',
  OFF_SITE: 'Außerhalb des Standorts',
  ON_SITE: 'Vor Ort',
  PRE_SALES: 'Vorverkäufe',
  addVacancy: 'Anfrage hinzufügen',
  selectCloseReason: 'Wählen Sie den Grund für das Schließen der Anfrage:',
  assignHrEmployee: 'Wählen Sie die Person aus, die Sie der Anfrage zuweisen möchten:',
  attachment: 'Attachment',
  availability: 'Voraussichtliche Verfügbarkeit',
  by: 'von',
  closeVacancyWarning:
    'Nach der Genehmigung ist es nicht möglich, diese Anfrage wiederherzustellen!',
  code: 'Referenznummer',
  copy: 'Anfrage kopieren',
  copyVacancy: 'Anfrage kopieren',
  createdBy: 'Erstellt von',
  createdDate: 'Erstellungsdatum',
  currency: 'Währung',
  department: 'Abteilung',
  dropzoneFormatError:
    'Falsches Dateiformat! Akzeptierte Dateierweiterungen: .doc, .docx, .txt, .pdf, .jpg, .jpeg',
  editVacancy: 'Anfrage bearbeiten',
  email: 'E-mail Adresse',
  eras: 'Externe Personalvermittlungsagenturen',
  extendByOutsourcing: 'Erweitern durch Outsourcing',
  generateTableList: 'Liste der Anfragen exportieren',
  goBack: 'Zurückgehen',
  goBackVacancies: 'Zurück zur Liste der Gesuche',
  hrEmployee: 'HR Mitarbeiter',
  hrEmployeeEdit: 'HR Mitarbeiter bearbeiten',
  hrbp: 'HR Geschäftspartner',
  locations: 'Ort',
  manager: 'Einstellungsmanager',
  mdRate: 'Erwarteter Stundensatz',
  mdRateFrom: 'Stundensatz von',
  mdRateFromLowerThanToError: 'Diese Rate muss niedriger sein als die Rate BIS',
  mdRateLowerEqualZeroError: 'Betrag muss größer als 0 sein',
  mdRateTo: 'Stundensatz bis',
  mdRateToHigherThanFromError: 'Dieser Satz muss höher sein als der Satz VON',
  minYearsOfExperience: 'Min. Jahre Erfahrung auf einer ähnlichen Position',
  minYearsOfExperienceShort: 'Erfahrung (Jahre)',
  modifiedBy: 'Geändert von',
  modifiedDate: 'Geändertes Datum',
  new: 'Neu',
  noVacancies: 'Keine Anfragen entsprechen den angegebenen Kriterien',
  notVisible: 'Eingeschränkte Sichtbarkeit',
  numberOfCandidates: 'Anzahl der Bewerber',
  numberOfVacancies: 'Anzahl der Gesuche',
  numberValid: 'Der Wert sollte nur aus Zahlen bestehen',
  optionalSkills: 'Optionale Technologien/Fähigkeiten',
  phoneNumber: 'Telefonnummer',
  position: 'Stelle',
  requester: 'Antragsteller',
  role: 'Rolle',
  vacanciesAndCandidates: 'Anzahl der Anfragen / Anzahl der Bewerber',
  visible: 'Sichtbar für alle Benutzer',
  priority: {
    label: 'Priorität',
    low: 'Niedrig',
    urgent: 'Dringend',
    normal: 'Normal',
    critical: 'Kritisch',
  },
  status: {
    [VacancyStatusEnumDto.Archived]: 'Archiviert',
    [VacancyStatusEnumDto.Open]: 'Offen',
    [VacancyStatusEnumDto.Closed]: 'Geschlossen',
    [VacancyStatusEnumDto.OnHold]: 'In Wartestellung',
  },
  candidatesList: {
    header: 'Kandidatenliste',
    name: 'Name',
    email: 'E-mail',
    phoneNumber: 'Telefonnummer',
    status: 'Status',
    createdAt: 'Datum hinzugefügt',
    externalId: 'Kandidate ID',
    expand: 'Erweitern',
    collapse: 'Zusammenklappen',
    sentAt: 'Gesendet am',
  },
  dialog: {
    title: 'Sind Sie sicher, dass Sie das Outsourcing hinzufügen möchten?',
    warning:
      'Wenn Sie auf die Schaltfläche "Bestätigen" klicken, wird eine E-Mail mit den Anfragedaten an externe Personalvermittler gesendet.',
    acceptLabel: 'Bestätigen',
  },
};
