import { LocalStorageParam, setLocalStorage } from './localStorage.util';
import { Location } from 'history';
import { RouteEnum } from '../layouts/routes/Route.model';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { useCallback } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { NavigateOptions } from 'react-router/dist/lib/context';
import { isPrivacyPolicySigningRequired } from '@/views/signPrivacyPolicy/util/isPrivacyPolicySigningRequired';

type Path = string | RouteEnum;
export type PathParams = Path | { pathname: Path; params: Record<string, string> };

interface Return {
  push: (path: PathParams, state?: NavigateOptions) => void;
  location: Location;
}

export const useOcdHistory = (): Return => {
  const navigate = useNavigate();
  const location = useLocation();
  const { value } = useUserContext();
  const { initializationDone, privacyPolicyStatus } = value;

  return {
    push: useCallback(
      (path, state) => {
        const privacyPolicySigningRequired = isPrivacyPolicySigningRequired(privacyPolicyStatus);

        if (!initializationDone) {
          navigate(RouteEnum.USERS_INITIALIZE);
          return;
        }

        if (privacyPolicySigningRequired) {
          navigate(RouteEnum.USERS_SIGN_PRIVACY_POLICY);
          return;
        }

        setLocalStorage(LocalStorageParam.LAST_VISITED_LOCATION, path);

        if (typeof path === 'object') {
          navigate(
            {
              pathname: path.pathname,
              search: `?${createSearchParams(path.params)}`,
            },
            {
              state,
            },
          );
          return;
        }
        navigate(path, { state });
      },
      [initializationDone, privacyPolicyStatus],
    ),
    location,
  };
};
