import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';
import { DataOperationsErrorContext } from '@/errorHandling/DataOperationsErrorContext.ts';
import { AxiosError } from 'axios';
import { PATHS_NOT_REQUIRING_AUTHORIZATION } from '@/api/util/httpErrorHandler.util.ts';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

export const useGetQueryClient = (): QueryClient => {
  const { handleMinorError, handleOperationSuccess } = useContext(DataOperationsErrorContext);
  return useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        mutations: {
          retry: false,
        },
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError: (error: unknown) => {
          handleError(error, handleMinorError);
        },
        onSuccess: (_data, query) => {
          if ((query?.options?.meta as Partial<QueryClientMetaModel>)?.enableSuccessAlert) {
            handleOperationSuccess();
          }
        },
      }),
      mutationCache: new MutationCache({
        onError: (error: unknown) => {
          handleError(error, handleMinorError);
        },
        onSuccess: (_data, _variables, _context, mutation) => {
          if (!(mutation?.options?.meta as Partial<QueryClientMetaModel>)?.disableSuccessAlert) {
            handleOperationSuccess();
          }
        },
      }),
    });
  }, [handleMinorError, handleOperationSuccess]);
};

const handleError = (error: unknown, handleMinorError: (error: string) => void) => {
  if (!error) {
    handleMinorError('Unknown Error');
    return;
  }
  const pathname = window.location.pathname;
  if (PATHS_NOT_REQUIRING_AUTHORIZATION.some(path => pathname.includes(path))) {
    return;
  }
  if (typeof error === 'string') {
    handleMinorError(error);
    return;
  }
  handleMinorError((error as AxiosError)?.message);
};
