import { number, object, ObjectSchema, string } from 'yup';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchLanguageFilterFormModel } from '@/views/profileSearch/components/filters/language/model/ProfileSearchLanguageFilterForm.model.ts';

export const useProfileSearchAddLanguageValidation =
  (): ObjectSchema<ProfileSearchLanguageFilterFormModel> => {
    const { t } = useTranslationWithPrefix('validation');

    return object().shape({
      language: object().shape({
        value: number().required(t('general.required')),
        label: string().required(t('general.required')),
      }),
    });
  };
