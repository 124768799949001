import { createTheme } from '@mui/material/styles';
import { components } from './utils/components.ts';
import { colors } from './utils/colors.ts';
import { typography } from './utils/typography.ts';
import { variables } from './utils/variables.ts';
import { KnowMeTypography, KnowMeTypographyOverrides } from '@/utils/typography.ts';
import { Theme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    colors: typeof colors;
    variables: typeof variables;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors: Partial<typeof colors>;
    variables: Partial<typeof variables>;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends KnowMeTypography {}

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends Partial<KnowMeTypography> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends KnowMeTypographyOverrides {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const knowMeTheme = createTheme({
  palette: {
    primary: {
      ...colors,
      main: '#fff',
    },
    warning: {
      main: colors.red,
    },
  },
  colors,
  components,
  typography,
  variables,
});
