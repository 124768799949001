import { number, object, ObjectSchema, string } from 'yup';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchSkillFilterFormModel } from '@/views/profileSearch/components/filters/skill/model/ProfileSearchSkillFilterForm.model.ts';

export const useProfileSearchAddSkillValidation =
  (): ObjectSchema<ProfileSearchSkillFilterFormModel> => {
    const { t } = useTranslationWithPrefix('validation');

    return object().shape({
      skill: object().shape({
        value: number().required(t('general.required')),
        label: string().required(t('general.required')),
      }),
      experienceInYears: number().required(t('general.required')),
      level: number().required(t('general.required')),
    });
  };
