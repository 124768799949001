import React, { useCallback, useMemo, useState } from 'react';
import { NotificationsContext, NotificationsContextProps } from './Notifications.context';
import { useGetNotifications } from '@/api/useGetNotifications.util';
import { NotificationDto, NotificationTypeDto } from '@/api/generated';

export const NotificationsContextProvider: React.FC = props => {
  const [menuBadges, setMenuBadges] = useState<Record<NotificationTypeDto, NotificationDto[]>>({
    [NotificationTypeDto.AccountsAwaitingApproval]: [],
  });

  const { getNotifications } = useGetNotifications();

  const updateNotifications = useCallback(async (types?: NotificationTypeDto[]) => {
    const response = await getNotifications(types);
    setMenuBadges(cur => {
      const copy = { ...cur };
      response.notifications.forEach(notification => {
        copy[notification.type].push(notification);
      });

      return copy;
    });
  }, []);

  const context: NotificationsContextProps = useMemo(() => {
    return {
      menuBadges,
      setMenuBadges,
      updateNotifications,
    };
  }, [menuBadges, updateNotifications]);

  return (
    <>
      <NotificationsContext.Provider value={context}>
        {props.children}
      </NotificationsContext.Provider>
    </>
  );
};
