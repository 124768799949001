import { RoleTypeDto, VacancyStatusEnumDto } from '@/api/generated';
import { RecruitmentBoardSearchToolbarTranslation } from '@/assets/locales/views/recruitmentBoard/searchToolbar/recruitmentBoardSearchToolbar.translation.en';

export const recruitmentBoardSearchToolbar: RecruitmentBoardSearchToolbarTranslation = {
  categories: {
    opportunityName: 'Name der Opportunity',
    opportunityOwner: 'Inhaber der Opportunity',
    requesters: 'Antragsteller',
    requestingCountry: 'Antragstellendes Land',
    status: 'Status',
  },
  statuses: {
    [VacancyStatusEnumDto.Archived]: 'Archiviert',
    [VacancyStatusEnumDto.Open]: 'Öffnen',
    [VacancyStatusEnumDto.Closed]: 'Schließen',
    [VacancyStatusEnumDto.OnHold]: 'In Wartestellung',
  },
  roles: {
    [RoleTypeDto.Admin]: 'Admin',
    [RoleTypeDto.Employee]: 'Arbeitsnehmer',
    [RoleTypeDto.ExternalPartner]: 'Externer Partner',
    [RoleTypeDto.GroupExternalPartner]: 'Gruppe Externer Partner',
    [RoleTypeDto.LocalResourceManager]: 'Lokaler Ressourcenmanager',
    [RoleTypeDto.PreSales]: 'Vorverkäufe',
    [RoleTypeDto.Requester]: 'Antragsteller',
  },
};
