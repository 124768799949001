import { UserProfileAwardsSectionTranslation } from '@/assets/locales/views/userProfile/sections/awardsSection/userProfile.awardsSection.translation.en';

export const userProfileAwardsSectionTranslation: UserProfileAwardsSectionTranslation = {
  headerTitle: 'Auszeichnungen',
  content: {
    emptyItemsLabel: 'Auszeichnungen',
    button: {
      edit: 'Bearbeiten',
    },
  },
  modal: {
    title: 'Auszeichnung hinzufügen',
  },
  form: {
    actions: 'Handlungen',
    title: 'Titel',
    associatedWith: 'Assoziiert mit',
    issuer: 'Emittent',
    issueDate: 'Ausgabedatum',
    description: 'Beschreibung',
    button: {
      save: 'Speichern',
    },
  },
};
