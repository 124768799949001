import { PathParams } from '@/utils/useOcdHistory.util.ts';

export enum LocalStorageParam {
  LAST_VISITED_LOCATION = 'LAST_VISITED_LOCATION',
}

export const setLocalStorage = (key: LocalStorageParam, value: PathParams): void => {
  if (typeof value === 'object') {
    localStorage.setItem(key, value.pathname);
    return;
  }
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: LocalStorageParam): string | null => {
  return localStorage.getItem(key);
};
