import { GeneralErrorTranslation } from '@/assets/locales/generalError/generalError.translation.en.ts';

export const generalErrorTranslation: GeneralErrorTranslation = {
  goBack: 'Revenir',
  logIn: 'Se connecter',
  title: "Oups ! Quelque chose s'est mal passé",
  notFound: {
    message: "Le site n'existe pas !",
  },
  forbidden: {
    message: "Vous n'êtes pas autorisé à accéder à ce site ou votre session a expiré.",
    subMessage:
      "Si vous pensez devoir être autorisé, demandez à votre responsable ou superviseur de vous accorder l'accès.",
  },
  notAuthorized: {
    message: 'Accès refusé',
    subMessage:
      "Vous n'avez pas les autorisations requises pour vous connecter au système KnowMe. Si vous pensez qu'il s'agit d'une erreur ou si vous devriez posséder ces autorisations, veuillez contacter Orange Cyberdefense pour obtenir de l'aide.",
  },
  waitingForApproval: {
    message: "Votre compte est en attente d'approbation",
    subMessage: "Nous vous informerons une fois qu'il sera approuvé.",
  },
};
