import { UserInitializeTranslation } from '@/assets/locales/userInitialize/userInitialize.translation.en.ts';

export const userInitialize: UserInitializeTranslation = {
  form: {
    buttons: {
      submit: 'Einreichen',
    },
    tooltips: {
      agreeToPrivacyPolicy:
        'Öffnen Sie den Link zur Datenschutzrichtlinie und klicken Sie auf das Kontrollkästchen, um das Formular einzureichen.',
    },
    fields: {
      company: 'Unternehmen',
      location: 'Ort',
      phone: 'Telefon',
      agreeToPrivacyPolicy1:
        'Wenn Sie dieses System betreten, stimmen Sie der Datenschutzerklärung von Orange Cyberdefense KnowMe zu, die Sie hier finden können:',
      agreeToPrivacyPolicy2: 'hier klicken',
      agreeToPrivacyPolicy3:
        'Bitte wenden Sie sich an Ihr lokales SPOC, wenn Sie Fragen dazu haben, wie Orange Cyberdefense den Datenschutz für das KnowMe-Portal behandelt.',
    },
    error: {
      initializingUserAccount:
        'Fehler beim Initialisieren des Benutzerkontos. Bitte versuchen Sie es später noch einmal.',
    },
  },
};
