import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  ldsDualRing: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ldsDualRingInner: {
    display: 'block',
    width: 92,
    height: 92,
    margin: 1,
    borderRadius: '50%',
    border: '5px solid #3f51b5',
    borderColor: '#3f51b5 transparent #3f51b5 transparent',
    animation: '$rotate 1.2s linear infinite',
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

export default useStyles;
