import {
  CompanyProfileTypeDto,
  RoleTypeDto,
  UserStatusDto,
  VacancyClosureReasonDto,
  VacancyProjectStatusDto,
  VacancyStatusEnumDto,
} from '@/api/generated';

type DictionaryTranslations = Record<string, Record<string, string>> & {
  projectStatus: Record<
    (typeof VacancyProjectStatusDto)[keyof typeof VacancyProjectStatusDto],
    string
  >;
  vacancyStatus: Record<(typeof VacancyStatusEnumDto)[keyof typeof VacancyStatusEnumDto], string>;
  employeeStatuses: Record<(typeof UserStatusDto)[keyof typeof UserStatusDto], string>;
  companyProfiles: Record<
    (typeof CompanyProfileTypeDto)[keyof typeof CompanyProfileTypeDto],
    string
  >;
  defaultRoles: Record<(typeof RoleTypeDto)[keyof typeof RoleTypeDto], string>;
  closureReasons: Record<VacancyClosureReasonDto, string>;
};

const dictionaryTranslations: DictionaryTranslations = {
  vacancyCandidateStatus: {
    NEW: 'vacancyCandidate.status.new',
    ASSIGNED: 'vacancyCandidate.status.assigned',
    CV_SCREENING: 'vacancyCandidate.status.cvScreening',
    INITIALLY_ACCEPTED: 'vacancyCandidate.status.initialAccept',
    REJECTED: 'vacancyCandidate.status.rejected',
    AWAITING_VERIFICATION: 'vacancyCandidate.status.awaitingVerification',
    ARRANGING_INTERVIEW: 'vacancyCandidate.status.arrangingInterview',
    INTERVIEW_SET: 'vacancyCandidate.status.interviewSet',
    EVALUATION: 'vacancyCandidate.status.evaluation',
    APPROVED: 'vacancyCandidate.status.approved',
    CONFIRMED: 'vacancyCandidate.status.confirmed',
    CONTRACTED: 'vacancyCandidate.status.contracted',
    CLOSED: 'vacancyCandidate.status.closed',
    REASSIGNED: 'vacancyCandidate.status.reassigned',
  },
  vacancyStatus: {
    [VacancyStatusEnumDto.Archived]: `vacancies.status.${[VacancyStatusEnumDto.Archived]}`,
    [VacancyStatusEnumDto.Open]: `vacancies.status.${[VacancyStatusEnumDto.Open]}`,
    [VacancyStatusEnumDto.Closed]: `vacancies.status.${[VacancyStatusEnumDto.Closed]}`,
    [VacancyStatusEnumDto.OnHold]: `vacancies.status.${[VacancyStatusEnumDto.OnHold]}`,
  },
  languageProficiences: {
    BASIC: 'profile.forms.language.basic',
    INTERMEDIATE: 'profile.forms.language.intermediate',
    ADVANCED: 'profile.forms.language.advanced',
    PROFESSIONAL: 'profile.forms.language.professional',
    BILINGUAL: 'profile.forms.language.bilingual',
  },
  contractTypes: {
    B2B: 'contractTypes.B2B',
    UOP: 'contractTypes.UOP',
    OUT: 'contractTypes.OUT',
    OTHER: 'contractTypes.OTHER',
    UOD: 'contractTypes.UOD',
    UOD0: 'contractTypes.UOD0',
    UOD50: 'contractTypes.UOD50',
    UOP50: 'contractTypes.UOP50',
    UZ: 'contractTypes.UZ',
    UZ0: 'contractTypes.UZ0',
    UZ20: 'contractTypes.UZ20',
    UZ50: 'contractTypes.UZ50',
    UOD20: 'contractTypes.UOD20',
    MC: 'contractTypes.MC',
  },
  priority: {
    LOW: 'vacancies.priority.low',
    NORMAL: 'vacancies.priority.normal',
    URGENT: 'vacancies.priority.urgent',
    CRITICAL: 'vacancies.priority.critical',
  },
  evaluationType: {
    MICRO_EVALUATION: 'employeesEvaluations.feedback',
    EVALUATION: 'employeesEvaluations.regularEvaluation',
  },
  salaryChangeRequestRateType: {
    HOURLY: 'dashboard.salaryChangeRequestModal.hourly',
    MONTHLY: 'dashboard.salaryChangeRequestModal.monthly',
  },
  salaryChangeRequestRateKind: {
    NET: 'dashboard.salaryChangeRequestModal.net',
    GROSS: 'dashboard.salaryChangeRequestModal.gross',
  },
  companyProfiles: {
    AFFILIATES: `companyDetails.companyProfile.${CompanyProfileTypeDto.Affiliates}`,
    EXTERNAL: `companyDetails.companyProfile.${CompanyProfileTypeDto.External}`,
    INTERNAL: `companyDetails.companyProfile.${CompanyProfileTypeDto.Internal}`,
  },
  employeeStatuses: {
    ACTIVE: `companyDetails.employeeStatus.${UserStatusDto.Active}`,
    INACTIVE: `companyDetails.employeeStatus.${UserStatusDto.Inactive}`,
    WAITING_FOR_APPROVAL: `companyDetails.employeeStatus.${UserStatusDto.WaitingForApproval}`,
  },
  projectStatus: {
    [VacancyProjectStatusDto.Active]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.Active,
    ]}`,
    [VacancyProjectStatusDto.Archived]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.Archived,
    ]}`,
    [VacancyProjectStatusDto.ClosedLost]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.ClosedLost,
    ]}`,
    [VacancyProjectStatusDto.ClosedWon]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.ClosedWon,
    ]}`,
    [VacancyProjectStatusDto.OnHold]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.OnHold,
    ]}`,
    [VacancyProjectStatusDto.NoSalesforceId]: `companyDetails.projectStatus.${[
      VacancyProjectStatusDto.NoSalesforceId,
    ]}`,
  },
  vacancyVisibility: {
    OPEN: 'vacancies.visible',
    CLOSED: 'vacancies.notVisible',
  },
  vacancyAttendance: {
    ON_SITE: 'vacancies.ON_SITE',
    OFF_SITE: 'vacancies.OFF_SITE',
    HYBRID: 'vacancies.HYBRID',
  },
  vacancyRequest: {
    PRE_SALES: 'vacancies.PRE_SALES',
    DELIVERY: 'vacancies.DELIVERY',
  },
  defaultRoles: {
    ADMIN: `defaultRoles.${RoleTypeDto.Admin}`,
    EMPLOYEE: `defaultRoles.${RoleTypeDto.Employee}`,
    EXTERNAL_PARTNER: `defaultRoles.${RoleTypeDto.ExternalPartner}`,
    GROUP_EXTERNAL_PARTNER: `defaultRoles.${RoleTypeDto.GroupExternalPartner}`,
    LOCAL_RESOURCE_MANAGER: `defaultRoles.${RoleTypeDto.LocalResourceManager}`,
    PRE_SALES: `defaultRoles.${RoleTypeDto.PreSales}`,
    REQUESTER: `defaultRoles.${RoleTypeDto.Requester}`,
  },
  closureReasons: {
    [VacancyClosureReasonDto.CandidateNotFound]: 'Candidates not found',
    [VacancyClosureReasonDto.CandidatesSigned]: 'Candidates signed',
    [VacancyClosureReasonDto.PriceTooHigh]: 'Price too high',
    [VacancyClosureReasonDto.ReasonUnknown]: 'Reason unknown',
  },
};
export default dictionaryTranslations;
