import React from 'react';

import { Box } from '@mui/material';

import logo from '@/assets/img/logo-knowme.png';
import { LogoStyles } from './logo.styles.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util';
import { RouteEnum } from '@/layouts/routes/Route.model';

export const Logo: React.FC = () => {
  const { push } = useOcdHistory();

  const handleClick = () => {
    push(RouteEnum.ROOT);
  };

  return (
    <Box sx={LogoStyles} onClick={handleClick}>
      <img src={logo} style={{ height: '100%' }} alt="smallLogo" />
    </Box>
  );
};
