import { number, object, ObjectSchema, string } from 'yup';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchCertificateFilterFormModel } from '@/views/profileSearch/components/filters/certificate/model/ProfileSearchCertificateFilterForm.model.ts';

export const useProfileSearchAddCertificateValidation =
  (): ObjectSchema<ProfileSearchCertificateFilterFormModel> => {
    const { t } = useTranslationWithPrefix('validation');

    return object().shape({
      certificate: object().shape({
        value: number().required(t('general.required')),
        label: string().required(t('general.required')),
      }),
    });
  };
