import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

type Props = {
  title?: string;
  button?: React.ReactNode;
  customTitle?: string;
};
const ViewHeader: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { title, button, customTitle } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
      <Grid item>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="f20Medium" sx={{ pl: 2 }}>
            {customTitle ?? t(`pageTitles.${title}`)}
          </Typography>
        </Box>
      </Grid>
      <Grid item>{button && <Box>{button}</Box>}</Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default ViewHeader;
