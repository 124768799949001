import {
  userProfileMainSectionTranslation,
  UserProfileMainSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/mainSection/userProfile.mainSection.translation.en';
import {
  UserProfileLanguagesSectionTranslation,
  userProfileLanguagesSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/languagesSection/userProfile.languagesSection.translation.en';
import {
  userProfileSkillsSectionTranslation,
  UserProfileSkillsSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.en';
import {
  userProfileProjectsSectionTranslation,
  UserProfileProjectsSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/projectsSection/userProfile.projectsSection.translation.en';
import {
  userProfileEducationsSectionTranslation,
  UserProfileEducationsSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/educationsSection/userProfile.educationsSection.translation.en';
import {
  userProfileCertificatesSectionTranslation,
  UserProfileCertificatesSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/certificatesSection/userProfile.certificatesSection.translation.en';
import {
  userProfileAwardsSectionTranslation,
  UserProfileAwardsSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/awardsSection/userProfile.awardsSection.translation.en.ts';
import {
  userProfileCoursesSectionTranslation,
  UserProfileCoursesSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/coursesSection/userProfile.coursesSection.translation.en';
import {
  userProfileExperiencesSectionTranslation,
  UserProfileExperiencesSectionTranslation,
} from '@/assets/locales/views/userProfile/sections/experiencesSection/userProfile.experiencesSection.translation.en';
import {
  editDeleteTranslation,
  EditDeleteTranslation,
} from '@/assets/locales/views/userProfile/editDelete.translation.en.ts';
import {
  userProfileSectionWrapperTranslation,
  UserProfileSectionWrapperTranslation,
} from './sections/sectionWrapper/userProfile.sectionWrapper.translation.en';
import {
  userProfileErrorsTranslation,
  UserProfileErrorsTranslation,
} from '@/assets/locales/views/userProfile/errors/userProfile.errors.translation.en.ts';
import {
  UserProfileEmptySectionTranslation,
  userProfileEmptySectionTranslation,
} from '@/assets/locales/views/userProfile/sections/emptySection/userProfile.emptySection.translation.en';
import {
  userProfileCareerPathSectionTranslation,
  UserProfileCareerPathSectionTranslation
} from "@/assets/locales/views/userProfile/sections/userProfile.growthPathSection.translation.en.ts";

export type UserProfileTranslation = {
  awardsSection: UserProfileAwardsSectionTranslation;
  careerPathSection: UserProfileCareerPathSectionTranslation;
  certificatesSection: UserProfileCertificatesSectionTranslation;
  coursesSection: UserProfileCoursesSectionTranslation;
  editDelete: EditDeleteTranslation;
  educationsSection: UserProfileEducationsSectionTranslation;
  emptySection: UserProfileEmptySectionTranslation;
  errors: UserProfileErrorsTranslation;
  experiencesSection: UserProfileExperiencesSectionTranslation;
  languagesSection: UserProfileLanguagesSectionTranslation;
  mainSection: UserProfileMainSectionTranslation;
  projectsSection: UserProfileProjectsSectionTranslation;
  sectionWrapper: UserProfileSectionWrapperTranslation;
  skillsSection: UserProfileSkillsSectionTranslation;
  tooltip: {
    customItem: string;
  };
};

export const userProfileTranslation: UserProfileTranslation = {
  awardsSection: userProfileAwardsSectionTranslation,
  careerPathSection: userProfileCareerPathSectionTranslation,
  certificatesSection: userProfileCertificatesSectionTranslation,
  coursesSection: userProfileCoursesSectionTranslation,
  editDelete: editDeleteTranslation,
  educationsSection: userProfileEducationsSectionTranslation,
  emptySection: userProfileEmptySectionTranslation,
  errors: userProfileErrorsTranslation,
  experiencesSection: userProfileExperiencesSectionTranslation,
  languagesSection: userProfileLanguagesSectionTranslation,
  mainSection: userProfileMainSectionTranslation,
  projectsSection: userProfileProjectsSectionTranslation,
  sectionWrapper: userProfileSectionWrapperTranslation,
  skillsSection: userProfileSkillsSectionTranslation,
  tooltip: {
    customItem: 'This item was created by a user and will not be included in the search module.',
  },
};
