import React from 'react';

export interface KnowMeTypography {
  f24Medium: React.CSSProperties;
  f20Medium: React.CSSProperties;
  f20Regular: React.CSSProperties;
  f16Medium: React.CSSProperties;
  f16Regular: React.CSSProperties;
  f14Medium: React.CSSProperties;
  f14Regular: React.CSSProperties;
  f12Medium: React.CSSProperties;
  f12Regular: React.CSSProperties;
  fontFamily: string;
  headerMedium: React.CSSProperties;
  headerRegular: React.CSSProperties;
  subHeaderLarge: React.CSSProperties;
  subHeaderMedium: React.CSSProperties;
  bodyText: React.CSSProperties;
  capitalLettersBold: React.CSSProperties;
  capitalLettersRegular: React.CSSProperties;
  weightBold: number;
  weightMedium: number;
  weightRegular: number;
}

export interface KnowMeTypographyOverrides {
  f24Medium: true;
  f20Medium: true;
  f20Regular: true;
  f16Medium: true;
  f16Regular: true;
  f14Medium: true;
  f14Regular: true;
  f12Medium: true;
  f12Regular: true;
  fontFamily: true;
  headerMedium: true;
  headerRegular: true;
  subHeaderLarge: true;
  subHeaderMedium: true;
  bodyText: true;
  capitalLettersBold: true;
  capitalLettersRegular: true;
  weightBold: true;
  weightMedium: true;
  weightRegular: true;
}

const f12Regular: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'left',
};

export const typography: KnowMeTypography = {
  // new typography
  f24Medium: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f20Medium: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f20Regular: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f16Medium: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f16Regular: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f14Medium: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f14Regular: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  f12Medium: {
    ...f12Regular,
    fontWeight: 600,
  },
  f12Regular,
  // new typography end
  fontFamily: ['Usual'].join(','),

  // Headers
  headerMedium: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.2px',
  },
  headerRegular: {
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: 1.5,
    letterSpacing: '0.2px',
  },
  subHeaderLarge: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.88,
    letterSpacing: '0.2px',
  },
  subHeaderMedium: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: '0.2px',
  },
  bodyText: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.83,
    letterSpacing: '0.2px',
  },
  capitalLettersBold: {
    textTransform: 'uppercase' as const,
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: '1px',
  },
  capitalLettersRegular: {
    textTransform: 'uppercase' as const,
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: 1.4,
    letterSpacing: '1px',
  },

  // Font Weight
  weightBold: 600,
  weightMedium: 500,
  weightRegular: 300,
} as const;
