import React from 'react';
import { NotificationTypeDto, RoleTypeDto } from '@/api/generated';
import { RouteEnum } from '../../../routes/Route.model';
import { ROUTES_ROLES } from '../../../routes/routesRoles.model';
import SearchIcon from '@/assets/icons/sideBar/SearchIcon.png';
import MyProfileIcon from '@/assets/icons/sideBar/MyProfileIcon.png';
import ProjectsListIcon from '@/assets/icons/sideBar/ProjectsListIcon.png';
import RequestResourceIcon from '@/assets/icons/sideBar/RequestResourceIcon.png';
import RequestTrackerIcon from '@/assets/icons/sideBar/RequestTrackerIcon.png';
import CandidatesIcon from '@/assets/icons/sideBar/CandidatesIcon.png';
import CompaniesIcon from '@/assets/icons/sideBar/CompaniesIcon.png';
import DictionariesIcon from '@/assets/icons/sideBar/DictionariesIcon.png';
import ReportsIcon from '@/assets/icons/sideBar/ReportsIcon.png';
import AiIcon from '@/assets/icons/ai.svg';

export type MenuItemType =
  | 'addVacancy'
  | 'aiSearch'
  | 'companies'
  | 'profileSearch'
  | 'projects'
  | 'recruitmentBoard'
  | 'recruitmentCandidates'
  | 'reports'
  | 'settingsDictionaries'
  | 'userProfile';

export interface MenuItemModel {
  id: MenuItemType;
  icon: React.JSX.Element;
  availableFor: RoleTypeDto[];
  route: RouteEnum;
  badgeType?: NotificationTypeDto;
  badgeFor?: RoleTypeDto[];
}

export const MENU_ITEMS: MenuItemModel[] = [
  {
    id: 'aiSearch',
    icon: <img style={{ width: '18px', height: '18px' }} src={AiIcon} />,
    availableFor: ROUTES_ROLES[RouteEnum.AI_SEARCH],
    route: RouteEnum.AI_SEARCH,
  },
  {
    id: 'userProfile',
    icon: <img src={MyProfileIcon} alt="MyProfileIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.USER_PROFILE],
    route: RouteEnum.USER_PROFILE,
  },
  {
    id: 'profileSearch',
    icon: <img src={SearchIcon} alt="Search" />,
    availableFor: ROUTES_ROLES[RouteEnum.PROFILE_SEARCH],
    route: RouteEnum.PROFILE_SEARCH,
  },
  {
    id: 'projects',
    icon: <img src={ProjectsListIcon} alt="ProjectsListIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.PROJECTS],
    route: RouteEnum.PROJECTS,
  },
  {
    id: 'addVacancy',
    icon: <img src={RequestResourceIcon} alt="RequestResourceIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.ADD_VACANCY],
    route: RouteEnum.ADD_VACANCY,
  },
  {
    id: 'recruitmentBoard',
    icon: <img src={RequestTrackerIcon} alt="RequestTrackerIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.RECRUITMENT_BOARD],
    route: RouteEnum.RECRUITMENT_BOARD,
  },
  {
    id: 'recruitmentCandidates',
    icon: <img src={CandidatesIcon} alt="CandidatesIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.CANDIDATES],
    route: RouteEnum.CANDIDATES,
  },
  {
    id: 'companies',
    icon: <img src={CompaniesIcon} alt="CompaniesIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.COMPANIES],
    route: RouteEnum.COMPANIES,
    badgeType: NotificationTypeDto.AccountsAwaitingApproval,
    badgeFor: [RoleTypeDto.Admin],
  },
  {
    id: 'settingsDictionaries',
    icon: <img src={DictionariesIcon} alt="DictionariesIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.DICTIONARIES],
    route: RouteEnum.DICTIONARIES,
  },
  {
    id: 'reports',
    icon: <img src={ReportsIcon} alt="ReportsIcon" />,
    availableFor: ROUTES_ROLES[RouteEnum.REPORTS],
    route: RouteEnum.REPORTS,
  },
];
