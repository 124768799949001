import { MenuTranslation } from '@/assets/locales/menu/menu.translation.en.ts';

export const menuTranslation: MenuTranslation = {
  addVacancy: 'Demander une ressource',
  aiSearch: 'AI search',
  companies: 'Entreprises',
  profileSearch: 'Rechercher des profils',
  projects: 'Liste des projets',
  recruitmentBoard: 'Suivi des demandes',
  recruitmentCandidates: 'Candidats',
  reports: 'Rapports',
  settingsDictionaries: 'Dictionnaires',
  userProfile: 'Mon profil',
};
