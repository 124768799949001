/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CandidateCommentDto } from '../models';
// @ts-ignore
import type { ChangeCandidateStatusDto } from '../models';
// @ts-ignore
import type { LastCandidateContactDateDto } from '../models';
// @ts-ignore
import type { UpdateVacancyCandidateDto } from '../models';
// @ts-ignore
import type { UpdateVacancyCandidateStateDto } from '../models';
// @ts-ignore
import type { VacancyCandidateDetailsDto } from '../models';
// @ts-ignore
import type { VacancyCandidateTimelineDto } from '../models';
// @ts-ignore
import type { VacancyNamesDto } from '../models';
/**
 * VacancyCandidateApi - axios parameter creator
 * @export
 */
export const VacancyCandidateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change vacancy candidate active state
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateStateDto} updateVacancyCandidateStateDto Update vacancy candidate state request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeActiveState: async (candidateId: string, updateVacancyCandidateStateDto: UpdateVacancyCandidateStateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('changeActiveState', 'candidateId', candidateId)
            // verify required parameter 'updateVacancyCandidateStateDto' is not null or undefined
            assertParamExists('changeActiveState', 'updateVacancyCandidateStateDto', updateVacancyCandidateStateDto)
            const localVarPath = `/api/v1/recruitment/vacancies/candidates/{candidateId}/activate`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVacancyCandidateStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ocd vacancy candidate status
         * @param {string} candidateId 
         * @param {ChangeCandidateStatusDto} changeCandidateStatusDto Change ocd candidate status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVacancyCandidateStatus: async (candidateId: string, changeCandidateStatusDto: ChangeCandidateStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('changeVacancyCandidateStatus', 'candidateId', candidateId)
            // verify required parameter 'changeCandidateStatusDto' is not null or undefined
            assertParamExists('changeVacancyCandidateStatus', 'changeCandidateStatusDto', changeCandidateStatusDto)
            const localVarPath = `/api/v1/recruitment/vacancies/candidates/{candidateId}/status`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCandidateStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create vacancy candidate comment
         * @param {string} candidateId 
         * @param {CandidateCommentDto} candidateCommentDto Create candidate comment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateComment: async (candidateId: string, candidateCommentDto: CandidateCommentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('createCandidateComment', 'candidateId', candidateId)
            // verify required parameter 'candidateCommentDto' is not null or undefined
            assertParamExists('createCandidateComment', 'candidateCommentDto', candidateCommentDto)
            const localVarPath = `/api/v1/recruitment/candidates/{candidateId}/comments`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(candidateCommentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get ERA vacancy candidate details
         * @param {string} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateDetails: async (candidateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('getCandidateDetails', 'candidateId', candidateId)
            const localVarPath = `/api/v1/era/recruitment/vacancies/candidates/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vacancy candidate timeline
         * @param {string} candidateId 
         * @param {string} acceptLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyCandidateTimeline: async (candidateId: string, acceptLanguage: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('getVacancyCandidateTimeline', 'candidateId', candidateId)
            // verify required parameter 'acceptLanguage' is not null or undefined
            assertParamExists('getVacancyCandidateTimeline', 'acceptLanguage', acceptLanguage)
            const localVarPath = `/api/v1/recruitment/vacancies/candidates/{candidateId}/timeline`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acceptLanguage != null) {
                localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vacancy names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyNames: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recruitment/vacancies/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Provide Vacancy Candidate last contact date after HR verification in BS database
         * @summary Update vacancy candidate lastContactDate
         * @param {string} candidateId 
         * @param {LastCandidateContactDateDto} lastCandidateContactDateDto Provide last contact date for Vacancy Candidate after HR verification in BS database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastContactDateForCandidate: async (candidateId: string, lastCandidateContactDateDto: LastCandidateContactDateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('lastContactDateForCandidate', 'candidateId', candidateId)
            // verify required parameter 'lastCandidateContactDateDto' is not null or undefined
            assertParamExists('lastContactDateForCandidate', 'lastCandidateContactDateDto', lastCandidateContactDateDto)
            const localVarPath = `/api/v1/recruitment/vacancies/candidates/{candidateId}/lastContactDate`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lastCandidateContactDateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Vacancy Candidate data
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateDto} updateVacancyCandidateDto Update ocd vacancy candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyCandidate: async (candidateId: string, updateVacancyCandidateDto: UpdateVacancyCandidateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('updateVacancyCandidate', 'candidateId', candidateId)
            // verify required parameter 'updateVacancyCandidateDto' is not null or undefined
            assertParamExists('updateVacancyCandidate', 'updateVacancyCandidateDto', updateVacancyCandidateDto)
            const localVarPath = `/api/v1/era/recruitment/vacancies/candidates/{candidateId}`
                .replace(`{${"candidateId"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVacancyCandidateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyCandidateApi - functional programming interface
 * @export
 */
export const VacancyCandidateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyCandidateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change vacancy candidate active state
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateStateDto} updateVacancyCandidateStateDto Update vacancy candidate state request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeActiveState(candidateId: string, updateVacancyCandidateStateDto: UpdateVacancyCandidateStateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeActiveState(candidateId, updateVacancyCandidateStateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.changeActiveState']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update ocd vacancy candidate status
         * @param {string} candidateId 
         * @param {ChangeCandidateStatusDto} changeCandidateStatusDto Change ocd candidate status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeVacancyCandidateStatus(candidateId: string, changeCandidateStatusDto: ChangeCandidateStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeVacancyCandidateStatus(candidateId, changeCandidateStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.changeVacancyCandidateStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create vacancy candidate comment
         * @param {string} candidateId 
         * @param {CandidateCommentDto} candidateCommentDto Create candidate comment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateComment(candidateId: string, candidateCommentDto: CandidateCommentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateComment(candidateId, candidateCommentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.createCandidateComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get ERA vacancy candidate details
         * @param {string} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidateDetails(candidateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyCandidateDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidateDetails(candidateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.getCandidateDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get vacancy candidate timeline
         * @param {string} candidateId 
         * @param {string} acceptLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyCandidateTimeline(candidateId: string, acceptLanguage: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyCandidateTimelineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyCandidateTimeline(candidateId, acceptLanguage, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.getVacancyCandidateTimeline']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get vacancy names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyNames(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyNamesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyNames(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.getVacancyNames']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Provide Vacancy Candidate last contact date after HR verification in BS database
         * @summary Update vacancy candidate lastContactDate
         * @param {string} candidateId 
         * @param {LastCandidateContactDateDto} lastCandidateContactDateDto Provide last contact date for Vacancy Candidate after HR verification in BS database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lastContactDateForCandidate(candidateId: string, lastCandidateContactDateDto: LastCandidateContactDateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lastContactDateForCandidate(candidateId, lastCandidateContactDateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.lastContactDateForCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Vacancy Candidate data
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateDto} updateVacancyCandidateDto Update ocd vacancy candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacancyCandidate(candidateId: string, updateVacancyCandidateDto: UpdateVacancyCandidateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacancyCandidate(candidateId, updateVacancyCandidateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyCandidateApi.updateVacancyCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VacancyCandidateApi - factory interface
 * @export
 */
export const VacancyCandidateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyCandidateApiFp(configuration)
    return {
        /**
         * 
         * @summary Change vacancy candidate active state
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateStateDto} updateVacancyCandidateStateDto Update vacancy candidate state request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeActiveState(candidateId: string, updateVacancyCandidateStateDto: UpdateVacancyCandidateStateDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeActiveState(candidateId, updateVacancyCandidateStateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ocd vacancy candidate status
         * @param {string} candidateId 
         * @param {ChangeCandidateStatusDto} changeCandidateStatusDto Change ocd candidate status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeVacancyCandidateStatus(candidateId: string, changeCandidateStatusDto: ChangeCandidateStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeVacancyCandidateStatus(candidateId, changeCandidateStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create vacancy candidate comment
         * @param {string} candidateId 
         * @param {CandidateCommentDto} candidateCommentDto Create candidate comment request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateComment(candidateId: string, candidateCommentDto: CandidateCommentDto, options?: any): AxiosPromise<void> {
            return localVarFp.createCandidateComment(candidateId, candidateCommentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get ERA vacancy candidate details
         * @param {string} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateDetails(candidateId: string, options?: any): AxiosPromise<VacancyCandidateDetailsDto> {
            return localVarFp.getCandidateDetails(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vacancy candidate timeline
         * @param {string} candidateId 
         * @param {string} acceptLanguage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyCandidateTimeline(candidateId: string, acceptLanguage: string, options?: any): AxiosPromise<VacancyCandidateTimelineDto> {
            return localVarFp.getVacancyCandidateTimeline(candidateId, acceptLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vacancy names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyNames(options?: any): AxiosPromise<VacancyNamesDto> {
            return localVarFp.getVacancyNames(options).then((request) => request(axios, basePath));
        },
        /**
         * Provide Vacancy Candidate last contact date after HR verification in BS database
         * @summary Update vacancy candidate lastContactDate
         * @param {string} candidateId 
         * @param {LastCandidateContactDateDto} lastCandidateContactDateDto Provide last contact date for Vacancy Candidate after HR verification in BS database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lastContactDateForCandidate(candidateId: string, lastCandidateContactDateDto: LastCandidateContactDateDto, options?: any): AxiosPromise<void> {
            return localVarFp.lastContactDateForCandidate(candidateId, lastCandidateContactDateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Vacancy Candidate data
         * @param {string} candidateId 
         * @param {UpdateVacancyCandidateDto} updateVacancyCandidateDto Update ocd vacancy candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyCandidate(candidateId: string, updateVacancyCandidateDto: UpdateVacancyCandidateDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacancyCandidate(candidateId, updateVacancyCandidateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyCandidateApi - interface
 * @export
 * @interface VacancyCandidateApi
 */
export interface VacancyCandidateApiInterface {
    /**
     * 
     * @summary Change vacancy candidate active state
     * @param {string} candidateId 
     * @param {UpdateVacancyCandidateStateDto} updateVacancyCandidateStateDto Update vacancy candidate state request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    changeActiveState(candidateId: string, updateVacancyCandidateStateDto: UpdateVacancyCandidateStateDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Update ocd vacancy candidate status
     * @param {string} candidateId 
     * @param {ChangeCandidateStatusDto} changeCandidateStatusDto Change ocd candidate status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    changeVacancyCandidateStatus(candidateId: string, changeCandidateStatusDto: ChangeCandidateStatusDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Create vacancy candidate comment
     * @param {string} candidateId 
     * @param {CandidateCommentDto} candidateCommentDto Create candidate comment request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    createCandidateComment(candidateId: string, candidateCommentDto: CandidateCommentDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get ERA vacancy candidate details
     * @param {string} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    getCandidateDetails(candidateId: string, options?: RawAxiosRequestConfig): AxiosPromise<VacancyCandidateDetailsDto>;

    /**
     * 
     * @summary Get vacancy candidate timeline
     * @param {string} candidateId 
     * @param {string} acceptLanguage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    getVacancyCandidateTimeline(candidateId: string, acceptLanguage: string, options?: RawAxiosRequestConfig): AxiosPromise<VacancyCandidateTimelineDto>;

    /**
     * 
     * @summary Get vacancy names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    getVacancyNames(options?: RawAxiosRequestConfig): AxiosPromise<VacancyNamesDto>;

    /**
     * Provide Vacancy Candidate last contact date after HR verification in BS database
     * @summary Update vacancy candidate lastContactDate
     * @param {string} candidateId 
     * @param {LastCandidateContactDateDto} lastCandidateContactDateDto Provide last contact date for Vacancy Candidate after HR verification in BS database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    lastContactDateForCandidate(candidateId: string, lastCandidateContactDateDto: LastCandidateContactDateDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Update Vacancy Candidate data
     * @param {string} candidateId 
     * @param {UpdateVacancyCandidateDto} updateVacancyCandidateDto Update ocd vacancy candidate request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApiInterface
     */
    updateVacancyCandidate(candidateId: string, updateVacancyCandidateDto: UpdateVacancyCandidateDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VacancyCandidateApi - object-oriented interface
 * @export
 * @class VacancyCandidateApi
 * @extends {BaseAPI}
 */
export class VacancyCandidateApi extends BaseAPI implements VacancyCandidateApiInterface {
    /**
     * 
     * @summary Change vacancy candidate active state
     * @param {string} candidateId 
     * @param {UpdateVacancyCandidateStateDto} updateVacancyCandidateStateDto Update vacancy candidate state request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public changeActiveState(candidateId: string, updateVacancyCandidateStateDto: UpdateVacancyCandidateStateDto, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).changeActiveState(candidateId, updateVacancyCandidateStateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ocd vacancy candidate status
     * @param {string} candidateId 
     * @param {ChangeCandidateStatusDto} changeCandidateStatusDto Change ocd candidate status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public changeVacancyCandidateStatus(candidateId: string, changeCandidateStatusDto: ChangeCandidateStatusDto, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).changeVacancyCandidateStatus(candidateId, changeCandidateStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create vacancy candidate comment
     * @param {string} candidateId 
     * @param {CandidateCommentDto} candidateCommentDto Create candidate comment request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public createCandidateComment(candidateId: string, candidateCommentDto: CandidateCommentDto, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).createCandidateComment(candidateId, candidateCommentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get ERA vacancy candidate details
     * @param {string} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public getCandidateDetails(candidateId: string, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).getCandidateDetails(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vacancy candidate timeline
     * @param {string} candidateId 
     * @param {string} acceptLanguage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public getVacancyCandidateTimeline(candidateId: string, acceptLanguage: string, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).getVacancyCandidateTimeline(candidateId, acceptLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vacancy names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public getVacancyNames(options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).getVacancyNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Provide Vacancy Candidate last contact date after HR verification in BS database
     * @summary Update vacancy candidate lastContactDate
     * @param {string} candidateId 
     * @param {LastCandidateContactDateDto} lastCandidateContactDateDto Provide last contact date for Vacancy Candidate after HR verification in BS database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public lastContactDateForCandidate(candidateId: string, lastCandidateContactDateDto: LastCandidateContactDateDto, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).lastContactDateForCandidate(candidateId, lastCandidateContactDateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Vacancy Candidate data
     * @param {string} candidateId 
     * @param {UpdateVacancyCandidateDto} updateVacancyCandidateDto Update ocd vacancy candidate request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyCandidateApi
     */
    public updateVacancyCandidate(candidateId: string, updateVacancyCandidateDto: UpdateVacancyCandidateDto, options?: RawAxiosRequestConfig) {
        return VacancyCandidateApiFp(this.configuration).updateVacancyCandidate(candidateId, updateVacancyCandidateDto, options).then((request) => request(this.axios, this.basePath));
    }
}

