import { ProfileTranslation } from '@/assets/locales/profile/profile.translation.en';

export const profileTranslation: ProfileTranslation = {
  profileSkills: {
    addTechnology: 'Veuillez indiquer le nom de la compétence',
  },
  profileProjects: {
    project: 'Projet',
    client: 'Client',
  },
  skills: 'Compétences et Technologies',
  onboarding: 'Intégration',
  readMore: 'Lire la suite',
  aboutMe: 'À propos de moi',
  linkedIn: 'Profil LinkedIn',
  edit: 'Modifier',
  delete: 'Supprimer',
  set: 'Définir',
  aboutMeDescription: 'Quelques mots sur vous-même',
  department: 'Département',
  supervisor: 'Superviseur',
  accomplishments: 'Réalisations',
  additionalInformation: 'Informations supplémentaires',
  avatar: 'Photo de profil',
  avatarDescription: 'Ajouter et modifier la photo de profil',
  avatarInfo:
    'Vous ne voyez pas votre photo ? Montrez votre meilleur côté - complétez votre profil à 95 % pour changer votre avatar.',
  awards: 'Honneurs et récompenses',
  awardsDescription: 'Montrez combien vous êtes apprécié',
  basicInfo: 'Informations de base',
  certificates: 'Certificats',
  contact: 'Contact',
  copied: 'Lien copié dans le presse-papiers',
  courses: 'Cours',
  coursesDescription: 'Ajoutez des cours en cours ou terminés',
  downloadFiles: 'Télécharger les fichiers',
  download: 'Télécharger',
  editProfile: 'Modifier le profil',
  education: 'Formation',
  entryIncomplete: "L'entrée doit être complétée.",
  experiences: 'Expérience professionnelle',
  experience: 'Expérience professionnelle',
  forms: {
    dialog: {
      areYouSure: 'Êtes-vous sûr de vouloir supprimer cette entrée ?',
      warning: 'Une fois approuvé, il ne sera pas possible de restaurer ces données !',
    },
    availability: {
      title: "Définir la disponibilité de l'employé",
      date: 'Date de début',
      '1month': '1 mois',
      months: 'mois',
      months2: 'mois',
      indefinite: 'Non précisé',
      note: 'Informations supplémentaires',
      period: 'Période de disponibilité',
      available: 'Employé disponible',
      changePosition: 'Changer le poste souhaité',
      departmentOnly: 'Seulement mon département',
      positionTooltip: "Vérifiez si l'employé souhaite changer de poste.",
      tooltip:
        "Cochez la case et remplissez le formulaire pour informer les autres dans l'entreprise que votre employé pourrait être disponible pour leurs projets. N'oubliez pas de mettre à jour cette case lorsque l'employé est affecté.",
      sendNotification: 'Envoyer une notification',
    },
    avatar: {
      zoomSlider: 'Zoom',
      header: 'Photo de profil',
      buttonAdd: 'Changer ou ajouter une photo',
      buttonDelete: 'Supprimer la photo',
    },
    award: {
      addAward: 'Ajouter une récompense',
      associatedWith: 'Associé à',
      header: 'Honneurs et récompenses',
      issueDate: 'Date de délivrance',
      issuer: 'Émetteur',
      title: 'Titre',
    },
    basicInfo: {
      location: 'Lieu',
      firstName: 'Prénom',
      header: 'Modifier les informations de base',
      lastName: 'Nom',
      mobile: 'Numéro de téléphone',
      photoError: 'Mauvais format de fichier ! Téléchargez le fichier JPG ou PNG.',
      position: 'Poste',
    },
    buttonDelete: 'Supprimer',
    buttonSave: 'Enregistrer',
    buttonSaveAndSend: 'Enregistrer et envoyer',
    buttonSend: 'Envoyer',
    buttonConfirm: 'Confirmer',
    buttonEnd: 'Fermer',
    buttonCancel: 'Annuler',
    buttonClose: 'Fermer',
    buttonNext: 'Suivant',
    buttonEdit: 'Modifier',
    certificate: {
      addCertificate: 'Ajouter un certificat',
      create: 'Créer :',
      credentialId: 'ID de la certification',
      credentialUrl: 'URL de la certification',
      expirationDate: "Date d'expiration",
      header: 'Certification',
      issued: 'Émis',
      issueDate: "Date d'émission",
      name: 'Nom',
      noData: 'Aucune donnée disponible',
      nonExpiring: "Cette certification n'expire pas",
      organization: 'Organisation émettrice',
      validIndefinitely: 'Valable indéfiniment',
      validUntil: "Valable jusqu'au",
      viewCredential: "Voir l'ID de la certification",
    },
    course: {
      addCourse: 'Ajouter un cours',
      courseLink: 'Lien du cours',
      date: 'Date de fin du cours',
      header: 'Cours',
      name: 'Nom du cours',
      organizer: 'Organisateur',
      url: 'URL du cours',
    },
    description: 'Description',
    dropzone: 'Cliquez ou faites glisser le fichier ici...',
    dropzoneFormatError: 'Format de fichier incorrect !',
    dropzoneSizeError: 'Le fichier téléchargé est trop volumineux !',
    dropzoneFile: 'Fichier ajouté :',
    education: {
      addEducation: 'Ajouter une formation',
      activities: 'Activités et associations',
      degree: 'Diplôme',
      endDate: 'Date de fin',
      fieldOfStudy: "Domaine d'étude",
      header: 'Formation',
      school: 'École',
      startDate: 'Date de début',
      stillStudying: "Je suis encore en cours d'étude",
    },
    experience: {
      addExperience: 'Ajouter une expérience',
      company: 'Entreprise',
      currentPosition: 'Je travaille actuellement dans ce rôle',
      endDate: 'Date de fin',
      header: 'Expérience professionnelle',
      location: 'Lieu',
      position: 'Poste',
      startDate: 'Date de début',
    },
    evaluation: {
      instruction:
        "Sélectionnez les options appropriées ci-dessous si vous souhaitez déléguer l'évaluation ou si vous ne souhaitez pas que l'employé s'auto-évalue.",
      title: "Vous commencez le processus d'évaluation",
      delegate: "Voulez-vous déléguer l'évaluation ?",
      selfEvaluation: "Souhaitez-vous que l'employé {{name}} s'auto-évalue ?",
      yes: 'Oui',
      no: 'Non',
    },
    formError: 'Ce champ est requis',
    language: {
      advanced: 'Avancé',
      basic: 'Basique',
      bilingual: 'Langue maternelle ou bilingue',
      header: 'Ajouter une langue',
      intermediate: 'Intermédiaire',
      language: 'Langue',
      professional: 'Professionnel',
      proficiency: 'Niveau de compétence',
    },
    pdfFile: 'Fichier PDF',
    project: {
      associatedWith: 'Associé à',
      addProject: 'Ajouter un projet',
      client: 'Client',
      endDate: 'Date de fin',
      header: 'Projet',
      name: 'Nom du projet',
      ongoing: 'Je travaille actuellement sur ce projet',
      projectLink: 'Lien du projet',
      role: 'Rôle',
      startDate: 'Date de début',
      urlAddress: 'URL du projet',
    },
    recommendation: {
      author: 'Auteur',
      description: 'Contenu',
      header: 'Recommandation',
      recommendationLink: 'Lien de la recommandation',
      viewOriginal: 'Voir la recommandation originale',
    },
    salary: {
      title: 'Demande de modification du taux',
      employee: 'Employé',
      type: 'Type de contrat',
      newContractType: 'Nouveau type de contrat',
      date: 'Date de début',
      rate: 'Taux',
      companies: 'Clients',
      noticePeriod: 'Période de préavis',
      '1month': '1 mois',
      months: 'mois',
      showHistory: "Afficher l'historique",
      monthly: 'Mensuel',
      hourly: 'Horaire',
      rateKind: 'Type de taux',
      rateType: 'Type de taux',
      currency: 'Devise',
      indefinite: 'Période indéfinie',
      unknown: 'Inconnu',
      position: 'Poste',
      changePosition: 'Changer de poste',
      changeNoticePeriod: 'Changer la période de préavis',
      changeCustomers: 'Changer la liste des clients',
      changeContractType: 'Changer le type de contrat',
      requestTooltip: 'La demande a expiré, changez la date ou rejetez la demande.',
    },
    skill: {
      add: 'Sélectionnez une compétence ou ajoutez-en une nouvelle',
      addAnother: 'Ajouter une autre compétence',
      buttonPropose: 'Proposer une compétence',
      endorsementBy: 'Endossé par',
      footer1: 'Vous ne voyez pas la compétence',
      footer2: 'a-t-il ?',
      header: 'Ajouter une compétence',
      editHeader: 'Modifier une compétence',
      people: 'personnes',
      people2: 'personnes',
      person: 'personne',
      proposeHeader: 'Proposer des compétences',
      proposed: "Compétences proposées par d'autres utilisateurs :",
      proposedBy: 'Proposé par :',
      instruction:
        "Si vous souhaitez ajouter une compétence qui n'est pas dans les suggestions, confirmez en appuyant sur Entrée.",
      search: 'Rechercher...',
      skills: 'Compétences',
      rate: {
        '1': 'Débutant',
        '2': 'Débutant avancé',
        '3': 'Compétent',
        '4': 'Expérimenté',
        '5': 'Expert',
      },
      description: {
        '1a': "Vous avez peu ou pas d'expérience avec la technologie ou la compétence",
        '1b': "Vous avez besoin d'instructions claires pour effectuer une tâche",
        '1c': "Vous n'avez pas encore une compréhension intuitive de la compétence / technologie",
        '2a': 'Vous avez une certaine expérience avec la compétence ou la technologie',
        '2b': 'Vous êtes capable de résoudre des problèmes de base rencontrés',
        '2c': "Vous avez encore besoin d'un soutien significatif de la part de collègues plus expérimentés",
        '3a': 'Vous avez une certaine expérience directe - vous avez utilisé la technologie ou la compétence dans le passé',
        '3b': "Vous êtes capable d'effectuer des tâches de base de manière autonome",
        '3c': "Vous avez besoin d'aide de temps en temps",
        '4a': "Vous êtes capable et expérimenté dans l'utilisation de la technologie / compétence",
        '4b': 'Vous démontrez une maîtrise de la compétence / technologie',
        '4c': 'Vous êtes capable de travailler de manière autonome',
        '5a': "Vous êtes entièrement capable et expérimenté dans l'utilisation de la technologie / compétence pour résoudre des problèmes pratiques",
        '5b': "Vous n'avez besoin d'aucune assistance pour accomplir des tâches, c'est plutôt vous qui êtes sollicité pour aider",
        '5c': 'Vous démontrez la capacité à diriger et former les autres',
        '5d': 'Vous êtes reconnu par les autres comme un expert en la matière',
      },
    },
    switchLabel: 'Afficher le contenu en polonais',
  },
  generateCV: 'Générer le CV',
  askForFeedback: 'Demander des retours',
  languages: 'Langues',
  languagesDescription: 'Informations sur les langues que vous parlez',
  managersNote: 'Notes des managers',
  present: 'présent',
  projects: 'Projets',
  publications: 'Publications',
  skillsDescription: 'Mettez en valeur vos forces',
  viewFile: 'Voir le document joint',
  viewLink: 'Voir le lien fourni',
  showAllButton: 'Montrer tout',
  evaluations: {
    type: "Type d'évaluation",
    date: "Date de l'évaluation",
  },
};
