import { UserProfileCoursesSectionTranslation } from '@/assets/locales/views/userProfile/sections/coursesSection/userProfile.coursesSection.translation.en.ts';

export const userProfileCoursesSectionTranslation: UserProfileCoursesSectionTranslation = {
  headerTitle: 'Cours',
  content: {
    emptyItemsLabel: 'courses',
    button: {
      edit: 'Modifier',
    },
  },
  modal: {
    title: 'Ajouter un cours',
  },
  form: {
    actions: 'Actions',
    name: 'Nom',
    date: 'Date de fin',
    description: 'Description',
    organizer: 'Organisateur',
    url: 'Url',
    button: {
      save: 'Enregistrer',
    },
  },
};
