export interface SignPrivacyPolicyTranslation {
  form: {
    buttons: {
      submit: string;
    };
    tooltips: {
      agreeToPrivacyPolicy: string;
    };
    fields: {
      agreeToPrivacyPolicy1: string;
      agreeToPrivacyPolicy2: string;
      agreeToPrivacyPolicy3: string;
    };
    error: {
      signingPrivacyPolicy: string;
    };
  };
}

export const signPrivacyPolicyTranslation: SignPrivacyPolicyTranslation = {
  form: {
    buttons: {
      submit: 'Submit',
    },
    tooltips: {
      agreeToPrivacyPolicy: 'Please open the privacy policy link and click the checkbox to submit the form',
    },
    fields: {
      agreeToPrivacyPolicy1: 'By entering this system, you agree to the Orange Cyberdefense KnowMe privacy policy which can be found here: ',
      agreeToPrivacyPolicy2: 'click here',
      agreeToPrivacyPolicy3: 'Please contact your local SPOC should you have any questions on how Orange Cyberdefense handles data privacy for the KnowMe portal',
    },
    error: {
      signingPrivacyPolicy: 'Error while signing the privacy policy. Please try again later.',
    },
  },
};
