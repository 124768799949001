import { UserProfileEducationsSectionTranslation } from '@/assets/locales/views/userProfile/sections/educationsSection/userProfile.educationsSection.translation.en.ts';

export const userProfileEducationsSectionTranslation: UserProfileEducationsSectionTranslation = {
  headerTitle: 'Éducation',
  content: {
    emptyItemsLabel: "entrées d'éducation",
    button: {
      edit: 'Modifier',
    },
  },
  modal: {
    title: 'Ajouter une éducation',
  },
  form: {
    actions: 'Actions',
    school: 'École',
    degree: 'Diplôme',
    fieldOfStudy: "Domaine d'étude",
    startDate: 'Date de début',
    endDate: 'Date de fin',
    stillStudying: 'Je suis encore en études',
    activities: 'Activités',
    description: 'Description',
    button: {
      save: 'Enregistrer',
    },
    present: 'Encore en études',
  },
};
