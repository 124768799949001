import React, { useMemo, useState } from 'react';
import { PageContext, PageContextProps, PageDetailsModel } from './Page.context';

export const PageContextProvider: React.FC = props => {
  const [pageDetails, setPageDetails] = useState<PageDetailsModel>({ title: 'knowMe' });
  const context: PageContextProps = useMemo(() => {
    return {
      pageDetails,
      setPageDetails,
    };
  }, [pageDetails]);

  return (
    <>
      <PageContext.Provider value={context}>{props.children}</PageContext.Provider>
    </>
  );
};
