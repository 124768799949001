import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useGetQueryClient } from '@/config/useGetQueryClient.util.ts';

export const QueryClientWrapper: React.FC = props => {
  const queryClient = useGetQueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </>
  );
};
