import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { isAuthorized } from '../authorization';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix';
import { MENU_ITEMS, MenuItemModel } from './model/SideBarMenuItems.model';
import { RouteEnum } from '../../routes/Route.model';
import { useOcdHistory } from '@/utils/useOcdHistory.util';
import { useNotificationsContext } from '@/contexts/notifications/Notifications.context';
import {
  SideBarListItemBadgeStyles,
  SideBarListItemButtonStyles,
} from '@/layouts/private/sidebar/SideBar.styles.ts';
import ErrorIcon from '@mui/icons-material/Error';
import { usePageContext } from '@/contexts/pageContext/Page.context.ts';
import { isPrivacyPolicySigningRequired } from '@/views/signPrivacyPolicy/util/isPrivacyPolicySigningRequired';

const SideBarMenu: React.FC = () => {
  const { value } = useUserContext();
  const { role } = value;

  return (
    <List sx={{ mt: 6 }}>
      {MENU_ITEMS.filter(({ availableFor }) =>
        availableFor.some(roleItem => isAuthorized(roleItem, role)),
      ).map(menuItem => (
        <ListItemContainer key={menuItem.id} data={menuItem} />
      ))}
    </List>
  );
};

interface ListItemContainerProps {
  data: MenuItemModel;
}

const ListItemContainer: React.FC<ListItemContainerProps> = ({ data }) => {
  const { pathname } = useLocation();
  const { menuBadges } = useNotificationsContext();
  const isSelected = !!matchPath(pathname, data.route);
  const { value: { initializationDone, privacyPolicyStatus } } = useUserContext();
  const privacyPolicySigningRequired = isPrivacyPolicySigningRequired(
    privacyPolicyStatus,
  );
  const isMenuDisabled = !initializationDone || privacyPolicySigningRequired;

  const badgesCount = useMemo(() => {
    if (!data.badgeType) {
      return 0;
    }

    return menuBadges[data.badgeType]?.length ?? 0;
  }, [data, menuBadges]);

  return (
    <ListItemButton
      id={data.id}
      component={'div'}
      disableGutters
      dense={false}
      selected={isSelected}
      sx={SideBarListItemButtonStyles}
      disabled={isMenuDisabled}
    >
      <OcdRouterLink to={data}>
        <MenuItemContent data={data} badgesCount={badgesCount} />
      </OcdRouterLink>
    </ListItemButton>
  );
};

interface MenuItemContentProps {
  data: MenuItemModel;
  badgesCount: number;
}

const MenuItemContent: React.FC<MenuItemContentProps> = ({ data, badgesCount }) => {
  const { t } = useTranslationWithPrefix('menu');
  const { value } = useUserContext();
  const { role } = value;

  const isBadgeVisible = data.badgeFor === undefined || isAuthorized(data.badgeFor, role);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
      <ListItemIcon sx={{ minWidth: '5px', pr: 4 }}>{data.icon}</ListItemIcon>
      <ListItemText primary={`${t(data.id)}`} />
      {isBadgeVisible && badgesCount > 0 && (
        <ErrorIcon color="warning" sx={SideBarListItemBadgeStyles} />
      )}
    </Box>
  );
};

interface OcdRouterLinkProps {
  to: MenuItemModel;
  className?: string;
}

const OcdRouterLink: React.FC<OcdRouterLinkProps> = props => {
  const { to, className, children } = props;
  const { push } = useOcdHistory();
  const { value } = useUserContext();
  const { setPageDetails } = usePageContext();

  const handleClick = useCallback(() => {
    const { route, id } = to;
    if (route === RouteEnum.USER_PROFILE) {
      if (!value.userProfileId) {
        push(route);
        return;
      }
      push({
        pathname: RouteEnum.USER_PROFILE,
        params: { userProfileId: value.userProfileId ?? '' },
      });
    } else {
      push(route);
    }
    setPageDetails({ title: id });
  }, [push, to, value.userProfileId]);

  return (
    <Box className={className} onClick={handleClick}>
      {children}
    </Box>
  );
};

export default SideBarMenu;
