import { RoleTypeDto, VacancyStatusEnumDto } from '@/api/generated';

export interface RecruitmentBoardSearchToolbarTranslation {
  categories: {
    opportunityName: string;
    opportunityOwner: string;
    requesters: string;
    requestingCountry: string;
    status: string;
  };
  statuses: Record<VacancyStatusEnumDto, string>;
  roles: Record<RoleTypeDto, string>;
}

export const recruitmentBoardSearchToolbar: RecruitmentBoardSearchToolbarTranslation = {
  categories: {
    opportunityName: 'Opportunity name',
    opportunityOwner: 'Opportunity owner',
    requesters: 'Requesters',
    requestingCountry: 'Requesting country',
    status: 'Status',
  },
  statuses: {
    [VacancyStatusEnumDto.Archived]: 'Archived',
    [VacancyStatusEnumDto.Open]: 'Open',
    [VacancyStatusEnumDto.Closed]: 'Closed',
    [VacancyStatusEnumDto.OnHold]: 'On hold',
  },
  roles: {
    [RoleTypeDto.Admin]: 'Admin',
    [RoleTypeDto.Employee]: 'Employee',
    [RoleTypeDto.ExternalPartner]: 'External Partner',
    [RoleTypeDto.GroupExternalPartner]: 'Group External Partner',
    [RoleTypeDto.LocalResourceManager]: 'Local Resource Manager',
    [RoleTypeDto.PreSales]: 'Pre-Sales',
    [RoleTypeDto.Requester]: 'Requester',
  },
};
