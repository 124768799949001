import React, { useEffect } from 'react';
import FullPageSpinner from '@/components/ui/fullPageSpinner/FullPageSpinner';
import { useOcdHistory } from '@/utils/useOcdHistory.util';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import { useGetSession } from '@/contexts/userContext/util/useGetSession.util.ts';
import { useGetCurrentUser } from '@/contexts/userContext/util/useGetCurrentUser.util.ts';
import { useSignal } from '@preact/signals-react';
import {
  UserContext,
  UserContextProps,
  UserDefaultValues,
} from '@/contexts/userContext/User.context.ts';
import { UserBasicDto } from '@/api/generated';

const PATHS_WITHOUT_SESSION = [
  RouteEnum.NOT_AUTHORIZED,
  RouteEnum.LOGIN,
  RouteEnum.WAITING_FOR_APPROVAL,
];

export const UserContextProvider: React.FC = ({ children }) => {
  const user = useSignal<UserContextProps>(UserDefaultValues);
  const isInitialized = useSignal(false);
  const { location } = useOcdHistory();
  const pathWithoutSession = PATHS_WITHOUT_SESSION.map(path => path.toString()).includes(
    location.pathname,
  );
  const { isGettingSession, getSessionDetails, isErrorGettingSession } = useGetSession();
  const setUser = (newUser: UserBasicDto) => {
    user.value = {
      ...newUser,
      userId: newUser.id,
      fullName: `${newUser.firstName} ${newUser.lastName}`,
      dataReady: true,
    };
  };
  const { getCurrentUser, isGettingCurrentUser } = useGetCurrentUser({ onSuccess: setUser });

  const init = async () => {
    if (pathWithoutSession) {
      isInitialized.value = true;
      user.value = {
        ...user.value,
        dataReady: true,
      };
      return;
    }
    const sessionDetails = await getSessionDetails();
    if (!sessionDetails?.data) {
      return;
    }

    await getCurrentUser();
    isInitialized.value = true;
  };

  useEffect(() => {
    if (window.location.pathname === '/logout') {
      return;
    }

    init();
  }, []);

  if (!isInitialized.value || isGettingSession || isGettingCurrentUser || isErrorGettingSession) {
    return <FullPageSpinner />;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
