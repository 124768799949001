export type EditDeleteTranslation = {
  edit: string;
  delete: string;
  modal: {
    title: string;
    description: string;
    cancel: string;
    confirm: string;
  };
};

export const editDeleteTranslation: EditDeleteTranslation = {
  edit: 'Edit',
  delete: 'Delete',
  modal: {
    title: 'Please confirm',
    description: 'Are you sure you want to delete this item?',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
};
