import { useUserProfileApiClient } from '@/api/util/useUserProfileApiClient.util.ts';
import { useMutation } from '@tanstack/react-query';
import { UserProfileItemDto, UserProfileSearchRequestDto } from '@/api/generated-user-profile';
import { AxiosError } from 'axios';

type Return = {
  searchUserProfile: (request: UserProfileSearchRequestDto) => Promise<UserProfileItemDto[]>;
  searchUserProfileLoading: boolean;
  searchUserProfileDone: boolean;
  searchUserProfileError: boolean;
};

export const useSearchUserProfile = (): Return => {
  const { searchUserProfileApi } = useUserProfileApiClient();

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation<
    UserProfileItemDto[],
    AxiosError,
    UserProfileSearchRequestDto
  >({
    mutationFn: async request => {
      const response = await searchUserProfileApi.searchUserProfile(0, 100, request);
      return response.data.profiles;
    },
  });

  return {
    searchUserProfile: mutateAsync,
    searchUserProfileLoading: isLoading,
    searchUserProfileError: isError,
    searchUserProfileDone: isSuccess,
  };
};
