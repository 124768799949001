import {
  profileSearchTranslation,
  ProfileSearchTranslation,
} from '@/assets/locales/views/profileSearch/profileSearch.translation.en.ts';
import {
  vacancyDetailsTranslation,
  VacancyDetailsTranslation,
} from '@/assets/locales/views/vacancyDetails/vacancyDetails.translation.en.ts';
import {
  ProjectsTranslation,
  projectsTranslation,
} from '@/assets/locales/views/projects/projects.translation.en.ts';
import {
  UserProfileTranslation,
  userProfileTranslation,
} from '@/assets/locales/views/userProfile/userProfile.translation.en.ts';
import {
  recruitmentBoardTranslation,
  RecruitmentBoardTranslation,
} from '@/assets/locales/views/recruitmentBoard/recruitmentBoard.translation.en.ts';
import {
  reportsTranslation,
  ReportsTranslation,
} from '@/assets/locales/views/reports/reports.translation.en.ts';
import {
  signPrivacyPolicyTranslation,
  SignPrivacyPolicyTranslation,
} from '@/assets/locales/views/signPrivacyPolicy/signPrivacyPolicy.translation.en';
import {
  aiSearchTranslation,
  AiSearchTranslation,
} from '@/assets/locales/views/aiSearch/aiSearch.translation.en.ts';

export type ViewsTranslation = {
  aiSearch: AiSearchTranslation;
  profileSearch: ProfileSearchTranslation;
  projects: ProjectsTranslation;
  recruitmentBoard: RecruitmentBoardTranslation;
  reports: ReportsTranslation;
  userProfile: UserProfileTranslation;
  vacancyDetails: VacancyDetailsTranslation;
  signPrivacyPolicy: SignPrivacyPolicyTranslation;
};

export const viewsTranslation: ViewsTranslation = {
  aiSearch: aiSearchTranslation,
  profileSearch: profileSearchTranslation,
  projects: projectsTranslation,
  recruitmentBoard: recruitmentBoardTranslation,
  reports: reportsTranslation,
  userProfile: userProfileTranslation,
  vacancyDetails: vacancyDetailsTranslation,
  signPrivacyPolicy: signPrivacyPolicyTranslation,
};
