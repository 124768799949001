const ENDPOINTS = {
  COMPANIES: '/api/v1/companies',
  USERS: '/api/v1/users',
  USERS_SHORT: '/api/v1/users/short',
  CURRENT_USER: '/api/v1/users/current',
  DICTIONARIES: '/api/v1/dictionaries',
  DICTIONARIES_OPS: '/api/v1/dictionaries-operations-history',
  FILES: '/api/v1/files',
  FIELDS: '/api/v1/fields',
  PROJECTS: '/api/v1/vacancyProjects',
  BUSINESS_PROJECTS: '/api/v1/businessProjects',
  LANGUAGES: '/api/v1/users/languages',
  RECRUITMENT: '/api/v1/recruitment',
  CANDIDATE: '/api/v1/candidates',
  VACANCY_CANDIDATES: '/api/v1/vacancyCandidates',
  BANK: '/api/v1/bank',
  LISTS: '/api/v1/dictionary',
  ERA: '/api/v1/era/recruitment',
};

export default ENDPOINTS;
