export const colors = {
  // new colors
  // // greyscale
  black: '#111111',
  darkGrey: '#303030',
  grey: '#AFB1B6',
  lightGrey: '#D8D8DA',
  stroke: '#EAEAEA',
  bg: '#F7F7F8',
  white: '#FFFFFF',
  // // system colors
  darkBlue: '#005A97',
  blue: '#006FBA',
  turquoise: '#15BEF0',
  turquoiseDark: '#05a1d0',
  green: '#0D7B60',
  darkGreen: '#025740',
  lightGreen: '#DDFFF7',
  lightPurple: '#801EFF',
  purple: '#F2E8FF',
  red: '#BD2832',
  darkRed: '#a20c16',
  alpha50: 'rgba(17, 17, 17, 0.5)',
  // new colors end

  coolGrey: '#a2aaad',
  darkSkyBlue: '#56b7e6',
  oceanBlue: '#0067b9',
  primary: '#0057B8',
  redPink: '#e40046',
  silver: '#d0d3d4',
  silverTwo: '#dee0e0',
  starYellow: '#ffb400',
  steelGrey: '#7c878e',
  turquoiseBlue: '#00a5bd',
  whiteGray2: '#d8d8d8',
  whiteGray: '#f6f6f6',
  yellow: '#ffb500',
  // Colors with an alpha channel
  black_65: 'rgba(29, 29, 27, 0.65)',
  black_80: 'rgba(29, 29, 27, 0.8)',
  silver_70: 'rgba(208, 211, 212, 0.7)',
  silver_40: 'rgba(208, 211, 212, 0.4)',
  silver_20: 'rgba(208, 211, 212, 0.2)',
  yellow76: 'rgba(252,250,234,0.76)',
} as const;
