import React from 'react';
import SideBarMenu from '../sideBarMenu/SideBarMenu.comp';
import { Logo } from '../logo/Logo.comp';
import { SideBarDrawerClasses } from '@/layouts/private/sidebar/SideBar.styles.ts';
import { Drawer } from '@mui/material';

const SideBar: React.FC = () => {
  return (
    <Drawer variant="permanent" sx={SideBarDrawerClasses}>
      <Logo />
      <SideBarMenu />
    </Drawer>
  );
};

export default SideBar;
