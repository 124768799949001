import { ReportsTranslation } from '@/assets/locales/views/reports/reports.translation.en';

export const reportsTranslation: ReportsTranslation = {
  form: {
    reportType: 'Art des Berichts',
    reportRange: 'Berichtsbereich',
    location: 'Ort',
    dateFrom: 'Datum von',
    dateTo: 'Datum bis',
    button: {
      generate: 'Erstellen',
    },
  },
};
