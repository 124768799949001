/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { GenerateReportDto } from '../models';
// @ts-ignore
import type { OcdReportTypeDto } from '../models';
// @ts-ignore
import type { ReportRangeTypeDto } from '../models';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OcdReportTypeDto} reportType 
         * @param {ReportRangeTypeDto} rangeType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcdReport: async (reportType: OcdReportTypeDto, rangeType: ReportRangeTypeDto, dateFrom: string, dateTo: string, generateReportDto: GenerateReportDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('getOcdReport', 'reportType', reportType)
            // verify required parameter 'rangeType' is not null or undefined
            assertParamExists('getOcdReport', 'rangeType', rangeType)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getOcdReport', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getOcdReport', 'dateTo', dateTo)
            // verify required parameter 'generateReportDto' is not null or undefined
            assertParamExists('getOcdReport', 'generateReportDto', generateReportDto)
            const localVarPath = `/api/v1/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }

            if (rangeType !== undefined) {
                localVarQueryParameter['rangeType'] = rangeType;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substring(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substring(0,10) :
                    dateTo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OcdReportTypeDto} reportType 
         * @param {ReportRangeTypeDto} rangeType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOcdReport(reportType: OcdReportTypeDto, rangeType: ReportRangeTypeDto, dateFrom: string, dateTo: string, generateReportDto: GenerateReportDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOcdReport(reportType, rangeType, dateFrom, dateTo, generateReportDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportsApi.getOcdReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {OcdReportTypeDto} reportType 
         * @param {ReportRangeTypeDto} rangeType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {GenerateReportDto} generateReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOcdReport(reportType: OcdReportTypeDto, rangeType: ReportRangeTypeDto, dateFrom: string, dateTo: string, generateReportDto: GenerateReportDto, options?: any): AxiosPromise<string> {
            return localVarFp.getOcdReport(reportType, rangeType, dateFrom, dateTo, generateReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
    /**
     * 
     * @param {OcdReportTypeDto} reportType 
     * @param {ReportRangeTypeDto} rangeType 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {GenerateReportDto} generateReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getOcdReport(reportType: OcdReportTypeDto, rangeType: ReportRangeTypeDto, dateFrom: string, dateTo: string, generateReportDto: GenerateReportDto, options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
    /**
     * 
     * @param {OcdReportTypeDto} reportType 
     * @param {ReportRangeTypeDto} rangeType 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {GenerateReportDto} generateReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getOcdReport(reportType: OcdReportTypeDto, rangeType: ReportRangeTypeDto, dateFrom: string, dateTo: string, generateReportDto: GenerateReportDto, options?: RawAxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getOcdReport(reportType, rangeType, dateFrom, dateTo, generateReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}

