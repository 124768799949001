export type ReportsTranslation = {
  form: {
    reportType: string;
    reportRange: string;
    location: string;
    dateFrom: string;
    dateTo: string;
    button: {
      generate: string;
    };
  };
};

export const reportsTranslation: ReportsTranslation = {
  form: {
    reportType: 'Report type',
    reportRange: 'Report range',
    location: 'Location',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    button: {
      generate: 'Generate',
    },
  },
};
