import { createContext, useContext } from 'react';
import { Signal } from '@preact/signals-react';
import { signal } from '@preact/signals';
import { VacancyDetailsDto } from '@/api/generated';

export interface CopyObjectContextProps {
  vacancyDetailsCopy: Signal<VacancyDetailsDto | null>;
}

export const CopyObjectDefaultValues: CopyObjectContextProps = {
  vacancyDetailsCopy: signal(null)
};
export const CopyObjectContext = createContext<CopyObjectContextProps>(CopyObjectDefaultValues);

export const useCopyObjectContext = (): CopyObjectContextProps => {
  return useContext<CopyObjectContextProps>(CopyObjectContext);
};
