import React from 'react';
import { colors } from '@/utils/colors.ts';
import { LinearProgress, LinearProgressProps, SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

type Props = LinearProgressProps & { value: number; label: string };

export const AiSearchParamItem: React.FC<Props> = props => {
  const { value, label } = props;

  return (
    <>
      <Grid sx={ResultItemGird} container alignItems="center" spacing={1}>
        <Grid item xs={4} style={{ textAlign: 'left' }}>
          {label}:
        </Grid>
        <Grid item xs={6}>
          <LinearProgress variant="determinate" value={value * 100} />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value * 100,
          )}%`}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const ResultItemGird: SxProps = {
  fontSize: 12,
  fontWeight: 600,
  padding: '2px 0',
  color: colors.black_65,
  display: 'flex',
};
