import React from 'react';
import { FormFieldLabelStyles } from '@/components/form/model/FormFieldLabel.styles.ts';
import { Box, SxProps, Theme } from '@mui/material';
import { colors } from '@/utils/colors.ts';

interface Props {
  label: React.ReactNode;
  isRequired: boolean;
  sx?: SxProps<Theme>;
  isError: boolean;
}

export const FormFieldLabel: React.FC<Props> = props => {
  const { label, isRequired, sx, isError } = props;

  return (
    <>
      <Box sx={[FormFieldLabelStyles(isError), ...(Array.isArray(sx) ? sx : [sx])]}>
        {label}
        {isRequired && (
          <Box component="span" sx={{ color: colors.red }}>
            *
          </Box>
        )}
      </Box>
    </>
  );
};
