import { UserProfileExperiencesSectionTranslation } from '@/assets/locales/views/userProfile/sections/experiencesSection/userProfile.experiencesSection.translation.en.ts';

export const userProfileExperiencesSectionTranslation: UserProfileExperiencesSectionTranslation = {
  headerTitle: 'Expérience Professionnelle',
  content: {
    emptyItemsLabel: 'expériences professionnelles',
    button: {
      edit: 'Modifier',
    },
  },
  modal: {
    title: 'Ajouter une expérience',
  },
  form: {
    actions: 'Actions',
    position: 'Poste',
    company: 'Entreprise',
    location: 'Localisation',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    currentPosition: 'Poste actuel ?',
    description: 'Description',
    button: {
      save: 'Enregistrer',
    },
    present: 'Poste actuel',
  },
};
