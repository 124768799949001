import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { DRAWER_WIDTH } from '@/layouts/private/Layout.tsx';
import { typography } from '@/utils/typography.ts';
import { colors } from '@/utils/colors.ts';

export const components: Components<Omit<Theme, 'components'>> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f20Regular,
        width: 180,
        height: 180,
        backgroundColor: 'transparent',
        color: theme.colors.grey,
        border: `1px solid ${theme.colors.stroke}`,
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f14Regular,
        '&.MuiTableCell-head': {
          color: theme.colors.grey,
        },
        '&.MuiTableCell-body': {
          '&:first-of-type': {
            color: theme.colors.turquoise,
          },
        },
      }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        '&.MuiAccordion-root': {
          borderRadius: 8,
          '&:last-of-type, :first-of-type': {
            borderRadius: 8,
          },
          '&::before': {
            height: 0,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...theme.typography.f16Regular,
        borderRadius: '8px',
        border: '1px solid',
        padding: '8px 24px',
        textTransform: 'capitalize',
        shadow: 'none',
        boxShadow: 'none',
        '&:hover': {
          shadow: 'none',
          boxShadow: 'none',
        },
        ...(ownerState.variant === 'contained' && {
          background: theme.colors.blue,
          borderColor: theme.colors.blue,
          color: theme.colors.white,
          '&:hover': {
            background: theme.colors.darkBlue,
            shadow: 'none',
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            background: theme.colors.lightGrey,
            borderColor: theme.colors.lightGrey,
          },
          '&:focus': {
            background: theme.colors.blue,
            borderColor: theme.colors.darkBlue,
          },
        }),
        ...(ownerState.variant === 'outlined' && {
          background: theme.colors.white,
          color: theme.colors.blue,
          borderColor: theme.colors.blue,
          '&:hover': {
            color: theme.colors.darkBlue,
            borderColor: theme.colors.darkBlue,
            shadow: 'none',
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            color: theme.colors.lightGrey,
            borderColor: theme.colors.lightGrey,
          },
          '&:focus': {
            color: theme.colors.darkBlue,
            borderColor: theme.colors.darkBlue,
          },
        }),
        ...(ownerState.color === 'warning' && {
          backgroundColor: theme.colors.red,
          borderColor: theme.colors.red,
          '&:hover': {
            backgroundColor: theme.colors.darkRed,
            borderColor: theme.colors.darkRed,
            shadow: 'none',
            boxShadow: 'none',
          },
        }),
        ...(ownerState.color === 'success' && {
          backgroundColor: theme.colors.green,
          borderColor: theme.colors.green,
          '&:hover': {
            backgroundColor: theme.colors.darkGreen,
            borderColor: theme.colors.darkGreen,
          },
        }),
        ...(ownerState.color === 'info' && {
          backgroundColor: theme.colors.grey,
          borderColor: theme.colors.grey,
          '&:hover': {
            backgroundColor: theme.colors.darkGrey,
            borderColor: theme.colors.darkGrey,
          },
        }),
      }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.colors.stroke,
        marginTop: 16,
        marginBottom: 16,
      }),
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.colors.blue,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.color === 'default' && {
          backgroundColor: theme.colors.lightGreen,
        }),
        ...(ownerState.color === 'primary' && {
          backgroundColor: theme.colors.lightGrey,
        }),
        ...(ownerState.color === 'info' && {
          backgroundColor: theme.colors.purple,
          color: theme.colors.lightPurple,
        }),
      }),
      label: {
        ...typography.f14Regular,
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSize: '20px',
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      root: {
        color: colors.lightGrey,
      },
      thumb: {
        color: colors.white,
        border: `6px solid ${colors.turquoise}`,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '& .MuiTabs-flexContainer': {
          paddingBottom: '2px',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f14Regular,
        padding: '8px 32px',
        borderRadius: 8,
        border: 'none',
        color: theme.colors.darkGrey,
        '&.Mui-selected': {
          color: theme.colors.turquoise,
          border: `1px solid ${theme.colors.turquoise}`,
        },
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.colors.grey,
        '&.Mui-checked': {
          color: theme.colors.blue,
        },
        '&:hover': {
          color: theme.colors.darkBlue,
        },
        '&.Mui-disabled': {
          color: theme.colors.black,
        },
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        margin: 'auto',
        marginLeft: `calc((100vw - 960px + ${DRAWER_WIDTH}px)/2)`,
        width: 960,
      },
      paper: {
        width: 960,
        maxWidth: 960,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        minHeight: '450px',
        paddingTop: 48,
        paddingLeft: 170,
        paddingRight: 170,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingRight: 24,
        paddingBottom: 24,
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: '8px',
        backgroundColor: colors.lightGrey,
        borderRadius: '100px',
      },
      bar: {
        backgroundColor: colors.turquoise,
        borderRadius: '100px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f12Regular,
        color: theme.colors.black,
        border: `1px solid ${theme.colors.stroke}`,
        borderRadius: 4,
        transform: 'none',
        '&.Mui-focused': {
          color: theme.colors.black,
        },
        '&:hover:not(.Mui-focused)': {
          borderColor: theme.colors.lightGrey,
        },
      }),
      notchedOutline: {
        border: 'none',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f12Regular,
        color: theme.colors.darkGrey,
        transform: 'none',
        top: -4,
        '&.Mui-focused': {
          color: theme.colors.darkGrey,
        },
        '&.Mui-disabled': {
          color: theme.colors.darkGrey,
        },
      }),
      asterisk: ({ theme }) => ({
        color: theme.colors.red,
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        ...theme.typography.f14Regular,
      }),
      asterisk: ({ theme }) => ({
        color: theme.colors.red,
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f12Regular,
        color: theme.colors.darkGrey,
        transform: 'none',
        '&.Mui-focused': {
          color: theme.colors.darkGrey,
        },
      }),
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.f14Regular,
        border: `1px solid ${theme.colors.stroke}`,
        borderRadius: 4,
        padding: '12px 16px',
        '&:hover:not(.Mui-focused):not(.Mui-error)': {
          borderColor: theme.colors.lightGrey,
        },
        '&::before': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled, .Mui-error)': {
          '&::before': {
            borderBottom: 'none',
          },
        },
        '&.Mui-disabled': {
          '&::before': {
            borderBottom: 'none',
          },
        },
      }),
      input: ({ theme }) => ({
        padding: 0,
        '&::placeholder': {
          ...theme.typography.f14Regular,
          color: theme.colors.grey,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiInput-root': {
          '&.Mui-disabled': {
            backgroundColor: theme.colors.bg,
          },
          '&.Mui-focused': {
            borderColor: theme.colors.blue,
          },
          '&.Mui-error': {
            borderColor: theme.colors.red,
          },
        },
        '&.MuiOutlinedInput-root': {
          minHeight: '46px',
          '&.Mui-disabled': {
            backgroundColor: theme.colors.bg,
          },
          '&.Mui-error': {
            borderColor: theme.colors.red,
          },
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            '&:hover': {
              border: 'none',
            },
          },
        },
      }),
      input: ({ theme }) => ({
        '&.MuiOutlinedInput-input': {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': theme.colors.darkGrey,
          },
          '&::placeholder': {
            ...theme.typography.f14Regular,
            color: theme.colors.grey,
            opacity: 1,
          },
          '&.Mui-focused': {
            borderColor: theme.colors.blue,
          },
          '&.Mui-error': {
            borderColor: theme.colors.blue,
          },
        },
        '&.MuiInput-input': {
          '&::placeholder': {
            opacity: 1,
          },
          '&.Mui-disabled': {
            '-webkit-text-fill-color': theme.colors.darkGrey,
          },
        },
      }),
    },
  },
};
