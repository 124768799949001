import { UserProfileCertificatesSectionTranslation } from '@/assets/locales/views/userProfile/sections/certificatesSection/userProfile.certificatesSection.translation.en.ts';

export const userProfileCertificatesSectionTranslation: UserProfileCertificatesSectionTranslation =
  {
    headerTitle: 'Zertifikate',
    content: {
      emptyItemsLabel: 'Zertifikate',
      button: {
        edit: 'Bearbeiten',
      },
    },
    modal: {
      title: 'Zertifikat hinzufügen',
    },
    form: {
      actions: 'Handlungen',
      certificate: 'Zertifikat',
      credentialId: 'Berechtigungsnachweis ID',
      credentialUrl: 'Berechtigungsnachweis URL',
      expirationDate: 'Ablaufdatum',
      issueDate: 'Veröffentlichungsdatum',
      nonDictionaryCertificateValue: 'Wert des Zertifikats ohne Wörterbuch',
      nonExpiring: 'Diese Berechtigungsnachweis läuft nicht ab',
      organization: 'Organisation',
      button: {
        save: 'Speichern',
      },
      present: 'Nicht ablaufend',
    },
  };
