import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SelectSearchFormField } from '@/components/form/SelectSearchFormField.comp.tsx';
import { useDictionariesContext } from '@/contexts/dictionaries/Dictionaries.context.ts';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExtendedDictionaryEntryDto } from '@/api/generated';
import { LanguageSearchRequestDto } from '@/api/generated-user-profile';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchLanguageFilterFormModel } from '@/views/profileSearch/components/filters/language/model/ProfileSearchLanguageFilterForm.model.ts';
import { useProfileSearchAddLanguageValidation } from '@/views/profileSearch/components/filters/language/util/useProfileSearchAddLanguage.validation.ts';
import { ProfileSearchAddSkillButtons } from '@/views/profileSearch/components/filters/ProfileSearchAddSkillButtons.comp.tsx';
import {
  ProfileSearchAddFilterMode,
  ProfileSearchAddFilterProps,
} from '@/views/profileSearch/components/filters/model/ProfileSearchAddFilterProps.model.ts';

type Props = ProfileSearchAddFilterProps<LanguageSearchRequestDto>;

export const ProfileSearchAddLanguageFilter: React.FC<Props> = props => {
  const { languages } = useDictionariesContext();
  const { onReset: handleReset, currentItems, mode } = props;
  const validation = useProfileSearchAddLanguageValidation();
  const { t } = useTranslationWithPrefix('views.profileSearch.form.addLanguageFilter');
  const [options, setOptions] = useState<Required<ExtendedDictionaryEntryDto>[]>([]);

  const editingMode = mode === ProfileSearchAddFilterMode.EDIT;

  const formMethods = useForm<ProfileSearchLanguageFilterFormModel>({
    defaultValues: editingMode
      ? {
          language: {
            value: props.initValue.value,
            label: languages.find(item => item.id === props.initValue.value)?.value ?? '',
          },
        }
      : {},
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (currentItems.length === 0) {
      setOptions(languages);
    } else {
      setOptions(languages.filter(item => !currentItems.some(x => x.value === item.id)));
    }
  }, [currentItems]);

  const handleSubmit: SubmitHandler<ProfileSearchLanguageFilterFormModel> = useCallback(
    async form => {
      if (editingMode) {
        return;
      }
      if (!form.language) {
        return;
      }
      const language = {
        value: form.language.value,
      };
      props.onSubmit(language);
    },
    [editingMode, props],
  );

  const disabled = editingMode;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={editingMode ? 9 : 2}>
            <SelectSearchFormField
              options={options}
              name="language"
              label={t('language')}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <ProfileSearchAddSkillButtons
              onReset={handleReset}
              isSubmittable={!editingMode}
              isEditable={false}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
