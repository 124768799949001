import {
  CompanyEmployeesApiFactory,
  Configuration,
  DictionariesApiFactory,
  LogoutApiFactory,
  NotificationsApiFactory,
  RecruitmentBoardApiFactory,
  ReportsApiFactory,
  PrivacyPolicyApiFactory,
  SessionApiFactory,
  UserInitializeApiFactory,
  UsersAccountStatusApiFactory,
  UsersCompanyDataApiFactory,
  UsersEraApiFactory,
  CompaniesApiFactory,
  LoginApiFactory,
  FilesApiFactory,
  UsersApiFactory,
  UserPrivacyPolicyApiFactory,
} from '@/api/generated';
import { useMemo } from 'react';
import { useAxiosInstance } from './useAxiosInstance.util';
import CONFIG from '@/config/config';

interface Return {
  companiesApi: ReturnType<typeof CompaniesApiFactory>;
  companyEmployeesApi: ReturnType<typeof CompanyEmployeesApiFactory>;
  dictionariesApi: ReturnType<typeof DictionariesApiFactory>;
  filesApi: ReturnType<typeof FilesApiFactory>;
  loginApi: ReturnType<typeof LoginApiFactory>;
  logoutApi: ReturnType<typeof LogoutApiFactory>;
  notificationApi: ReturnType<typeof NotificationsApiFactory>;
  privacyPolicyApi: ReturnType<typeof PrivacyPolicyApiFactory>;
  recruitmentBoardApi: ReturnType<typeof RecruitmentBoardApiFactory>;
  reportsApi: ReturnType<typeof ReportsApiFactory>;
  sessionApi: ReturnType<typeof SessionApiFactory>;
  userAccountStatusApi: ReturnType<typeof UsersAccountStatusApiFactory>;
  userInitializeApi: ReturnType<typeof UserInitializeApiFactory>;
  userPrivacyPolicyApi: ReturnType<typeof UserPrivacyPolicyApiFactory>;
  usersApi: ReturnType<typeof UsersApiFactory>;
  usersCompanyDataApi: ReturnType<typeof UsersCompanyDataApiFactory>;
  usersEraApi: ReturnType<typeof UsersEraApiFactory>;
}

export const useApiClient = (): Return => {
  const basePath = CONFIG.HOST;
  const configuration = getConfiguration(basePath);
  const axiosInstance = useAxiosInstance();

  return useMemo(() => {
    return {
      companiesApi: CompaniesApiFactory(configuration, undefined, axiosInstance),
      companyEmployeesApi: CompanyEmployeesApiFactory(configuration, undefined, axiosInstance),
      dictionariesApi: DictionariesApiFactory(configuration, undefined, axiosInstance),
      filesApi: FilesApiFactory(configuration, undefined, axiosInstance),
      loginApi: LoginApiFactory(configuration, undefined, axiosInstance),
      logoutApi: LogoutApiFactory(configuration, undefined, axiosInstance),
      notificationApi: NotificationsApiFactory(configuration, undefined, axiosInstance),
      privacyPolicyApi: PrivacyPolicyApiFactory(configuration, undefined, axiosInstance),
      recruitmentBoardApi: RecruitmentBoardApiFactory(configuration, undefined, axiosInstance),
      reportsApi: ReportsApiFactory(configuration, undefined, axiosInstance),
      sessionApi: SessionApiFactory(configuration, undefined, axiosInstance),
      userAccountStatusApi: UsersAccountStatusApiFactory(configuration, undefined, axiosInstance),
      userInitializeApi: UserInitializeApiFactory(configuration, undefined, axiosInstance),
      userPrivacyPolicyApi: UserPrivacyPolicyApiFactory(configuration, undefined, axiosInstance),
      usersApi: UsersApiFactory(configuration, undefined, axiosInstance),
      usersCompanyDataApi: UsersCompanyDataApiFactory(configuration, undefined, axiosInstance),
      usersEraApi: UsersEraApiFactory(configuration, undefined, axiosInstance),
    };
  }, [axiosInstance]);
};

const getConfiguration = (basePath: string): Configuration => ({
  basePath,
  isJsonMime(_mime: string): boolean {
    return true;
  },
});
