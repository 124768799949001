import { TopBarTranslation } from './topBar/topBar.translation.en';

import { topBarTranslation } from './topBar/topBar.translation.fr';

export type LayoutsTranslation = {
  topBar: TopBarTranslation;
};

export const layoutsTranslation: LayoutsTranslation = {
  topBar: topBarTranslation,
};
