export type UserProfileSectionWrapperTranslation = {
  button: {
    add: string;
    addMore: string;
  };
};

export const userProfileSectionWrapperTranslation: UserProfileSectionWrapperTranslation = {
  button: {
    add: 'Add',
    addMore: 'Add more',
  },
};
