import { VacancyDetailsTranslation } from '@/assets/locales/views/vacancyDetails/vacancyDetails.translation.en.ts';

import { vacancyDetailsContentTranslation } from '@/assets/locales/views/vacancyDetails/content/vacancyDetails.content.translation.fr.ts';
import { vacancyDetailsFooterTranslation } from '@/assets/locales/views/vacancyDetails/footer/vacancyDetails.footer.translation.fr.ts';
import { vacancyDetailsHeaderTranslation } from '@/assets/locales/views/vacancyDetails/header/vacancyDetails.header.translation.fr.ts';

export const vacancyDetailsTranslation: VacancyDetailsTranslation = {
  content: vacancyDetailsContentTranslation,
  footer: vacancyDetailsFooterTranslation,
  header: vacancyDetailsHeaderTranslation,
};
