import { DictionaryTypeDto, OcdReportTypeDto, ReportRangeTypeDto } from '@/api/generated';

export type DictionaryTranslations = {
  dictionaryType: Record<DictionaryTypeDto, string>;
  reportType: Record<OcdReportTypeDto, string>;
  reportRange: Record<ReportRangeTypeDto, string>;
};

export const dictionariesTranslation: DictionaryTranslations = {
  dictionaryType: {
    [DictionaryTypeDto.Certificate]: 'Certificate',
    [DictionaryTypeDto.Language]: 'Language',
    [DictionaryTypeDto.Currency]: 'Currency',
    [DictionaryTypeDto.Skill]: 'Skill',
    [DictionaryTypeDto.Location]: 'Location',
    [DictionaryTypeDto.Sector]: 'Sector',
  },
  reportType: {
    [OcdReportTypeDto.AverageDayRateAndMargin]: 'Average day rate and margin',
    [OcdReportTypeDto.Certificates]: 'Certificates',
    [OcdReportTypeDto.CvDownloads]: 'Resume files downloads',
    [OcdReportTypeDto.LandscapeOfTechnology]: 'Landscape of technology',
    [OcdReportTypeDto.NumberOfRequestsPerPartner]: 'Number of requests per partner',
    [OcdReportTypeDto.TimeToSignCandidate]: 'Time to sign first candidate',
    [OcdReportTypeDto.TimeToFindCandidate]: 'Time to find first candidate',
    [OcdReportTypeDto.TopReasonForLoosing]: 'Top reason for loosing',
    [OcdReportTypeDto.WinLossRatio]: 'Win loss ratio',
  },
  reportRange: {
    [ReportRangeTypeDto.Global]: 'Global',
    [ReportRangeTypeDto.Country]: 'Country',
  },
};
