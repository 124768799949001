/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateVacancyCandidateDto } from '../models';
// @ts-ignore
import type { CreateVacancyDto } from '../models';
// @ts-ignore
import type { CreatedVacancyDto } from '../models';
// @ts-ignore
import type { UpdateVacancyDto } from '../models';
// @ts-ignore
import type { UpdateVacancyStatusDto } from '../models';
// @ts-ignore
import type { VacancyCandidateListDto } from '../models';
// @ts-ignore
import type { VacancyDetailsDto } from '../models';
// @ts-ignore
import type { VacancyDetailsForSearchDto } from '../models';
/**
 * VacancyApi - axios parameter creator
 * @export
 */
export const VacancyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new vacancy
         * @param {CreateVacancyDto} createVacancyDto Create vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancy: async (createVacancyDto: CreateVacancyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVacancyDto' is not null or undefined
            assertParamExists('createVacancy', 'createVacancyDto', createVacancyDto)
            const localVarPath = `/api/v1/recruitment/vacancies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVacancyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new candidate
         * @param {string} vacancyId 
         * @param {CreateVacancyCandidateDto} createVacancyCandidateDto Add new candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancyCandidate: async (vacancyId: string, createVacancyCandidateDto: CreateVacancyCandidateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('createVacancyCandidate', 'vacancyId', vacancyId)
            // verify required parameter 'createVacancyCandidateDto' is not null or undefined
            assertParamExists('createVacancyCandidate', 'createVacancyCandidateDto', createVacancyCandidateDto)
            const localVarPath = `/api/v1/era/recruitment/vacancies/{vacancyId}/candidates`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVacancyCandidateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get candidate list for given vacancy
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyCandidateList: async (vacancyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('getVacancyCandidateList', 'vacancyId', vacancyId)
            const localVarPath = `/api/v1/recruitment/vacancies/{vacancyId}/candidates`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vacancy details
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyDetails: async (vacancyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('getVacancyDetails', 'vacancyId', vacancyId)
            const localVarPath = `/api/v1/recruitment/vacancies/{vacancyId}`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vacancy details for search
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyDetailsForSearch: async (vacancyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('getVacancyDetailsForSearch', 'vacancyId', vacancyId)
            const localVarPath = `/api/v1/recruitment/vacancies/{vacancyId}/search`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vacancy details
         * @param {string} vacancyId 
         * @param {UpdateVacancyDto} updateVacancyDto Update vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancy: async (vacancyId: string, updateVacancyDto: UpdateVacancyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('updateVacancy', 'vacancyId', vacancyId)
            // verify required parameter 'updateVacancyDto' is not null or undefined
            assertParamExists('updateVacancy', 'updateVacancyDto', updateVacancyDto)
            const localVarPath = `/api/v1/recruitment/vacancies/{vacancyId}`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVacancyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Vacancy status
         * @param {string} vacancyId 
         * @param {UpdateVacancyStatusDto} [updateVacancyStatusDto] Update vacancy status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyStatus: async (vacancyId: string, updateVacancyStatusDto?: UpdateVacancyStatusDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyId' is not null or undefined
            assertParamExists('updateVacancyStatus', 'vacancyId', vacancyId)
            const localVarPath = `/api/v1/recruitment/vacancies/{vacancyId}/status`
                .replace(`{${"vacancyId"}}`, encodeURIComponent(String(vacancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVacancyStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyApi - functional programming interface
 * @export
 */
export const VacancyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new vacancy
         * @param {CreateVacancyDto} createVacancyDto Create vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVacancy(createVacancyDto: CreateVacancyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedVacancyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVacancy(createVacancyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.createVacancy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create new candidate
         * @param {string} vacancyId 
         * @param {CreateVacancyCandidateDto} createVacancyCandidateDto Add new candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVacancyCandidate(vacancyId: string, createVacancyCandidateDto: CreateVacancyCandidateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVacancyCandidate(vacancyId, createVacancyCandidateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.createVacancyCandidate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get candidate list for given vacancy
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyCandidateList(vacancyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyCandidateListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyCandidateList(vacancyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.getVacancyCandidateList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get vacancy details
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyDetails(vacancyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyDetails(vacancyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.getVacancyDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get vacancy details for search
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyDetailsForSearch(vacancyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyDetailsForSearchDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyDetailsForSearch(vacancyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.getVacancyDetailsForSearch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update vacancy details
         * @param {string} vacancyId 
         * @param {UpdateVacancyDto} updateVacancyDto Update vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacancy(vacancyId: string, updateVacancyDto: UpdateVacancyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacancy(vacancyId, updateVacancyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.updateVacancy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Vacancy status
         * @param {string} vacancyId 
         * @param {UpdateVacancyStatusDto} [updateVacancyStatusDto] Update vacancy status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacancyStatus(vacancyId: string, updateVacancyStatusDto?: UpdateVacancyStatusDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacancyStatus(vacancyId, updateVacancyStatusDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyApi.updateVacancyStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VacancyApi - factory interface
 * @export
 */
export const VacancyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new vacancy
         * @param {CreateVacancyDto} createVacancyDto Create vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancy(createVacancyDto: CreateVacancyDto, options?: any): AxiosPromise<CreatedVacancyDto> {
            return localVarFp.createVacancy(createVacancyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new candidate
         * @param {string} vacancyId 
         * @param {CreateVacancyCandidateDto} createVacancyCandidateDto Add new candidate request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancyCandidate(vacancyId: string, createVacancyCandidateDto: CreateVacancyCandidateDto, options?: any): AxiosPromise<void> {
            return localVarFp.createVacancyCandidate(vacancyId, createVacancyCandidateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get candidate list for given vacancy
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyCandidateList(vacancyId: string, options?: any): AxiosPromise<VacancyCandidateListDto> {
            return localVarFp.getVacancyCandidateList(vacancyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vacancy details
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyDetails(vacancyId: string, options?: any): AxiosPromise<VacancyDetailsDto> {
            return localVarFp.getVacancyDetails(vacancyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vacancy details for search
         * @param {string} vacancyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyDetailsForSearch(vacancyId: string, options?: any): AxiosPromise<VacancyDetailsForSearchDto> {
            return localVarFp.getVacancyDetailsForSearch(vacancyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vacancy details
         * @param {string} vacancyId 
         * @param {UpdateVacancyDto} updateVacancyDto Update vacancy request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancy(vacancyId: string, updateVacancyDto: UpdateVacancyDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacancy(vacancyId, updateVacancyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Vacancy status
         * @param {string} vacancyId 
         * @param {UpdateVacancyStatusDto} [updateVacancyStatusDto] Update vacancy status request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyStatus(vacancyId: string, updateVacancyStatusDto?: UpdateVacancyStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacancyStatus(vacancyId, updateVacancyStatusDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyApi - interface
 * @export
 * @interface VacancyApi
 */
export interface VacancyApiInterface {
    /**
     * 
     * @summary Create new vacancy
     * @param {CreateVacancyDto} createVacancyDto Create vacancy request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    createVacancy(createVacancyDto: CreateVacancyDto, options?: RawAxiosRequestConfig): AxiosPromise<CreatedVacancyDto>;

    /**
     * 
     * @summary Create new candidate
     * @param {string} vacancyId 
     * @param {CreateVacancyCandidateDto} createVacancyCandidateDto Add new candidate request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    createVacancyCandidate(vacancyId: string, createVacancyCandidateDto: CreateVacancyCandidateDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Get candidate list for given vacancy
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    getVacancyCandidateList(vacancyId: string, options?: RawAxiosRequestConfig): AxiosPromise<VacancyCandidateListDto>;

    /**
     * 
     * @summary Get vacancy details
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    getVacancyDetails(vacancyId: string, options?: RawAxiosRequestConfig): AxiosPromise<VacancyDetailsDto>;

    /**
     * 
     * @summary Get vacancy details for search
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    getVacancyDetailsForSearch(vacancyId: string, options?: RawAxiosRequestConfig): AxiosPromise<VacancyDetailsForSearchDto>;

    /**
     * 
     * @summary Update vacancy details
     * @param {string} vacancyId 
     * @param {UpdateVacancyDto} updateVacancyDto Update vacancy request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    updateVacancy(vacancyId: string, updateVacancyDto: UpdateVacancyDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Update Vacancy status
     * @param {string} vacancyId 
     * @param {UpdateVacancyStatusDto} [updateVacancyStatusDto] Update vacancy status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApiInterface
     */
    updateVacancyStatus(vacancyId: string, updateVacancyStatusDto?: UpdateVacancyStatusDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VacancyApi - object-oriented interface
 * @export
 * @class VacancyApi
 * @extends {BaseAPI}
 */
export class VacancyApi extends BaseAPI implements VacancyApiInterface {
    /**
     * 
     * @summary Create new vacancy
     * @param {CreateVacancyDto} createVacancyDto Create vacancy request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public createVacancy(createVacancyDto: CreateVacancyDto, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).createVacancy(createVacancyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new candidate
     * @param {string} vacancyId 
     * @param {CreateVacancyCandidateDto} createVacancyCandidateDto Add new candidate request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public createVacancyCandidate(vacancyId: string, createVacancyCandidateDto: CreateVacancyCandidateDto, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).createVacancyCandidate(vacancyId, createVacancyCandidateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get candidate list for given vacancy
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public getVacancyCandidateList(vacancyId: string, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).getVacancyCandidateList(vacancyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vacancy details
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public getVacancyDetails(vacancyId: string, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).getVacancyDetails(vacancyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vacancy details for search
     * @param {string} vacancyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public getVacancyDetailsForSearch(vacancyId: string, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).getVacancyDetailsForSearch(vacancyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vacancy details
     * @param {string} vacancyId 
     * @param {UpdateVacancyDto} updateVacancyDto Update vacancy request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public updateVacancy(vacancyId: string, updateVacancyDto: UpdateVacancyDto, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).updateVacancy(vacancyId, updateVacancyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Vacancy status
     * @param {string} vacancyId 
     * @param {UpdateVacancyStatusDto} [updateVacancyStatusDto] Update vacancy status request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyApi
     */
    public updateVacancyStatus(vacancyId: string, updateVacancyStatusDto?: UpdateVacancyStatusDto, options?: RawAxiosRequestConfig) {
        return VacancyApiFp(this.configuration).updateVacancyStatus(vacancyId, updateVacancyStatusDto, options).then((request) => request(this.axios, this.basePath));
    }
}

