import {
  recruitmentBoardSearchToolbar,
  RecruitmentBoardSearchToolbarTranslation,
} from './searchToolbar/recruitmentBoardSearchToolbar.translation.en';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';

export interface RecruitmentBoardTranslation {
  columns: Record<VacancyCandidateStatusEnumDto, string>;
  agencyName: string;
  assignedHr: string;
  assignedHrbp: string;
  availability: {
    available: string;
    availableFrom: string;
    no: string;
    noData: string;
    yes: string;
  };
  expectedDailyRate: string;
  hiringManager: string;
  locations: string;
  position: string;
  sharedWithEra: string;
  startingDate: string;
  unassignedCandidates: string;
  yearsOfExperience: string;
  searchToolbar: RecruitmentBoardSearchToolbarTranslation;
  vacancy: {
    opportunityOwner: string;
    finalRateForCandidate: string;
    targetMargin: string;
  };
}

export const recruitmentBoardTranslation: RecruitmentBoardTranslation = {
  columns: {
    [VacancyCandidateStatusEnumDto.New]: 'New',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Request for interview',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interview',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Rejected',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Selected',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offered',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Signed',
  },
  agencyName: 'External recruitment agency',
  assignedHr: 'Assigned recruiter',
  assignedHrbp: 'Assigned HRBP',
  availability: {
    available: 'Available',
    availableFrom: 'Available from',
    no: 'Unavailable',
    noData: 'No data',
    yes: 'Available',
  },
  expectedDailyRate: 'Expected daily rate',
  hiringManager: 'Hiring manager',
  locations: 'Locations',
  position: 'Position',
  sharedWithEra: ' Shared with External Recruitment Agencies',
  startingDate: 'Starting date',
  unassignedCandidates: 'Unassigned candidates',
  yearsOfExperience: 'Years of experience',
  searchToolbar: recruitmentBoardSearchToolbar,
  vacancy: {
    opportunityOwner: 'Opportunity owner',
    finalRateForCandidate: 'Final rate for candidate',
    targetMargin: 'Target margin',
  },
};
