import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ErrorIcon from '@mui/icons-material/Error';
import { fallbackUiStyles } from './fallbackUi.styles.ts';
import InfoIcon from '@/assets/icons/InfoIcon';
import React, { ReactNode } from 'react';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';

type Props = {
  errorDetails: ReactNode;
  errorMessage: string | null;
};

export const FallbackUI: React.FC<Props> = props => {
  const classes = fallbackUiStyles();
  const { t } = useTranslationWithPrefix('components.fallbackUi');

  const { errorDetails, errorMessage } = props;

  if (
    import.meta.env.VITE_ENVIRONMENT !== 'local' &&
    errorMessage?.startsWith('error loading dynamically imported module')
  ) {
    window.location.reload();
    return null;
  }

  return (
    <Container className={classes.main} maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.title}>
              <ErrorIcon />
              {t('title')}
            </Typography>
            <Box className={classes.separator}></Box>
            <Box mt={2}>
              <Typography className={classes.message}>{t('message')}</Typography>
              <code>{errorMessage}</code>
              <br />
              <code>{errorDetails}</code>
              <Typography className={classes.instruction}>
                <InfoIcon />
                {t('instruction', {
                  supportEmail: import.meta.env.VITE_SUPPORT_EMAIL,
                })}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
