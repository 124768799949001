export type UserProfileCareerPathSectionTranslation = {
  error: string;
  loading: string;
  headerTitle: string;
  content: {
    header: {
      estimatedTime: string;
    };
    milestones: {
      description: string;
      reason: string;
      button: string;
      courseDuration: string;
    };
    chat: {
      incentive: string;
      startChatButton: string;
      submitYourQuestion: string;
      sendMessageButton: string;
    };
  };
};

export const userProfileCareerPathSectionTranslation: UserProfileCareerPathSectionTranslation = {
  error: "We're sorry, but we can't load the career path for {{name}}`s profile",
  loading: "Please wait while we're preparing a career path for {{name}}`s profile",
  headerTitle: 'Career path',
  content: {
    header: {
      estimatedTime: 'Estimated time',
    },
    milestones: {
      description: 'Description',
      reason: 'Reason',
      button: 'Go to the course',
      courseDuration: 'Course duration',
    },
    chat: {
      incentive: 'Would you like to make any changes to the plan?',
      startChatButton: 'Start chat',
      submitYourQuestion: 'What changes would you like to implement in the plan?',
      sendMessageButton: 'Send',
    },
  },
};
