import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useLogin } from '@/views/login/util/useLogin.util.ts';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { DEFAULT_ROUTE } from '@/views/signInHandler/SignInHandler';
import { SxProps } from '@mui/material';

export const LoginView: React.FC = () => {
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { login } = useLogin();
  const { push } = useOcdHistory();
  const { value } = useUserContext();

  useEffect(() => {
    if (value.dataReady && value.userId.length > 0) {
      push(DEFAULT_ROUTE);
    }
  }, [value.dataReady, value.userId.length]);

  const handleSubmit = async () => {
    const username = usernameRef.current?.value ?? '';
    const password = passwordRef.current?.value ?? '';
    console.debug('username', username);
    console.debug('password', password);
    await login({ username, password });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            sx={TextFieldStyles}
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            inputRef={usernameRef}
          />
          <TextField
            sx={TextFieldStyles}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={passwordRef}
          />
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const TextFieldStyles: SxProps = {
  background: 'white',
  '& .MuiFormLabel-root': {
    top: '-15px',
  },
};
