import { VacancyStatusEnumDto } from '@/api/generated';

import { VacanciesTranslation } from '@/assets/locales/vacancies/vacancies.translation.en.ts';

export const vacanciesTranslation: VacanciesTranslation = {
  DELIVERY: 'Livraison',
  HYBRID: 'Hybride',
  OFF_SITE: 'Hors site',
  ON_SITE: 'Sur site',
  PRE_SALES: 'Pré-vente',
  addVacancy: 'Ajouter une demande',
  selectCloseReason: 'Sélectionnez la raison de la fermeture de la demande :',
  assignHrEmployee: 'Sélectionnez la personne à qui vous souhaitez attribuer la demande :',
  attachment: 'Pièce jointe',
  availability: 'Disponibilité attendue',
  by: 'par',
  closeVacancyWarning: 'Une fois approuvée, il ne sera pas possible de restaurer cette demande !',
  code: 'Numéro de référence',
  copy: 'Copier la demande',
  copyVacancy: 'Copier la demande',
  createdBy: 'Créé par',
  createdDate: 'Date de création',
  currency: 'Devise',
  department: 'Département',
  dropzoneFormatError:
    'Format de fichier incorrect ! Extensions de fichier acceptées : .doc, .docx, .txt, .pdf, .jpg, .jpeg',
  editVacancy: 'Modifier la demande',
  email: 'Adresse e-mail',
  eras: 'Agences de recrutement externes',
  extendByOutsourcing: 'Prolonger par externalisation',
  generateTableList: 'Exporter la liste des demandes',
  goBack: 'Revenir en arrière',
  goBackVacancies: 'Revenir à la liste des demandes',
  hrEmployee: 'Employé RH',
  hrEmployeeEdit: "Modifier l'employé RH",
  hrbp: 'Partenaire RH',
  locations: 'Lieu',
  manager: 'Responsable du recrutement',
  mdRate: 'Taux horaire attendu',
  mdRateFrom: 'Taux horaire à partir de',
  mdRateFromLowerThanToError: 'Ce taux doit être inférieur au taux À',
  mdRateLowerEqualZeroError: 'Le montant doit être supérieur à 0',
  mdRateTo: "Taux horaire jusqu'à",
  mdRateToHigherThanFromError: 'Ce taux doit être supérieur au taux DE',
  minYearsOfExperience: 'Expérience min. dans un poste similaire',
  minYearsOfExperienceShort: 'Expérience (années)',
  modifiedBy: 'Modifié par',
  modifiedDate: 'Date de modification',
  new: 'Nouveau',
  noVacancies: 'Aucune demande ne correspond aux critères donnés',
  notVisible: 'Visibilité limitée',
  numberOfCandidates: 'Nombre de candidats',
  numberOfVacancies: 'Nombre de demandes',
  numberValid: 'La valeur doit être constituée uniquement de chiffres',
  optionalSkills: 'Technologies / compétences facultatives',
  phoneNumber: 'Numéro de téléphone',
  position: 'Poste',
  requester: 'Demandeur',
  role: 'Rôle',
  vacanciesAndCandidates: 'Nombre de demandes / Nombre de candidats',
  visible: 'Visible pour tous les utilisateurs',
  priority: {
    label: 'Priorité',
    low: 'Faible',
    urgent: 'Urgent',
    normal: 'Normal',
    critical: 'Critique',
  },
  status: {
    [VacancyStatusEnumDto.Archived]: 'Archivé',
    [VacancyStatusEnumDto.Open]: 'Ouvert',
    [VacancyStatusEnumDto.Closed]: 'Fermé',
    [VacancyStatusEnumDto.OnHold]: 'En attente',
  },
  candidatesList: {
    header: 'Liste des candidats',
    name: 'Nom',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    status: 'Statut',
    createdAt: "Date d'ajout",
    externalId: 'ID du candidat',
    expand: 'Développer',
    collapse: 'Réduire',
    sentAt: "Date d'envoi",
  },
  dialog: {
    title: 'Êtes-vous sûr de vouloir ajouter une externalisation ?',
    warning:
      'En cliquant sur le bouton "Confirmer", un e-mail contenant les détails de la demande sera envoyé aux agences de recrutement externes.',
    acceptLabel: 'Confirmer',
  },
};
