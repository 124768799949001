import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { alertStyles } from './alert.styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';

type AlarmType = 'error' | 'success';

type Props = {
  message: string | null;
  handleAlertShown: () => void;
  type: AlarmType;
};

export const Alert: React.FC<Props> = props => {
  const { message, handleAlertShown, type } = props;
  const [visible, setVisible] = useState(true);
  const classes = alertStyles();
  const { t } = useTranslationWithPrefix('alerts');
  const defaultMessage = type === 'error' ? 'error' : 'success';
  const icon = type === 'error' ? <ErrorOutlineOutlinedIcon /> : <CheckCircleOutlineOutlinedIcon />;
  const alertClasses = [classes.alert].concat([type]);

  const handleHide = (
    _event: React.SyntheticEvent<unknown, Event> | Event,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setVisible(false);
    handleAlertShown();
  };

  return (
    <Snackbar
      open={visible}
      style={{ top: '70px' }}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleHide}
    >
      <div className={alertClasses.join(' ')}>
        <div className={classes.icon}>{icon}</div>
        {message ?? t(defaultMessage)}
      </div>
    </Snackbar>
  );
};
