import { createContext, ReactElement, useContext } from 'react';
import { FieldValues, FormProvider, FormProviderProps } from 'react-hook-form';
import { ObjectSchema } from 'yup';

type Props<
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
> = FormProviderProps<TFieldValues, TContext, TTransformedValues> & {
  validation: ObjectSchema<object>;
};

interface FormProviderKnowMeProps {
  validation?: ObjectSchema<object>;
}

const FormProviderKnowMeDefaultValues: FormProviderKnowMeProps = {
  validation: undefined,
};
export const FormProviderKnowMePropsContext = createContext<FormProviderKnowMeProps>(
  FormProviderKnowMeDefaultValues,
);

export const useFormProviderKnowMePropsContext = (): FormProviderKnowMeProps => {
  return useContext<FormProviderKnowMeProps>(FormProviderKnowMePropsContext);
};

export const FormProviderKnowMe = <
  TFieldValues extends FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: Props<TFieldValues, TContext, TTransformedValues>,
): ReactElement<Props<TFieldValues, TContext, TTransformedValues>> => {
  const { validation, ...rest } = props;

  return (
    <>
      <FormProvider {...rest}>
        <FormProviderKnowMePropsContext.Provider value={{ validation }}>
          {props.children}
        </FormProviderKnowMePropsContext.Provider>
      </FormProvider>
    </>
  );
};
