/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { UserProfileFullTextSearchRequestDto } from '../models';
// @ts-ignore
import type { UserProfileFullTextSearchResultDto } from '../models';
// @ts-ignore
import type { UserProfileSearchRequestDto } from '../models';
// @ts-ignore
import type { UserProfileSearchResultDto } from '../models';
/**
 * SearchUserProfileApi - axios parameter creator
 * @export
 */
export const SearchUserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} size 
         * @param {UserProfileSearchRequestDto} userProfileSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfile: async (page: number, size: number, userProfileSearchRequestDto: UserProfileSearchRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('searchUserProfile', 'page', page)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('searchUserProfile', 'size', size)
            // verify required parameter 'userProfileSearchRequestDto' is not null or undefined
            assertParamExists('searchUserProfile', 'userProfileSearchRequestDto', userProfileSearchRequestDto)
            const localVarPath = `/api/v1/user-profiles/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserProfileFullTextSearchRequestDto} userProfileFullTextSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfileFullText: async (userProfileFullTextSearchRequestDto: UserProfileFullTextSearchRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileFullTextSearchRequestDto' is not null or undefined
            assertParamExists('searchUserProfileFullText', 'userProfileFullTextSearchRequestDto', userProfileFullTextSearchRequestDto)
            const localVarPath = `/api/v1/user-profiles/full-text-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileFullTextSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchUserProfileApi - functional programming interface
 * @export
 */
export const SearchUserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchUserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} size 
         * @param {UserProfileSearchRequestDto} userProfileSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUserProfile(page: number, size: number, userProfileSearchRequestDto: UserProfileSearchRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileSearchResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUserProfile(page, size, userProfileSearchRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchUserProfileApi.searchUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UserProfileFullTextSearchRequestDto} userProfileFullTextSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUserProfileFullText(userProfileFullTextSearchRequestDto: UserProfileFullTextSearchRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileFullTextSearchResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUserProfileFullText(userProfileFullTextSearchRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchUserProfileApi.searchUserProfileFullText']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchUserProfileApi - factory interface
 * @export
 */
export const SearchUserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchUserProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} size 
         * @param {UserProfileSearchRequestDto} userProfileSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfile(page: number, size: number, userProfileSearchRequestDto: UserProfileSearchRequestDto, options?: any): AxiosPromise<UserProfileSearchResultDto> {
            return localVarFp.searchUserProfile(page, size, userProfileSearchRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserProfileFullTextSearchRequestDto} userProfileFullTextSearchRequestDto User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfileFullText(userProfileFullTextSearchRequestDto: UserProfileFullTextSearchRequestDto, options?: any): AxiosPromise<UserProfileFullTextSearchResultDto> {
            return localVarFp.searchUserProfileFullText(userProfileFullTextSearchRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchUserProfileApi - interface
 * @export
 * @interface SearchUserProfileApi
 */
export interface SearchUserProfileApiInterface {
    /**
     * 
     * @param {number} page 
     * @param {number} size 
     * @param {UserProfileSearchRequestDto} userProfileSearchRequestDto User profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchUserProfileApiInterface
     */
    searchUserProfile(page: number, size: number, userProfileSearchRequestDto: UserProfileSearchRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileSearchResultDto>;

    /**
     * 
     * @param {UserProfileFullTextSearchRequestDto} userProfileFullTextSearchRequestDto User profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchUserProfileApiInterface
     */
    searchUserProfileFullText(userProfileFullTextSearchRequestDto: UserProfileFullTextSearchRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileFullTextSearchResultDto>;

}

/**
 * SearchUserProfileApi - object-oriented interface
 * @export
 * @class SearchUserProfileApi
 * @extends {BaseAPI}
 */
export class SearchUserProfileApi extends BaseAPI implements SearchUserProfileApiInterface {
    /**
     * 
     * @param {number} page 
     * @param {number} size 
     * @param {UserProfileSearchRequestDto} userProfileSearchRequestDto User profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchUserProfileApi
     */
    public searchUserProfile(page: number, size: number, userProfileSearchRequestDto: UserProfileSearchRequestDto, options?: RawAxiosRequestConfig) {
        return SearchUserProfileApiFp(this.configuration).searchUserProfile(page, size, userProfileSearchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserProfileFullTextSearchRequestDto} userProfileFullTextSearchRequestDto User profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchUserProfileApi
     */
    public searchUserProfileFullText(userProfileFullTextSearchRequestDto: UserProfileFullTextSearchRequestDto, options?: RawAxiosRequestConfig) {
        return SearchUserProfileApiFp(this.configuration).searchUserProfileFullText(userProfileFullTextSearchRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

