import React from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import {
  ProfileSearchAddFilterButtonStyles,
  ProfileSearchDeleteFilterButtonStyles,
  ProfileSearchEditFilterButtonStyles,
} from '@/views/profileSearch/styles/profileSearch.styles.ts';
import { colors } from '@/utils/colors.ts';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Stack } from '@mui/material';

type Props = {
  onReset: () => void;
  isSubmittable: boolean;
} & (
  | {
      isEditable: true;
      onEdit: () => void;
    }
  | {
      isEditable: false;
    }
);

export const ProfileSearchAddSkillButtons: React.FC<Props> = props => {
  const { onReset: handleReset, isSubmittable, isEditable } = props;

  return (
    <>
      <Stack direction="row" alignContent="center" justifyContent="center" sx={{ pt: '16px' }}>
        {isSubmittable && (
          <>
            <IconButton type="submit" sx={ProfileSearchAddFilterButtonStyles}>
              <AddCircleOutlineOutlinedIcon htmlColor={colors.green} fontSize="inherit" />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem
              style={{ textAlign: 'center', margin: '0 8px' }}
            />
          </>
        )}
        {!isSubmittable && isEditable && (
          <>
            <IconButton onClick={props.onEdit} sx={ProfileSearchEditFilterButtonStyles}>
              <BuildCircleOutlinedIcon htmlColor={colors.blue} fontSize="inherit" />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem
              style={{ textAlign: 'center', margin: '0 8px' }}
            />
          </>
        )}

        <IconButton onClick={handleReset} sx={ProfileSearchDeleteFilterButtonStyles}>
          <RemoveCircleOutlineIcon htmlColor={colors.redPink} fontSize="inherit" />
        </IconButton>
      </Stack>
    </>
  );
};
