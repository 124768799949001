import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { UserProfileSectionDto } from '@/api/generated-user-profile';
import { skillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.de';
import { ProfileSearchTranslation } from '@/assets/locales/views/profileSearch/profileSearch.translation.en';

export const profileSearchTranslation: ProfileSearchTranslation = {
  content: {
    vacancyTitle: 'Kandidaten für {{vacancy}} finden',
    activeSkillsFilters: 'Filter für aktive Fähigkeiten',
    activeCertificateFilters: 'Aktive Zertifikate filtern',
    activeLanguageFilters: 'Filter für aktive Sprachen',
    searchResults: 'Suchergebnisse',
    searching: 'Suchen...',
    noResults: 'Suche erbrachte keine Ergebnisse',
    results: {
      assignedToVacancy: 'Zugeordnet zu',
      assignToVacancy: 'Zuweisen auf Vakanz',
      menu: {
        open: 'Öffnen',
        openInNewTab: 'In neuer Registerkarte öffnen',
      },
    },
    button: {
      addAnother: 'Ein weiteres hinzufügen',
    },
  },
  form: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    addType: 'Filter hinzufügen',
    availableOnly: 'Nur verfügbar',
    fullTextSearch: 'Ausbau der Kompetenzen, Projekte, Zertifikate, Sprachen usw.',
    addTypeOption: {
      [ProfileSearchAddItemType.skills]: 'Fähigkeit',
      [ProfileSearchAddItemType.certificates]: 'Zertifikate',
      [ProfileSearchAddItemType.languages]: 'Sprache',
    },
    addSkillFilter: {
      skill: 'Fähigkeit',
      level: 'Niveau',
      experienceInYears: 'Erfahrung in Jahren',
      skillsDescriptions,
    },
    addCertificateFilter: {
      certificate: 'Zertifikate',
    },
    addLanguageFilter: {
      language: 'Sprache',
    },
    button: {
      search: 'Suchen',
      clearFilters: 'Filter entfernen',
    },
  },
  fullText: {
    categories: {
      header:
        'Hier finden Sie eine Liste der Ergebnisse für Ihren Suchbegriff in allen Kategorien. Bitte wählen Sie die gewünschte Kategorie aus, um genauere Ergebnisse zu erhalten.',
      section: {
        [UserProfileSectionDto.Award]: 'Preise',
        [UserProfileSectionDto.Course]: 'Kurse',
        [UserProfileSectionDto.Certificate]: 'Zertifikate',
        [UserProfileSectionDto.Project]: 'Projekte',
        [UserProfileSectionDto.Experience]: 'Berufserfahrung',
        [UserProfileSectionDto.Language]: 'Sprachen',
        [UserProfileSectionDto.Skill]: 'Fähigkeiten',
      },
    },
    skillsPropositions: {
      header:
        'Dies sind vorhandene Fähigkeiten, die Ihrem Suchbegriff ähnlich sind. Bitte wählen Sie die gewünschte Qualifikation aus, um genauere Ergebnisse zu erhalten.',
    },
  },
};
