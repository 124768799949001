import { useUserProfileApiClient } from '@/api/util/useUserProfileApiClient.util.ts';
import { useMutation } from '@tanstack/react-query';
import {
  UserProfileFullTextSearchRequestDto,
  UserProfileFullTextSearchResultDto,
} from '@/api/generated-user-profile';
import { AxiosError } from 'axios';

type Return = {
  searchUserProfileFullText: (
    request: UserProfileFullTextSearchRequestDto,
  ) => Promise<UserProfileFullTextSearchResultDto>;
  searchUserProfileFullTextLoading: boolean;
  searchUserProfileFullTextDone: boolean;
  searchUserProfileFullTextError: boolean;
};

export const useSearchUserProfileFullText = (): Return => {
  const { searchUserProfileApi } = useUserProfileApiClient();

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation<
    UserProfileFullTextSearchResultDto,
    AxiosError,
    UserProfileFullTextSearchRequestDto
  >({
    mutationFn: async request => {
      const response = await searchUserProfileApi.searchUserProfileFullText(request);
      return response.data;
    },
  });

  return {
    searchUserProfileFullText: mutateAsync,
    searchUserProfileFullTextLoading: isLoading,
    searchUserProfileFullTextError: isError,
    searchUserProfileFullTextDone: isSuccess,
  };
};
