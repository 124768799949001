import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileEducationDto } from '@/api/generated-user-profile';

export type UserProfileEducationsSectionTranslation = SectionTranslationType<
  Omit<UserProfileEducationDto, 'userProfileId' | 'educationId' | 'contentLanguage'>
>;

export const userProfileEducationsSectionTranslation: UserProfileEducationsSectionTranslation = {
  headerTitle: 'Education',
  content: {
    emptyItemsLabel: 'education entries',
    button: {
      edit: 'Edit',
    },
  },
  modal: {
    title: 'Add education',
  },
  form: {
    actions: 'Actions',
    school: 'School',
    degree: 'Degree',
    fieldOfStudy: 'Field of study',
    startDate: 'Start date',
    endDate: 'End date',
    stillStudying: 'I am still studying',
    activities: 'Activities',
    description: 'Description',
    button: {
      save: 'Save',
    },
    present: 'Still studying',
  },
};
