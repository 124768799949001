import { useApiClient } from '@/api/util/useApiClient.util';
import { ExtendedDictionaryEntryDto } from '@/api/generated';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { RefetchModel } from '@/api/model/Refetch.model.ts';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';

interface Return {
  getAllDictionaries: RefetchModel<ExtendedDictionaryEntryDto[]>;
  dictionaries: ExtendedDictionaryEntryDto[];
  isFetchingDictionariesTypes: boolean;
}

type Params = {
  disabled?: boolean;
  onSuccess?: (data: ExtendedDictionaryEntryDto[]) => void;
};

export const useGetAllDictionaries = (params: Params): Return => {
  const { disabled, onSuccess } = params;
  const { dictionariesApi } = useApiClient();

  const { refetch, data, isLoading } = useQuery<ExtendedDictionaryEntryDto[], AxiosError>({
    enabled: disabled !== true,
    queryFn: async () => {
      const response = await dictionariesApi.getDictionaries();
      return response?.data;
    },
    onSuccess: data => {
      onSuccess?.(data);
    },
    queryKey: [QueryKeyEnum.DICTIONARIES],
  });

  return {
    getAllDictionaries: refetch,
    isFetchingDictionariesTypes: isLoading,
    dictionaries: data ?? [],
  };
};
