export enum ProfileSearchAddFilterMode {
  'ADD' = 'ADD',
  'EDIT' = 'EDIT',
}

export type ProfileSearchAddFilterProps<T> = {
  currentItems: T[];
  onReset: () => void;
} & (
  | {
      mode: ProfileSearchAddFilterMode.ADD;
      onSubmit: (skill: T) => void;
    }
  | {
      mode: ProfileSearchAddFilterMode.EDIT;
      onEdit: (params: { item: { value: number }; oldItem: { value: number } }) => void;
      initValue: T;
    }
);
