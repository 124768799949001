import { VacancyDetailsFooterTranslation } from '@/assets/locales/views/vacancyDetails/footer/vacancyDetails.footer.translation.en.ts';

export const vacancyDetailsFooterTranslation: VacancyDetailsFooterTranslation = {
  dialog: {
    archive: {
      message: 'Êtes-vous sûr de vouloir archiver cette demande ?',
      button: 'Archiver la demande',
    },
    close: {
      message: 'Êtes-vous sûr de vouloir fermer cette demande ?',
      button: 'Fermer la demande',
    },
    onHold: {
      message: 'Êtes-vous sûr de vouloir mettre cette demande en attente ?',
      button: 'Mettre la demande en attente',
    },
    open: {
      message: 'Êtes-vous sûr de vouloir rouvrir cette demande ?',
      button: 'Rouvrir la demande',
    },
  },
  button: {
    archive: 'Archiver la demande',
    close: 'Fermer la demande',
    reopen: 'Rouvrir la demande',
    onHold: 'Mettre la demande en attente',
  },
};
