import { makeStyles } from '@mui/styles';
import { colors } from '@/utils/colors.ts';
import { SxProps } from '@mui/material';
import { typography } from '@/utils/typography.ts';

export const ProfileSearchResultNameStyles: SxProps = {
  ...typography.f14Regular,
  color: colors.blue,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const ProfileSearchResultItemStyles: SxProps = {
  ...typography.f14Regular,
  color: colors.black,
};

export const ProfileSearchResultItemLocationStyles: SxProps = {
  ...typography.f12Regular,
  color: colors.grey,
};

export const ProfileSearchResultPaper: SxProps = {
  padding: 3,
  height: '100%',
  transition: 'all .2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
};

const ProfileSearchFilterButtonStyles: SxProps = {
  background: 'none',
  fontSize: '26px',
  color: 'black',
  border: 'none',
};

export const ProfileSearchAddFilterButtonStyles: SxProps = {
  ...ProfileSearchFilterButtonStyles,
  color: colors.green,
  '&:hover': {
    textShadow: '0 0 1px ' + colors.green,
  },
};

export const ProfileSearchDeleteFilterButtonStyles: SxProps = {
  ...ProfileSearchFilterButtonStyles,
  color: colors.redPink,
  '&:hover': {
    textShadow: '0 0 1px ' + colors.redPink,
  },
};

export const ProfileSearchEditFilterButtonStyles: SxProps = {
  ...ProfileSearchFilterButtonStyles,
  color: colors.blue,
  '&:hover': {
    textShadow: '0 0 1px ' + colors.blue,
  },
};

export const profileSearchStyles = makeStyles(theme => ({
  topBarSearchField: {
    background: 'white',
    borderRadius: 4,
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  deleteFilterButton: {
    color: theme.colors.redPink,
    '&:hover': {
      textShadow: '0 0 1px ' + theme.colors.redPink,
    },
  },
  fullTextCategoriesHeader: {
    color: colors.black_80,
    fontSize: 14,
    fontWeight: 400,
    paddingBottom: 4,
  },
  fullTextCategoryItem: {
    color: 'white',
    fontSize: 12,
    borderRadius: 4,
    width: '100%',
    background: colors.darkSkyBlue,
    '&:hover': {
      fontWeight: 600,
    },
  },
  skillsPropositionsItem: {
    color: 'white',
    fontSize: 12,
    background: colors.darkSkyBlue,
    '&:hover': {
      fontWeight: 600,
    },
  },
}));
