import { SxProps, Theme } from '@mui/material';

export const TOP_BAR_HEIGHT = 64;

export const TopBarStyles: SxProps<Theme> = {
  color: 'primary.black',
  height: TOP_BAR_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: 5,
  backgroundColor: 'primary.white',
  position: 'sticky',
  top: 0,
  zIndex: 500,
  width: '100%',
};

export const TopBarItemsStyles: SxProps<Theme> = {
  alignItems: 'center',
  justifyContent: 'space-between',
};
