export type TopBarTranslation = {
  language: {
    english: string;
    french: string;
    german: string;
  };
  tooltip: {
    profile: string;
    userGuide: string;
    logout: string;
  };
};

export const topBarTranslation: TopBarTranslation = {
  language: {
    english: 'English',
    french: 'French',
    german: 'German',
  },
  tooltip: {
    profile: 'Edit profile',
    userGuide: 'Donwload User Guide',
    logout: 'Logout',
  },
};
