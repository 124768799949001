import { UserStatusDto, VacancyProjectStatusDto } from '@/api/generated';
import { CompanyDetailsTranslation } from '@/assets/locales/companyDetails/companyDetails.translation.en.ts';

const addCompany = 'Ajouter une entreprise';
const address = 'Adresse principale';
const companyName = "Nom de l'entreprise";
const companyProfile = 'Profil';
const description = 'Description';
const email = 'Email principal';
const firstName = 'Nom';
const lastName = 'Prénom';
const location = 'Localisation';
const operatingLocations = "Lieux d'exploitation";
const phoneNumber = 'Numéro de téléphone principal';
const status = 'Statut';

export const companyDetailsTranslation: CompanyDetailsTranslation = {
  modal: {
    title: {
      addCompany,
      editCompany: "Modifier l'entreprise",
    },
    addMsa: 'Ajouter une MSA',
    addNda: 'Ajouter un NDA',
    address,
    companyName,
    companyProfile,
    defaultRole: 'Rôle par défaut',
    description,
    emailDomain: "Domaine de l'email de l'entreprise, par ex. 'gmail.com'",
    email,
    location,
    operatingLocations,
    orangeAffiliate: 'Filiale Orange',
    phoneNumber,
  },
  button: {
    addCompany,
  },
  details: {
    address,
    companyProfile,
    description,
    defaultRole: 'Rôle par défaut',
    domainName: "Domaine de l'entreprise",
    email,
    location,
    msa: 'MSA',
    nda: 'NDA',
    operatingLocations,
    phoneNumber,
    status,
    button: {
      edit: 'Modifier',
      deactivate: 'Désactiver',
      activate: 'Activer',
    },
  },
  table: {
    noResults: 'Aucun résultat',
    columns: {
      actions: 'Approbations en attente',
      companyName,
      companyProfile,
      location: 'Localisation',
    },
    filters: {
      companyName,
      companyProfile,
      employeeFirstName: "Nom de l'employé",
      employeeLastName: "Prénom de famille de l'employé",
      employeeStatus: "Statut de l'employé",
    },
  },
  orangeAffiliate: 'Filiale Orange',
  deactivate: 'Êtes-vous sûr de vouloir désactiver cette entreprise ?',
  activate: 'Êtes-vous sûr de vouloir activer cette entreprise ?',
  editUser: 'Modifier les données',
  representative: "Représentant de l'agence",
  agencyName: "Nom de l'agence",
  ordersCount: 'Nombre de commandes',
  goBack: 'Retour à la liste des entreprises',
  fullName: 'Nom',
  employee: 'Employé',
  employeeFirstName: "Nom de l'employé",
  employeeLastName: "Prénom de l'employé",
  affiliates: 'Affiliés',
  external: 'Externe',
  internal: 'Interne',
  warning: 'Êtes-vous sûr de vouloir supprimer cet employé ?',
  phoneNo: 'Numéro de téléphone',
  editEmployee: "Modifier l'employé",
  employeeStatus: {
    [UserStatusDto.Active]: 'Actif',
    [UserStatusDto.Inactive]: 'Inactif',
    [UserStatusDto.WaitingForApproval]: "En attente d'approbation",
  },
  projectStatus: {
    [VacancyProjectStatusDto.Active]: 'Actif',
    [VacancyProjectStatusDto.Archived]: 'Archivé',
    [VacancyProjectStatusDto.ClosedLost]: 'Fermé perdu',
    [VacancyProjectStatusDto.ClosedWon]: 'Fermé gagné',
    [VacancyProjectStatusDto.NoSalesforceId]: "Pas d'ID Salesforce",
    [VacancyProjectStatusDto.OnHold]: 'En attente',
  },
  buttons: {
    selectAll: 'Tout sélectionner',
    deselectAll: 'Tout désélectionner',
  },
  companyProfile: {
    AFFILIATES: 'Affiliés',
    EXTERNAL: 'Externe',
    INTERNAL: 'Interne',
  },
  employeesTable: {
    column: {
      firstName,
      lastName,
      companyName,
      status,
    },
    filters: {
      firstName,
      lastName,
      status,
    },
  },
};
