import React, { ReactElement, useCallback } from 'react';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { FormPropsModel } from './model/FormProps.model';
import { SelectOption, SelectOptions } from './model/SelectOption.model';
import FormControl from '@mui/material/FormControl';
import CreatableSelect from 'react-select/creatable';
import Select, { StylesConfig, MenuPlacement } from 'react-select';
import { TextError } from './TextError.comp';
import { FormSelectCustomOptionsEnum } from '@/components/ui/formSelect/FormSelectCustomOptions.enum.ts';
import { Box } from '@mui/material';
import { colors } from '@/utils/colors.ts';
import { typography } from '@/utils/typography.ts';
import { isFormFieldRequired } from '@/utils/form/isFormFieldRequired.util.ts';
import { useFormProviderKnowMePropsContext } from '@/components/form/FormProviderKnowMe.comp.tsx';
import { FormFieldLabel } from '@/components/form/FormFieldLabel.comp.tsx';
import { CompanyProfileTypeDto, VacancyCompanyRecommendedStatusDto } from '@/api/generated';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import { useDictionaryTranslation } from '@/common/useDictionaryTranslation.js';

type OptionIdType = number | string;

type BasicProps<ID_TYPE extends OptionIdType = number> = {
  options: SelectOptions<ID_TYPE>;
  customOptions?: FormSelectCustomOptionsEnum;
  isSearchable?: boolean;
  isCreatable?: boolean;
  menuPlacement?: MenuPlacement;
} & (
  | {
      isMulti: true;
      onChange?: (event: [{ id: string; label: string; value: ID_TYPE }]) => void;
    }
  | {
      isMulti?: false;
      onChange?: (event: { label: string; value: ID_TYPE }) => void;
    }
);

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  ID_TYPE extends OptionIdType = number,
> = FormPropsModel<TName> &
  BasicProps<ID_TYPE> &
  (
    | {
        isCreatable: true;
        onCreate: (inputValue: string) => void;
      }
    | {
        isCreatable?: false;
        onCreate?: void;
      }
  );

export const SelectSearchFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  ID_TYPE extends OptionIdType = number,
>(
  props: Props<TFieldValues, TName, ID_TYPE>,
): ReactElement<Props<TFieldValues, TName, ID_TYPE>> => {
  const {
    disabled,
    fullWidth,
    customOptions,
    label,
    placeholder,
    options,
    name,
    isCreatable,
    onCreate,
    menuPlacement,
    ...rest
  } = props;
  const { control } = useFormContext<TFieldValues>();
  const { validation } = useFormProviderKnowMePropsContext();
  const render: ControllerProps<TFieldValues, TName>['render'] = useCallback(
    ({ field, fieldState: { error } }) => {
      const isRequired = isFormFieldRequired(field.name, validation);
      const selectProps = {
        ...field,
        ...rest,
        placeholder: placeholder ?? label,
        isDisabled: disabled,
        options: options.map(option => ({
          ...option,
          value: option.id,
          label:
            customOptions === FormSelectCustomOptionsEnum.VACANCY_COMPANY ? (
              <VacancyCompanyOption
                // @ts-expect-error: Special case for VacancyCompanyOption
                data={option}
                label={option.value}
              />
            ) : (
              option.value
            ),
        })),
        menuPlacement: menuPlacement ?? 'bottom',
      };

      const styles: StylesConfig = {
        control: (styles, state) => ({
          ...styles,
          height: '100%',
          padding: '4px 6px',
          color: state.isDisabled ? colors.darkGrey : colors.black,
          borderColor: error ? colors.red : state.isFocused ? colors.blue : colors.stroke,
          boxShadow: 'none',
          ':hover': {
            borderColor: error ? colors.red : state.isFocused ? colors.blue : colors.lightGrey,
          },
          backgroundColor: state.isDisabled ? colors.bg : colors.white,
        }),
        indicatorsContainer: styles => ({ ...styles, height: '100%' }),
        container: styles => ({ ...styles, height: '100%' }),
        menu: styles => ({ ...styles, zIndex: 1000 }),
        menuList: styles => ({ ...styles, maxHeight: '250px' }),
        indicatorSeparator: styles => ({ ...styles, display: 'none' }),
        dropdownIndicator: styles => ({ ...styles, color: colors.blue }),
        singleValue: styles => ({ ...styles, ...typography.f14Regular, color: 'inherit' }),
        option: (styles, state) => ({
          ...styles,
          background: (state.data as SelectOption).isRecommendedStatus
            ? colors.yellow76
            : styles.background,
        }),
        placeholder: styles => ({
          ...styles,
          ...typography.f14Regular,
          color: colors.darkGrey,
          opacity: 0.4,
        }),
      };

      return (
        <FormControl fullWidth={fullWidth ?? true} sx={{ height: '100%', mt: 2 }}>
          <Box style={{ position: 'relative' }}>
            <Box style={{ position: 'relative' }}>
              {isCreatable ? (
                // @ts-expect-error: options type mismatch ?
                <CreatableSelect
                  {...selectProps}
                  onCreateOption={onCreate}
                  styles={styles}
                  className={error ? 'react-select-error' : ''}
                />
              ) : (
                // @ts-expect-error: options type mismatch ?
                <Select
                  {...selectProps}
                  styles={styles}
                  className={error ? 'react-select-error' : ''}
                />
              )}
            </Box>
            <FormFieldLabel label={label} isRequired={isRequired} isError={Boolean(error)} />
            <TextError error={error} />
          </Box>
        </FormControl>
      );
    },
    [
      validation,
      rest,
      placeholder,
      label,
      disabled,
      options,
      menuPlacement,
      fullWidth,
      isCreatable,
      onCreate,
      customOptions,
    ],
  );

  return (
    <>
      <Controller control={control} name={name} render={render} />
    </>
  );
};

const VacancyCompanyOption: React.FC<{
  data: { isRecommendedStatus: VacancyCompanyRecommendedStatusDto; profile: CompanyProfileTypeDto };
  label: string;
}> = props => {
  const { data, label } = props;
  const { dt } = useDictionaryTranslation();

  return (
    <>
      <label style={{ color: data.isRecommendedStatus ? 'black' : 'inherit', paddingRight: '4px' }}>
        {label} - {dt('companyProfiles', data.profile)}
      </label>
      {data.isRecommendedStatus && <StarBorderRoundedIcon />}
    </>
  );
};
