import React from 'react';
import { colors } from '@/utils/colors.ts';
import { LinearProgressProps, SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

type Props = LinearProgressProps & { value: number; label: string; onDetailsClick: () => void };

export const AiSearchParamItemOverall: React.FC<Props> = props => {
  const { value, label, onDetailsClick } = props;

  return (
    <>
      <Grid
        sx={ResultItemGird}
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item style={{ textAlign: 'left' }}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" display="span" style={{ marginRight: 8 }}>
              {label}:
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <b>{`${Math.round(value * 100)}%`}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button sx={DetailsButton} onClick={onDetailsClick}>
            Details
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const ResultItemGird: SxProps = {
  position: 'relative',
  bottom: 10,
  left: 0,
  zIndex: 1,
  fontSize: 12,
  fontWeight: 600,
  margin: '0 0',
  color: colors.black_65,
  display: 'flex',
  background: '#E9F6FF',
  width: '100%',
  padding: 2,
  borderRadius: '0 0 4px 4px',
};

const DetailsButton: SxProps = {
  background: colors.black_80,
  color: colors.white,
  borderRadius: '50px',
  padding: '4px 12px',
  textTransform: 'capitalize',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'center',
  '&:hover': {
    background: colors.black,
  },
};
