import {
  OcdCvSectionDto,
  ResumeFileFormatDto,
  ResumeTemplateTypeDto,
} from '@/api/generated-user-profile';

export type UserProfileMainSectionTranslation = {
  title: string;
  noDataYetLine1: string;
  noDataYetLine2: string;
  button: {
    createProfile: string;
  };
  content: {
    availability: {
      availableFrom: string;
      available: string;
      isAvailable: {
        yes: string;
        no: string;
      };
      noData: string;
      modal: {
        title: string;
        button: {
          edit: string;
        };
      };
    };
    division: string;
    divisionLocation: string;
    employeeLocation: string;
    manager: string;
    mobile: string;
    position: string;
    summaryAnonymized: string;
    summaryPublic: string;
    button: {
      edit: string;
      downloadCv: string;
    };
    cvModal: {
      content: {
        button: {
          download: string;
        };
        whichSections: string;
        whichProjects: string;
        whichExperiences: string;
        section: Record<OcdCvSectionDto, string>;
        parameters: {
          anonymous: {
            employeeLabel: string;
            customerLabel: string;
            options: {
              yes: string;
              no: string;
            };
          };
          fileFormat: {
            label: string;
            options: Record<ResumeFileFormatDto, string>;
          };
          template: {
            label: string;
            options: Record<ResumeTemplateTypeDto, string>;
          };
        };
      };
      title: string;
    };
    lastModification: string;
  };
  availabilityForm: {
    available: string;
    availableFrom: string;
    button: {
      save: string;
    };
  };
  form: {
    avatar: {
      caption: string;
      scale: string;
      errors: {
        fileToBig: string;
        wrongFormat: string;
      };
    };
    division: string;
    divisionLocation: string;
    email: string;
    employeeLocation: string;
    firstName: string;
    lastName: string;
    manager: string;
    mobile: string;
    position: string;
    summaryAnonymized: string;
    summaryAnonymizedPlaceholder: string;
    summaryPublic: string;
    summaryPublicPlaceholder: string;
    button: {
      save: string;
    };
  };
  modal: {
    title: string;
    titleCreate: string;
  };
};

export const userProfileMainSectionTranslation: UserProfileMainSectionTranslation = {
  title: 'Profile',
  noDataYetLine1: 'You have no profile data set yet.',
  noDataYetLine2:
    "Press the 'Begin' button below to start creating your\n" + 'Professional Profile',
  button: {
    createProfile: 'Begin',
  },
  content: {
    availability: {
      availableFrom: 'Available from',
      available: 'Is available',
      noData: 'No data',
      isAvailable: {
        yes: 'Available',
        no: 'Unavailable',
      },
      modal: {
        title: 'Update availability',
        button: {
          edit: 'Update',
        },
      },
    },
    division: 'Division',
    divisionLocation: 'Division location',
    employeeLocation: 'Location',
    manager: 'Manager',
    mobile: 'Mobile',
    position: 'Position',
    summaryAnonymized: 'Anonymized description',
    summaryPublic: 'Standard description',
    button: {
      edit: 'Edit profile',
      downloadCv: 'Download CV',
    },
    cvModal: {
      content: {
        button: {
          download: 'Download',
        },
        whichSections: 'Please select which sections you would like to include',
        whichProjects: 'Please select which projects you would like to include',
        whichExperiences: 'Please select which work experiences you would like to include',
        parameters: {
          anonymous: {
            employeeLabel: "Anonymize employee's data",
            customerLabel: "Anonymize projects' data",
            options: {
              yes: 'Yes',
              no: 'No',
            },
          },
          fileFormat: {
            label: 'File format',
            options: {
              [ResumeFileFormatDto.Pdf]: 'PDF',
              [ResumeFileFormatDto.Docx]: 'DOCX',
            },
          },
          template: {
            label: 'Template',
            options: {
              [ResumeTemplateTypeDto.KnowMe]: 'KnowMe',
              [ResumeTemplateTypeDto.Ocd]: 'OCD',
            },
          },
        },
        section: {
          [OcdCvSectionDto.Awards]: 'Awards',
          [OcdCvSectionDto.Certificates]: 'Certificates',
          [OcdCvSectionDto.Contact]: 'Contact',
          [OcdCvSectionDto.Courses]: 'Courses',
          [OcdCvSectionDto.Description]: 'Description',
          [OcdCvSectionDto.Education]: 'Education',
          [OcdCvSectionDto.Experience]: 'Experience',
          [OcdCvSectionDto.Languages]: 'Languages',
          [OcdCvSectionDto.Projects]: 'Projects',
          [OcdCvSectionDto.Skills]: 'Skills',
        },
      },
      title: 'Generate CV',
    },
    lastModification: 'Last modification',
  },
  availabilityForm: {
    available: 'Is available',
    availableFrom: 'Available from',
    button: {
      save: 'Save',
    },
  },
  form: {
    avatar: {
      caption: 'Change image',
      scale: 'Scale',
      errors: {
        fileToBig: 'File is too big',
        wrongFormat: 'Wrong format',
      },
    },
    division: 'Division',
    divisionLocation: 'Division location',
    email: 'Email',
    employeeLocation: 'Location',
    firstName: 'First Name',
    lastName: 'Last Name',
    manager: 'Manager',
    mobile: 'Mobile',
    position: 'Position',
    summaryAnonymized: 'Anonymized description',
    summaryAnonymizedPlaceholder: 'Summarize you achievements without identifying info',
    summaryPublic: 'Standard description',
    summaryPublicPlaceholder: 'Summarize you achievements',
    button: {
      save: 'Save',
    },
  },
  modal: {
    title: 'Edit profile',
    titleCreate: 'Create profile',
  },
};
