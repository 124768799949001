import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';

export const initialProfileSearchRequestModel: ProfileSearchFormModel = {
  availableOnly: false,
  skills: [],
  certificates: [],
  languages: [],
  addType: null,
  fullTextSearch: '',
  firstName: '',
  lastName: '',
  section: undefined,
};
