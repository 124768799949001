import { useApiClient } from '@/api/util/useApiClient.util';
import { DictionaryEnumDto } from '@/api/generated';
import { useState } from 'react';

interface Return {
  getEnums: () => Promise<DictionaryEnumDto[]>;
  enums: DictionaryEnumDto[];
  isFetchingEnums: boolean;
}

export const useGetEnums = (): Return => {
  const { dictionariesApi } = useApiClient();
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState<DictionaryEnumDto[]>([]);

  return {
    getEnums: async () => {
      setIsFetching(true);
      try {
        const response = await dictionariesApi.getEnums();
        setResponse(response?.data);
        return response?.data;
      } finally {
        setIsFetching(false);
      }
    },
    isFetchingEnums: isFetching,
    enums: response ?? [],
  };
};
