import { isAxiosError, isErrorWithMessage } from './isAxiosError.guard';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import CONFIG from '../../config/config';
import { LocalStorageParam, setLocalStorage } from '@/utils/localStorage.util.ts';
import { HTTP_STATUS } from './ErrorCode.model';
import { SYSTEM_ROUTS } from '@/routes/SystemRoutes.enum.ts';
import { OcdErrorCodesEnum } from '../models/OcdErrorCodes.enum';

type Return = (
  error: unknown,
  errorHandler: (error: string) => void,
) => Promise<unknown> | undefined;

export const PATHS_NOT_REQUIRING_AUTHORIZATION = [
  RouteEnum.NOT_AUTHORIZED,
  RouteEnum.LOGIN,
  RouteEnum.WAITING_FOR_APPROVAL,
];

export const LOGIN_REDIRECT_URL = CONFIG.HOST + '/login';

const handleError = (error: unknown, errorHandler: (error: string) => void) => {
  if (typeof error === 'string') {
    errorHandler(error);
  } else if (isErrorWithMessage(error)) {
    errorHandler(error.message);
  }
};

export const httpErrorHandler: Return = (error, errorHandler) => {
  if (!isAxiosError(error)) {
    handleError(error, errorHandler);
    return Promise.reject(error);
  }
  if (
    error.response?.status !== HTTP_STATUS.FORBIDDEN &&
    error.response?.status !== HTTP_STATUS.UNAUTHORIZED
  ) {
    handleError(error, errorHandler);
    throw error;
  }
  const pathname = window.location.pathname;
  if (PATHS_NOT_REQUIRING_AUTHORIZATION.some(path => pathname.includes(path))) {
    return;
  }

  if (error.response.data && (error.response.data as { status?: number }).status) {
    const { status } = error.response.data as { status?: string };

    if (status === OcdErrorCodesEnum.NOT_AUTHORIZED) {
      (window as Window).location = window.location.origin + RouteEnum.NOT_AUTHORIZED;
      return;
    }
    if (status === OcdErrorCodesEnum.WAIT_FOR_APPROVAL) {
      (window as Window).location = window.location.origin + RouteEnum.WAITING_FOR_APPROVAL;
      return;
    }
  }

  if (!SYSTEM_ROUTS.includes(pathname)) {
    setLocalStorage(LocalStorageParam.LAST_VISITED_LOCATION, pathname);
  }

  window.location.href = LOGIN_REDIRECT_URL;
};
