import { useCallback, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { ThemeProvider } from '@mui/material/styles';
import SideBar from './layouts/private/sidebar/SideBar.comp';
import { TopBar } from './layouts/private/topBar/TopBar.comp.tsx';
import { knowMeTheme } from './theme.ts';
import { ErrorBoundary } from '@/errorHandling/ErrorBoundary.comp.tsx';
import { AppRoutes } from './routes/AppRoutes.comp';
import { UserInitializeController } from './layouts/controllers/UserInitialize.controller';
import { LocalStorageParam, setLocalStorage } from './utils/localStorage.util';
import { returnPromise } from './utils/returnPromise.util';
import { useLogout } from './api/useLogout.util';
import { CopyObjectContextProvider } from './contexts/copyObject/CopyObjectContextProvider.util';
import { DictionariesContextProvider } from './contexts/dictionaries/DictionariesContextProvider.util';
import { NotificationsContextProvider } from './contexts/notifications/NotificationsContextProvider.util';
import { SYSTEM_ROUTS } from '@/routes/SystemRoutes.enum.ts';
import { UserContextProvider } from '@/contexts/userContext/UserContextProvider.util.tsx';
import { QueryClientWrapper } from '@/config/QueryClientWrapper.comp.tsx';
import { UserContextVerifier } from '@/contexts/userContext/UserContextVerifier.util.tsx';
import { LogoutController } from '@/layouts/controllers/Logout.controller.tsx';
import Box from '@mui/material/Box';
import { PageContextProvider } from '@/contexts/pageContext/PageContext.provider.tsx';

const TIMEOUT = 60 * 30 * 1000;

function App() {
  const { logout } = useLogout();

  const setCurrentLocation = useCallback(async () => {
    await returnPromise(null, 500);
    const pathname = window.location.pathname;

    if (!SYSTEM_ROUTS.includes(pathname)) {
      setLocalStorage(LocalStorageParam.LAST_VISITED_LOCATION, pathname);
    }
  }, []);

  useEffect(() => {
    void setCurrentLocation();
  }, []);

  const onIdle = async () => {
    await logout();
  };

  useIdleTimer({
    timeout: TIMEOUT,
    onIdle: onIdle,
  });

  return (
    <PageContextProvider>
      <ThemeProvider theme={knowMeTheme}>
        <ErrorBoundary>
          <QueryClientWrapper>
            <BrowserRouter>
              <LogoutController />
              <UserContextProvider>
                <UserContextVerifier>
                  <DictionariesContextProvider>
                    <NotificationsContextProvider>
                      <CopyObjectContextProvider>
                        <UserInitializeController />
                        <Box sx={{ display: 'flex' }}>
                          <SideBar />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              backgroundColor: 'primary.bg',
                              width: '100%',
                              minHeight: '100vh',
                            }}
                          >
                            <TopBar />
                            <AppRoutes />
                          </Box>
                        </Box>
                      </CopyObjectContextProvider>
                    </NotificationsContextProvider>
                  </DictionariesContextProvider>
                </UserContextVerifier>
              </UserContextProvider>
            </BrowserRouter>
          </QueryClientWrapper>
        </ErrorBoundary>
      </ThemeProvider>
    </PageContextProvider>
  );
}

export default App;
