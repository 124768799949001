import { VacancyDetailsHeaderTranslation } from '@/assets/locales/views/vacancyDetails/header/vacancyDetails.header.translation.en';

export const vacancyDetailsHeaderTranslation: VacancyDetailsHeaderTranslation = {
  button: {
    copy: 'Anfrage kopieren',
    edit: 'Anfrage bearbeiten',
    addCandidate: 'Kandidaten hinzufügen',
    findCandidates: 'Kandidaten suchen',
  },
};
