export type ValidationTranslation = {
  charactersLimit: string;
  date: { maxCurrentDate: string; maxDate: string; minCurrentDate: string; notEarlierThen: string };
  email: string;
  general: { required: string; wrongFormat: string };
  iban: string;
  incorrectFileExtension: string;
  integerNumber: string;
  isNumber: string;
  length: string;
  max: string;
  maxLength: string;
  maxLengthOfMessage: string;
  maxStringLength: string;
  min: string;
  minLength: string;
  minNumber: string;
  minStringLength: string;
  mobile: string;
  nip: string;
  noResults: string;
  passwordMustMatch: string;
  passwordRequirements: string;
  pesel: string;
  phone: string;
  pinsMustMatch: string;
  rating: string;
  regon: string;
  required: string;
  stringLength: string;
  tooLargeFileSize: string;
};

export const validationTranslation: ValidationTranslation = {
  charactersLimit: 'Maximum number of characters is {{noOfChars}}',
  date: {
    maxCurrentDate: 'Date cannot be in the future',
    maxDate: 'Date cannot be later then {{maxDate}}',
    minCurrentDate: 'Date cannot be in the past',
    notEarlierThen: '{{to}} cannot be earlier then {{from}}',
  },
  email: 'E-mail is not valid',
  general: { required: 'Field is required', wrongFormat: 'Wrong format provided' },
  iban: 'The IBAN number is incorrect',
  incorrectFileExtension: 'Incorrect file extension. Supported extensions: {{correctExtensions}}',
  integerNumber: 'Field must be an integer',
  isNumber: 'Field has to be a number',
  length: 'Field has to be {{length}} long',
  max: 'Maximum value is {{howMany}}',
  maxLength: 'Field has to be at most {{max}} characters long',
  maxLengthOfMessage: 'The maximum length of the message is {{max}} characters',
  maxStringLength: 'Maximum length for this field is: {{max}}',
  min: 'Minimum value is {{howMany}}',
  minLength: 'Field has to be at least {{min}} characters long',
  minNumber: 'Minimum value for this field is: {{minNumber}}',
  minStringLength: 'Minimum length for this field is: {{min}}',
  mobile: 'Phone number is not valid - only plus (+), dashes (-) and digits allowed',
  nip: 'Invalid NIP value',
  noResults: 'No results',
  passwordMustMatch: 'Passwords must match',
  passwordRequirements:
    "Passwords must contain one digit, one small letter, one capital letter and one special character. Password's length must be between 8 and 100 characters.",
  pesel: 'The PESEL number is incorrect',
  phone: 'Phone number is not valid (XXX-XXX-XXX)',
  pinsMustMatch: 'Pins must match',
  rating: 'Please select your rating',
  regon: 'Invalid REGON value',
  required: 'This field is required',
  stringLength: 'This field has to be {{length}} characters long',
  tooLargeFileSize: 'File size is too large. Maximum file size is: {{maxFileSize}}',
};
