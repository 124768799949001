import { useApiClient } from '@/api/util/useApiClient.util.ts';
import { SessionDetailsDto } from '@/api/generated';
import { useQuery } from '@tanstack/react-query';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';
import { AxiosError } from 'axios';
import { RefetchModel } from '@/api/model/Refetch.model.ts';

type Response = SessionDetailsDto | undefined;

type Return = {
  getSessionDetails: RefetchModel<Response>;
  isGettingSession: boolean;
  isErrorGettingSession: boolean;
  data: Response;
};

export const useGetSession = (): Return => {
  const { sessionApi } = useApiClient();
  const { isLoading, refetch, isError, fetchStatus, data } = useQuery<Response, AxiosError>({
    enabled: false,
    queryFn: async () => {
      const response = await sessionApi.getSessionDetails();
      return response.data;
    },
    queryKey: [QueryKeyEnum.SESSION_GET],
  });
  return {
    getSessionDetails: refetch,
    isGettingSession: fetchStatus !== 'idle' && isLoading,
    isErrorGettingSession: isError,
    data,
  };
};
