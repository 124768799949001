export type VacancyCandidateModalTranslation = {
  title: {
    add: string;
    edit: string;
  };
  form: {
    firstName: string;
    lastName: string;
    languages: string;
    location: string;
    expectedDailyRate: string;
    currencyId: string;
    startDayAvailability: string;
    externalResumeFileUrl: string;
    resume: string;
  };
};

export const vacancyCandidateModalTranslation: VacancyCandidateModalTranslation = {
  title: {
    add: 'Add candidate',
    edit: 'Edit candidate',
  },
  form: {
    firstName: 'First name',
    lastName: 'Last name',
    languages: 'Languages',
    location: 'Location',
    expectedDailyRate: 'Expected daily rate from',
    currencyId: 'Currency',
    startDayAvailability: 'Start day availability',
    externalResumeFileUrl: 'CV url',
    resume: 'Resume file',
  },
};
