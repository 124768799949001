import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SelectSearchFormField } from '@/components/form/SelectSearchFormField.comp.tsx';
import { useDictionariesContext } from '@/contexts/dictionaries/Dictionaries.context.ts';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { ProfileSearchSkillFilterFormModel } from '@/views/profileSearch/components/filters/skill/model/ProfileSearchSkillFilterForm.model.ts';
import { useProfileSearchAddSkillValidation } from '@/views/profileSearch/components/filters/skill/util/useProfileSearchAddSkill.validation.ts';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExtendedDictionaryEntryDto } from '@/api/generated';
import { SkillSearchRequestDto } from '@/api/generated-user-profile';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { SliderFormField } from '@/components/form/SliderFormField.comp.tsx';
import { ProfileSearchAddSkillButtons } from '@/views/profileSearch/components/filters/ProfileSearchAddSkillButtons.comp.tsx';
import {
  ProfileSearchAddFilterMode,
  ProfileSearchAddFilterProps,
} from '@/views/profileSearch/components/filters/model/ProfileSearchAddFilterProps.model.ts';
import { UserProfileSkillSlider } from '@/views/userProfile/components/sections/skills/UserProfileSkillSlider.comp.tsx';
import { profileSearchSkillFilterMarksModel } from '@/views/profileSearch/components/filters/skill/model/ProfileSearchSkillFilterMarks.model.ts';
import { SelectSearchOptionModel } from '@/components/form/model/SelectSearchOption.model.ts';

type Props = ProfileSearchAddFilterProps<SkillSearchRequestDto>;

export const ProfileSearchAddSkillFilter: React.FC<Props> = props => {
  const { skills } = useDictionariesContext();
  const { onReset: handleReset, currentItems, mode } = props;
  const validation = useProfileSearchAddSkillValidation();
  const { t } = useTranslationWithPrefix('views.profileSearch.form.addSkillFilter');
  const [options, setOptions] = useState<Required<ExtendedDictionaryEntryDto>[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [oldItem, setOldItem] = useState<SelectSearchOptionModel<number> | undefined>(undefined);

  const editingMode = mode === ProfileSearchAddFilterMode.EDIT;

  const defaultValues: ProfileSearchSkillFilterFormModel = editingMode
    ? {
        skill: {
          value: props.initValue.value,
          label: skills.find(item => item.id === props.initValue.value)?.value ?? 'No label',
        },
        level: props.initValue.level,
        experienceInYears: props.initValue.experienceInMonths / 12,
      }
    : {
        level: 0,
        experienceInYears: 0,
      };

  const formMethods = useForm<ProfileSearchSkillFilterFormModel>({
    defaultValues,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    if (currentItems.length === 0) {
      setOptions(skills);
    } else {
      setOptions(skills.filter(item => !currentItems.some(x => x.value === item.id)));
    }
  }, [currentItems]);

  const handleSubmit: SubmitHandler<ProfileSearchSkillFilterFormModel> = useCallback(
    async form => {
      if (!form.skill) {
        return;
      }
      const skill = {
        value: form.skill.value,
        level: form.level,
        experienceInMonths: form.experienceInYears * 12,
      };
      if (editingMode) {
        if (!oldItem) {
          return;
        }
        if (oldItem.value === skill.value) {
          setIsEditing(false);
          return;
        }
        props.onEdit({ item: skill, oldItem });
        return;
      }
      props.onSubmit(skill);
    },
    [editingMode, oldItem, props],
  );

  const handleEdit = () => {
    setOldItem(formMethods.getValues().skill);
    setIsEditing(true);
  };

  const selectedSkillLevel = formMethods.watch('level');
  const disabled = editingMode && !isEditing;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Grid container alignItems="center" spacing={4} sx={{ pt: disabled ? 1 : 2 }}>
          <Grid item xs={10}>
            <Grid container alignItems="center" spacing={6}>
              <Grid item xs={3}>
                <SelectSearchFormField
                  options={options}
                  name="skill"
                  label={t('skill')}
                  disabled={editingMode}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid container direction="column">
                  <UserProfileSkillSlider
                    selectedSkillLevel={selectedSkillLevel}
                    disabled={disabled}
                    hideDescriptions
                    t={t}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <SliderFormField
                  name="experienceInYears"
                  label={t('experienceInYears')}
                  min={0}
                  max={5}
                  step={1}
                  marks={profileSearchSkillFilterMarksModel}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <ProfileSearchAddSkillButtons
              onReset={handleReset}
              isSubmittable={!editingMode || isEditing}
              onEdit={handleEdit}
              isEditable
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
