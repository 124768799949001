import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileCourseDto } from '@/api/generated-user-profile';

export type UserProfileCoursesSectionTranslation = SectionTranslationType<
  Omit<UserProfileCourseDto, 'userProfileId' | 'courseId' | 'contentLanguage'>
>;

export const userProfileCoursesSectionTranslation: UserProfileCoursesSectionTranslation = {
  headerTitle: 'Courses',
  content: {
    emptyItemsLabel: 'courses',
    button: {
      edit: 'Edit',
    },
  },
  modal: {
    title: 'Add course',
  },
  form: {
    actions: 'Actions',
    name: 'Name',
    date: 'Completion date',
    description: 'Description',
    organizer: 'Organizer',
    url: 'Url',
    button: {
      save: 'Save',
    },
  },
};
