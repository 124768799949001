import { SearchTranslation } from '@/assets/locales/search/search.translation.en.ts';

export const searchTranslation: SearchTranslation = {
  buttonSearch: 'Suchen',
  categories: {
    availability: 'Nur verfügbar',
    certificates: 'Bescheinigung',
    companies: 'Vorheriges Unternehmen',
    contractType: 'Art des Vertrages',
    createdBy: 'Erstellt von',
    cvSourceCompany: 'Lebenslauf-Quelle Unternehmen',
    email: 'E-mail',
    experience: 'Betriebszugehörigkeit (z.B. 2J6M)',
    firstName: 'Vorname',
    language: 'Sprache',
    lastName: 'Nachname',
    location: 'Ort',
    locations: 'Ort',
    names: 'Vorname und/oder Nachname',
    positions: 'Stelle',
    projects: 'Projekt',
    requesters: 'Antragstellers',
    skills: 'Fächigkeit',
    starred: 'mit einem Sternchen versehen',
    status: 'Status',
    uploaderOfCv: 'Hochlader des Lebenslaufs',
  },
  choose: 'Wählen...',
  clearParameters: 'Löschen',
  downloadCV: 'Lebenslauf herunterladen',
  downloadCVMultiple: 'Mehrere Lebensläufe herunterladen',
  downloadXls: 'Herunterladen xls',
  hideParameters: 'Parameters verstecken',
  parameters: 'Parameters suchen',
  paramsNumber: 'Parameters suchen:',
  search: 'Suchen...',
  selectedEmployees: 'Ausgewählte Arbeitnehmer:',
  showParameters: 'Parameters zeigen',
  table: {
    action: 'Aktion',
    fullName: 'Voller Name',
    firstName: 'Vorname',
    lastModificationDate: 'Letztes Update',
    lastName: 'Nachname',
    location: 'Ort',
    maxRows: 'Maximale Reihen pro Seite',
    nextPage: 'Nächste Seite',
    position: 'Stelle',
    previousPage: 'Vorherige Seite',
    profile: 'Profil',
    showProfile: 'Profil zeigen',
    of: 'von',
    moreThan: 'mehr als',
    availability: 'Verfügbarkeit',
    true: 'Ja',
    false: 'Nein',
    from: 'Aus',
    positionChange: 'Interessiert an einem Übergang in',
  },
  title: 'Suche nach Mitarbeitern',
  switchToAdvanced: 'Zu den erweiterten Filtern wechseln',
  switchToBasic: 'Zu den Basisfiltern wechseln',
  from: 'aus',
  to: 'zu',
  lastMeetingDate: 'Datum der letzten Sitzung',
  profileCompleteness: 'Vollständigkeit des Profils (%)',
  withoutAMeeting: 'Ohne eine Sitzung innerhalb der letzten ...',
  months3: '3 Monaten',
  months6: '6 Monaten',
  months9: '9 Monaten',
  withProfileCompleteness: 'Vollständigkeit des Profils unter ...',
  withProfileOlder: 'Profil älter als ...',
  selectTimePeriod: 'Zeitraum auswählen',
  selectValue: 'Wert auswählen',
  lastModificationDate: 'Datum der letzten Aktualisierung',
  showProficiencies: 'Fertigkeiten zeigen',
  hideProficiencies: 'Fähigkeiten verstecken',
  downloading: 'Herunterlanden...',
  nextMeetingDate: 'Datum der nächsten Sitzung',
  nextMeetingDateWithin: 'Datum der nächsten Sitzung innerhalb ...',
  days7: '1 Woche',
  days14: '2 Wochen',
  days30: '1 Monat',
  notSet: 'nicht festgelegt',
  createdAt: 'Datum erstellen',
  replyDate: 'Antwortdatum',
  startingDate: 'Datum des Beginns der Beschäftigung',
  changeDate: 'Datum ändern',
};
