import React from 'react';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import { useForm } from 'react-hook-form';
import { initialProfileSearchRequestModel } from '@/views/profileSearch/model/InitialProfileSearchRequest.model.ts';
import {
  ProfileSearchAddItemType,
  ProfileSearchFormModel,
} from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { ProfileSearchAddFilter } from '@/views/profileSearch/components/filters/ProfileSearchAddFilter.comp.tsx';
import { ProfileSearchSkillsFilters } from '@/views/profileSearch/components/filters/skill/ProfileSearchSkillsFilters.comp.tsx';
import { ProfileSearchResults } from '@/views/profileSearch/components/results/ProfileSearchResults.comp.tsx';
import { Box } from '@mui/material';
import { ProfileSearchCertificatesFilters } from '@/views/profileSearch/components/filters/certificate/ProfileSearchCertificatesFilters.comp.tsx';
import { ProfileSearchLanguagesFilters } from '@/views/profileSearch/components/filters/language/ProfileSearchLanguagesFilters.comp.tsx';
import { ProfileSearchForm } from '@/views/profileSearch/components/ProfileSearchForm.comp.tsx';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { useHandleSearchProfile } from '@/views/profileSearch/util/useHandleSearchProfile.util.ts';
import { ProfileSearchController } from '@/views/profileSearch/components/ProfileSearch.controller.tsx';
import { VacancyDetailsForSearch } from '@/views/profileSearch/model/vacancyDetailsForSearch.model.ts';
import { ProfileFullTextSearchCategories } from '@/views/profileSearch/components/ProfileFullTextSearchCategories.comp.tsx';
import { ProfileFullTextSearchSkillsPropositions } from '@/views/profileSearch/components/ProfileFullTextSearchSkillsPropositions.comp.tsx';
import { ProfileSearchFilterType } from '@/views/profileSearch/model/ProfileSearchFilterType.model.ts';

const ProfileSearchView: React.FC = () => {
  const { t } = useTranslationWithPrefix('views.profileSearch');
  const { handleSubmitSearch, isSearching, isSearchDone } = useHandleSearchProfile();
  const formMethods = useForm<ProfileSearchFormModel>({
    defaultValues: initialProfileSearchRequestModel,
  });

  const resetAddType = () => {
    formMethods.setValue('addType', null);
  };

  const handleSubmitFilter = (prop: ProfileSearchFilterType) => (item: { value: number }) => {
    const currentItems = formMethods.watch(prop);
    formMethods.setValue(prop, [...currentItems, item]);
    resetAddType();
  };

  const handleRemoveFilter = (item: { value: number }, prop: ProfileSearchFilterType) => () => {
    const currentItems = [...formMethods.getValues()[prop]];
    const index = currentItems.findIndex(x => x.value === item.value);
    if (index < 0) {
      return;
    }
    currentItems.splice(index, 1);
    formMethods.setValue(prop, currentItems);
  };

  const handleEditFilter =
    (prop: ProfileSearchFilterType) =>
    (params: { item: { value: number }; oldItem: { value: number } | undefined }) => {
      const { item, oldItem } = params;
      if (!oldItem) {
        return;
      }
      const currentItems = [...formMethods.getValues()[prop]];
      const index = currentItems.findIndex(x => x.value === oldItem.value);
      if (index < 0) {
        return;
      }
      currentItems.splice(index, 1);
      formMethods.setValue(prop, [...currentItems, item]);
    };

  const addType = formMethods.watch('addType');
  const skills = formMethods.watch('skills');
  const certificates = formMethods.watch('certificates');
  const languages = formMethods.watch('languages');

  const handleAddAnother = (type: ProfileSearchAddItemType) => () => {
    formMethods.setValue('addType', {
      value: type,
      label: t('form.addTypeOption.' + type),
    });
  };

  return (
    <>
      <ProfileSearchController formMethods={formMethods} onSubmitSearch={handleSubmitSearch} />
      <Box display="flex" flexDirection="column">
        <ViewWrapper
          header={{
            pageTitle: !VacancyDetailsForSearch.value ? 'profileSearch' : undefined,
            customTitle: VacancyDetailsForSearch.value
              ? t('content.vacancyTitle', { vacancy: VacancyDetailsForSearch.value.name })
              : undefined,
          }}
        >
          <ProfileSearchForm
            formMethods={formMethods}
            addType={addType}
            handleSubmitSearch={handleSubmitSearch}
          />
          <ProfileSearchAddFilter
            addTypeItemType={addType?.value}
            onFilterSubmit={handleSubmitFilter}
            onReset={resetAddType}
            currentLanguages={languages}
            currentCertificates={certificates}
            currentSkills={skills}
          />
          <ProfileSearchSkillsFilters
            onAddAnother={handleAddAnother}
            skills={skills}
            onRemove={handleRemoveFilter}
            onEdit={handleEditFilter}
          />
          <ProfileSearchCertificatesFilters
            onAddAnother={handleAddAnother}
            certificates={certificates}
            onRemove={handleRemoveFilter}
            onEdit={handleEditFilter}
          />
          <ProfileSearchLanguagesFilters
            onAddAnother={handleAddAnother}
            languages={languages}
            onRemove={handleRemoveFilter}
            onEdit={handleEditFilter}
          />
        </ViewWrapper>
        <ProfileFullTextSearchCategories
          formMethods={formMethods}
          handleSubmitSearch={handleSubmitSearch}
        />
        <ProfileFullTextSearchSkillsPropositions
          formMethods={formMethods}
          onSubmitSearch={handleSubmitSearch}
        />
        <ProfileSearchResults
          isSearching={isSearching}
          resultsReady={isSearchDone}
          formMethods={formMethods}
        />
      </Box>
    </>
  );
};

export default ProfileSearchView;
