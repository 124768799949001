import React from 'react';
import { UserProfileSectionDto } from '@/api/generated-user-profile';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { Box } from '@mui/material';
import { profileSearchStyles } from '@/views/profileSearch/styles/profileSearch.styles.ts';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { UseFormReturn } from 'react-hook-form';
import { HandleSubmitSearchType } from '@/views/profileSearch/util/useHandleSearchProfile.util.ts';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { ProfileSearchResultsModel } from '@/views/profileSearch/model/ProfileSearchResults.model.ts';

type Props = {
  formMethods: UseFormReturn<ProfileSearchFormModel>;
  handleSubmitSearch: HandleSubmitSearchType;
};

export const ProfileFullTextSearchCategories: React.FC<Props> = props => {
  const { formMethods, handleSubmitSearch } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.fullText.categories');
  const classes = profileSearchStyles();
  const { value: valueResults } = ProfileSearchResultsModel;

  const searchedPhrase = formMethods.watch('fullTextSearch');

  if (valueResults.profiles.length > 0 || !valueResults.items.length || !searchedPhrase) {
    return null;
  }

  const handleSelectCategoryClick = (section: UserProfileSectionDto) => async () => {
    formMethods.setValue('section', section);
    const form = formMethods.getValues();
    await handleSubmitSearch({ form });
  };

  return (
    <>
      <ViewWrapper sx={{ pt: 0 }}>
        <Box className={classes.fullTextCategoriesHeader}>{t('header')}</Box>
        <Grid container spacing={1}>
          {valueResults.items.map(category => (
            <Grid item key={category.section} xs={4}>
              <Chip
                className={classes.fullTextCategoryItem}
                onClick={handleSelectCategoryClick(category.section)}
                label={t(`section.${category.section}`) + ': ' + category.quantity}
                clickable
                color="primary"
                disabled={category.quantity === 0}
              />
            </Grid>
          ))}
        </Grid>
      </ViewWrapper>
    </>
  );
};
