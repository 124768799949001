/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileAward1Dto } from '../models';
/**
 * UserProfileAwardApi - axios parameter creator
 * @export
 */
export const UserProfileAwardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileAward: async (userProfileId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileAward', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileAward', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/awards`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileAward1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileAward: async (userProfileId: string, userProfileAwardId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileAward', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileAwardId' is not null or undefined
            assertParamExists('deleteUserProfileAward', 'userProfileAwardId', userProfileAwardId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileAward', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/awards/{userProfileAwardId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileAwardId"}}`, encodeURIComponent(String(userProfileAwardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAward: async (userProfileId: string, userProfileAwardId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileAward', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileAwardId' is not null or undefined
            assertParamExists('updateUserProfileAward', 'userProfileAwardId', userProfileAwardId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileAward', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/awards/{userProfileAwardId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileAwardId"}}`, encodeURIComponent(String(userProfileAwardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileAward1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileAwardApi - functional programming interface
 * @export
 */
export const UserProfileAwardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileAwardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileAward(userProfileId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileAward(userProfileId, lang, userProfileAward1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAwardApi.createUserProfileAward']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileAward(userProfileId, userProfileAwardId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAwardApi.deleteUserProfileAward']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileAward(userProfileId, userProfileAwardId, lang, userProfileAward1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAwardApi.updateUserProfileAward']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileAwardApi - factory interface
 * @export
 */
export const UserProfileAwardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileAwardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileAward(userProfileId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileAward(userProfileId, lang, userProfileAward1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileAward(userProfileId, userProfileAwardId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAwardId 
         * @param {LangDto} lang 
         * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileAward(userProfileId, userProfileAwardId, lang, userProfileAward1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileAwardApi - interface
 * @export
 * @interface UserProfileAwardApi
 */
export interface UserProfileAwardApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApiInterface
     */
    createUserProfileAward(userProfileId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAwardId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApiInterface
     */
    deleteUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAwardId 
     * @param {LangDto} lang 
     * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApiInterface
     */
    updateUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileAwardApi - object-oriented interface
 * @export
 * @class UserProfileAwardApi
 * @extends {BaseAPI}
 */
export class UserProfileAwardApi extends BaseAPI implements UserProfileAwardApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApi
     */
    public createUserProfileAward(userProfileId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileAwardApiFp(this.configuration).createUserProfileAward(userProfileId, lang, userProfileAward1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAwardId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApi
     */
    public deleteUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileAwardApiFp(this.configuration).deleteUserProfileAward(userProfileId, userProfileAwardId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAwardId 
     * @param {LangDto} lang 
     * @param {UserProfileAward1Dto} [userProfileAward1Dto] User Profile Award update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAwardApi
     */
    public updateUserProfileAward(userProfileId: string, userProfileAwardId: string, lang: LangDto, userProfileAward1Dto?: UserProfileAward1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileAwardApiFp(this.configuration).updateUserProfileAward(userProfileId, userProfileAwardId, lang, userProfileAward1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

