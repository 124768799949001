import React from 'react';
import {
  CertificatesSearchRequestDto,
  LanguageSearchRequestDto,
} from '@/api/generated-user-profile';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchContentWrapper } from '@/views/profileSearch/components/ProfileSearchContentWrapper.comp.tsx';
import { ProfileSearchAddCertificateFilter } from '@/views/profileSearch/components/filters/certificate/ProfileSearchAddCertificateFilter.comp.tsx';
import Grid from '@mui/material/Grid';
import { ProfileSearchFilterType } from '@/views/profileSearch/model/ProfileSearchFilterType.model.ts';
import { ProfileSearchAddFilterMode } from '@/views/profileSearch/components/filters/model/ProfileSearchAddFilterProps.model.ts';
import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';

type Props = {
  certificates: CertificatesSearchRequestDto[];
  onRemove: (item: { value: number }, prop: ProfileSearchFilterType) => () => void;
  onEdit: (
    prop: ProfileSearchFilterType,
  ) => (params: { item: { value: number }; oldItem: { value: number } | undefined }) => void;
  onAddAnother: (type: ProfileSearchAddItemType) => () => void;
};

export const ProfileSearchCertificatesFilters: React.FC<Props> = props => {
  const { certificates, onRemove, onEdit, onAddAnother } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.content');

  if (certificates.length === 0) {
    return null;
  }

  return (
    <>
      <ProfileSearchContentWrapper
        title={t('activeCertificateFilters')}
        onAddAnotherClick={onAddAnother(ProfileSearchAddItemType.certificates)}
      >
        <Grid container spacing={1}>
          {(certificates as Required<LanguageSearchRequestDto>[])
            .sort((a, b) => b.value - a.value)
            .map(item => (
              <Grid item xs={3} key={item.value}>
                <ProfileSearchAddCertificateFilter
                  mode={ProfileSearchAddFilterMode.EDIT}
                  initValue={item}
                  onReset={onRemove(item, 'certificates')}
                  onEdit={onEdit('certificates')}
                  currentItems={[]}
                />
              </Grid>
            ))}
        </Grid>
      </ProfileSearchContentWrapper>
    </>
  );
};
