import { ProfileTranslation } from '@/assets/locales/profile/profile.translation.en';

export const profileTranslation: ProfileTranslation = {
  profileSkills: {
    addTechnology: 'Benennen Sie die Fähigkeit',
  },
  profileProjects: {
    project: 'Projekt',
    client: 'Kunde',
  },
  skills: 'Fähigkeiten & Technologien',
  onboarding: 'Onboarding',
  readMore: 'Mehr lesen',
  aboutMe: 'Über mich',
  linkedIn: 'LinkedIn Profil',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  set: 'Einstellen',
  aboutMeDescription: 'Ein paar Worte über Sie selbst',
  department: 'Abteilung',
  supervisor: 'Abteilungsleiter',
  accomplishments: 'Leistungen',
  additionalInformation: 'Zusätzliche Informationen',
  avatar: 'Profilbild',
  avatarDescription: 'Profilbild hinzufügen und bearbeiten',
  avatarInfo:
    'Sie können Ihr Foto nicht sehen? Zeigen Sie sich von Ihrer guten Seite - vervollständigen Sie Ihr Profil bis zu 95%, um Ihren Avatar zu ändern.',
  awards: 'Ehrungen und Preise',
  awardsDescription: 'Zeigen Sie, wie sehr Sie geschätzt werden',
  basicInfo: 'Grundsätzliche Informationen',
  certificates: 'Zertifikate',
  contact: 'Kontakt',
  copied: 'Link in die Zwischenablage kopiert',
  courses: 'Kurse',
  coursesDescription: 'Laufende oder abgeschlossene Kurse hinzufügen',
  downloadFiles: 'Dateien herunterladen',
  download: 'Herunterladen',
  editProfile: 'Profil bearbeiten',
  education: 'Bildung',
  entryIncomplete: 'Der Eintrag muss ausgefüllt werden.',
  experiences: 'Berufserfahrung',
  experience: 'Berufserfahrung',
  forms: {
    dialog: {
      areYouSure: 'Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?',
      warning: 'Nach der Genehmigung können diese Daten nicht mehr wiederhergestellt werden!',
    },
    availability: {
      title: 'Verfügbarkeit der Mitarbeiter einstellen',
      date: 'Startdatum',
      '1month': '1 Monat',
      months: 'Monaten',
      months2: 'Monaten',
      indefinite: 'Nicht spezifiziert',
      note: 'Zusätzliche Informationen',
      period: 'Verfügbarkeitsdauer',
      available: 'Verfügbarkeit der Mitarbeiter',
      changePosition: 'Gewünschte Position ändern',
      departmentOnly: 'Nur meine Abteilung',
      positionTooltip: 'Prüfen Sie, ob der Arbeitnehmer die Stelle wechseln möchte.',
      tooltip:
        'Aktivieren Sie das Kontrollkästchen und füllen Sie das Formular aus, um andere im Unternehmen wissen zu lassen, dass Ihr Mitarbeiter für ihre Projekte zur Verfügung steht. Denken Sie daran, dieses Häkchen zu aktualisieren, wenn der Mitarbeiter eine Zuweisung hat.',
      sendNotification: 'Benachrichtigung senden',
    },
    avatar: {
      zoomSlider: 'Vergrößerung',
      header: 'Profilbild',
      buttonAdd: 'Bild ändern oder hinzufügen',
      buttonDelete: 'Bild löschen',
    },
    award: {
      addAward: 'Auszeichnung hinzufügen',
      associatedWith: 'Verbunden mit',
      header: 'Ehrungen und Preise',
      issueDate: 'Ausgabedatum',
      issuer: 'Emittent',
      title: 'Titel',
    },
    basicInfo: {
      location: 'Ort',
      firstName: 'Vorname',
      header: 'Grundsätzliche Informationen bearbeiten',
      lastName: 'Nachname',
      mobile: 'Telefonnummer',
      photoError: 'Falsches Dateiformat! Laden Sie die JPG- oder PNG-Datei hoch.',
      position: 'Stelle',
    },
    buttonDelete: 'Löschen',
    buttonSave: 'Speichern',
    buttonSaveAndSend: 'Speichern und senden',
    buttonSend: 'Senden',
    buttonConfirm: 'Bestätigen',
    buttonEnd: 'Schließen',
    buttonCancel: 'Abbrechen',
    buttonClose: 'Schließen',
    buttonNext: 'Nächste',
    buttonEdit: 'Bearbeiten',
    certificate: {
      addCertificate: 'Zertifikat hinzufügen',
      create: 'Erstellen:',
      credentialId: 'Berechtigungsnachweis-ID',
      credentialUrl: 'Berechtigungsnachweis-URL',
      expirationDate: 'Ablaufdatum',
      header: 'Zertifizierung',
      issued: 'Ausgabe',
      issueDate: 'Ausgabedatum',
      name: 'Name',
      noData: 'Keine Daten verfügbar',
      nonExpiring: 'Dieser Berechtigungsnachweis läuft nicht ab',
      organization: 'Ausgebende Organisation',
      validIndefinitely: 'Unbefristet gültig',
      validUntil: 'Gültig bis',
      viewCredential: 'Berechtigungsnachweis-ID anzeigen',
    },
    course: {
      addCourse: 'Kurs hinzufügen',
      courseLink: 'Link zum Kurs',
      date: 'Datum des Kursabschlusses',
      header: 'Kurs',
      name: 'Kursname',
      organizer: 'Veranstalter',
      url: 'Kurs URL',
    },
    description: 'Beschreibung',
    dropzone: 'Klicken oder ziehen Sie die Datei hier...',
    dropzoneFormatError: 'Falsches Dateiformat!',
    dropzoneSizeError: 'Hochgeladene Datei ist zu groß!',
    dropzoneFile: 'Datei hinzugefügt:',
    education: {
      addEducation: 'Ausbildung hinzufügen',
      activities: 'Aktivitäten und Gesellschaften',
      degree: 'Abschluss/ Titel',
      endDate: 'Enddatum',
      fieldOfStudy: 'Studienfach',
      header: 'Ausbildung',
      school: 'Schule',
      startDate: 'Startdatum',
      stillStudying: 'Ich studiere noch',
    },
    experience: {
      addExperience: 'Berufserfahrung hinzufügen',
      company: 'Unternehmen',
      currentPosition: 'Ich arbeite derzeit in dieser Rolle',
      endDate: 'Abschlussdatum',
      header: 'Work experience',
      location: 'Location',
      position: 'Position',
      startDate: 'Startdatum',
    },
    evaluation: {
      instruction:
        'Wählen Sie die entsprechenden Optionen unten aus, wenn Sie die Beurteilung delegieren möchten oder wenn Sie nicht möchten, dass der Mitarbeiter eine Selbstbeurteilung vornimmt.',
      title: 'Sie beginnen den Bewertungsprozess',
      delegate: 'Möchten Sie die Bewertung delegieren?',
      selfEvaluation: 'Möchten Sie, dass der Mitarbeiter {{name}} eine Selbstbewertung vornimmt?',
      yes: 'Ja',
      no: 'Nein',
    },
    formError: 'Dieses Feld ist erforderlich',
    language: {
      advanced: 'Fortgeschritten',
      basic: 'Grundlegend',
      bilingual: 'Muttersprache oder zweisprachig',
      header: 'Sprache hinzufügen',
      intermediate: 'Mittelstufe',
      language: 'Sprache',
      professional: 'Professionell',
      proficiency: 'Befähigung',
    },
    pdfFile: 'PDF Datei',
    project: {
      associatedWith: 'Assoziiert mit',
      addProject: 'Projekt hinzufügen',
      client: 'Kunde',
      endDate: 'Abschlussdatum',
      header: 'Projekt',
      name: 'Projektname',
      ongoing: 'Ich arbeite derzeit an diesem Projekt',
      projectLink: 'Projekt Link',
      role: 'Rolle',
      startDate: 'Startdatum',
      urlAddress: 'Projekt URL',
    },
    recommendation: {
      author: 'Autor',
      description: 'Inhalt',
      header: 'Zulassung',
      recommendationLink: 'Zulassung Link',
      viewOriginal: 'Die Original-Empfehlung ansehen',
    },
    salary: {
      title: 'Antrag auf Änderung des Satzes',
      employee: 'Arbeitsnehmer',
      type: 'Art des Vertrages',
      newContractType: 'Neue Vertragsart',
      date: 'Startdatum',
      rate: 'Satz',
      companies: 'Kunde',
      noticePeriod: 'Kündigungsfrist',
      '1month': '1 Monat',
      months: 'Monaten',
      showHistory: 'Verlauf anzeigen',
      monthly: 'Monatlich',
      hourly: 'Stunde',
      rateKind: 'Art des Satzes',
      rateType: 'Art des Satzes',
      currency: 'Währung',
      indefinite: 'Unbestimmte Dauer',
      unknown: 'Unbekannt',
      position: 'Stelle',
      changePosition: 'Stelle ändern',
      changeNoticePeriod: 'Kündigungsfrist ändern',
      changeCustomers: 'Kundenlist ändern',
      changeContractType: 'Vertragsart ändern',
      requestTooltip:
        'Der Antrag ist abgelaufen, ändern Sie das Datum oder lehnen Sie den Antrag ab.',
    },
    skill: {
      add: 'Wählen Sie eine Fähigkeit aus oder fügen Sie eine neue hinzu',
      addAnother: 'Eine weitere Fähigkeit hinzufügen',
      buttonPropose: 'Fähigkeit vorschlagen',
      endorsementBy: 'Unterstützt von',
      footer1: 'Sie sehen die Fähigkeit nicht',
      footer2: 'hat?',
      header: 'Fähigkeit hinzufügen',
      editHeader: 'Fähigkeit bearbeiten',
      people: 'Leute',
      people2: 'Leute',
      person: 'Person',
      proposeHeader: 'Fähigkeiten vorschlagen',
      proposed: 'Von anderen Benutzern vorgeschlagene Fertigkeiten:',
      proposedBy: 'Vorgeschlagen von: ',
      instruction:
        'Wenn Sie eine Fähigkeit hinzufügen möchten, die nicht in den Vorschlägen enthalten ist, bestätigen Sie mit der Eingabetaste.',
      search: 'Suchen...',
      skills: 'Fächigkeiten',
      rate: {
        1: 'Anfänger',
        2: 'Fortgeschrittene Anfänger',
        3: 'Kompetent',
        4: 'Fähig',
        5: 'Experte',
      },
      description: {
        '1a': 'Sie haben wenig bis keine Erfahrung mit der Technologie oder den Fähigkeiten',
        '1b': 'Sie brauchen klare Anweisungen, wie etwas zu tun ist, damit Sie es tun können.',
        '1c': 'Sie haben noch kein intuitives Verständnis für die Fähigkeit / Technologie',
        '2a': 'Sie haben bereits Erfahrung mit der entsprechenden Fähigkeit oder Technologie',
        '2b': 'Sie sind in der Lage, grundlegende Probleme, auf die Sie stoßen, selbst zu beheben',
        '2c': 'Sie brauchen immer noch erhebliche Unterstützung von erfahreneren Teamkollegen',
        '3a': 'Sie haben direkte Erfahrung - Sie haben die Technologie oder die Fähigkeit in der Vergangenheit genutzt',
        '3b': 'Sie sind in der Lage, grundlegende Aufgaben selbständig auszuführen',
        '3c': 'Sie brauchen von Zeit zu Zeit die Hilfe anderer',
        '4a': 'Sie sind fähig und erfahren in der Anwendung der Technologie / Fertigkeit',
        '4b': 'Sie zeigen, dass Sie die Fertigkeiten/Technologie beherrschen',
        '4c': 'Sie sind in der Lage, selbständig zu arbeiten',
        '5a': 'Sie sind in der Lage und erfahren, die Technologie / Fertigkeit zur Lösung praktischer Probleme einzusetzen',
        '5b': 'Sie brauchen keine Hilfe bei der Durchführung von Aufgaben, sondern Sie sind es, der um Hilfe gebeten wird',
        '5c': 'Sie haben die Fähigkeit, andere zu führen und auszubilden',
        '5d': 'Sie werden von anderen als Fachexperte anerkannt',
      },
    },
    switchLabel: 'Inhalt auf Polnisch anzeigen',
  },
  generateCV: 'Lebenslauf erstellen',
  askForFeedback: 'Rückmeldung anfordern',
  languages: 'Sprache',
  languagesDescription: 'Informationen über die von Ihnen gesprochenen Sprachen',
  managersNote: 'Hinweise für Manager',
  present: 'aktuell',
  projects: 'Projekte',
  publications: 'Veröffentlichungen',
  skillsDescription: 'Präsentieren Sie Ihre Stärken',
  viewFile: 'Siehe das beigefügte Dokument',
  viewLink: 'Siehe den angegebenen Link',
  showAllButton: 'Alles anzeigen',
  evaluations: {
    type: 'Art der Bewertung',
    date: 'Bewertungdatum',
  },
};
