import { SxProps } from '@mui/material';

export const TopBarLanguageChangeLanguageIconStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '8px',
  border: '1px solid #999999',
  borderRadius: '50%',
};
