import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  text: string;
  isUser?: boolean;
};

export const AiSearchChatBubble: React.FC<Props> = props => {
  const { text, isUser } = props;

  return (
    <Box
      alignSelf={isUser ? 'flex-start' : 'flex-end'}
      style={{
        background: isUser ? '#ACE2FF' : 'white',
        borderRadius: 50,
        fontSize: '16px',
        minWidth: '160px',
        padding: 16,
        margin: '4px 0',
      }}
    >
      {text}
    </Box>
  );
};
