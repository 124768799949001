import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileSkillDto } from '@/api/generated-user-profile';
import { SkillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.en.ts';

export const skillsDescriptions: SkillsDescriptions = {
  0: {
    title: 'Kein Wissen',
    descriptions: [],
  },
  1: {
    title: 'Level 1 - Anfänger',
    descriptions: [
      'Erfahrungen, die in einem Klassenzimmer und/oder in Laborszenarien oder als Praktikant am Arbeitsplatz unter Anleitung eines erfahrenen Beraters gesammelt wurden',
      'Bei der Durchführung der Installation wird Hilfe erwartet',
      'Verständnis und Fähigkeit zur Diskussion von Terminologie, Konzepten, Grundsätzen und Fragen.',
      'Die gesamte Palette der Referenz- und Ressourcenmaterialien kann genutzt werden',
    ],
  },
  2: {
    title: 'Level 2 - Mittelstufe',
    descriptions: [
      'In der Lage sein, Aufgaben in diesem Kompetenzbereich wie gefordert erfolgreich zu erledigen',
      'Von Zeit zu Zeit kann die Hilfe eines Experten erforderlich sein, aber sie können die Fertigkeit selbständig ausführen.',
      'Der Schwerpunkt liegt auf Installations- und Konfigurationskenntnissen/-fertigkeiten',
      'Verständnis und Fähigkeit, die Anwendung und Implikationen von Installationsänderungen zu diskutieren',
      'Schwerpunkt auf der eingehenden Fehlerbehebung bei Produktproblemen',
    ],
  },
  3: {
    title: 'Level 3 - Fortgeschritten',
    descriptions: [
      'Hat die mit dieser Fertigkeit verbundenen Handlungen ohne Hilfe ausgeführt',
      'Anerkennung innerhalb ihrer unmittelbaren Organisation als "Fachexperte", wenn schwierige Fragen zu diesem Produkt auftreten',
      'Fähigkeit, andere in das Produkt einzuweisen und mit komplexem Design umzugehen',
    ],
  },
  4: {
    title: 'Level 4 - Experte',
    descriptions: [
      'Bekannt als Experte für dieses Produkt',
      'In der Lage sein, Anleitung zu geben, Probleme zu lösen und Fragen zu beantworten, die mit diesem Fachgebiet zusammenhängen',
      'Fähigkeit, bei der Anwendung dieser Kompetenz über mehrere Projekte und komplexe Entwürfe hinweg konstant hervorragende Leistungen zu erbringen',
      'Extern und von Produktanbietern als "absoluter Fachmann" anerkannt',
      'Als Experte für das Produkt angesehen',
    ],
  },
};

export type UserProfileSkillsSectionTranslation = SectionTranslationType<
  Omit<UserProfileSkillDto, 'userProfileId' | 'skillId' | 'contentLanguage'>
> & {
  content: {
    tableHeader: {
      skill: string;
      level: string;
      experienceInYears: string;
      actions: string;
    };
    experienceMonths: string;
    experienceYears: string;
    skillsDescriptions: SkillsDescriptions;
  };
  form: {
    skillsDescriptions: SkillsDescriptions;
  };
};

export const userProfileSkillsSectionTranslation: UserProfileSkillsSectionTranslation = {
  headerTitle: 'Fähigkeiten',
  content: {
    emptyItemsLabel: 'Fähigkeiten',
    tableHeader: {
      skill: 'Fähigkeit',
      level: 'Niveau',
      experienceInYears: 'Erfahrung',
      actions: 'Handlungen',
    },
    experienceMonths: 'Monaten',
    experienceYears: 'Jahren',
    skillsDescriptions,
    button: {
      edit: 'Bearbeiten',
    },
  },
  form: {
    actions: 'Handlungen',
    experienceInMonths: 'Erfahrung',
    level: 'Ihr Niveau einstellen',
    nonDictionarySkillValue: 'Nicht-Wörterbuchfähigkeitswert',
    skill: 'Fähigkeit',
    skillsDescriptions,
    button: {
      save: 'Speichern',
    },
  },
  modal: {
    title: 'Fähigkeit hinzufügen',
  },
};
