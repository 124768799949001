export type VacancyDetailsContentTranslation = {
  section: {
    mandatory: string;
    nonMandatory: string;
    routing: string;
  };
  accountManager: string;
  addJobProfile: string;
  addRfp: string;
  affiliates: string;
  applicationStartDate: string;
  closureReason: string;
  creationDate: string;
  currency: string;
  customer: string;
  customerBudgetDailyRate: string;
  deliveryLocation: string;
  description: string;
  durationInMonths: string;
  expectedAvailability: string;
  externalPartners: string;
  finalRateForCandidate: string;
  jobProfile: string;
  jobTitle: string;
  language: string;
  localResourceManagersEmails: string;
  localResourceManagersEmailsEmpty: string;
  minYearsOfExperience: string;
  ocdEmployees: string;
  opportunityName: string;
  opportunityOwner: string;
  outOfOfficeHours: string;
  requestType: string;
  requester: string;
  requestingCountry: string;
  requiredNoCandidatesSigned: string;
  requiredSkills: string;
  rfp: string;
  salesforceId: string;
  specialClearances: string;
  startDate: string;
  status: string;
  targetMargin: string;
  timeOnSite: string;
};

export const vacancyDetailsContentTranslation: VacancyDetailsContentTranslation = {
  section: {
    mandatory: 'Mandatory',
    nonMandatory: 'Non-mandatory',
    routing: 'Routing',
  },
  accountManager: 'Opportunity owner',
  addJobProfile: 'Add Job Profile',
  addRfp: 'Add RFP',
  affiliates: 'Affiliates',
  applicationStartDate: 'Request Start Date',
  closureReason: 'Closure reason',
  creationDate: 'Creation date',
  currency: 'Currency',
  customer: 'Customer',
  customerBudgetDailyRate: 'Customer Budget - Daily Rate',
  deliveryLocation: 'Delivery location',
  description: 'Description',
  durationInMonths: 'Duration (months)',
  expectedAvailability: 'Expected availability per week in days',
  externalPartners: 'External partners',
  finalRateForCandidate: 'Final Rate for Candidate',
  jobProfile: 'Job profile',
  jobTitle: 'Job title',
  language: 'Language',
  localResourceManagersEmails: 'Local Resource Managers Emails',
  localResourceManagersEmailsEmpty: 'No data',
  minYearsOfExperience: 'Min. years of experience on similar position',
  ocdEmployees: 'OCD employees',
  opportunityName: 'Opportunity Name',
  opportunityOwner: 'Opportunity owner',
  outOfOfficeHours: 'Out of office hours',
  requestType: 'Request Type',
  requester: 'Requester',
  requestingCountry: 'Requesting country',
  requiredNoCandidatesSigned: 'Required number of candidates',
  requiredSkills: 'Required technologies / skills',
  rfp: 'RFP',
  salesforceId: 'Salesforce Opportunity ID',
  specialClearances: 'Special clearances',
  startDate: 'Start date',
  status: 'Status',
  targetMargin: 'OCD Target Margin',
  timeOnSite: '% Of Time On Site',
};
