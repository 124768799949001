import { RoleTypeDto } from '@/api/generated';

export const isAuthorized = (
  authorizedRoles: RoleTypeDto | RoleTypeDto[] = [],
  currentUserRole: RoleTypeDto | undefined,
): boolean => {
  if (!currentUserRole) {
    return false;
  }
  if (Array.isArray(authorizedRoles) && authorizedRoles.length) {
    return authorizedRoles.includes(currentUserRole);
  }
  if (typeof authorizedRoles === 'string' && authorizedRoles.length) {
    return currentUserRole === authorizedRoles;
  }
  return true;
};

export const hasPermission = (
  permissionRequired: string | undefined,
  currentUserPermissions: Record<string, string>,
) =>
  permissionRequired &&
  currentUserPermissions !== undefined &&
  Object.keys(currentUserPermissions)?.length &&
  currentUserPermissions[permissionRequired];
