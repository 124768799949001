import { Translations } from '@/assets/locales/en.ts';

import { vacancyCandidateTranslation } from './vacancyCandidate/vacancyCandidate.translation.de';
import { validationTranslation } from './validation/validation.translation.de';
import { userInitialize } from '@/assets/locales/userInitialize/userInitialize.translation.de';
import { vacanciesTranslation } from './vacancies/vacancies.translation.de';
import { menuTranslation } from './menu/menu.translation.de';
import { companyDetailsTranslation } from './companyDetails/companyDetails.translation.de';
import { RoleTypeDto } from '@/api/generated';
import { userDetailsTranslation } from '@/assets/locales/userDetails/userDetails.translation.de.ts';
import { pageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.de.ts';
import { viewsTranslation } from '@/assets/locales/views/views.translation.de.ts';
import { componentsTranslation } from '@/assets/locales/components/components.translation.de.ts';
import { generalErrorTranslation } from '@/assets/locales/generalError/generalError.translation.de.ts';
import { layoutsTranslation } from '@/assets/locales/layouts/layouts.translation.de';
import { profileTranslation } from '@/assets/locales/profile/profile.translation.de';
import { dictionariesTranslation } from '@/assets/locales/dictionaries/dictionaries.translation.de';
import { searchTranslation } from '@/assets/locales/search/search.translation.de.ts';

export const NAMESPACE = 'ocd';

export const de: Translations = {
  [NAMESPACE]: {
    userDetails: userDetailsTranslation,
    pageTitles: pageTitlesTranslation,
    alerts: {
      success: 'Vorgang erfolgreich abgeschlossen.',
      error:
        'Vorgang ist fehlgeschlagen, versuchen Sie es erneut oder wenden Sie sich an den Anwendungsadministrator.',
    },
    dictionaries: {
      ...dictionariesTranslation,
      active: 'Aktiv',
      addButton: 'Eintrag hinzufügen',
      approve: 'Genehmigung',
      archive: 'Archivierung',
      buttonArchive: 'Archivieren',
      choose: 'Auswählen',
      date: 'Datum',
      dictionary: 'Wörterbuch',
      entries: 'Enträge',
      edit: 'Wörterbucheintrag bearbeiten',
      history: 'Operationsverlauf',
      maxRows: 'Maximale Reihen pro Seite',
      merge: 'zusammenführen',
      mergeResult: 'Ergebnis zusammenführen',
      modalHeader: 'Eintrag zum Wörterbuch hinzufügen',
      name: 'Name',
      nextPage: 'Nächste Seite',
      operation: 'Vorgang',
      previousPage: 'Vorherige Seite',
      result: 'Ergebnis',
      reverse: 'Die Änderung rückgängig machen',
      selectedItems: 'Ausgewählte Elemente:',
      search: 'Suchen...',
      title: 'Wörterbücher',
    },
    components: componentsTranslation,
    meetings: {
      employeeView: 'Sicht des Arbeitnehmers',
      managerView: 'Sicht des Managers',
      meetingNotes: 'Notizen zur Sitzung',
      addNote: 'Notiz hinzufügen',
      editNote: 'Notiz bearbeiten',
      addComment: 'Kommentar hinzufügen',
      editComment: 'Kommentar bearbeiten',
      goBack: 'Zurück zur Notizliste',
      note: 'Notiz zur Sitzung',
      meetingDate: 'Datum der Sitzung',
      meetingTitle: 'Zusammenfassung',
      modal: {
        meetingType: 'Art der Sitzung',
        title: 'Titel',
        date: 'Datum',
        nextMeetingDate: 'Datum der nächsten Sitzung',
        buttonSave: 'Speichern',
        formError: 'Dieses Feld ist erforderlich',
        buttonCancel: 'Abbrechen',
        addParticipant: 'Teilnehmer hinzufügen *',
        textEditor: 'Notiz schreiben',
        editNextMeeting: 'Datum der nächsten Sitzung ändern',
        noNextDate: 'es wurde kein Datum festgelegt',
      },
      comments: 'Kommentare',
      noComments: 'Es gibt keine Kommentare',
      comment: 'Komment',
      buttonDelete: 'löschen',
      modified: 'Modifiziert',
      added: 'Hinzugefügt',
      areYouSure: 'Sind Sie sicher, dass Sie diese Notiz löschen wollen?',
      warning: 'Einmal genehmigt, kann diese Notiz nicht mehr zurückgegeben werden!',
      evaluation: 'Bewertung der Mitarbeiter',
      salary: 'Gehaltsverhandlung',
      development: 'Entwicklungsplan',
      other: 'Andere',
      filter: 'Filter',
      participant: 'Teilnehmer(in)',
      startDate: 'Datum wählen - von',
      endDate: 'Datum wählen - bis',
      buttonClear: 'Alle Filter löschen',
    },
    menu: menuTranslation,
    search: searchTranslation,
    fullText: {
      goBack: 'Zurück',
      resultCount: 'Ergebnisse suchen: ',
      more: 'mehr...',
      missing: 'Fehlt:',
      all: 'Alles',
      employees: 'Arbeitsnehmer',
      projects: 'Projekte',
      publications: 'Veröffentlichungen',
      maxResults: 'Maximale Anzahl von Ergebnissen pro Seite',
      employeesTab: {
        position: 'Position:',
        location: 'Ort:',
        projects: 'Projekte:',
        experience: 'Erfahrungen:',
        certificates: 'Zertifikate:',
        courses: 'Kurse:',
      },
    },
    generalError: generalErrorTranslation,
    sortIcons: {
      name: 'Name',
      manDays: 'Man-Tage gemeldet',
    },
    vacancies: vacanciesTranslation,
    validation: validationTranslation,
    companyDetails: companyDetailsTranslation,
    vacancyCandidate: vacancyCandidateTranslation,
    utils: {
      addFile: 'Datei hinzufügen',
    },
    defaultRoles: {
      [RoleTypeDto.Admin]: 'Admin',
      [RoleTypeDto.Employee]: 'Arbeitsnehmer',
      [RoleTypeDto.ExternalPartner]: 'Externe Partner',
      [RoleTypeDto.GroupExternalPartner]: 'Gruppe Externe Partner',
      [RoleTypeDto.LocalResourceManager]: 'Lokaler Ressourcenmanager',
      [RoleTypeDto.PreSales]: 'Vorverkäufe',
      [RoleTypeDto.Requester]: 'Antragsteller',
    },
    userInitialize,
    views: viewsTranslation,
    layouts: layoutsTranslation,
    profile: profileTranslation,
  },
};
