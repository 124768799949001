import React, { useContext, useEffect, useState } from 'react';
import { useAiUserProfileSearchInit } from '@/views/aiSearch/util/useAiUserProfileSearchInit.util.ts';
import { v4 as uuidv4 } from 'uuid';
import {
  AIUserProfileSearchQueryResultDto,
  AIUserProfileSearchResultItemDto,
  AIUserProfileSearchStateDto,
  SearchForceModeDto,
} from '@/api/generated';
import { AiSearchSearch } from '@/views/aiSearch/components/AiSearchSearch.comp.tsx';
import { AiSearchParameters } from '@/views/aiSearch/components/parameters/AiSearchParameters.comp.tsx';
import {
  AiSearchParametersModel,
  normalAiSearchParameters,
} from '@/views/aiSearch/model/AiSearchParameters.model.ts';
import { AiSearchInProgress } from '@/views/aiSearch/components/AiSearchInProgress.comp.tsx';
import { AiSearchHistory } from '@/views/aiSearch/components/AiSearchHistory.comp.tsx';
import { divideWeights } from '@/views/aiSearch/util/operationsOnAiWeights.util.ts';
import { useAiUserProfileSearchCheckState } from '@/views/aiSearch/util/useAiUserProfileSearchCheckState.util.ts';
import { returnPromise } from '@/utils/returnPromise.util.ts';
import { useGetAiUserProfileResponse } from '@/views/aiSearch/util/useGetAiUserProfileResponse.util.ts';
import { UserProfileChatInputModel } from '@/views/userProfile/components/sections/careerPath/model/UserProfileChatInput.model.ts';
import { DataOperationsErrorContext } from '@/errorHandling/DataOperationsErrorContext.ts';
import Box from '@mui/material/Box';

export const AiSearchView: React.FC = () => {
  const [currentSearch, setCurrentSearch] = useState('');
  const chatId = uuidv4();
  const [results, setResults] = useState<Record<string, AIUserProfileSearchResultItemDto[]>>({});
  const { aiSearchInit } = useAiUserProfileSearchInit();
  const { aiSearchStateCheck } = useAiUserProfileSearchCheckState();
  const { getSearchResult } = useGetAiUserProfileResponse();
  const [parameters, setParameters] = useState<AiSearchParametersModel>(normalAiSearchParameters);
  const [isPending, setIsPending] = useState(false);
  const [mode, setMode] = useState<SearchForceModeDto>(SearchForceModeDto.Default);
  const { handleMinorError } = useContext(DataOperationsErrorContext);

  const handleUpdateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event.target.value);
  };

  useEffect(() => {
    UserProfileChatInputModel.value = [];
  }, []);

  const scrollToBottom = () => {
    const div = document.getElementById('autoScrollDiv');
    if (!div) {
      return;
    }

    div.scrollTo({ left: 0, top: div.scrollHeight, behavior: 'smooth' });
  };

  const handleSubmitSearch = async () => {
    if (currentSearch === '') {
      return;
    }
    setIsPending(true);
    setResults(cur => {
      cur[currentSearch] = [];
      return cur;
    });
    scrollToBottom();
    const initResponse = await aiSearchInit({
      chatId,
      request: {
        weights: divideWeights(parameters),
        message: currentSearch,
        expectedResults: 20,
      },
      searchMode: mode,
    });

    let stateResponse: AIUserProfileSearchQueryResultDto | undefined;
    do {
      await returnPromise(5000);
      stateResponse = await aiSearchStateCheck({ chatId, searchId: initResponse.searchId });
    } while (stateResponse?.state === AIUserProfileSearchStateDto.InProgress);

    if (stateResponse?.state === AIUserProfileSearchStateDto.Error) {
      handleMinorError('Error while searching for AI user profiles, please try again');
      setIsPending(false);
      return;
    }

    const response = await getSearchResult({ chatId, searchId: initResponse.searchId });

    setResults(cur => {
      cur[currentSearch] = [...response];
      return cur;
    });
    UserProfileChatInputModel.value.push(currentSearch);
    setCurrentSearch('');
    scrollToBottom();
    setIsPending(false);
  };

  const resultsLength = Object.keys(results).length;
  useEffect(() => {
    scrollToBottom();
  }, [resultsLength]);

  return (
    <>
      <Box pt={10} style={{ background: '#F7F7F8', width: '100%', height: '100vh' }}>
        <AiSearchParameters
          parameters={parameters}
          setParameters={setParameters}
          mode={mode}
          setMode={setMode}
        />
        <Box maxHeight="55vh" overflow="auto" style={{ marginBottom: '32px' }} id="autoScrollDiv">
          <AiSearchHistory results={results} isSearching={isPending} />
          <AiSearchInProgress isSearching={isPending} />
        </Box>
        <AiSearchSearch
          isSearching={isPending}
          searchValue={currentSearch}
          handleUpdateSearch={handleUpdateSearch}
          handleSubmitSearch={handleSubmitSearch}
        />
      </Box>
    </>
  );
};
