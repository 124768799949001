import { interviewNoteRequest } from '@/assets/locales/vacancyCandidate/interviewNoteRequest/interviewNoteRequest.en.ts';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';

export interface VacancyCandidateTranslation extends Record<string, unknown> {
  status: Record<VacancyCandidateStatusEnumDto, string>;
  transition: Record<VacancyCandidateStatusEnumDto, string>;
  candidateInactiveBanner: string;
  buttons: {
    activate: string;
    deactivate: string;
  };
  goToVacancy: string;
  content: {
    availability: string;
    currency: string;
    cvSource: string;
    cvUploadedBy: string;
    expectedDailyRate: string;
    firstName: string;
    languages: string;
    lastName: string;
    location: string;
    position: string;
    resume: string;
    title: string;
    button: {
      addComment: string;
      editCandidate: string;
      goToUserProfile: string;
    };
  };
}

export const vacancyCandidateTranslation: VacancyCandidateTranslation = {
  status: {
    [VacancyCandidateStatusEnumDto.New]: 'New',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Interview requested',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewed',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Candidate rejected',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Candidate selected',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Offer signed',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offer sent',
  },
  transition: {
    [VacancyCandidateStatusEnumDto.New]: 'New',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Request for interview',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewed',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Reject candidate',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Select candidate',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Offer signed',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offer sent',
  },
  interviewNoteRequest,
  downloadCV: 'Download CV',
  approve: 'Accept',
  reject: 'Reject',
  existsInDatabase: 'Exists in database',
  notExistInDatabase: "Doesn't exist in database",
  lastContactDate: 'Last contact date',
  headline: 'Select the last contact date',
  instructions: 'Choose the last contact date by your company with the candidate',
  addComment: 'Add comment for candidate',
  comment: 'Comment',
  buttonSave: 'Save',
  interview: {
    title: 'Interview',
    summary: 'Summary',
    date: 'Date',
    technicalInterviewer: 'Technical interviewer',
    maxDateMessage: 'Date should not be in future',
    minDateMessage: 'Date should not be in the past',
  },
  addInterview: 'Add interview',
  selectVacancy: 'Select Vacancy',
  selectVacancyToAssign: 'Select vacancy to assign to candidate.',
  noVacancy: 'No vacancy',
  vacancy: 'Vacancy',
  changeVacancy: 'Change vacancy',
  addCommentBtn: 'Add comment',
  editCandidate: "Edit candidate's data",
  timeline: {
    readMore: 'Read more',
    readLess: 'Read less',
    statusChange: 'Status change:',
    meetingSummary: 'Post-meeting feedback:',
    meetingStatus: 'Interview status:',
    activeStateChange: 'Candidate state change:',
  },
  closeCandidate: 'Close candidate',
  statusChange: {
    closed: 'Closing the candidate',
    rejected: 'Rejecting the candidate',
    closedReason: 'Closing reason',
    closedDescription: 'Description',
    rejectedReason: 'Rejection reason',
    rejectedDescription: 'Description',
    closedInstruction: 'Choose the reason for closing the candidate',
    rejectedInstruction: 'Choose the reason for rejecting the candidate',
    closedDetails: 'Close details',
    rejectedDetails: 'Rejection details',
  },
  notesList: {
    header: "Interviews' notes",
    expand: 'Expand',
    collapse: 'Collapse',
    table: {
      date: "Meeting's date",
      author: "Note's author",
      vacancy: 'Vacancy',
    },
  },
  buttons: {
    activate: 'Activate',
    deactivate: 'Deactivate',
  },
  candidateInactiveBanner: 'Candidate is inactive.',
  goToVacancy: 'Go to request',
  content: {
    availability: 'Availability',
    currency: 'Currency',
    cvSource: 'CV source',
    cvUploadedBy: 'CV uploaded by',
    expectedDailyRate: 'Expected daily rate',
    firstName: 'First name',
    languages: 'Languages',
    lastName: 'Last name',
    location: 'Location',
    position: 'Position',
    resume: 'Resume',
    title: 'Candidate details',
    button: {
      addComment: 'Add comment',
      editCandidate: 'Edit candidate',
      goToUserProfile: "Go to candidate's profile",
    },
  },
};
