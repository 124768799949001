import { useApiClient } from './util/useApiClient.util';

interface Return {
  logout: () => Promise<void>;
}

export const useLogout = (): Return => {
  const { logoutApi } = useApiClient();
  return {
    logout: async () => {
      const response = await logoutApi.logout();
      (window as Window).location = response.data.targetUrl;
    },
  };
};
