import React from 'react';
import { fullScreenLoaderStyles } from './styles/fullScreenLoader.styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

export const FullScreenLoader: React.FC = () => {
  const styles = fullScreenLoaderStyles();
  return (
    <>
      <Container maxWidth="md" className={styles.main}>
        <Paper className={styles.paper}>
          <Box className={styles.wrapper}>
            <CircularProgress />
          </Box>
        </Paper>
      </Container>
    </>
  );
};
