import { MenuItemType } from '@/layouts/private/sideBarMenu/model/SideBarMenuItems.model.tsx';

export type MenuTranslation = Record<MenuItemType, string>;

export const menuTranslation: MenuTranslation = {
  addVacancy: 'Request resource',
  aiSearch: 'AI search',
  companies: 'Companies',
  profileSearch: 'Search Profiles',
  projects: 'Projects list',
  recruitmentBoard: 'Request Tracker',
  recruitmentCandidates: 'Candidates',
  reports: 'Reports',
  settingsDictionaries: 'Dictionaries',
  userProfile: 'My profile',
};
