import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileCertificateDto } from '@/api/generated-user-profile';

export type UserProfileCertificatesSectionTranslation = SectionTranslationType<
  Omit<UserProfileCertificateDto, 'userProfileId' | 'certificateId' | 'contentLanguage'>
>;

export const userProfileCertificatesSectionTranslation: UserProfileCertificatesSectionTranslation =
  {
    headerTitle: 'Certificates',
    content: {
      emptyItemsLabel: 'certificates',
      button: {
        edit: 'Edit',
      },
    },
    modal: {
      title: 'Add certificate',
    },
    form: {
      actions: 'Actions',
      certificate: 'Certificate',
      credentialId: 'Credential ID',
      credentialUrl: 'Credential URL',
      expirationDate: 'Expiration date',
      issueDate: 'Issue date',
      nonDictionaryCertificateValue: 'Non dictionary certificate value',
      nonExpiring: 'This credential does not expire',
      organization: 'Organization',
      button: {
        save: 'Save',
      },
      present: 'Non expiring',
    },
  };
