import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useApiClient } from '@/api/util/useApiClient.util.ts';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { DEFAULT_ROUTE } from '@/views/signInHandler/SignInHandler';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

type Request = {
  username: string;
  password: string;
};

type Return = {
  login: (request: Request) => Promise<void>;
  isLoggingIn: boolean;
};

export const useLogin = (): Return => {
  const { loginApi } = useApiClient();
  const queryClient = useQueryClient();
  const { push } = useOcdHistory();

  const { mutateAsync, isLoading } = useMutation<void, AxiosError, Request>({
    meta: { disableSuccessAlert: true } as QueryClientMetaModel,
    mutationFn: async request => {
      const base64Credentials = btoa(`${request.username}:${request.password}`);
      await loginApi.login({
        headers: {
          Authorization: `Basic ${base64Credentials}`,
          'Content-Type': 'application/json',
        },
      });
    },
    onSuccess: async () => {
      await queryClient.fetchQuery([QueryKeyEnum.SESSION_GET]);
      await queryClient.fetchQuery([QueryKeyEnum.CURRENT_USER_GET]);
      push(DEFAULT_ROUTE);
    },
  });

  return {
    login: mutateAsync,
    isLoggingIn: isLoading,
  };
};
