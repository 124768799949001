import React from 'react';
import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { ProfileSearchAddSkillFilter } from '@/views/profileSearch/components/filters/skill/ProfileSearchAddSkillFilter.comp.tsx';
import Paper from '@mui/material/Paper';
import { ProfileSearchAddCertificateFilter } from '@/views/profileSearch/components/filters/certificate/ProfileSearchAddCertificateFilter.comp.tsx';
import { ProfileSearchAddLanguageFilter } from '@/views/profileSearch/components/filters/language/ProfileSearchAddLanguageFilter.comp.tsx';
import {
  CertificatesSearchRequestDto,
  LanguageSearchRequestDto,
  SkillSearchRequestDto,
} from '@/api/generated-user-profile';
import { ProfileSearchFilterType } from '@/views/profileSearch/model/ProfileSearchFilterType.model.ts';
import { ProfileSearchAddFilterMode } from '@/views/profileSearch/components/filters/model/ProfileSearchAddFilterProps.model.ts';

type Props = {
  addTypeItemType: ProfileSearchFilterType | undefined;
  onFilterSubmit: (prop: ProfileSearchFilterType) => (item: { value: number }) => void;
  onReset: () => void;
  currentSkills: SkillSearchRequestDto[];
  currentCertificates: CertificatesSearchRequestDto[];
  currentLanguages: LanguageSearchRequestDto[];
};

const Wrapper: React.FC<Record<string, unknown>> = props => {
  return <Paper style={{ margin: '12px 0', padding: '8px' }}>{props.children}</Paper>;
};

export const ProfileSearchAddFilter: React.FC<Props> = props => {
  const {
    addTypeItemType,
    onFilterSubmit,
    onReset,
    currentCertificates,
    currentSkills,
    currentLanguages,
  } = props;

  if (addTypeItemType === ProfileSearchAddItemType.skills) {
    return (
      <Wrapper>
        <ProfileSearchAddSkillFilter
          mode={ProfileSearchAddFilterMode.ADD}
          onSubmit={onFilterSubmit('skills')}
          onReset={onReset}
          currentItems={currentSkills}
        />
      </Wrapper>
    );
  }

  if (addTypeItemType === ProfileSearchAddItemType.languages) {
    return (
      <Wrapper>
        <ProfileSearchAddLanguageFilter
          mode={ProfileSearchAddFilterMode.ADD}
          onSubmit={onFilterSubmit('languages')}
          onReset={onReset}
          currentItems={currentLanguages}
        />
      </Wrapper>
    );
  }

  if (addTypeItemType === ProfileSearchAddItemType.certificates) {
    return (
      <Wrapper>
        <ProfileSearchAddCertificateFilter
          mode={ProfileSearchAddFilterMode.ADD}
          onSubmit={onFilterSubmit('certificates')}
          onReset={onReset}
          currentItems={currentCertificates}
        />
      </Wrapper>
    );
  }

  return null;
};
