/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const UserStatusDto = {
    Active: 'ACTIVE',
    WaitingForApproval: 'WAITING_FOR_APPROVAL',
    Inactive: 'INACTIVE'
} as const;

export type UserStatusDto = typeof UserStatusDto[keyof typeof UserStatusDto];



