import { FC, useEffect } from 'react';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { RouteEnum } from '../routes/Route.model';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { useNotificationsContext } from '@/contexts/notifications/Notifications.context.ts';

export const UserInitializeController: FC = () => {
  const history = useOcdHistory();
  const { value } = useUserContext();
  const { initializationDone, dataReady } = value;
  const { updateNotifications } = useNotificationsContext();

  useEffect(() => {
    if (dataReady && !initializationDone) {
      history.push(RouteEnum.USERS_INITIALIZE);
    }
  }, [dataReady, initializationDone]);

  useEffect(() => {
    if (dataReady && initializationDone) {
      updateNotifications();
    }
  }, [dataReady, initializationDone, updateNotifications]);

  return null;
};
