import { AiSearchUserProfileApiFactory, Configuration } from '@/api/generated';
import { useMemo } from 'react';
import { useAxiosInstance } from './useAxiosInstance.util';
import CONFIG from '@/config/config';

interface Return {
  aiSearchUserProfile: ReturnType<typeof AiSearchUserProfileApiFactory>;
}

export const useAiApiClient = (): Return => {
  const basePath = CONFIG.HOST;
  const configuration = getConfiguration(basePath);
  const axiosInstance = useAxiosInstance();

  return useMemo(() => {
    return {
      aiSearchUserProfile: AiSearchUserProfileApiFactory(configuration, undefined, axiosInstance),
    };
  }, [axiosInstance]);
};

const getConfiguration = (basePath: string): Configuration => ({
  basePath,
  isJsonMime(_mime: string): boolean {
    return true;
  },
});
