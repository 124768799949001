import { Signal } from '@preact/signals-react';
import { UserProfileFullTextSearchResultDto } from '@/api/generated-user-profile';

export const initialProfileSearchResults: UserProfileFullTextSearchResultDto = {
  items: [],
  skills: [],
  profiles: [],
};

export const ProfileSearchResultsModel = new Signal<UserProfileFullTextSearchResultDto>(
  structuredClone(initialProfileSearchResults),
);
