/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileCertificate1Dto } from '../models';
/**
 * UserProfileCertificateApi - axios parameter creator
 * @export
 */
export const UserProfileCertificateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} userProfileCertificate1Dto User Profile Certificate create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileCertificate: async (userProfileId: string, lang: LangDto, userProfileCertificate1Dto: UserProfileCertificate1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileCertificate', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileCertificate', 'lang', lang)
            // verify required parameter 'userProfileCertificate1Dto' is not null or undefined
            assertParamExists('createUserProfileCertificate', 'userProfileCertificate1Dto', userProfileCertificate1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/certificates`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileCertificate1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileCertificate: async (userProfileId: string, userProfileCertificateId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileCertificate', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileCertificateId' is not null or undefined
            assertParamExists('deleteUserProfileCertificate', 'userProfileCertificateId', userProfileCertificateId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileCertificate', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/certificates/{userProfileCertificateId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileCertificateId"}}`, encodeURIComponent(String(userProfileCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} [userProfileCertificate1Dto] User Profile Certificate update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileCertificate: async (userProfileId: string, userProfileCertificateId: string, lang: LangDto, userProfileCertificate1Dto?: UserProfileCertificate1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileCertificate', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileCertificateId' is not null or undefined
            assertParamExists('updateUserProfileCertificate', 'userProfileCertificateId', userProfileCertificateId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileCertificate', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/certificates/{userProfileCertificateId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileCertificateId"}}`, encodeURIComponent(String(userProfileCertificateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileCertificate1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileCertificateApi - functional programming interface
 * @export
 */
export const UserProfileCertificateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileCertificateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} userProfileCertificate1Dto User Profile Certificate create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileCertificate(userProfileId: string, lang: LangDto, userProfileCertificate1Dto: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileCertificate(userProfileId, lang, userProfileCertificate1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileCertificateApi.createUserProfileCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileCertificate(userProfileId, userProfileCertificateId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileCertificateApi.deleteUserProfileCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} [userProfileCertificate1Dto] User Profile Certificate update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, userProfileCertificate1Dto?: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileCertificate(userProfileId, userProfileCertificateId, lang, userProfileCertificate1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileCertificateApi.updateUserProfileCertificate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileCertificateApi - factory interface
 * @export
 */
export const UserProfileCertificateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileCertificateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} userProfileCertificate1Dto User Profile Certificate create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileCertificate(userProfileId: string, lang: LangDto, userProfileCertificate1Dto: UserProfileCertificate1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileCertificate(userProfileId, lang, userProfileCertificate1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileCertificate(userProfileId, userProfileCertificateId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileCertificateId 
         * @param {LangDto} lang 
         * @param {UserProfileCertificate1Dto} [userProfileCertificate1Dto] User Profile Certificate update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, userProfileCertificate1Dto?: UserProfileCertificate1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileCertificate(userProfileId, userProfileCertificateId, lang, userProfileCertificate1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileCertificateApi - interface
 * @export
 * @interface UserProfileCertificateApi
 */
export interface UserProfileCertificateApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileCertificate1Dto} userProfileCertificate1Dto User Profile Certificate create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApiInterface
     */
    createUserProfileCertificate(userProfileId: string, lang: LangDto, userProfileCertificate1Dto: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileCertificateId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApiInterface
     */
    deleteUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileCertificateId 
     * @param {LangDto} lang 
     * @param {UserProfileCertificate1Dto} [userProfileCertificate1Dto] User Profile Certificate update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApiInterface
     */
    updateUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, userProfileCertificate1Dto?: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileCertificateApi - object-oriented interface
 * @export
 * @class UserProfileCertificateApi
 * @extends {BaseAPI}
 */
export class UserProfileCertificateApi extends BaseAPI implements UserProfileCertificateApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileCertificate1Dto} userProfileCertificate1Dto User Profile Certificate create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApi
     */
    public createUserProfileCertificate(userProfileId: string, lang: LangDto, userProfileCertificate1Dto: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileCertificateApiFp(this.configuration).createUserProfileCertificate(userProfileId, lang, userProfileCertificate1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileCertificateId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApi
     */
    public deleteUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileCertificateApiFp(this.configuration).deleteUserProfileCertificate(userProfileId, userProfileCertificateId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileCertificateId 
     * @param {LangDto} lang 
     * @param {UserProfileCertificate1Dto} [userProfileCertificate1Dto] User Profile Certificate update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCertificateApi
     */
    public updateUserProfileCertificate(userProfileId: string, userProfileCertificateId: string, lang: LangDto, userProfileCertificate1Dto?: UserProfileCertificate1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileCertificateApiFp(this.configuration).updateUserProfileCertificate(userProfileId, userProfileCertificateId, lang, userProfileCertificate1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

