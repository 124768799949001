import { TopBarTranslation } from '@/assets/locales/layouts/topBar/topBar.translation.en.ts';

export const topBarTranslation: TopBarTranslation = {
  language: {
    english: 'Englisch',
    french: 'Französisch',
    german: 'Deutsch',
  },
  tooltip: {
    profile: 'Profil bearbeiten',
    userGuide: 'Benutzeranleitung herunterladen',
    logout: 'Abmelden',
  },
};
