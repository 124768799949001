import React from 'react';
import { AiSearchParameterControlDirection } from '@/views/aiSearch/model/AiSearchParameters.model.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import { Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { AiSearchParametersTitleStyles } from '@/views/aiSearch/components/parameters/styles/AiSearchParameters.styles.ts';

type Props = {
  title: string;
  value: number;
  onChange: (direction: AiSearchParameterControlDirection) => () => void;
  isIncreaseDisabled: boolean;
  isDecreaseDisabled: boolean;
  isDisabled: boolean;
};

export const AiSearchParameterControl: React.FC<Props> = props => {
  const { title, value, onChange, isDecreaseDisabled, isIncreaseDisabled, isDisabled } = props;
  const { t } = useTranslationWithPrefix('views.aiSearch.parameters');

  return (
    <>
      <Box sx={AiSearchParametersTitleStyles}>{t(`controls.${title}`)}</Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ height: '48px' }}
      >
        <IconButton
          sx={MyIconButton}
          onClick={onChange(AiSearchParameterControlDirection.decrease)}
          disabled={isDecreaseDisabled || isDisabled}
        >
          <RemoveCircleIcon />
        </IconButton>
        <Box
          sx={{
            textAlign: 'center',
            width: '48px',
            py: 1.5,
            background: 'white',
            border: '1px #D8D8DA solid',
          }}
        >
          {value}
        </Box>
        <IconButton
          sx={MyIconButton}
          disabled={isIncreaseDisabled || isDisabled}
          onClick={onChange(AiSearchParameterControlDirection.increase)}
        >
          <AddCircleIcon />
        </IconButton>
      </Stack>
    </>
  );
};

const MyIconButton: SxProps = {
  position: 'relative',
  borderColor: '#D8D8DA',
  color: '#006FBA',
  background: 'white',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  '&:hover': {
    background: '#006FBA',
    color: 'white',
  },
};
