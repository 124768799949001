import React from 'react';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  isSearching: boolean;
};

export const AiSearchInProgress: React.FC<Props> = props => {
  const { isSearching } = props;

  if (!isSearching) {
    return null;
  }

  return (
    <>
      <ViewWrapper sx={{ paddingTop: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb={3}>Please wait while we are searching for results...</Box>
          <CircularProgress />
        </Box>
      </ViewWrapper>
    </>
  );
};
