import {
  vacancyCandidateModalTranslation,
  VacancyCandidateModalTranslation,
} from '@/assets/locales/components/vacancyCandidateModal/vacancyCandidateModal.translation.en.ts';
import {
  fallbackUiTranslation,
  FallbackUiTranslation,
} from '@/assets/locales/components/falbackUi/fallbackUi.translation.en.ts';

export type ComponentsTranslation = {
  fallbackUi: FallbackUiTranslation;
  vacancyCandidateModal: VacancyCandidateModalTranslation;
};

export const componentsTranslation: ComponentsTranslation = {
  fallbackUi: fallbackUiTranslation,
  vacancyCandidateModal: vacancyCandidateModalTranslation,
};
