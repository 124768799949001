import { useApiClient } from './util/useApiClient.util';
import { UserStatusDto } from './generated';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

interface Return {
  getUserAccountStatus: (userId: string) => Promise<UserStatusDto | undefined>;
  isLoading: boolean;
  isError: boolean;
}

export const useGetUserAccountStatus = (): Return => {
  const { userAccountStatusApi } = useApiClient();
  const { isError, isLoading, mutateAsync } = useMutation<UserStatusDto, AxiosError, string>({
    meta: { disableSuccessAlert: true } as QueryClientMetaModel,
    mutationFn: async userId => {
      const response = await userAccountStatusApi.getUserAccountStatus(userId);
      return response.data;
    },
  });

  return {
    getUserAccountStatus: mutateAsync,
    isLoading,
    isError,
  };
};
