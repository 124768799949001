/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AIUserProfileSearchQueryResultDto } from '../models';
// @ts-ignore
import { AIUserProfileSearchRequestDto } from '../models';
// @ts-ignore
import { AIUserProfileSearchResultDto } from '../models';
// @ts-ignore
import { SearchForceModeDto } from '../models';
/**
 * AiSearchUserProfileApi - axios parameter creator
 * @export
 */
export const AiSearchUserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchUserProfileResult: async (chatId: string, searchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('getSearchUserProfileResult', 'chatId', chatId)
            // verify required parameter 'searchId' is not null or undefined
            assertParamExists('getSearchUserProfileResult', 'searchId', searchId)
            const localVarPath = `/api/v1/ai/ai/search-chat/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchId !== undefined) {
                localVarQueryParameter['searchId'] = searchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchUserProfileState: async (chatId: string, searchId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('getSearchUserProfileState', 'chatId', chatId)
            // verify required parameter 'searchId' is not null or undefined
            assertParamExists('getSearchUserProfileState', 'searchId', searchId)
            const localVarPath = `/api/v1/ai/ai/search-chat/{chatId}/state`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchId !== undefined) {
                localVarQueryParameter['searchId'] = searchId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {AIUserProfileSearchRequestDto} aIUserProfileSearchRequestDto AI User profile
         * @param {SearchForceModeDto} [searchMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfile: async (chatId: string, aIUserProfileSearchRequestDto: AIUserProfileSearchRequestDto, searchMode?: SearchForceModeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('searchUserProfile', 'chatId', chatId)
            // verify required parameter 'aIUserProfileSearchRequestDto' is not null or undefined
            assertParamExists('searchUserProfile', 'aIUserProfileSearchRequestDto', aIUserProfileSearchRequestDto)
            const localVarPath = `/api/v1/ai/ai/search-chat/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchMode !== undefined) {
                localVarQueryParameter['searchMode'] = searchMode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aIUserProfileSearchRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiSearchUserProfileApi - functional programming interface
 * @export
 */
export const AiSearchUserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiSearchUserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchUserProfileResult(chatId: string, searchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIUserProfileSearchResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchUserProfileResult(chatId, searchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchUserProfileState(chatId: string, searchId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIUserProfileSearchQueryResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSearchUserProfileState(chatId, searchId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {AIUserProfileSearchRequestDto} aIUserProfileSearchRequestDto AI User profile
         * @param {SearchForceModeDto} [searchMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUserProfile(chatId: string, aIUserProfileSearchRequestDto: AIUserProfileSearchRequestDto, searchMode?: SearchForceModeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIUserProfileSearchQueryResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUserProfile(chatId, aIUserProfileSearchRequestDto, searchMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AiSearchUserProfileApi - factory interface
 * @export
 */
export const AiSearchUserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiSearchUserProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchUserProfileResult(chatId: string, searchId: string, options?: any): AxiosPromise<AIUserProfileSearchResultDto> {
            return localVarFp.getSearchUserProfileResult(chatId, searchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} searchId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchUserProfileState(chatId: string, searchId: string, options?: any): AxiosPromise<AIUserProfileSearchQueryResultDto> {
            return localVarFp.getSearchUserProfileState(chatId, searchId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chatId 
         * @param {AIUserProfileSearchRequestDto} aIUserProfileSearchRequestDto AI User profile
         * @param {SearchForceModeDto} [searchMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUserProfile(chatId: string, aIUserProfileSearchRequestDto: AIUserProfileSearchRequestDto, searchMode?: SearchForceModeDto, options?: any): AxiosPromise<AIUserProfileSearchQueryResultDto> {
            return localVarFp.searchUserProfile(chatId, aIUserProfileSearchRequestDto, searchMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiSearchUserProfileApi - interface
 * @export
 * @interface AiSearchUserProfileApi
 */
export interface AiSearchUserProfileApiInterface {
    /**
     * 
     * @param {string} chatId 
     * @param {string} searchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApiInterface
     */
    getSearchUserProfileResult(chatId: string, searchId: string, options?: AxiosRequestConfig): AxiosPromise<AIUserProfileSearchResultDto>;

    /**
     * 
     * @param {string} chatId 
     * @param {string} searchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApiInterface
     */
    getSearchUserProfileState(chatId: string, searchId: string, options?: AxiosRequestConfig): AxiosPromise<AIUserProfileSearchQueryResultDto>;

    /**
     * 
     * @param {string} chatId 
     * @param {AIUserProfileSearchRequestDto} aIUserProfileSearchRequestDto AI User profile
     * @param {SearchForceModeDto} [searchMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApiInterface
     */
    searchUserProfile(chatId: string, aIUserProfileSearchRequestDto: AIUserProfileSearchRequestDto, searchMode?: SearchForceModeDto, options?: AxiosRequestConfig): AxiosPromise<AIUserProfileSearchQueryResultDto>;

}

/**
 * AiSearchUserProfileApi - object-oriented interface
 * @export
 * @class AiSearchUserProfileApi
 * @extends {BaseAPI}
 */
export class AiSearchUserProfileApi extends BaseAPI implements AiSearchUserProfileApiInterface {
    /**
     * 
     * @param {string} chatId 
     * @param {string} searchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApi
     */
    public getSearchUserProfileResult(chatId: string, searchId: string, options?: AxiosRequestConfig) {
        return AiSearchUserProfileApiFp(this.configuration).getSearchUserProfileResult(chatId, searchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chatId 
     * @param {string} searchId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApi
     */
    public getSearchUserProfileState(chatId: string, searchId: string, options?: AxiosRequestConfig) {
        return AiSearchUserProfileApiFp(this.configuration).getSearchUserProfileState(chatId, searchId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chatId 
     * @param {AIUserProfileSearchRequestDto} aIUserProfileSearchRequestDto AI User profile
     * @param {SearchForceModeDto} [searchMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiSearchUserProfileApi
     */
    public searchUserProfile(chatId: string, aIUserProfileSearchRequestDto: AIUserProfileSearchRequestDto, searchMode?: SearchForceModeDto, options?: AxiosRequestConfig) {
        return AiSearchUserProfileApiFp(this.configuration).searchUserProfile(chatId, aIUserProfileSearchRequestDto, searchMode, options).then((request) => request(this.axios, this.basePath));
    }
}
