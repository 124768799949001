import React, { useMemo } from 'react';
import { CopyObjectContext, CopyObjectContextProps } from './CopyObject.context';
import { useSignal } from '@preact/signals-react';
import { VacancyDetailsDto } from '@/api/generated';

export const CopyObjectContextProvider: React.FC = props => {
  const vacancyDetailsCopy = useSignal<VacancyDetailsDto | null>(null);

  const context: CopyObjectContextProps = useMemo(() => {
    return {
      vacancyDetailsCopy,
    };
  }, [vacancyDetailsCopy]);

  return (
    <>
      <CopyObjectContext.Provider value={context}>{props.children}</CopyObjectContext.Provider>
    </>
  );
};
