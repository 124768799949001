import React from 'react';
import { TabPanelProps } from '@mui/lab';
import Box from '@mui/material/Box';

type Props = TabPanelProps & { index: string };

export const TabPanel: React.FC<Props> = (props: TabPanelProps & { index: string }) => {
  const { children, value, index, ...other } = props;

  return (
    // @ts-expect-error: ref type issue
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
};
