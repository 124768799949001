import { VacancyDetailsHeaderTranslation } from '@/assets/locales/views/vacancyDetails/header/vacancyDetails.header.translation.en';

export const vacancyDetailsHeaderTranslation: VacancyDetailsHeaderTranslation = {
  button: {
    copy: 'Copier la demande',
    edit: 'Modifier la demande',
    addCandidate: 'Ajouter un candidat',
    findCandidates: 'Trouver des candidats',
  },
};
