import { UserInitializeTranslation } from '@/assets/locales/userInitialize/userInitialize.translation.en.ts';

export const userInitialize: UserInitializeTranslation = {
  form: {
    buttons: {
      submit: 'Soumettre',
    },
    tooltips: {
      agreeToPrivacyPolicy:
        'Veuillez ouvrir le lien vers la politique de confidentialité et cliquer sur la case à cocher pour soumettre le formulaire',
    },
    fields: {
      company: 'Société',
      location: 'Emplacement',
      phone: 'Téléphone',
      agreeToPrivacyPolicy1:
        "En accédant à ce système, vous acceptez la politique de confidentialité d'Orange Cyberdefense KnowMe que vous pouvez consulter ici :",
      agreeToPrivacyPolicy2: 'cliquez ici',
      agreeToPrivacyPolicy3:
        'Veuillez contacter votre SPOC local si vous avez des questions sur la façon dont Orange Cyberdefense gère la confidentialité des données pour le portail KnowMe',
    },
    error: {
      initializingUserAccount:
        "Erreur lors de l'initialisation du compte utilisateur. Veuillez réessayer plus tard.",
    },
  },
};
