import { useAiApiClient } from '@/api/util/useAiApiClient.util.ts';
import { useMutation } from '@tanstack/react-query';
import { AIUserProfileSearchResultItemDto } from '@/api/generated';
import { AxiosError } from 'axios';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

type Request = {
  chatId: string;
  searchId: string;
};

type Return = {
  getSearchResult: (req: Request) => Promise<AIUserProfileSearchResultItemDto[]>;
  isGettingSearchResult: boolean;
};

export const useGetAiUserProfileResponse = (): Return => {
  const { aiSearchUserProfile } = useAiApiClient();

  const { mutateAsync, isLoading } = useMutation<
    AIUserProfileSearchResultItemDto[],
    AxiosError,
    Request
  >({
    meta: {
      disableSuccessAlert: true,
    } as QueryClientMetaModel,
    mutationFn: async req => {
      const res = await aiSearchUserProfile.getSearchUserProfileResult(req.chatId, req.searchId);
      return res.data.profiles;
    },
  });

  return {
    getSearchResult: mutateAsync,
    isGettingSearchResult: isLoading,
  };
};
