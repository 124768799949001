import { Translations } from '@/assets/locales/en.ts';

import { vacancyCandidateTranslation } from './vacancyCandidate/vacancyCandidate.translation.fr';
import { validationTranslation } from './validation/validation.translation.fr';
import { userInitialize } from '@/assets/locales/userInitialize/userInitialize.translation.fr';
import { vacanciesTranslation } from './vacancies/vacancies.translation.fr';
import { menuTranslation } from './menu/menu.translation.fr';
import { companyDetailsTranslation } from './companyDetails/companyDetails.translation.fr';
import { RoleTypeDto } from '@/api/generated';
import { userDetailsTranslation } from '@/assets/locales/userDetails/userDetails.translation.fr.ts';
import { pageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.fr.ts';
import { viewsTranslation } from '@/assets/locales/views/views.translation.fr.ts';
import { componentsTranslation } from '@/assets/locales/components/components.translation.fr.ts';
import { generalErrorTranslation } from '@/assets/locales/generalError/generalError.translation.fr.ts';
import { layoutsTranslation } from '@/assets/locales/layouts/layouts.translation.fr.ts';
import { profileTranslation } from '@/assets/locales/profile/profile.translation.fr';
import { dictionariesTranslation } from '@/assets/locales/dictionaries/dictionaries.translation.fr';
import { searchTranslation } from '@/assets/locales/search/search.translation.fr.ts';

export const NAMESPACE = 'ocd';

export const fr: Translations = {
  [NAMESPACE]: {
    userDetails: userDetailsTranslation,
    pageTitles: pageTitlesTranslation,
    alerts: {
      success: 'Opération terminée avec succès.',
      error: "L'opération a échoué, réessayez ou contactez l'administrateur de l'application.",
    },
    dictionaries: {
      ...dictionariesTranslation,
      active: 'Actif',
      addButton: 'Ajouter une entrée',
      approve: 'Approbation',
      archive: 'Archivage',
      buttonArchive: 'Archiver',
      choose: 'Choisir',
      date: 'Date',
      dictionary: 'Dictionnaire',
      entries: 'Entrées',
      edit: "Modifier l'entrée du dictionnaire",
      history: 'Historique des opérations',
      maxRows: 'Nombre maximum de lignes par page',
      merge: 'Fusionner',
      mergeResult: 'Résultat de la fusion',
      modalHeader: 'Ajouter une entrée au dictionnaire',
      name: 'Nom',
      nextPage: 'Page suivante',
      operation: 'Opération',
      previousPage: 'Page précédente',
      result: 'Résultat',
      reverse: 'Inverser le changement',
      selectedItems: 'Éléments sélectionnés :',
      search: 'Recherche...',
      title: 'Dictionnaires',
    },
    components: componentsTranslation,
    meetings: {
      employeeView: "Vue de l'employé",
      managerView: 'Vue du gestionnaire',
      meetingNotes: 'Notes de la réunion',
      addNote: 'Ajouter une note',
      editNote: 'Modifier la note',
      addComment: 'Ajouter un commentaire',
      editComment: 'Modifier le commentaire',
      goBack: 'Retour à la liste des notes',
      note: 'Note de la réunion',
      meetingDate: 'Date de la réunion',
      meetingTitle: 'Résumé',
      modal: {
        meetingType: 'Type de réunion',
        title: 'Titre',
        date: 'Date',
        nextMeetingDate: 'Date de la prochaine réunion',
        buttonSave: 'Enregistrer',
        formError: 'Ce champ est obligatoire',
        buttonCancel: 'Annuler',
        addParticipant: 'Ajouter un participant *',
        textEditor: 'Écrire une note',
        editNextMeeting: 'Modifier la date de la prochaine réunion',
        noNextDate: "aucune date n'a été fixée",
      },
      comments: 'Commentaires',
      noComments: 'Aucun commentaire',
      comment: 'Commentaire',
      buttonDelete: 'Supprimer',
      modified: 'Modifié',
      added: 'Ajouté',
      areYouSure: 'Êtes-vous sûr de vouloir supprimer cette note ?',
      warning: 'Une fois approuvée, il ne sera plus possible de restaurer cette note !',
      evaluation: "Évaluation de l'employé",
      salary: 'Négociation salariale',
      development: 'Plan de développement',
      other: 'Autre',
      filter: 'Filtrer',
      participant: 'Participant',
      startDate: 'Choisir une date - à partir de',
      endDate: "Choisir une date - jusqu'à",
      buttonClear: 'Effacer tous les filtres',
    },
    menu: menuTranslation,
    search: searchTranslation,
    fullText: {
      goBack: 'Retour',
      resultCount: 'Résultats de la recherche : ',
      more: 'plus...',
      missing: 'Manquant :',
      all: 'Tous',
      employees: 'Employés',
      projects: 'Projets',
      publications: 'Publications',
      maxResults: 'Nombre maximum de résultats par page',
      employeesTab: {
        position: 'Poste :',
        location: 'Localisation :',
        projects: 'Projets :',
        experience: 'Expérience :',
        certificates: 'Certificats :',
        courses: 'Cours :',
      },
    },
    generalError: generalErrorTranslation,
    sortIcons: {
      name: 'Nom',
      manDays: 'Jours-homme rapportés',
    },
    vacancies: vacanciesTranslation,
    validation: validationTranslation,
    companyDetails: companyDetailsTranslation,
    vacancyCandidate: vacancyCandidateTranslation,
    utils: {
      addFile: 'Ajouter un fichier',
    },
    defaultRoles: {
      [RoleTypeDto.Admin]: 'Admin',
      [RoleTypeDto.Employee]: 'Employé',
      [RoleTypeDto.ExternalPartner]: 'Partenaire externe',
      [RoleTypeDto.GroupExternalPartner]: 'Partenaire externe de groupe',
      [RoleTypeDto.LocalResourceManager]: 'Gestionnaire de ressources locales',
      [RoleTypeDto.PreSales]: 'Avant-ventes',
      [RoleTypeDto.Requester]: 'Demandeur',
    },
    userInitialize,
    views: viewsTranslation,
    layouts: layoutsTranslation,
    profile: profileTranslation,
  },
};
