import { makeStyles } from '@mui/styles';

export const alertStyles = makeStyles(theme => ({
  alert: {
    color: '#fff',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    '&.error': {
      backgroundColor: '#e64033',
    },
    '&.success': {
      backgroundColor: '#53af50',
    },
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
}));
