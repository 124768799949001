import { VacancyStatusEnumDto } from '@/api/generated';

export interface VacanciesTranslation {
  DELIVERY: string;
  HYBRID: string;
  OFF_SITE: string;
  ON_SITE: string;
  PRE_SALES: string;
  addVacancy: string;
  assignHrEmployee: string;
  attachment: string;
  availability: string;
  by: string;
  closeVacancyWarning: string;
  code: string;
  copy: string;
  copyVacancy: string;
  createdBy: string;
  createdDate: string;
  currency: string;
  department: string;
  dropzoneFormatError: string;
  editVacancy: string;
  email: string;
  eras: string;
  extendByOutsourcing: string;
  generateTableList: string;
  goBack: string;
  goBackVacancies: string;
  hrEmployee: string;
  hrEmployeeEdit: string;
  hrbp: string;
  locations: string;
  selectCloseReason: string;
  manager: string;
  mdRate: string;
  mdRateFrom: string;
  mdRateFromLowerThanToError: string;
  mdRateLowerEqualZeroError: string;
  mdRateTo: string;
  mdRateToHigherThanFromError: string;
  minYearsOfExperience: string;
  minYearsOfExperienceShort: string;
  modifiedBy: string;
  modifiedDate: string;
  new: string;
  noVacancies: string;
  notVisible: string;
  numberOfCandidates: string;
  numberOfVacancies: string;
  numberValid: string;
  optionalSkills: string;
  phoneNumber: string;
  position: string;
  requester: string;
  role: string;
  status: Record<(typeof VacancyStatusEnumDto)[keyof typeof VacancyStatusEnumDto], string>;
  vacanciesAndCandidates: string;
  visible: string;
  priority: {
    label: string;
    low: string;
    urgent: string;
    normal: string;
    critical: string;
  };
  candidatesList: {
    header: string;
    name: string;
    email: string;
    phoneNumber: string;
    status: string;
    createdAt: string;
    externalId: string;
    expand: string;
    collapse: string;
    sentAt: string;
  };
  dialog: {
    title: string;
    warning: string;
    acceptLabel: string;
  };
}

export const vacanciesTranslation: VacanciesTranslation = {
  DELIVERY: 'Delivery',
  HYBRID: 'Hybrid',
  OFF_SITE: 'Off-site',
  ON_SITE: 'On-site',
  PRE_SALES: 'Pre-sales',
  addVacancy: 'Add request',
  selectCloseReason: 'Select the reason for closing the request:',
  assignHrEmployee: 'Select the person you want to assign to the request:',
  attachment: 'Attachment',
  availability: 'Expected availability',
  by: 'by',
  closeVacancyWarning: 'Once approved, it will not be possible to restore this request!',
  code: 'Reference number',
  copy: 'Copy request',
  copyVacancy: 'Copy request',
  createdBy: 'Created by',
  createdDate: 'Created date',
  currency: 'Currency',
  department: 'Department',
  dropzoneFormatError:
    'Wrong file format! Accepted file extensions: .doc, .docx, .txt, .pdf, .jpg, .jpeg',
  editVacancy: 'Edit request',
  email: 'E-mail address',
  eras: 'External Recruitment Agencies',
  extendByOutsourcing: 'Extend by outsourcing',
  generateTableList: 'Export requests list',
  goBack: 'Go back',
  goBackVacancies: 'Go back to requests list',
  hrEmployee: 'HR Employee',
  hrEmployeeEdit: 'Edit HR Employee',
  hrbp: 'HR Business Partner',
  locations: 'Location',
  manager: 'Hiring manager',
  mdRate: 'Expected hourly rate',
  mdRateFrom: 'Hourly rate from',
  mdRateFromLowerThanToError: 'This rate must be lower than the rate TO',
  mdRateLowerEqualZeroError: 'Amount must be greater than 0',
  mdRateTo: 'Hourly rate to',
  mdRateToHigherThanFromError: 'This rate must be higher than the rate FROM',
  minYearsOfExperience: 'Min. years of experience on similar position',
  minYearsOfExperienceShort: 'Experience (years)',
  modifiedBy: 'Modified by',
  modifiedDate: 'Modified date',
  new: 'New',
  noVacancies: 'No requests match given criteria',
  notVisible: 'Limited visibility',
  numberOfCandidates: 'Number of candidates',
  numberOfVacancies: 'Number of requests',
  numberValid: 'The value should consist of numbers only',
  optionalSkills: 'Optional technologies / skills',
  phoneNumber: 'Phone number',
  position: 'Position',
  requester: 'Requester',
  role: 'Role',
  vacanciesAndCandidates: 'Number of requests / Number of candidates',
  visible: 'Visible for all users',
  priority: {
    label: 'Priority',
    low: 'Low',
    urgent: 'Urgent',
    normal: 'Normal',
    critical: 'Critical',
  },
  status: {
    [VacancyStatusEnumDto.Archived]: 'Archived',
    [VacancyStatusEnumDto.Open]: 'Open',
    [VacancyStatusEnumDto.Closed]: 'Closed',
    [VacancyStatusEnumDto.OnHold]: 'On hold',
  },
  candidatesList: {
    header: 'Candidates list',
    name: 'Name',
    email: 'E-mail',
    phoneNumber: 'Phone number',
    status: 'Status',
    createdAt: 'Date added',
    externalId: 'Candidate ID',
    expand: 'Expand',
    collapse: 'Collapse',
    sentAt: 'Sent date',
  },
  dialog: {
    title: 'Are you sure you want to add outsourcing?',
    warning:
      'Clicking "Confirm" button will send an e-mail with request details to external recruitment agencies.',
    acceptLabel: 'Confirm',
  },
};
