import React from 'react';
import Container from '@mui/material/Container';

import useStyles from './full-page-spinner.styles';

function FullPageSpinner() {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.ldsDualRing}>
        <div className={classes.ldsDualRingInner}></div>
      </div>
    </Container>
  );
}

export default FullPageSpinner;
