/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const VacancyProjectStatusDto = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED',
    ClosedLost: 'CLOSED_LOST',
    ClosedWon: 'CLOSED_WON',
    NoSalesforceId: 'NO_SALESFORCE_ID',
    OnHold: 'ON_HOLD'
} as const;

export type VacancyProjectStatusDto = typeof VacancyProjectStatusDto[keyof typeof VacancyProjectStatusDto];



