export type SearchTranslation = {
  buttonSearch: string;
  categories: {
    availability: string;
    certificates: string;
    companies: string;
    contractType: string;
    createdBy: string;
    cvSourceCompany: string;
    email: string;
    experience: string;
    firstName: string;
    language: string;
    lastName: string;
    location: string;
    locations: string;
    names: string;
    positions: string;
    projects: string;
    requesters: string;
    skills: string;
    starred: string;
    status: string;
    uploaderOfCv: string;
  };
  choose: string;
  clearParameters: string;
  downloadCV: string;
  downloadCVMultiple: string;
  downloadXls: string;
  hideParameters: string;
  parameters: string;
  paramsNumber: string;
  search: string;
  selectedEmployees: string;
  showParameters: string;
  table: {
    action: string;
    fullName: string;
    firstName: string;
    lastModificationDate: string;
    lastName: string;
    location: string;
    maxRows: string;
    nextPage: string;
    position: string;
    previousPage: string;
    profile: string;
    showProfile: string;
    of: string;
    moreThan: string;
    availability: string;
    true: string;
    false: string;
    from: string;
    positionChange: string;
  };
  title: string;
  switchToAdvanced: string;
  switchToBasic: string;
  from: string;
  to: string;
  lastMeetingDate: string;
  profileCompleteness: string;
  withoutAMeeting: string;
  months3: string;
  months6: string;
  months9: string;
  withProfileCompleteness: string;
  withProfileOlder: string;
  selectTimePeriod: string;
  selectValue: string;
  lastModificationDate: string;
  showProficiencies: string;
  hideProficiencies: string;
  downloading: string;
  nextMeetingDate: string;
  nextMeetingDateWithin: string;
  days7: string;
  days14: string;
  days30: string;
  notSet: string;
  createdAt: string;
  replyDate: string;
  startingDate: string;
  changeDate: string;
};

export const searchTranslation: SearchTranslation = {
  buttonSearch: 'Search',
  categories: {
    certificates: 'Certification',
    companies: 'Previous company',
    locations: 'Location',
    names: 'First name and/or last name',
    positions: 'Position',
    projects: 'Project',
    skills: 'Skill',
    availability: 'Available only',
    starred: 'Starred',
    experience: 'Years in the company (e.g. 2y6m)',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    status: 'Status',
    createdBy: 'Created by',
    contractType: 'Contract type',
    requesters: 'Requesters',
    location: 'Location',
    language: 'Language',
    cvSourceCompany: 'CV source company',
    uploaderOfCv: 'Uploader of CV',
  },
  choose: 'Choose...',
  clearParameters: 'Clear',
  downloadCV: 'Download CV',
  downloadCVMultiple: 'Download multiple CVs',
  downloadXls: 'Download xls',
  hideParameters: 'Hide parameters',
  parameters: 'Search parameters',
  paramsNumber: 'Search parameters:',
  search: 'Search...',
  selectedEmployees: 'Selected employees:',
  showParameters: 'Show parameters',
  table: {
    action: 'Action',
    fullName: 'Full Name',
    firstName: 'First Name',
    lastModificationDate: 'Last update',
    lastName: 'Last Name',
    location: 'Location',
    maxRows: 'Maximum rows per page',
    nextPage: 'Next page',
    position: 'Position',
    previousPage: 'Previous page',
    profile: 'Profile',
    showProfile: 'Show profile',
    of: 'of',
    moreThan: 'more than',
    availability: 'Availability',
    true: 'Yes',
    false: 'No',
    from: 'From',
    positionChange: 'Interested in transition into',
  },
  title: 'Search for employees',
  switchToAdvanced: 'Switch to advanced filters',
  switchToBasic: 'Switch to basic filters',
  from: 'from',
  to: 'to',
  lastMeetingDate: 'Last meeting date',
  profileCompleteness: 'Profile completeness (%)',
  withoutAMeeting: 'Without a meeting within last ...',
  months3: '3 months',
  months6: '6 months',
  months9: '9 months',
  withProfileCompleteness: 'Profile completeness under ...',
  withProfileOlder: 'Profile older than ...',
  selectTimePeriod: 'Select time period',
  selectValue: 'Select value',
  lastModificationDate: 'Last update date',
  showProficiencies: 'show proficiencies',
  hideProficiencies: 'hide proficiencies',
  downloading: 'Downloading...',
  nextMeetingDate: 'Next meeting date',
  nextMeetingDateWithin: 'Next meeting date within ...',
  days7: '1 week',
  days14: '2 weeks',
  days30: '1 month',
  notSet: 'not set',
  createdAt: 'Create date',
  replyDate: 'Reply date',
  startingDate: 'Employment start date',
  changeDate: 'Change date',
};
