import { SearchTranslation } from '@/assets/locales/search/search.translation.en.ts';

export const searchTranslation: SearchTranslation = {
  buttonSearch: 'Rechercher',
  categories: {
    availability: 'Disponible seulement',
    certificates: 'Certification',
    companies: 'Ancienne entreprise',
    contractType: 'Type de contrat',
    createdBy: 'Créé par',
    cvSourceCompany: 'Entreprise source du CV',
    email: 'E-mail',
    experience: "Années dans l'entreprise (par ex. 2a6m)",
    firstName: 'Prénom',
    language: 'Langue',
    lastName: 'Nom',
    location: 'Emplacement',
    locations: 'Localisation',
    names: 'Prénom et/ou nom',
    positions: 'Poste',
    projects: 'Projet',
    requesters: 'Demandeurs',
    skills: 'Compétence',
    starred: 'Favoris',
    status: 'Statut',
    uploaderOfCv: 'Téléverseur du CV',
  },
  choose: 'Choisir...',
  clearParameters: 'Effacer',
  downloadCV: 'Télécharger le CV',
  downloadCVMultiple: 'Télécharger plusieurs CV',
  downloadXls: 'Télécharger xls',
  hideParameters: 'Masquer les paramètres',
  parameters: 'Paramètres de recherche',
  paramsNumber: 'Paramètres de recherche :',
  search: 'Recherche...',
  selectedEmployees: 'Employés sélectionnés :',
  showParameters: 'Afficher les paramètres',
  table: {
    action: 'Action',
    fullName: 'Nom complet',
    firstName: 'Prénom',
    lastModificationDate: 'Dernière mise à jour',
    lastName: 'Nom',
    location: 'Localisation',
    maxRows: 'Nombre maximum de lignes par page',
    nextPage: 'Page suivante',
    position: 'Poste',
    previousPage: 'Page précédente',
    profile: 'Profil',
    showProfile: 'Voir le profil',
    of: 'de',
    moreThan: 'plus de',
    availability: 'Disponibilité',
    true: 'Oui',
    false: 'Non',
    from: 'De',
    positionChange: 'Intéressé par une transition vers',
  },
  title: "Recherche d'employés",
  switchToAdvanced: 'Passer aux filtres avancés',
  switchToBasic: 'Passer aux filtres de base',
  from: 'de',
  to: 'à',
  lastMeetingDate: 'Date de la dernière réunion',
  profileCompleteness: 'Complétude du profil (%)',
  withoutAMeeting: 'Sans réunion dans les derniers...',
  months3: '3 mois',
  months6: '6 mois',
  months9: '9 mois',
  withProfileCompleteness: 'Complétude du profil sous ...',
  withProfileOlder: 'Profil de plus de ...',
  selectTimePeriod: 'Sélectionnez la période',
  selectValue: 'Sélectionnez la valeur',
  lastModificationDate: 'Date de la dernière mise à jour',
  showProficiencies: 'afficher les compétences',
  hideProficiencies: 'masquer les compétences',
  downloading: 'Téléchargement...',
  nextMeetingDate: 'Date de la prochaine réunion',
  nextMeetingDateWithin: 'Prochaine réunion dans ...',
  days7: '1 semaine',
  days14: '2 semaines',
  days30: '1 mois',
  notSet: 'non défini',
  createdAt: 'Date de création',
  replyDate: 'Date de réponse',
  startingDate: "Date de début d'emploi",
  changeDate: 'Date de changement',
};
