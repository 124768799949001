import { SxProps } from '@mui/system';
import { colors } from '@/utils/colors.ts';

export const AiSearchSearchResultPaperStyles: SxProps = {
  padding: 2,
};
export const AiSearchSearchResultNameStyles: SxProps = {
  fontSize: 16,
  fontWeight: 500,
  color: colors.black_80,
  paddingBottom: 2,
};
