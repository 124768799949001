import {
  OcdCvSectionDto,
  ResumeFileFormatDto,
  ResumeTemplateTypeDto,
} from '@/api/generated-user-profile';
import { UserProfileMainSectionTranslation } from '@/assets/locales/views/userProfile/sections/mainSection/userProfile.mainSection.translation.en';

export const userProfileMainSectionTranslation: UserProfileMainSectionTranslation = {
  title: 'Profil',
  noDataYetLine1: "Vous n'avez pas encore de données de profil définies.",
  noDataYetLine2:
    'Appuyez sur le bouton "Commencer" ci-dessous pour commencer à créer votre\nProfil Professionnel',
  button: {
    createProfile: 'Commencer',
  },
  content: {
    availability: {
      availableFrom: 'Disponible depuis',
      available: 'Est disponible',
      noData: 'Pas de données',
      isAvailable: {
        yes: 'Disponible',
        no: 'Non disponible',
      },
      modal: {
        title: 'Mettre à jour la disponibilité',
        button: {
          edit: 'Mettre à jour',
        },
      },
    },
    division: 'Division',
    divisionLocation: 'Localisation de la division',
    employeeLocation: 'Localisation',
    manager: 'Responsable',
    mobile: 'Mobile',
    position: 'Poste',
    summaryAnonymized: 'Description anonymisée',
    summaryPublic: 'Description standard',
    button: {
      edit: 'Modifier le profil',
      downloadCv: 'Télécharger le CV',
    },
    cvModal: {
      content: {
        button: {
          download: 'Télécharger',
        },
        whichSections: 'Veuillez sélectionner les sections que vous souhaitez inclure',
        whichProjects: 'Veuillez sélectionner les projets que vous souhaitez inclure',
        whichExperiences:
          'Veuillez sélectionner les expériences professionnelles que vous souhaitez inclure',
        parameters: {
          anonymous: {
            employeeLabel: 'Anonymiser les données des employés',
            customerLabel: 'Anonymiser les données des projets',
            options: {
              yes: 'Oui',
              no: 'Non',
            },
          },
          fileFormat: {
            label: 'Format de fichier',
            options: {
              [ResumeFileFormatDto.Pdf]: 'PDF',
              [ResumeFileFormatDto.Docx]: 'DOCX',
            },
          },
          template: {
            label: 'Modèle',
            options: {
              [ResumeTemplateTypeDto.KnowMe]: 'KnowMe',
              [ResumeTemplateTypeDto.Ocd]: 'OCD',
            },
          },
        },
        section: {
          [OcdCvSectionDto.Awards]: 'Distinctions',
          [OcdCvSectionDto.Certificates]: 'Certificats',
          [OcdCvSectionDto.Contact]: 'Contact',
          [OcdCvSectionDto.Courses]: 'Cours',
          [OcdCvSectionDto.Description]: 'Description',
          [OcdCvSectionDto.Education]: 'Formation',
          [OcdCvSectionDto.Experience]: 'Expérience',
          [OcdCvSectionDto.Languages]: 'Langues',
          [OcdCvSectionDto.Projects]: 'Projets',
          [OcdCvSectionDto.Skills]: 'Compétences',
        },
      },
      title: 'Générer le CV',
    },
    lastModification: 'Dernière modification',
  },
  availabilityForm: {
    available: 'Est disponible',
    availableFrom: 'Disponible depuis',
    button: {
      save: 'Enregistrer',
    },
  },
  form: {
    avatar: {
      caption: "Changer l'image",
      scale: 'Échelle',
      errors: {
        fileToBig: 'Fichier trop volumineux',
        wrongFormat: 'Format incorrect',
      },
    },
    division: 'Division',
    divisionLocation: 'Localisation de la division',
    email: 'Email',
    employeeLocation: 'Localisation',
    firstName: 'Prénom',
    lastName: 'Nom',
    manager: 'Responsable',
    mobile: 'Mobile',
    position: 'Poste',
    summaryAnonymized: 'Description anonymisée',
    summaryAnonymizedPlaceholder: 'Résumez vos réalisations sans informations identifiantes',
    summaryPublic: 'Description standard',
    summaryPublicPlaceholder: 'Résumez vos réalisations',
    button: {
      save: 'Enregistrer',
    },
  },
  modal: {
    title: 'Modifier le profil',
    titleCreate: 'Créer un profil',
  },
};
