import {
  OcdCvSectionDto,
  ResumeFileFormatDto,
  ResumeTemplateTypeDto,
} from '@/api/generated-user-profile';
import { UserProfileMainSectionTranslation } from '@/assets/locales/views/userProfile/sections/mainSection/userProfile.mainSection.translation.en';

export const userProfileMainSectionTranslation: UserProfileMainSectionTranslation = {
  title: 'Profil',
  noDataYetLine1: 'Sie haben noch keinen Profildatensatz.',
  noDataYetLine2:
    "Klicken Sie unten auf die Schaltfläche 'Anfangen', um Ihr berufliches Profil zu erstellen.",
  button: {
    createProfile: 'Anfangen',
  },
  content: {
    availability: {
      availableFrom: 'Verfügbar von',
      available: 'Ist verfügbar',
      noData: 'Keine Angaben',
      isAvailable: {
        yes: 'Verfügbar',
        no: 'Nicht verfügbar',
      },
      modal: {
        title: 'Verfügbarkeit aktualisieren',
        button: {
          edit: 'Update',
        },
      },
    },
    division: 'Abteilung',
    divisionLocation: 'Standort der Abteilung',
    employeeLocation: 'Ort',
    manager: 'Manager',
    mobile: 'Mobile',
    position: 'Stelle',
    summaryAnonymized: 'Anonyme Beschreibung',
    summaryPublic: 'Standardbeschreibung',
    button: {
      edit: 'Profil bearbeiten',
      downloadCv: 'Lebenslauf herunterladen',
    },
    cvModal: {
      content: {
        button: {
          download: 'Herunterladen',
        },
        whichSections: 'Bitte wählen Sie die Abschnitte aus, die Sie einschließen möchten',
        whichProjects: 'Bitte wählen Sie die Projekte aus, die Sie einschließen möchten',
        whichExperiences: 'Bitte wählen Sie aus, welche Arbeitserfahrungen Sie angeben möchten',
        parameters: {
          anonymous: {
            employeeLabel: 'Anonymisierung der Daten von Angestellten',
            customerLabel: 'Anonymisierung der Daten von Projekten',
            options: {
              yes: 'Ja',
              no: 'Nein',
            },
          },
          fileFormat: {
            label: 'Dateiformat',
            options: {
              [ResumeFileFormatDto.Pdf]: 'PDF',
              [ResumeFileFormatDto.Docx]: 'DOCX',
            },
          },
          template: {
            label: 'Vorlage',
            options: {
              [ResumeTemplateTypeDto.KnowMe]: 'KnowMe',
              [ResumeTemplateTypeDto.Ocd]: 'OCD',
            },
          },
        },
        section: {
          [OcdCvSectionDto.Awards]: 'Preise',
          [OcdCvSectionDto.Certificates]: 'Zertifikate',
          [OcdCvSectionDto.Contact]: 'Kontact',
          [OcdCvSectionDto.Courses]: 'Kurse',
          [OcdCvSectionDto.Description]: 'Beschreibung',
          [OcdCvSectionDto.Education]: 'Ausbildung',
          [OcdCvSectionDto.Experience]: 'Erfahrung',
          [OcdCvSectionDto.Languages]: 'Sprachen',
          [OcdCvSectionDto.Projects]: 'Projekte',
          [OcdCvSectionDto.Skills]: 'Fähigkeiten',
        },
      },
      title: 'Lebenslauf erstellen',
    },
    lastModification: 'Letzte Änderung',
  },
  availabilityForm: {
    available: 'Ist verfügbar',
    availableFrom: 'Verfügbar von',
    button: {
      save: 'Speichern',
    },
  },
  form: {
    avatar: {
      caption: 'Bild ändern',
      scale: 'Maßstab',
      errors: {
        fileToBig: 'Datei ist zu groß',
        wrongFormat: 'Falsches Format',
      },
    },
    division: 'Abteilung',
    divisionLocation: 'Standort der Abteilung',
    email: 'Email',
    employeeLocation: 'Ort',
    firstName: 'Vorname',
    lastName: 'Nachname',
    manager: 'Manager',
    mobile: 'Mobile',
    position: 'Stelle',
    summaryAnonymized: 'Anonymisierte Beschreibung',
    summaryAnonymizedPlaceholder:
      'Fassen Sie Ihre Leistungen ohne identifizierende Informationen zusammen',
    summaryPublic: 'Standardbeschreibung',
    summaryPublicPlaceholder: 'Fassen Sie Ihre Leistungen zusammen',
    button: {
      save: 'Speichern',
    },
  },
  modal: {
    title: 'Profil bearbeiten',
    titleCreate: 'Profil erstellen',
  },
};
