import { DictionaryTypeDto, OcdReportTypeDto, ReportRangeTypeDto } from '@/api/generated';

import { DictionaryTranslations } from '@/assets/locales/dictionaries/dictionaries.translation.en.ts';

export const dictionariesTranslation: DictionaryTranslations = {
  dictionaryType: {
    [DictionaryTypeDto.Certificate]: 'Certificat',
    [DictionaryTypeDto.Language]: 'Langue',
    [DictionaryTypeDto.Currency]: 'Devise',
    [DictionaryTypeDto.Skill]: 'Compétence',
    [DictionaryTypeDto.Location]: 'Lieu',
    [DictionaryTypeDto.Sector]: 'Secteur',
  },
  reportType: {
    [OcdReportTypeDto.AverageDayRateAndMargin]: 'Tarif journalier moyen et marge',
    [OcdReportTypeDto.Certificates]: 'Certificats',
    [OcdReportTypeDto.CvDownloads]: 'Téléchargements de fichiers de CV',
    [OcdReportTypeDto.LandscapeOfTechnology]: 'Paysage technologique',
    [OcdReportTypeDto.NumberOfRequestsPerPartner]: 'Nombre de demandes par partenaire',
    [OcdReportTypeDto.TimeToSignCandidate]: 'Temps pour signer le premier candidat',
    [OcdReportTypeDto.TimeToFindCandidate]: 'Temps pour trouver le premier candidat',
    [OcdReportTypeDto.TopReasonForLoosing]: 'Principale raison de perte',
    [OcdReportTypeDto.WinLossRatio]: 'Ratio de victoires/défaites',
  },
  reportRange: {
    [ReportRangeTypeDto.Global]: 'Global',
    [ReportRangeTypeDto.Country]: 'Pays',
  },
};
