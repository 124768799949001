/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { VacancyProjectBasicDto } from '../models';
// @ts-ignore
import type { VacancyProjectDetailsDto } from '../models';
/**
 * VacancyProjectsApi - axios parameter creator
 * @export
 */
export const VacancyProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveVacancyProject: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('archiveVacancyProject', 'projectId', projectId)
            const localVarPath = `/api/v1/vacancyProjects/{projectId}/archive`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new project
         * @param {VacancyProjectBasicDto} [vacancyProjectBasicDto] Create new project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancyProject: async (vacancyProjectBasicDto?: VacancyProjectBasicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vacancyProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacancyProjectBasicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Vacancy Projects
         * @param {string} [salesforceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVacancyProjects: async (salesforceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vacancyProjects`
                .replace(`{${"salesforceId"}}`, encodeURIComponent(String(salesforceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyProject: async (projectId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getVacancyProject', 'projectId', projectId)
            const localVarPath = `/api/v1/vacancyProjects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project
         * @param {number} projectId 
         * @param {VacancyProjectBasicDto} vacancyProjectBasicDto Update project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyProject: async (projectId: number, vacancyProjectBasicDto: VacancyProjectBasicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateVacancyProject', 'projectId', projectId)
            // verify required parameter 'vacancyProjectBasicDto' is not null or undefined
            assertParamExists('updateVacancyProject', 'vacancyProjectBasicDto', vacancyProjectBasicDto)
            const localVarPath = `/api/v1/vacancyProjects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacancyProjectBasicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyProjectsApi - functional programming interface
 * @export
 */
export const VacancyProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveVacancyProject(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveVacancyProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsApi.archiveVacancyProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new project
         * @param {VacancyProjectBasicDto} [vacancyProjectBasicDto] Create new project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVacancyProject(vacancyProjectBasicDto?: VacancyProjectBasicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyProjectDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVacancyProject(vacancyProjectBasicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsApi.createVacancyProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Vacancy Projects
         * @param {string} [salesforceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVacancyProjects(salesforceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VacancyProjectDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVacancyProjects(salesforceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsApi.getAllVacancyProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVacancyProject(projectId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VacancyProjectDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVacancyProject(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsApi.getVacancyProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update project
         * @param {number} projectId 
         * @param {VacancyProjectBasicDto} vacancyProjectBasicDto Update project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVacancyProject(projectId: number, vacancyProjectBasicDto: VacancyProjectBasicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVacancyProject(projectId, vacancyProjectBasicDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsApi.updateVacancyProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VacancyProjectsApi - factory interface
 * @export
 */
export const VacancyProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveVacancyProject(projectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.archiveVacancyProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new project
         * @param {VacancyProjectBasicDto} [vacancyProjectBasicDto] Create new project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVacancyProject(vacancyProjectBasicDto?: VacancyProjectBasicDto, options?: any): AxiosPromise<VacancyProjectDetailsDto> {
            return localVarFp.createVacancyProject(vacancyProjectBasicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Vacancy Projects
         * @param {string} [salesforceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVacancyProjects(salesforceId?: string, options?: any): AxiosPromise<Array<VacancyProjectDetailsDto>> {
            return localVarFp.getAllVacancyProjects(salesforceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVacancyProject(projectId: number, options?: any): AxiosPromise<VacancyProjectDetailsDto> {
            return localVarFp.getVacancyProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project
         * @param {number} projectId 
         * @param {VacancyProjectBasicDto} vacancyProjectBasicDto Update project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVacancyProject(projectId: number, vacancyProjectBasicDto: VacancyProjectBasicDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateVacancyProject(projectId, vacancyProjectBasicDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyProjectsApi - interface
 * @export
 * @interface VacancyProjectsApi
 */
export interface VacancyProjectsApiInterface {
    /**
     * 
     * @summary Archive project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApiInterface
     */
    archiveVacancyProject(projectId: number, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Create a new project
     * @param {VacancyProjectBasicDto} [vacancyProjectBasicDto] Create new project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApiInterface
     */
    createVacancyProject(vacancyProjectBasicDto?: VacancyProjectBasicDto, options?: RawAxiosRequestConfig): AxiosPromise<VacancyProjectDetailsDto>;

    /**
     * 
     * @summary Vacancy Projects
     * @param {string} [salesforceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApiInterface
     */
    getAllVacancyProjects(salesforceId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VacancyProjectDetailsDto>>;

    /**
     * 
     * @summary Get project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApiInterface
     */
    getVacancyProject(projectId: number, options?: RawAxiosRequestConfig): AxiosPromise<VacancyProjectDetailsDto>;

    /**
     * 
     * @summary Update project
     * @param {number} projectId 
     * @param {VacancyProjectBasicDto} vacancyProjectBasicDto Update project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApiInterface
     */
    updateVacancyProject(projectId: number, vacancyProjectBasicDto: VacancyProjectBasicDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VacancyProjectsApi - object-oriented interface
 * @export
 * @class VacancyProjectsApi
 * @extends {BaseAPI}
 */
export class VacancyProjectsApi extends BaseAPI implements VacancyProjectsApiInterface {
    /**
     * 
     * @summary Archive project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApi
     */
    public archiveVacancyProject(projectId: number, options?: RawAxiosRequestConfig) {
        return VacancyProjectsApiFp(this.configuration).archiveVacancyProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new project
     * @param {VacancyProjectBasicDto} [vacancyProjectBasicDto] Create new project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApi
     */
    public createVacancyProject(vacancyProjectBasicDto?: VacancyProjectBasicDto, options?: RawAxiosRequestConfig) {
        return VacancyProjectsApiFp(this.configuration).createVacancyProject(vacancyProjectBasicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Vacancy Projects
     * @param {string} [salesforceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApi
     */
    public getAllVacancyProjects(salesforceId?: string, options?: RawAxiosRequestConfig) {
        return VacancyProjectsApiFp(this.configuration).getAllVacancyProjects(salesforceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApi
     */
    public getVacancyProject(projectId: number, options?: RawAxiosRequestConfig) {
        return VacancyProjectsApiFp(this.configuration).getVacancyProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project
     * @param {number} projectId 
     * @param {VacancyProjectBasicDto} vacancyProjectBasicDto Update project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsApi
     */
    public updateVacancyProject(projectId: number, vacancyProjectBasicDto: VacancyProjectBasicDto, options?: RawAxiosRequestConfig) {
        return VacancyProjectsApiFp(this.configuration).updateVacancyProject(projectId, vacancyProjectBasicDto, options).then((request) => request(this.axios, this.basePath));
    }
}

