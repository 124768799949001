import { AIUserProfileSearchWeightDto } from '@/api/generated';

export type AiSearchTranslation = {
  parameters: {
    controls: Record<keyof AIUserProfileSearchWeightDto, string>;
  };
};

export const aiSearchTranslation: AiSearchTranslation = {
  parameters: {
    controls: {
      positionWeight: 'Position',
      skillWeight: 'Skills',
      languagesWeight: 'Languages',
      experienceWeight: 'Experience',
      certificatesWeight: 'Certificates',
    },
  },
};
