import { MenuTranslation } from '@/assets/locales/menu/menu.translation.en.ts';

export const menuTranslation: MenuTranslation = {
  addVacancy: 'Ressource anfordern',
  aiSearch: 'AI search',
  companies: 'Unternehmen',
  profileSearch: 'Profile suchen',
  projects: 'Liste der Projekte',
  recruitmentBoard: 'Anfrage-Tracker',
  recruitmentCandidates: 'Kandidaten',
  reports: 'Meldungen',
  settingsDictionaries: 'Wörterbücher',
  userProfile: 'Mein Profil',
};
