import { LanguageProficiencyDto, UserProfileLanguageDto } from '@/api/generated-user-profile';
import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';

export type UserProfileLanguagesSectionTranslation = SectionTranslationType<
  Omit<UserProfileLanguageDto, 'userProfileId' | 'languageId' | 'contentLanguage'>
> & {
  content: {
    tableHeader: {
      language: string;
      level: string;
      actions: string;
    };
    proficiencyLevels: Record<LanguageProficiencyDto, string>;
  };
  form: {
    proficiencyLevels: Record<LanguageProficiencyDto, string>;
  };
};

const proficiencyLevels = {
  [LanguageProficiencyDto.Basic]: 'Basic',
  [LanguageProficiencyDto.Intermediate]: 'Intermediate',
  [LanguageProficiencyDto.Advanced]: 'Advanced',
  [LanguageProficiencyDto.Professional]: 'Professional',
  [LanguageProficiencyDto.Bilingual]: 'Bilingual',
};

export const userProfileLanguagesSectionTranslation: UserProfileLanguagesSectionTranslation = {
  headerTitle: 'Languages',
  content: {
    emptyItemsLabel: 'languages',
    tableHeader: {
      language: 'Language',
      level: 'Proficiency',
      actions: 'Actions',
    },
    proficiencyLevels,
    button: {
      edit: 'Edit',
    },
  },
  form: {
    actions: 'Actions',
    language: 'Language',
    proficiencyLevel: 'Proficiency',
    proficiencyLevels,
    button: {
      save: 'Save',
    },
  },
  modal: {
    title: 'Add language',
  },
};
