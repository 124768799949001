import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileSkillDto } from '@/api/generated-user-profile';
import { SkillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.en.ts';

export const skillsDescriptions: SkillsDescriptions = {
  0: {
    title: 'Aucune connaissance',
    descriptions: [],
  },
  1: {
    title: 'Niveau 1 - Débutant',
    descriptions: [
      "Expérience acquise dans un environnement de classe et/ou de laboratoire ou en tant que stagiaire sur le terrain sous la supervision d'un consultant senior",
      "Une assistance est attendue lors de l'installation",
      'Compréhension et capacité à discuter de la terminologie, des concepts, des principes et des problèmes',
      'La gamme complète de documents de référence et de ressources peut être utilisée',
    ],
  },
  2: {
    title: 'Niveau 2 - Intermédiaire',
    descriptions: [
      'Capable de réaliser avec succès les tâches dans cette compétence comme demandé',
      "Une aide d'un expert peut être nécessaire de temps en temps, mais la compétence peut être réalisée de manière autonome",
      'Focus sur les connaissances et compétences en installation et configuration',
      "Compréhension et capacité à discuter de l'application et des implications des modifications de l'installation",
      'Focus sur le dépannage approfondi des problèmes de produit',
    ],
  },
  3: {
    title: 'Niveau 3 - Avancé',
    descriptions: [
      'Réalisation des actions associées à cette compétence sans assistance',
      'Reconnu au sein de leur organisation immédiate comme « un expert en la matière » lorsque des questions difficiles surviennent concernant ce produit',
      "Capable de coacher d'autres personnes sur le produit et de gérer des conceptions complexes",
    ],
  },
  4: {
    title: 'Niveau 4 - Expert',
    descriptions: [
      'Connu comme un expert en ce produit',
      "Capable de fournir des conseils, de dépanner et de répondre à des questions liées à ce domaine d'expertise",
      "Capable de démontrer une excellence constante dans l'application de cette compétence à travers plusieurs projets et conceptions complexes",
      'Reconnu extérieurement et par les fournisseurs de produits comme « un expert absolu en la matière »',
      'Considéré comme une autorité sur le produit',
    ],
  },
};

export type UserProfileSkillsSectionTranslation = SectionTranslationType<
  Omit<UserProfileSkillDto, 'userProfileId' | 'skillId' | 'contentLanguage'>
> & {
  content: {
    tableHeader: {
      skill: string;
      level: string;
      experienceInYears: string;
      actions: string;
    };
    experienceMonths: string;
    experienceYears: string;
    skillsDescriptions: SkillsDescriptions;
  };
  form: {
    skillsDescriptions: SkillsDescriptions;
  };
};

export const userProfileSkillsSectionTranslation: UserProfileSkillsSectionTranslation = {
  headerTitle: 'Compétences',
  content: {
    emptyItemsLabel: 'compétences',
    tableHeader: {
      skill: 'Compétence',
      level: 'Niveau',
      experienceInYears: 'Expérience',
      actions: 'Actions',
    },
    experienceMonths: 'mois',
    experienceYears: 'ans',
    skillsDescriptions,
    button: {
      edit: 'Modifier',
    },
  },
  form: {
    actions: 'Actions',
    experienceInMonths: 'Expérience',
    level: 'Ajuster votre niveau',
    nonDictionarySkillValue: 'Valeur de compétence non répertoriée',
    skill: 'Compétence',
    skillsDescriptions,
    button: {
      save: 'Enregistrer',
    },
  },
  modal: {
    title: 'Ajouter une compétence',
  },
};
