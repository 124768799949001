import { RouteEnum } from './Route.model';
import { RoleTypeDto } from '@/api/generated';

const ReqLrmAdm = [RoleTypeDto.Requester, RoleTypeDto.LocalResourceManager, RoleTypeDto.Admin];

export const ROUTES_ROLES: Record<RouteEnum, RoleTypeDto[]> = {
  [RouteEnum.AI_SEARCH]: [...ReqLrmAdm],
  [RouteEnum.CANDIDATES]: [...ReqLrmAdm],
  [RouteEnum.CANDIDATES_ID]: [...ReqLrmAdm],
  [RouteEnum.COMPANIES]: [...ReqLrmAdm],
  [RouteEnum.COMPANIES_ID]: [...ReqLrmAdm],
  [RouteEnum.COMPANY_EMPLOYEE]: [...ReqLrmAdm],
  [RouteEnum.MY_PROFILE]: [...ReqLrmAdm, RoleTypeDto.PreSales],
  [RouteEnum.USER_PROFILE]: [...ReqLrmAdm, RoleTypeDto.PreSales, RoleTypeDto.Employee],
  [RouteEnum.PROJECTS]: [...ReqLrmAdm, RoleTypeDto.PreSales],
  [RouteEnum.PROJECT_DETAILS]: [...ReqLrmAdm, RoleTypeDto.PreSales],
  [RouteEnum.RECRUITMENT_BOARD]: [
    ...ReqLrmAdm,
    RoleTypeDto.PreSales,
    RoleTypeDto.ExternalPartner,
    RoleTypeDto.GroupExternalPartner,
  ],
  [RouteEnum.DICTIONARIES]: [RoleTypeDto.Admin],
  [RouteEnum.ADD_VACANCY]: [...ReqLrmAdm, RoleTypeDto.PreSales],
  [RouteEnum.EDIT_VACANCY]: [...ReqLrmAdm, RoleTypeDto.PreSales, RoleTypeDto.GroupExternalPartner],
  [RouteEnum.VACANCY_ID]: [
    ...ReqLrmAdm,
    RoleTypeDto.PreSales,
    RoleTypeDto.ExternalPartner,
    RoleTypeDto.GroupExternalPartner,
  ],
  [RouteEnum.VACANCIES_CANDIDATE]: [
    ...ReqLrmAdm,
    RoleTypeDto.ExternalPartner,
    RoleTypeDto.GroupExternalPartner,
  ],
  [RouteEnum.REPORTS]: [RoleTypeDto.Admin],
  [RouteEnum.PROFILE_SEARCH]: [...ReqLrmAdm, RoleTypeDto.Employee],

  [RouteEnum.FORBIDDEN]: [],
  [RouteEnum.LOGIN_SUCCESSFUL]: [],
  [RouteEnum.NOT_AUTHORIZED]: [],
  [RouteEnum.NOT_FOUND]: [],
  [RouteEnum.ROOT]: [],
  [RouteEnum.USERS_INITIALIZE]: [],
  [RouteEnum.WAITING_FOR_APPROVAL]: [],
  [RouteEnum.USERS_SIGN_PRIVACY_POLICY]: [],
  [RouteEnum.LOGIN]: [],
};
