import { useVacancyApiClient } from '@/api/util/useVacancyApiClient.util.ts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { VacancyCandidateUserProfileDto } from '@/api/generated';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';

type Request = {
  vacancyId: string;
  userProfile: VacancyCandidateUserProfileDto;
};

type Return = {
  assignProfileToVacancy: (req: Request) => Promise<void>;
  isAssigningProfileToVacancy: boolean;
};

export const useAssignProfileToVacancy = (): Return => {
  const { vacancyCandidateUserProfileApi } = useVacancyApiClient();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation<void, AxiosError, Request>({
    mutationFn: async req => {
      const res = await vacancyCandidateUserProfileApi.addVacancyCandidate(
        req.vacancyId,
        req.userProfile,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries([QueryKeyEnum.VACANCY_DETAILS_GET]);
    },
  });
  return {
    assignProfileToVacancy: mutateAsync,
    isAssigningProfileToVacancy: isLoading,
  };
};
