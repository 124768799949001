import { TopBarTranslation } from '@/assets/locales/layouts/topBar/topBar.translation.en.ts';

export const topBarTranslation: TopBarTranslation = {
  language: {
    english: 'Anglais',
    french: 'Français',
    german: 'Allemand',
  },
  tooltip: {
    profile: 'Modifier le profil',
    userGuide: "Télécharger le guide de l'utilisateur",
    logout: 'Déconnexion',
  },
};
