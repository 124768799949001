/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const VacancyClosureReasonDto = {
    CandidatesSigned: 'CANDIDATES_SIGNED',
    CandidateNotFound: 'CANDIDATE_NOT_FOUND',
    PriceTooHigh: 'PRICE_TOO_HIGH',
    ReasonUnknown: 'REASON_UNKNOWN'
} as const;

export type VacancyClosureReasonDto = typeof VacancyClosureReasonDto[keyof typeof VacancyClosureReasonDto];



