import { LanguageProficiencyDto } from '@/api/generated-user-profile';
import { UserProfileLanguagesSectionTranslation } from '@/assets/locales/views/userProfile/sections/languagesSection/userProfile.languagesSection.translation.en.ts';

const proficiencyLevels = {
  [LanguageProficiencyDto.Basic]: 'Grundlagen',
  [LanguageProficiencyDto.Intermediate]: 'Mittelstufe',
  [LanguageProficiencyDto.Advanced]: 'Fortschrittlich',
  [LanguageProficiencyDto.Professional]: 'Professionell',
  [LanguageProficiencyDto.Bilingual]: 'Zweisprachig',
};

export const userProfileLanguagesSectionTranslation: UserProfileLanguagesSectionTranslation = {
  headerTitle: 'Sprachen',
  content: {
    emptyItemsLabel: 'Sprachen',
    tableHeader: {
      language: 'Sprache',
      level: 'Kompetenz',
      actions: 'Handlungen',
    },
    proficiencyLevels,
    button: {
      edit: 'Bearbeiten',
    },
  },
  form: {
    actions: 'Handlungen',
    language: 'Sprache',
    proficiencyLevel: 'Kompetenz',
    proficiencyLevels,
    button: {
      save: 'Speichern',
    },
  },
  modal: {
    title: 'Sprache hinzufügen',
  },
};
