import { SxProps } from '@mui/material';
import { colors } from '@/utils/colors.ts';

export const GeneralErrorTitleStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  '& svg': {
    fontSize: '28px',
    alignSelf: 'center',
    mx: 1,
    '& path': {
      fill: colors.red,
    },
  },
};
