import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { emptyFunction } from '@/utils/emptyFunction.util';
import { NotificationDto, NotificationTypeDto } from '@/api/generated';

export type NotificationsContextProps = {
  menuBadges: Record<NotificationTypeDto, NotificationDto[]>;
  setMenuBadges: Dispatch<SetStateAction<Record<NotificationTypeDto, NotificationDto[]>>>;
  updateNotifications: (notificationsTypes?: NotificationTypeDto[]) => void;
};

export const NotificationsDefaultValues: NotificationsContextProps = {
  menuBadges: {
    ACCOUNTS_AWAITING_APPROVAL: []
  },
  setMenuBadges: emptyFunction,
  updateNotifications: emptyFunction
};
export const NotificationsContext = createContext<NotificationsContextProps>(
  NotificationsDefaultValues
);

export const useNotificationsContext = (): NotificationsContextProps => {
  return useContext<NotificationsContextProps>(NotificationsContext);
};
