import { useAiApiClient } from '@/api/util/useAiApiClient.util.ts';
import { useMutation } from '@tanstack/react-query';
import {
  AIUserProfileSearchQueryResultDto,
  AIUserProfileSearchRequestDto,
  SearchForceModeDto,
} from '@/api/generated';
import { AxiosError } from 'axios';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

type Request = {
  chatId: string;
  request: AIUserProfileSearchRequestDto;
  searchMode: SearchForceModeDto;
};

type Return = {
  aiSearchInit: (req: Request) => Promise<AIUserProfileSearchQueryResultDto>;
  isSearching: boolean;
};

export const useAiUserProfileSearchInit = (): Return => {
  const { aiSearchUserProfile } = useAiApiClient();

  const { mutateAsync, isLoading } = useMutation<
    AIUserProfileSearchQueryResultDto,
    AxiosError,
    Request
  >({
    meta: {
      disableSuccessAlert: true,
    } as QueryClientMetaModel,
    mutationFn: async req => {
      const res = await aiSearchUserProfile.searchUserProfile(
        req.chatId,
        req.request,
        req.searchMode,
      );
      return res.data;
    },
  });

  return {
    aiSearchInit: mutateAsync,
    isSearching: isLoading,
  };
};
