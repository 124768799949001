export type FallbackUiTranslation = {
  title: string;
  message: string;
  instruction: string;
};

export const fallbackUiTranslation: FallbackUiTranslation = {
  title: 'Ooops! Something went wrong.',
  message: 'We were unable to retrieve the data',
  instruction:
    'Try the operation again. If the error persists, please email us: {{supportEmail}} with details.',
};
