import React from 'react';
import Grid from '@mui/material/Grid';
import { ProfileSearchResultItem } from '@/views/profileSearch/components/results/ProfileSearchResultItem.comp.tsx';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { Box } from '@mui/material';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { ProfileSearchResultPaper } from '@/views/profileSearch/styles/profileSearch.styles.ts';
import { ProfileSearchResultItemVacancyButtons } from '@/views/profileSearch/components/results/ProfileSearchResultItemVacancyButtons.comp.tsx';
import { ProfileSearchResultsModel } from '@/views/profileSearch/model/ProfileSearchResults.model.ts';
import { UseFormReturn } from 'react-hook-form';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import {
  PROFILE_SEARCH_MODE,
  ProfileSearchModeEnum,
} from '@/views/profileSearch/model/profileSearchMode.model.ts';

type Props = {
  isSearching: boolean;
  resultsReady: boolean;
  formMethods: UseFormReturn<ProfileSearchFormModel>;
};

export const ProfileSearchResults: React.FC<Props> = props => {
  const { isSearching, resultsReady, formMethods } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.content');

  if (!resultsReady) {
    return null;
  }

  if (isSearching) {
    return (
      <>
        <ViewWrapper sx={{ pt: 0 }}>
          <Box my={1} style={{ textAlign: 'center' }}>
            {t('searching')}
          </Box>
        </ViewWrapper>
      </>
    );
  }

  if (
    PROFILE_SEARCH_MODE.value === ProfileSearchModeEnum.FULL_TEXT &&
    !formMethods.watch('section')
  ) {
    return null;
  }

  if (ProfileSearchResultsModel.value.profiles.length === 0) {
    return (
      <>
        <ViewWrapper sx={{ pt: 0 }}>
          <Box my={1} style={{ textAlign: 'center' }}>
            {t('noResults')}
          </Box>
        </ViewWrapper>
      </>
    );
  }

  return (
    <>
      <Container maxWidth="xl" fixed>
        <Box pb={2}>{t('searchResults')}</Box>
        <Grid container spacing={4}>
          {ProfileSearchResultsModel.value.profiles.map(profile => (
            <Grid
              item
              xs={3}
              key={profile.firstName + ' ' + profile.lastName}
              sx={{ height: '200%' }}
            >
              <Paper sx={ProfileSearchResultPaper}>
                <Grid
                  container
                  direction="column"
                  spacing={1}
                  sx={{ height: '100%' }}
                  justifyContent="space-between"
                >
                  <ProfileSearchResultItem item={profile} />
                  <ProfileSearchResultItemVacancyButtons item={profile} />
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
