import { VacancyCandidateModalTranslation } from '@/assets/locales/components/vacancyCandidateModal/vacancyCandidateModal.translation.en.ts';

export const vacancyCandidateModalTranslation: VacancyCandidateModalTranslation = {
  title: {
    add: 'Kandidat hinzufügen',
    edit: 'Kandidat bearbeiten',
  },
  form: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    languages: 'Sprachen',
    location: 'Ort',
    expectedDailyRate: 'Erwarteter Tagessatz von',
    currencyId: 'Währung',
    startDayAvailability: 'Verfügbarkeit am Starttag',
    externalResumeFileUrl: 'Lebenslauf-Url',
    resume: 'Lebenslauf-Datei',
  },
};
