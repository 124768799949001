import { AiSearchParametersModel } from '@/views/aiSearch/model/AiSearchParameters.model.ts';

const WeightParamRatio = 10;

const sumOfWeights = (weights: AiSearchParametersModel): number => {
  return Object.keys(weights).reduce((acc, key) => {
    const parsedKey = key as keyof AiSearchParametersModel;
    return acc + weights[parsedKey] / WeightParamRatio;
  }, 0);
};

export const divideWeights = (weights: AiSearchParametersModel): AiSearchParametersModel => {
  const newWeights = structuredClone(weights);
  const sum = sumOfWeights(weights);
  Object.keys(newWeights).forEach(key => {
    const parsedKey = key as keyof AiSearchParametersModel;
    newWeights[parsedKey] = newWeights[parsedKey] / (WeightParamRatio * sum);
  });
  return newWeights;
};

export const multiplyWeights = (weights: AiSearchParametersModel): AiSearchParametersModel => {
  const newWeights = structuredClone(weights);
  Object.keys(newWeights).forEach(key => {
    const parsedKey = key as keyof AiSearchParametersModel;
    newWeights[parsedKey] = newWeights[parsedKey] * WeightParamRatio;
  });
  return newWeights;
};
