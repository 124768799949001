import { createContext, useContext } from 'react';
import { Signal } from '@preact/signals-react';
import { DictionaryEnumDto, ExtendedDictionaryEntryDto } from '@/api/generated';
import { signal } from '@preact/signals';

export interface DictionariesContextProps {
  certificates: Required<ExtendedDictionaryEntryDto>[];
  currencies: Required<ExtendedDictionaryEntryDto>[];
  languages: Required<ExtendedDictionaryEntryDto>[];
  locations: Required<ExtendedDictionaryEntryDto>[];
  sectors: Required<ExtendedDictionaryEntryDto>[];
  skills: Required<ExtendedDictionaryEntryDto>[];
  dictionaryEnums: Signal<DictionaryEnumDto[]>;
}

export const DefaultValues: DictionariesContextProps = {
  certificates: [],
  currencies: [],
  dictionaryEnums: signal([]),
  languages: [],
  locations: [],
  sectors: [],
  skills: [],
};
export const DictionariesContext = createContext<DictionariesContextProps>(DefaultValues);

export const useDictionariesContext = (): DictionariesContextProps => {
  return useContext<DictionariesContextProps>(DictionariesContext);
};
