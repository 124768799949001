import React from 'react';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { GeneralErrorTitleStyles } from '@/views/generalError/generalError.styles.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import Button from '@mui/material/Button';

interface Props {
  message: string;
  subMessage?: string;
}

export const GeneralError: React.FC<Props> = props => {
  const { message, subMessage } = props;
  const { t } = useTranslationWithPrefix('generalError');

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <ViewWrapper>
        <Typography variant="f24Medium" sx={GeneralErrorTitleStyles}>
          <ErrorIcon />
          {t('title')}
          <ErrorIcon />
        </Typography>
        <Divider />
        <Typography variant="f16Regular">
          <Box sx={{ pt: 8, textAlign: 'center' }}>{message}</Box>
          {subMessage && <Box sx={{ pt: 4, textAlign: 'center' }}>{subMessage}</Box>}
        </Typography>
        <Grid container justifyContent="center" spacing={4} sx={{ pt: 8 }}>
          <Grid item>
            <Button onClick={handleGoBack} variant="outlined">
              {t('goBack')}
            </Button>
          </Grid>
          <Grid item>
            <Button href="/">{t('logIn')}</Button>
          </Grid>
        </Grid>
      </ViewWrapper>
    </>
  );
};
