import { UserDetailsTranslation } from '@/assets/locales/userDetails/userDetails.translation.en.ts';

export const userDetailsTranslation: UserDetailsTranslation = {
  modal: {
    title: {
      edit: 'Datei bearbeiten',
    },
    role: 'Rolle',
    location: 'Ort',
    operatingLocations: 'Einsatzorte',
    status: 'Status',
    email: 'Email',
    phoneNumber: 'Telefonnummer',
    buttons: {
      selectAll: 'Alles auswählen',
      deselectAll: 'Alles abwählen',
    },
  },
  content: {
    companyName: 'Unternehmensname',
    email: 'Haupt email',
    registeredAt: 'Registriert bei',
    role: 'Rolle',
    status: 'Status',
    lastLogin: 'Letzte Anmeldung',
    location: 'Ort',
    operatingLocations: 'Einsatzorte',
    phoneNumber: 'Haupttelefonnummer',
    button: {
      edit: 'Datei bearbeiten',
    },
  },
};
