import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { UserProfileSectionDto } from '@/api/generated-user-profile';
import {
  skillsDescriptions,
  SkillsDescriptions,
} from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.en';

export type ProfileSearchTranslation = {
  content: {
    activeCertificateFilters: string;
    activeLanguageFilters: string;
    activeSkillsFilters: string;
    noResults: string;
    searchResults: string;
    searching: string;
    vacancyTitle: string;
    results: {
      assignedToVacancy: string;
      assignToVacancy: string;
      menu: {
        open: string;
        openInNewTab: string;
      };
    };
    button: {
      addAnother: string;
    };
  };
  form: {
    addType: string;
    addTypeOption: Record<ProfileSearchAddItemType, string>;
    availableOnly: string;
    firstName: string;
    fullTextSearch: string;
    lastName: string;
    addSkillFilter: {
      skill: string;
      level: string;
      experienceInYears: string;
      skillsDescriptions: SkillsDescriptions;
    };
    addCertificateFilter: {
      certificate: string;
    };
    addLanguageFilter: {
      language: string;
    };
    button: {
      search: string;
      clearFilters: string;
    };
  };
  fullText: {
    categories: {
      header: string;
      section: Record<UserProfileSectionDto, string>;
    };
    skillsPropositions: {
      header: string;
    };
  };
};

export const profileSearchTranslation: ProfileSearchTranslation = {
  content: {
    vacancyTitle: 'Find candidates for {{vacancy}}',
    activeSkillsFilters: 'Active skills filters',
    activeCertificateFilters: 'Active certificates filters',
    activeLanguageFilters: 'Active languages filters',
    searchResults: 'Search results',
    searching: 'Searching...',
    noResults: 'Search returned no results',
    results: {
      assignedToVacancy: 'Assigned',
      assignToVacancy: 'Assign to vacancy',
      menu: {
        open: 'Open',
        openInNewTab: 'Open in new tab',
      },
    },
    button: {
      addAnother: 'Add another',
    },
  },
  form: {
    firstName: 'First name',
    lastName: 'Last name',
    addType: 'Add filter',
    availableOnly: 'Available only',
    fullTextSearch: 'Search in skills, projects, certificates, languages, etc.',
    addTypeOption: {
      [ProfileSearchAddItemType.skills]: 'Skill',
      [ProfileSearchAddItemType.certificates]: 'Certificate',
      [ProfileSearchAddItemType.languages]: 'Language',
    },
    addSkillFilter: {
      skill: 'Skill',
      level: 'Level',
      experienceInYears: 'Experience in years',
      skillsDescriptions,
    },
    addCertificateFilter: {
      certificate: 'Certificate',
    },
    addLanguageFilter: {
      language: 'Language',
    },
    button: {
      search: 'Search',
      clearFilters: 'Clear filters',
    },
  },
  fullText: {
    categories: {
      header:
        'Here is a list of results for your search phrase across all categories. Please select the category you would like to explore for more specific results.',
      section: {
        [UserProfileSectionDto.Award]: 'Awards',
        [UserProfileSectionDto.Course]: 'Courses',
        [UserProfileSectionDto.Certificate]: 'Certificates',
        [UserProfileSectionDto.Project]: 'Projects',
        [UserProfileSectionDto.Experience]: 'Work experience',
        [UserProfileSectionDto.Language]: 'Languages',
        [UserProfileSectionDto.Skill]: 'Skills',
      },
    },
    skillsPropositions: {
      header:
        'These are existing skills similar to your search phrase. Please select the skill you would like to explore for more specific results.',
    },
  },
};
