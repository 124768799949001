import { lazy } from 'react';
import { Forbidden } from '@/views/generalError/forbidden/Forbidden.comp.tsx';
import NotAuthorized from '@/views/generalError/notFound/NotAuthorized.view';
import { RouteEnum, RouteModel } from './Route.model';
import { NotFound } from '@/views/generalError/notAuthorized/NotFound.comp';
import { LoginSuccessfulView } from '@/views/loginSuccessful/LoginSuccessful.view';
import { WaitingForApprovalView } from '@/views/generalError/waitingForApproval/WaitingForApproval.view';
import { ROUTES_ROLES } from './routesRoles.model';
import ProfileSearchView from '@/views/profileSearch/ProfileSearch.view.tsx';
import { LoginView } from '@/views/login/Login.view.tsx';
import { AiSearchView } from '@/views/aiSearch/AiSearch.view.tsx';

const SignInHandler = lazy(() => import('@/views/signInHandler/SignInHandler'));
const Candidates = lazy(() => import('@/views/candidates/Candidates'));
const CompaniesView = lazy(() => import('@/views/companies/Companies.view'));
const CompanyDetailsView = lazy(() => import('@/views/companyDetails/CompanyDetails.view.tsx'));
const Projects = lazy(() => import('@/views/projects/Projects.view.tsx'));
const ProjectDetails = lazy(() => import('@/views/project/ProjectDetails.comp'));
const DictionariesView = lazy(() => import('@/views/dictionaries/Dictionaries.view'));
const VacancyDetails = lazy(() => import('@/views/vacancyDetails/VacancyDetails.view.tsx'));
const UserInitializeView = lazy(() => import('@/views/userInitialize/UserInitialize.view.tsx'));
const VacancyEdit = lazy(() => import('@/views/vacancyEdit/VacancyEdit.view.tsx'));
const UserProfileView = lazy(() => import('@/views/userProfile/UserProfile.view.tsx'));
const RecruitmentBoardWrapper = lazy(
  () => import('@/views/recruitmentBoard/components/RecruitmentBoard.wrapper'),
);
const CompanyUserDetails = lazy(() => import('@/views/userDetails/UserDetails.view.tsx'));
const VacancyCandidateDetailsWrapper = lazy(
  () => import('@/views/vacancyCandidateDetails/wrapper/VacancyCandidateDetails.wrapper'),
);
const ReportsView = lazy(() => import('@/views/reports/Reports.view'));
const SignPrivacyPolicyView = lazy(() => import('@/views/signPrivacyPolicy/SignPrivacyPolicy.view'));

export const ROUTES: RouteModel[] = [
  {
    path: RouteEnum.LOGIN,
    component: LoginView,
    roles: ROUTES_ROLES[RouteEnum.LOGIN],
  },
  {
    path: RouteEnum.AI_SEARCH,
    component: AiSearchView,
    roles: ROUTES_ROLES[RouteEnum.AI_SEARCH],
  },
  {
    path: RouteEnum.PROFILE_SEARCH,
    component: ProfileSearchView,
    roles: ROUTES_ROLES[RouteEnum.PROFILE_SEARCH],
  },
  {
    path: RouteEnum.ROOT,
    component: SignInHandler,
    roles: ROUTES_ROLES[RouteEnum.ROOT],
  },
  {
    path: RouteEnum.CANDIDATES,
    component: Candidates,
    roles: ROUTES_ROLES[RouteEnum.CANDIDATES],
  },
  {
    path: RouteEnum.CANDIDATES_ID,
    component: VacancyCandidateDetailsWrapper,
    roles: ROUTES_ROLES[RouteEnum.CANDIDATES_ID],
  },
  {
    path: RouteEnum.COMPANIES,
    component: CompaniesView,
    roles: ROUTES_ROLES[RouteEnum.COMPANIES],
  },
  {
    path: RouteEnum.COMPANIES_ID,
    component: CompanyDetailsView,
    roles: ROUTES_ROLES[RouteEnum.COMPANIES_ID],
  },
  {
    path: RouteEnum.COMPANY_EMPLOYEE,
    component: CompanyUserDetails,
    roles: ROUTES_ROLES[RouteEnum.COMPANY_EMPLOYEE],
  },
  {
    path: RouteEnum.MY_PROFILE,
    component: CompanyUserDetails,
    roles: ROUTES_ROLES[RouteEnum.MY_PROFILE],
  },
  {
    path: RouteEnum.FORBIDDEN,
    component: Forbidden,
    roles: ROUTES_ROLES[RouteEnum.FORBIDDEN],
  },
  {
    path: RouteEnum.NOT_FOUND,
    component: NotFound,
    roles: ROUTES_ROLES[RouteEnum.NOT_FOUND],
  },
  {
    path: RouteEnum.NOT_AUTHORIZED,
    component: NotAuthorized,
    roles: ROUTES_ROLES[RouteEnum.NOT_AUTHORIZED],
  },
  {
    path: RouteEnum.WAITING_FOR_APPROVAL,
    component: WaitingForApprovalView,
    roles: ROUTES_ROLES[RouteEnum.WAITING_FOR_APPROVAL],
  },
  {
    path: RouteEnum.LOGIN_SUCCESSFUL,
    component: LoginSuccessfulView,
    roles: ROUTES_ROLES[RouteEnum.LOGIN_SUCCESSFUL],
  },
  {
    path: RouteEnum.PROJECTS,
    component: Projects,
    roles: ROUTES_ROLES[RouteEnum.PROJECTS],
  },
  {
    path: RouteEnum.PROJECT_DETAILS,
    component: ProjectDetails,
    roles: ROUTES_ROLES[RouteEnum.PROJECT_DETAILS],
  },
  {
    path: RouteEnum.RECRUITMENT_BOARD,
    component: RecruitmentBoardWrapper,
    roles: ROUTES_ROLES[RouteEnum.RECRUITMENT_BOARD],
  },
  {
    path: RouteEnum.USER_PROFILE,
    component: UserProfileView,
    roles: ROUTES_ROLES[RouteEnum.USER_PROFILE],
  },
  {
    path: RouteEnum.DICTIONARIES,
    component: DictionariesView,
    roles: ROUTES_ROLES[RouteEnum.DICTIONARIES],
  },
  {
    path: RouteEnum.ADD_VACANCY,
    component: VacancyEdit,
    roles: ROUTES_ROLES[RouteEnum.ADD_VACANCY],
  },
  {
    path: RouteEnum.EDIT_VACANCY,
    component: VacancyEdit,
    roles: ROUTES_ROLES[RouteEnum.EDIT_VACANCY],
  },
  {
    path: RouteEnum.VACANCY_ID,
    component: VacancyDetails,
    roles: ROUTES_ROLES[RouteEnum.VACANCY_ID],
  },
  {
    path: RouteEnum.VACANCIES_CANDIDATE,
    component: VacancyCandidateDetailsWrapper,
    roles: ROUTES_ROLES[RouteEnum.VACANCIES_CANDIDATE],
  },
  {
    path: RouteEnum.USERS_INITIALIZE,
    component: UserInitializeView,
    roles: ROUTES_ROLES[RouteEnum.USERS_INITIALIZE],
  },
  {
    path: RouteEnum.REPORTS,
    component: ReportsView,
    roles: ROUTES_ROLES[RouteEnum.REPORTS],
  },
  {
    path: RouteEnum.USERS_SIGN_PRIVACY_POLICY,
    component: SignPrivacyPolicyView,
    roles: ROUTES_ROLES[RouteEnum.USERS_SIGN_PRIVACY_POLICY],
  },
];
