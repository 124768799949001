/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const RoleTypeDto = {
    Admin: 'ADMIN',
    Employee: 'EMPLOYEE',
    ExternalPartner: 'EXTERNAL_PARTNER',
    GroupExternalPartner: 'GROUP_EXTERNAL_PARTNER',
    LocalResourceManager: 'LOCAL_RESOURCE_MANAGER',
    PreSales: 'PRE_SALES',
    Requester: 'REQUESTER'
} as const;

export type RoleTypeDto = typeof RoleTypeDto[keyof typeof RoleTypeDto];



