import ENDPOINTS from '../config/endpoints';
import { LanguageEnum } from './models/Language.enum';
import { UserDto } from './models/user/User.dto';
import { httpClient } from './avatar-api';
import { RoleTypeDto } from './generated';

export const fetchCurrentUser = (lang: LanguageEnum): Promise<UserDto> => {
  return new Promise((resolve, reject) => {
    httpClient.get({
      headers: { 'Accept-Language': lang },
      endpoint: `${ENDPOINTS.CURRENT_USER}`,
      onSuccess: (data: UserDto) => resolve(data),
      onError: (error: UserDto) => reject(error)
    });
  });
};

export const fetchAllUsersShort = (lang: LanguageEnum) => {
  return new Promise((resolve, reject) => {
    httpClient.get({
      headers: { 'Accept-Language': lang },
      endpoint: ENDPOINTS.USERS_SHORT,
      onSuccess: (data: unknown) => resolve(data),
      onError: (error: unknown) => reject(error)
    });
  });
};

export const fetchUsersByRole = (roles: RoleTypeDto[]) => {
  return new Promise((resolve, reject) => {
    const query = roles.join(',');
    httpClient.get({
      endpoint: `${ENDPOINTS.LISTS}/users/roles?roles=${query}`,
      onSuccess: (data: unknown) => resolve(data),
      onError: (error: unknown) => reject(error)
    });
  });
};
