/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileExperience1Dto } from '../models';
/**
 * UserProfileExperienceApi - axios parameter creator
 * @export
 */
export const UserProfileExperienceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileExperience: async (userProfileId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileExperience', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileExperience', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/experiences`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileExperience1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileExperience: async (userProfileId: string, userProfileExperienceId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileExperience', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileExperienceId' is not null or undefined
            assertParamExists('deleteUserProfileExperience', 'userProfileExperienceId', userProfileExperienceId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileExperience', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/experiences/{userProfileExperienceId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileExperienceId"}}`, encodeURIComponent(String(userProfileExperienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileExperience: async (userProfileId: string, userProfileExperienceId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileExperience', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileExperienceId' is not null or undefined
            assertParamExists('updateUserProfileExperience', 'userProfileExperienceId', userProfileExperienceId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileExperience', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/experiences/{userProfileExperienceId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileExperienceId"}}`, encodeURIComponent(String(userProfileExperienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileExperience1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileExperienceApi - functional programming interface
 * @export
 */
export const UserProfileExperienceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileExperienceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileExperience(userProfileId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileExperience(userProfileId, lang, userProfileExperience1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileExperienceApi.createUserProfileExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileExperience(userProfileId, userProfileExperienceId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileExperienceApi.deleteUserProfileExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileExperience(userProfileId, userProfileExperienceId, lang, userProfileExperience1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileExperienceApi.updateUserProfileExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileExperienceApi - factory interface
 * @export
 */
export const UserProfileExperienceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileExperienceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileExperience(userProfileId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileExperience(userProfileId, lang, userProfileExperience1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileExperience(userProfileId, userProfileExperienceId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileExperienceId 
         * @param {LangDto} lang 
         * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileExperience(userProfileId, userProfileExperienceId, lang, userProfileExperience1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileExperienceApi - interface
 * @export
 * @interface UserProfileExperienceApi
 */
export interface UserProfileExperienceApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApiInterface
     */
    createUserProfileExperience(userProfileId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileExperienceId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApiInterface
     */
    deleteUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileExperienceId 
     * @param {LangDto} lang 
     * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApiInterface
     */
    updateUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileExperienceApi - object-oriented interface
 * @export
 * @class UserProfileExperienceApi
 * @extends {BaseAPI}
 */
export class UserProfileExperienceApi extends BaseAPI implements UserProfileExperienceApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApi
     */
    public createUserProfileExperience(userProfileId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileExperienceApiFp(this.configuration).createUserProfileExperience(userProfileId, lang, userProfileExperience1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileExperienceId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApi
     */
    public deleteUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileExperienceApiFp(this.configuration).deleteUserProfileExperience(userProfileId, userProfileExperienceId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileExperienceId 
     * @param {LangDto} lang 
     * @param {UserProfileExperience1Dto} [userProfileExperience1Dto] User Profile Experience update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileExperienceApi
     */
    public updateUserProfileExperience(userProfileId: string, userProfileExperienceId: string, lang: LangDto, userProfileExperience1Dto?: UserProfileExperience1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileExperienceApiFp(this.configuration).updateUserProfileExperience(userProfileId, userProfileExperienceId, lang, userProfileExperience1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

