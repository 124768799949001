import React from 'react';
import { AIUserProfileSearchResultItemDto } from '@/api/generated';
import { AiSearchParamItem } from '@/views/aiSearch/components/AiSearchParamItem.comp.tsx';
import { AiSearchParamItemOverall } from '@/views/aiSearch/components/AiSearchParamItemOverall.comp.tsx';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  AiSearchSearchResultNameStyles,
  AiSearchSearchResultPaperStyles,
} from '@/views/aiSearch/aiSearch.styles.ts';

type Props = {
  result: AIUserProfileSearchResultItemDto;
  handleResultClick: (id: string) => () => void;
};

export const AiSearchResultItem: React.FC<Props> = props => {
  const { result, handleResultClick } = props;

  return (
    <Grid item xs={4} style={{ position: 'relative' }}>
      <Paper sx={AiSearchSearchResultPaperStyles}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" py={1}>
          <Box mb={1} sx={AiSearchSearchResultNameStyles}>
            {result.profile.firstName} {result.profile.lastName}
          </Box>
          <AiSearchParamItem label="Position" value={result.positionResult?.match ?? 0} />
          <AiSearchParamItem label="Skill" value={result.skillsResult?.match ?? 0} />
          <AiSearchParamItem label="Certificates" value={result.certificatesResult?.match ?? 0} />
          <AiSearchParamItem label="Work experience" value={result.experiencesResult?.match ?? 0} />
          <AiSearchParamItem label="Languages" value={result.languagesResult?.match ?? 0} />
        </Box>
      </Paper>
      <AiSearchParamItemOverall
        label="Overall"
        value={result.calculatedMatch ?? 0}
        onDetailsClick={handleResultClick(result.profile.userProfileId)}
      />
    </Grid>
  );
};
