import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { isAuthorized } from '../authorization';
import { RoleTypeDto } from '@/api/generated';
import { useLogout } from '@/api/useLogout.util';
import { RouteEnum } from '@/layouts/routes/Route.model';
import { TopBarItemsStyles, TopBarStyles } from '@/layouts/private/topBar/TopBar.styles.ts';
import Grid from '@mui/material/Grid';
import LogOutIcon from '@/assets/icons/topBar/LogOutIcon.png';
import AccountIcon from '@/assets/icons/topBar/AccountIcon.png';
import GuideIcon from '@/assets/icons/topBar/GuideIcon.png';
import { usePageContext } from '@/contexts/pageContext/Page.context.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util';
import { TopBarLanguageChange } from '@/layouts/private/topBar/components/TopBarLanguageChange/TopBarLanguageChange.comp.tsx';
import Tooltip from '@mui/material/Tooltip';

export const TopBar: React.FC = () => {
  const { logout } = useLogout();
  const { value } = useUserContext();
  const { role } = value;
  const { pageDetails } = usePageContext();
  const { t } = useTranslationWithPrefix('pageTitles');
  const { t: tTopBar } = useTranslationWithPrefix('layouts.topBar.tooltip');
  const history = useOcdHistory();
  const canSeeItsProfile = !isAuthorized(
    [RoleTypeDto.ExternalPartner, RoleTypeDto.GroupExternalPartner],
    role,
  );

  const handleLogout = async () => {
    await logout();
  };

  const handleOpenGuide = () => {
    const win = window.open('/KnowMe_OCD-Manual.pdf', '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const handleOpenProfilePage = () => {
    history.push(RouteEnum.MY_PROFILE);
  };

  return (
    <Grid container sx={TopBarStyles}>
      <Grid item xs={6}>
        <Typography variant="f24Medium">{t(pageDetails.title)}</Typography>
      </Grid>
      <Grid item>
        <Grid container sx={TopBarItemsStyles}>
          {canSeeItsProfile && (
            <Grid item>
              <Tooltip title={tTopBar('profile')}>
                <IconButton size="large" sx={{ px: '10px' }} onClick={handleOpenProfilePage}>
                  <Avatar src={AccountIcon} sx={{ width: '100%', height: '100%' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Tooltip title={tTopBar('userGuide')}>
              <IconButton size="large" onClick={handleOpenGuide}>
                <img src={GuideIcon} alt="Log out" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <TopBarLanguageChange />
          </Grid>
          <Grid item>
            <Tooltip title={tTopBar('logout')}>
              <IconButton size="large" onClick={handleLogout}>
                <img src={LogOutIcon} alt="Log out" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
