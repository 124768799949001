import { makeStyles } from '@mui/styles';

export const fallbackUiStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    overflow: 'hidden',
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(0.75),
    boxShadow: theme.variables.boxShadowMenu,
  },
  message: {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.weightRegular,
  },
  title: {
    fontSize: 24,
    fontWeight: theme.typography.weightBold,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      fontSize: theme.spacing(4.5),
      alignSelf: 'center',
      marginRight: theme.spacing(1.5),
      '& path': {
        fill: theme.colors.redPink,
      },
    },
  },
  separator: {
    width: '100%',
    height: 2,
    opacity: 0.7,
    backgroundColor: theme.colors.silver,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  instruction: {
    display: 'flex',
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.weightRegular,
    fontSize: theme.spacing(1.75),
    alignItems: 'center',
    '& svg': {
      fontSize: theme.spacing(6),
      marginLeft: -10,
      marginRight: theme.spacing(0.5),
      marginTop: 2,
      '& path': {
        stroke: 'white',
        fill: theme.colors.darkSkyBlue,
      },
    },
  },
  link: {
    cursor: 'pointer',
    color: theme.colors.oceanBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '& a': {
      color: theme.colors.oceanBlue,
      textDecoration: 'none',
    },
  },
}));
