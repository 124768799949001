import { interviewNoteRequest } from '@/assets/locales/vacancyCandidate/interviewNoteRequest/interviewNoteRequest.de.ts';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';
import { VacancyCandidateTranslation } from '@/assets/locales/vacancyCandidate/vacancyCandidate.translation.en.ts';

export const vacancyCandidateTranslation: VacancyCandidateTranslation = {
  status: {
    [VacancyCandidateStatusEnumDto.New]: 'Neu',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Vorstellungsgespräch gewünscht',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewed',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Kandidat abgelehnt',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Kandidat ausgewählt',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Angebot unterzeichnet',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Angebotgesendet',
  },
  transition: {
    [VacancyCandidateStatusEnumDto.New]: 'Neu',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Antrag auf Vorstellungsgepräch',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewt',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Kandidat abgelehnt',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Kandidat auswählen',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Angebot unterzeichnet',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Angebot gesendet',
  },
  interviewNoteRequest,
  downloadCV: 'Lebenslauf herunterladen',
  approve: 'Akzeptieren',
  reject: 'Ablehnen',
  existsInDatabase: 'Existiert in der Datenbank',
  notExistInDatabase: 'Existiert nicht in der Datenbank',
  lastContactDate: 'Letzter Kontakttermin',
  headline: 'Wählen Sie das Datum der letzten Kontaktaufnahme',
  instructions:
    'Wählen Sie das Datum des letzten Kontakts zwischen Ihrem Unternehmen und dem Bewerber',
  addComment: 'Kommentar zum Kandidaten hinzufügen',
  comment: 'Kommentar',
  buttonSave: 'Speichern',
  interview: {
    title: 'Vorstellungsgespräch',
    summary: 'Zusammenfassung',
    date: 'Datum',
    technicalInterviewer: 'Technischer Interviewer',
    maxDateMessage: 'Datum sollte nicht in der Zukunft liegen',
    minDateMessage: 'Datum darf nicht in der Vergangenheit liegen',
  },
  addInterview: 'Vorstellungsgespräch hinzufügen',
  selectVacancy: 'Vakanz auswählen',
  selectVacancyToAssign: 'Vakanz auswählen, die dem Bewerber zugewiesen werden soll.',
  noVacancy: 'Keine Vakanz',
  vacancy: 'Vakanz',
  changeVacancy: 'Vakanz ändern',
  addCommentBtn: 'Kommentar hinzufügen',
  editCandidate: 'Daten des Kandidaten bearbeiten',
  timeline: {
    readMore: 'Mehr lesen',
    readLess: 'Weniger lesen',
    statusChange: 'Statusänderung:',
    meetingSummary: 'Feedback nach der Besprechung:',
    meetingStatus: 'Interviewstatus:',
    activeStateChange: 'Statusänderung des Bewerbers:',
  },
  closeCandidate: 'Kandidat schließen',
  statusChange: {
    closed: 'Kandidat schließen',
    rejected: 'Ablehnen des Bewerbers',
    closedReason: 'Grund für das Schließen',
    closedDescription: 'Beschreibung',
    rejectedReason: 'Grund der Ablehnung',
    rejectedDescription: 'Beschreibung',
    closedInstruction: 'Wählen Sie den Grund für das Schließen des Kandidaten',
    rejectedInstruction: 'Wählen Sie den Grund für die Ablehnung des Bewerbers',
    closedDetails: 'Schließen Details',
    rejectedDetails: 'Details zur Ablehnung',
  },
  notesList: {
    header: 'Notizen zu den Interviews',
    expand: 'Erweitern',
    collapse: 'Kollabieren',
    table: {
      date: 'Datum der Besprechung',
      author: 'Autor der Notiz',
      vacancy: 'Vakanz',
    },
  },
  buttons: {
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',
  },
  candidateInactiveBanner: 'Kandidat ist inaktiv',
  goToVacancy: 'Zur Anfrage gehen',
  content: {
    availability: 'Verfügbarkeit',
    currency: 'Währung',
    cvSource: 'Lebenslauf-Quelle',
    cvUploadedBy: 'Lebenslauf hochgeladen von',
    expectedDailyRate: 'Erwarteter Tagessatz',
    firstName: 'Vorname',
    languages: 'Sprachen',
    lastName: 'Nachname',
    location: 'Ort',
    position: 'Stelle',
    resume: 'Lebenslauf',
    title: 'Angaben zum Bewerber',
    button: {
      addComment: 'Kommentar hinzufügen',
      editCandidate: 'Kandidat bearbeiten',
      goToUserProfile: 'Zum Profil des Bewerbers gehen',
    },
  },
};
