import React from 'react';
import Grid from '@mui/material/Grid';
import { SliderFormField } from '@/components/form/SliderFormField.comp.tsx';
import { userProfileSkillsMarksModel } from '@/views/userProfile/components/sections/skills/model/UserProfileSkillsMarks.model.ts';
import Typography from '@mui/material/Typography';
import { colors } from '@/utils/colors.ts';
import { TFunction } from 'i18next';

interface Props {
  t: TFunction;
  selectedSkillLevel?: number;
  disabled?: boolean;
  hideDescriptions?: boolean;
}

export const UserProfileSkillSlider: React.FC<Props> = props => {
  const { t, selectedSkillLevel, disabled, hideDescriptions } = props;

  const skillDescriptions = structuredClone(
    t(`skillsDescriptions.${[selectedSkillLevel]}.descriptions`, {
      returnObjects: true,
    }) as string[],
  );

  return (
    <>
      <Grid item xs={12}>
        <SliderFormField
          name="level"
          label={t('level')}
          marks={userProfileSkillsMarksModel}
          min={0}
          max={4}
          disabled={disabled}
        />
      </Grid>
      {hideDescriptions !== true && selectedSkillLevel !== undefined && (
        <Grid item xs={12}>
          <Typography variant="f12Medium" sx={{ color: colors.black }}>
            {t(`skillsDescriptions.${[selectedSkillLevel]}.title`)}
          </Typography>
          <Typography variant="f12Regular" sx={{ color: colors.darkGrey }}>
            <ul style={{ paddingLeft: 10 }}>
              {skillDescriptions.map(d => (
                <li key={d.substring(0, 15)}>{d}</li>
              ))}
            </ul>
          </Typography>
        </Grid>
      )}
    </>
  );
};
