/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CvGenerationRequestDataDto } from '../models';
// @ts-ignore
import type { OcdCvSectionDto } from '../models';
// @ts-ignore
import type { ResumeFileFormatDto } from '../models';
// @ts-ignore
import type { ResumeTemplateTypeDto } from '../models';
/**
 * UserProfileCvApi - axios parameter creator
 * @export
 */
export const UserProfileCvApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {boolean} anonymousEmployee 
         * @param {boolean} anonymousCustomer 
         * @param {Array<OcdCvSectionDto>} sectionNames 
         * @param {Array<string>} experienceIds 
         * @param {Array<string>} projectIds 
         * @param {ResumeFileFormatDto} fileFormat 
         * @param {ResumeTemplateTypeDto} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOcdResume: async (userProfileId: string, anonymousEmployee: boolean, anonymousCustomer: boolean, sectionNames: Array<OcdCvSectionDto>, experienceIds: Array<string>, projectIds: Array<string>, fileFormat: ResumeFileFormatDto, template: ResumeTemplateTypeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('generateOcdResume', 'userProfileId', userProfileId)
            // verify required parameter 'anonymousEmployee' is not null or undefined
            assertParamExists('generateOcdResume', 'anonymousEmployee', anonymousEmployee)
            // verify required parameter 'anonymousCustomer' is not null or undefined
            assertParamExists('generateOcdResume', 'anonymousCustomer', anonymousCustomer)
            // verify required parameter 'sectionNames' is not null or undefined
            assertParamExists('generateOcdResume', 'sectionNames', sectionNames)
            // verify required parameter 'experienceIds' is not null or undefined
            assertParamExists('generateOcdResume', 'experienceIds', experienceIds)
            // verify required parameter 'projectIds' is not null or undefined
            assertParamExists('generateOcdResume', 'projectIds', projectIds)
            // verify required parameter 'fileFormat' is not null or undefined
            assertParamExists('generateOcdResume', 'fileFormat', fileFormat)
            // verify required parameter 'template' is not null or undefined
            assertParamExists('generateOcdResume', 'template', template)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/generate-cv`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (anonymousEmployee !== undefined) {
                localVarQueryParameter['anonymousEmployee'] = anonymousEmployee;
            }

            if (anonymousCustomer !== undefined) {
                localVarQueryParameter['anonymousCustomer'] = anonymousCustomer;
            }

            if (sectionNames) {
                localVarQueryParameter['sectionNames'] = sectionNames.join(COLLECTION_FORMATS.csv);
            }

            if (experienceIds) {
                localVarQueryParameter['experienceIds'] = experienceIds;
            }

            if (projectIds) {
                localVarQueryParameter['projectIds'] = projectIds;
            }

            if (fileFormat !== undefined) {
                localVarQueryParameter['fileFormat'] = fileFormat;
            }

            if (template !== undefined) {
                localVarQueryParameter['template'] = template;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileCvRequestData: async (userProfileId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('getUserProfileCvRequestData', 'userProfileId', userProfileId)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/cv-request`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileCvApi - functional programming interface
 * @export
 */
export const UserProfileCvApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileCvApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {boolean} anonymousEmployee 
         * @param {boolean} anonymousCustomer 
         * @param {Array<OcdCvSectionDto>} sectionNames 
         * @param {Array<string>} experienceIds 
         * @param {Array<string>} projectIds 
         * @param {ResumeFileFormatDto} fileFormat 
         * @param {ResumeTemplateTypeDto} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateOcdResume(userProfileId: string, anonymousEmployee: boolean, anonymousCustomer: boolean, sectionNames: Array<OcdCvSectionDto>, experienceIds: Array<string>, projectIds: Array<string>, fileFormat: ResumeFileFormatDto, template: ResumeTemplateTypeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateOcdResume(userProfileId, anonymousEmployee, anonymousCustomer, sectionNames, experienceIds, projectIds, fileFormat, template, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileCvApi.generateOcdResume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfileCvRequestData(userProfileId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CvGenerationRequestDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileCvRequestData(userProfileId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileCvApi.getUserProfileCvRequestData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileCvApi - factory interface
 * @export
 */
export const UserProfileCvApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileCvApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {boolean} anonymousEmployee 
         * @param {boolean} anonymousCustomer 
         * @param {Array<OcdCvSectionDto>} sectionNames 
         * @param {Array<string>} experienceIds 
         * @param {Array<string>} projectIds 
         * @param {ResumeFileFormatDto} fileFormat 
         * @param {ResumeTemplateTypeDto} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOcdResume(userProfileId: string, anonymousEmployee: boolean, anonymousCustomer: boolean, sectionNames: Array<OcdCvSectionDto>, experienceIds: Array<string>, projectIds: Array<string>, fileFormat: ResumeFileFormatDto, template: ResumeTemplateTypeDto, options?: any): AxiosPromise<string> {
            return localVarFp.generateOcdResume(userProfileId, anonymousEmployee, anonymousCustomer, sectionNames, experienceIds, projectIds, fileFormat, template, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileCvRequestData(userProfileId: string, options?: any): AxiosPromise<CvGenerationRequestDataDto> {
            return localVarFp.getUserProfileCvRequestData(userProfileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileCvApi - interface
 * @export
 * @interface UserProfileCvApi
 */
export interface UserProfileCvApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {boolean} anonymousEmployee 
     * @param {boolean} anonymousCustomer 
     * @param {Array<OcdCvSectionDto>} sectionNames 
     * @param {Array<string>} experienceIds 
     * @param {Array<string>} projectIds 
     * @param {ResumeFileFormatDto} fileFormat 
     * @param {ResumeTemplateTypeDto} template 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCvApiInterface
     */
    generateOcdResume(userProfileId: string, anonymousEmployee: boolean, anonymousCustomer: boolean, sectionNames: Array<OcdCvSectionDto>, experienceIds: Array<string>, projectIds: Array<string>, fileFormat: ResumeFileFormatDto, template: ResumeTemplateTypeDto, options?: RawAxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCvApiInterface
     */
    getUserProfileCvRequestData(userProfileId: string, options?: RawAxiosRequestConfig): AxiosPromise<CvGenerationRequestDataDto>;

}

/**
 * UserProfileCvApi - object-oriented interface
 * @export
 * @class UserProfileCvApi
 * @extends {BaseAPI}
 */
export class UserProfileCvApi extends BaseAPI implements UserProfileCvApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {boolean} anonymousEmployee 
     * @param {boolean} anonymousCustomer 
     * @param {Array<OcdCvSectionDto>} sectionNames 
     * @param {Array<string>} experienceIds 
     * @param {Array<string>} projectIds 
     * @param {ResumeFileFormatDto} fileFormat 
     * @param {ResumeTemplateTypeDto} template 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCvApi
     */
    public generateOcdResume(userProfileId: string, anonymousEmployee: boolean, anonymousCustomer: boolean, sectionNames: Array<OcdCvSectionDto>, experienceIds: Array<string>, projectIds: Array<string>, fileFormat: ResumeFileFormatDto, template: ResumeTemplateTypeDto, options?: RawAxiosRequestConfig) {
        return UserProfileCvApiFp(this.configuration).generateOcdResume(userProfileId, anonymousEmployee, anonymousCustomer, sectionNames, experienceIds, projectIds, fileFormat, template, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileCvApi
     */
    public getUserProfileCvRequestData(userProfileId: string, options?: RawAxiosRequestConfig) {
        return UserProfileCvApiFp(this.configuration).getUserProfileCvRequestData(userProfileId, options).then((request) => request(this.axios, this.basePath));
    }
}

