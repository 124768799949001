import React, { useEffect } from 'react';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { getLocalStorage, LocalStorageParam } from '@/utils/localStorage.util.ts';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';

export const LoginSuccessfulView: React.FC = () => {
  const { push } = useOcdHistory();

  useEffect(() => {
    const lastLocation = getLocalStorage(LocalStorageParam.LAST_VISITED_LOCATION);
    if (!lastLocation) {
      push(RouteEnum.ROOT);
      return;
    }

    push(lastLocation);
  }, [push]);

  return null;
};
