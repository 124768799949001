import { AxiosError } from 'axios';

export const isAxiosError = (error: unknown): error is AxiosError => {
  if (error === undefined || error === null) {
    return false;
  }

  const partialError = error as Partial<AxiosError>;

  return partialError.response?.status !== undefined;
};

export const isErrorWithMessage = (error: unknown): error is { message: string } => {
  if (error === undefined || error === null) {
    return false;
  }

  const partialError = error as Partial<{ message: string }>;

  return partialError.message !== undefined;
};
