import {
  ProjectDetails,
  ProjectsTranslation,
} from '@/assets/locales/views/projects/projects.translation.en.ts';

const projectColumns: ProjectDetails = {
  salesforceId: 'Salesforce Opportunity ID',
  customerName: 'Name des Kunden',
  requestingCountry: 'Antragstellendes Land',
  opportunityOwner: 'Inhaber der Opportunity',
  opportunityName: 'Name der Opportunity',
  status: 'Status',
};

export const projectsTranslation: ProjectsTranslation = {
  search: {
    filters: projectColumns,
  },
  table: {
    columns: {
      ...projectColumns,
    },
  },
  details: {
    ...projectColumns,
    description: 'Beschreibung',
    button: {
      edit: 'Projekt bearbeiten',
      archive: 'Projekt archivieren',
      goBack: 'Zurück zur Projektliste',
    },
    modal: {
      ...projectColumns,
      description: 'Beschreibung',
      header: {
        add: 'Projekt hinzufügen',
        edit: 'Projekt bearbeiten',
      },
    },
  },
};
