import React, { ChangeEvent, ReactElement, ReactNode, useCallback, forwardRef } from 'react';
import { Controller, FieldPath, FieldValues, PathValue, useFormContext } from 'react-hook-form';
import { FormPropsModel } from './model/FormProps.model';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { FormControlLabel } from '@mui/material';
import { TextError } from './TextError.comp';
import { typography } from '@/utils/typography.ts';
import { useFormProviderKnowMePropsContext } from '@/components/form/FormProviderKnowMe.comp.tsx';
import { isFormFieldRequired } from '@/utils/form/isFormFieldRequired.util.ts';
import { KnowMeCheckbox } from '@/components/form/KnowMeCheckbox.comp.tsx';

interface Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends FormPropsModel<TName> {
  LabelComponent?: ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  onChangeExternal?: (curValue: boolean) => void;
}

const CheckboxFormField = forwardRef(
  <
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues>,
  >(
    props: Props<TFieldValues, TName>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): ReactElement<Props<TFieldValues, TName>> => {
    const { LabelComponent, disabled, label, labelPlacement, name, onChangeExternal, ...rest } = props;
    const { control } = useFormContext<TFieldValues>();
    const { validation } = useFormProviderKnowMePropsContext();

    const handleChangeProp = useCallback(
      (
        handleChangeFn: (
          event: PathValue<TFieldValues, TName> | ChangeEvent<Element>,
          val: boolean,
        ) => void,
      ) =>
        (event: React.SyntheticEvent, checked: boolean) => {
          if (onChangeExternal) {
            onChangeExternal(checked);
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleChangeFn(event, checked);
        },
      [onChangeExternal],
    );

    const isComponentLabel = Boolean(LabelComponent);

    return (
      <>
        <Controller<TFieldValues, TName>
          control={control}
          {...props}
          name={name}
          render={({
            field: { onBlur: handleBlur, onChange: handleChange, value, name },
            fieldState: { error, invalid },
          }) => {
            const isRequired = isFormFieldRequired(name, validation);

            return (
              <FormControl ref={ref} {...rest}>
                <Grid container alignItems="center">
                  <Grid item xs={isComponentLabel ? 1 : 12}>
                    <FormControlLabel
                      control={
                        <KnowMeCheckbox value={value}
                           invalid={invalid
                      }/>}
                      label={label}
                      labelPlacement={labelPlacement}
                      onChange={handleChangeProp(handleChange)}
                      onBlur={handleBlur}
                      disabled={disabled}
                      required={isRequired}
                      slotProps={{
                        // @ts-expect-error: nvm
                        typography: {
                          ...typography.f14Regular,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>{Boolean(LabelComponent) && LabelComponent}</Grid>
                </Grid>
                <TextError error={error} />
              </FormControl>
            );
          }}
        />
      </>
    );
  },
);

CheckboxFormField.displayName = 'CheckboxFormField';

export {
  CheckboxFormField,
};
