/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CompanyDetailsDto } from '../models';
// @ts-ignore
import type { CreateCompanyDto } from '../models';
// @ts-ignore
import type { UpdateCompanyDto } from '../models';
/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto Create company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyDto: CreateCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('createCompany', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany: async (companyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompany', 'companyId', companyId)
            const localVarPath = `/api/v1/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto Update company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: string, updateCompanyDto: UpdateCompanyDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('updateCompany', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/api/v1/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto Create company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyDto: CreateCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.createCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompany(companyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompany(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.getCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto Update company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, updateCompanyDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompaniesApi.updateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto Create company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyDto: CreateCompanyDto, options?: any): AxiosPromise<void> {
            return localVarFp.createCompany(createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompany(companyId: string, options?: any): AxiosPromise<CompanyDetailsDto> {
            return localVarFp.getCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {UpdateCompanyDto} updateCompanyDto Update company request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompany(companyId, updateCompanyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - interface
 * @export
 * @interface CompaniesApi
 */
export interface CompaniesApiInterface {
    /**
     * 
     * @param {CreateCompanyDto} createCompanyDto Create company request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    createCompany(createCompanyDto: CreateCompanyDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    getCompany(companyId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyDetailsDto>;

    /**
     * 
     * @param {string} companyId 
     * @param {UpdateCompanyDto} updateCompanyDto Update company request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApiInterface
     */
    updateCompany(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI implements CompaniesApiInterface {
    /**
     * 
     * @param {CreateCompanyDto} createCompanyDto Create company request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public createCompany(createCompanyDto: CreateCompanyDto, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).createCompany(createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompany(companyId: string, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).getCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {UpdateCompanyDto} updateCompanyDto Update company request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public updateCompany(companyId: string, updateCompanyDto: UpdateCompanyDto, options?: RawAxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).updateCompany(companyId, updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }
}

