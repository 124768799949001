import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileProjectDto } from '@/api/generated-user-profile';
import {
  skillsDescriptions,
  SkillsDescriptions,
} from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.en';

export type UserProfileProjectsSectionTranslation = SectionTranslationType<
  Omit<UserProfileProjectDto, 'userProfileId' | 'projectId' | 'contentLanguage' | 'description'>
> & {
  form: {
    publicDescription: string;
    anonymizedDescription: string;
    tabs: {
      main: {
        title: string;
      };
      skills: {
        title: string;
        form: {
          skill: string;
          level: string;
          actions: string;
          skillsDescriptions: SkillsDescriptions;
          button: {
            add: string;
            save: string;
            delete: string;
          };
        };
      };
    };
  };
};

export const userProfileProjectsSectionTranslation: UserProfileProjectsSectionTranslation = {
  headerTitle: 'Projects',
  content: {
    emptyItemsLabel: 'projects',
    button: {
      edit: 'Edit',
    },
  },
  form: {
    actions: 'Actions',
    anonymizedDescription: 'Anonymized description',
    client: "Client's name",
    endDate: 'End date',
    industry: 'Industry',
    name: "Project's name",
    ongoing: 'Is ongoing?',
    publicDescription: 'Standard description',
    role: 'Role',
    roleDescription: 'Role description',
    sector: 'Sector',
    skills: 'Skills',
    startDate: 'Start date',
    type: 'Type',
    button: {
      save: 'Save',
    },
    present: 'Present',
    tabs: {
      main: {
        title: 'Project details',
      },
      skills: {
        title: 'Skills',
        form: {
          skillsDescriptions,
          skill: 'Skill',
          level: 'Level',
          actions: 'Actions',
          button: {
            add: 'Add skill',
            save: 'Save skill',
            delete: 'Delete skill',
          },
        },
      },
    },
  },
  modal: {
    title: 'Add project',
  },
};
