import { VacancyCandidateModalTranslation } from '@/assets/locales/components/vacancyCandidateModal/vacancyCandidateModal.translation.en.ts';

export const vacancyCandidateModalTranslation: VacancyCandidateModalTranslation = {
  title: {
    add: 'Ajouter un candidat',
    edit: 'Modifier le candidat',
  },
  form: {
    firstName: 'Prénom',
    lastName: 'Nom',
    languages: 'Langues',
    location: 'Lieu',
    expectedDailyRate: 'Taux journalier attendu à partir de',
    currencyId: 'Devise',
    startDayAvailability: 'Disponibilité à partir du jour',
    externalResumeFileUrl: 'URL du CV',
    resume: 'Fichier du CV',
  },
};
