import { MenuItemType } from '@/layouts/private/sideBarMenu/model/SideBarMenuItems.model.tsx';

export type PageTitlesTranslation = Record<MenuItemType, string> & {
  candidateDetails: string;
  candidates: string;
  companyDetails: string;
  companyEmployee: string;
  copyVacancy: string;
  editVacancy: string;
  eraCandidate: string;
  initializeAccount: string;
  knowMe: string;
  vacancyCandidateTimeline: string;
  vacancyDetails: string;
  signPrivacyPolicy: string;
  signUpdatedPrivacyPolicy: string;
};

export const pageTitlesTranslation: PageTitlesTranslation = {
  addVacancy: 'Request resource',
  aiSearch: 'AI search',
  candidateDetails: 'Candidate details',
  candidates: 'Candidates',
  companies: 'Companies',
  companyDetails: 'Company details',
  companyEmployee: 'Employee details',
  copyVacancy: 'Copy request',
  editVacancy: 'Edit request',
  eraCandidate: 'New candidate',
  initializeAccount: 'Initialize your account',
  knowMe: 'KnowMe',
  profileSearch: 'Profile search',
  projects: 'Projects',
  recruitmentBoard: 'Request tracker',
  recruitmentCandidates: 'Candidates',
  reports: 'Reports',
  settingsDictionaries: 'Dictionaries',
  userProfile: 'Profile',
  vacancyCandidateTimeline: 'Candidate timeline',
  vacancyDetails: 'Request details',
  signPrivacyPolicy: 'Sign privacy policy',
  signUpdatedPrivacyPolicy: 'Sign updated privacy policy',
};
