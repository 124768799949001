import { useEffect } from 'react';
import { RouteEnum } from '@/layouts/routes/Route.model';
import { useNavigate } from 'react-router-dom';

export const DEFAULT_ROUTE = RouteEnum.RECRUITMENT_BOARD;

const SignInHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(DEFAULT_ROUTE);
  }, [navigate]);

  return null;
};

export default SignInHandler;
