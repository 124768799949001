import { recruitmentBoardSearchToolbar } from './searchToolbar/recruitmentBoardSearchToolbar.translation.fr';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';
import { RecruitmentBoardTranslation } from '@/assets/locales/views/recruitmentBoard/recruitmentBoard.translation.en';

export const recruitmentBoardTranslation: RecruitmentBoardTranslation = {
  columns: {
    [VacancyCandidateStatusEnumDto.New]: 'Nouveau',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: "Demande d'entretien",
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Entretien',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Rejeté',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Sélectionné',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offre envoyée',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Offre signée',
  },
  agencyName: 'Agence de recrutement externe',
  assignedHr: 'Recruteur assigné',
  assignedHrbp: 'HRBP assigné',
  availability: {
    available: 'Disponible',
    availableFrom: 'Disponible à partir de',
    no: 'Non disponible',
    noData: 'Pas de données',
    yes: 'Disponible',
  },
  expectedDailyRate: 'Taux journalier attendu',
  hiringManager: 'Responsable du recrutement',
  locations: 'Lieux',
  position: 'Poste',
  sharedWithEra: 'Partagé avec les agences de recrutement externes',
  startingDate: 'Date de début',
  unassignedCandidates: 'Candidats non assignés',
  yearsOfExperience: "Années d'expérience",
  searchToolbar: {
    ...recruitmentBoardSearchToolbar,
  },
  vacancy: {
    opportunityOwner: "Propriétaire de l'opportunité",
    finalRateForCandidate: 'Taux final pour le candidat',
    targetMargin: 'Marge cible',
  },
};
