import React from 'react';
import { DataOperationsError } from '@/errorHandling/DataOperationsError.util.tsx';

export const DataOperationsErrorContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleDataOperationError: (_error: DataOperationsError) => {
    console.log('Handling Error');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleMinorError: (_error: string) => {
    console.log('Handling Minor Error');
  },
  handleOperationSuccess: (_message?: string) => {
    console.log('Handling operation success');
  },
});
