import React, { useEffect } from 'react';
import FullPageSpinner from '@/components/ui/fullPageSpinner/FullPageSpinner';
import { useUserContext } from '@/contexts/userContext/User.context.ts';
import { useOcdHistory } from '@/utils/useOcdHistory.util.ts';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import { isPrivacyPolicySigningRequired } from '@/views/signPrivacyPolicy/util/isPrivacyPolicySigningRequired';

/* THIS IS A QUICK FIX FOR THE ISSUE WITH THE USER CONTEXT NOT BEING READY WHEN THE ROUTES ARE BEING RENDERED */
// TODO: find better solution

export const UserContextVerifier: React.FC = ({ children }) => {
  const { value } = useUserContext();
  const { push } = useOcdHistory();

  useEffect(() => {
    if (!value.dataReady) {
      return;
    }

    const privacyPolicySigningRequired = isPrivacyPolicySigningRequired(
      value.privacyPolicyStatus,
    );

    if (!value?.initializationDone) {
      push(RouteEnum.USERS_INITIALIZE);
    } else if (privacyPolicySigningRequired) {
      push(RouteEnum.USERS_SIGN_PRIVACY_POLICY);
    }
  }, [value.dataReady, value.initializationDone, value.privacyPolicyStatus])

  if (!value.dataReady) {
    return <FullPageSpinner />;
  }

  return <>{children}</>;
};
