import { VacancyDetailsContentTranslation } from '@/assets/locales/views/vacancyDetails/content/vacancyDetails.content.translation.en.ts';

export const vacancyDetailsContentTranslation: VacancyDetailsContentTranslation = {
  section: {
    mandatory: 'Obligatoire',
    nonMandatory: 'Non obligatoire',
    routing: 'Routage',
  },
  accountManager: "Responsable de l'opportunité",
  addJobProfile: 'Ajouter un profil de poste',
  addRfp: 'Ajouter une demande de proposition',
  affiliates: 'Affiliés',
  applicationStartDate: 'Date de début de la demande',
  closureReason: 'Raison de la clôture',
  creationDate: 'Date de création',
  currency: 'Devise',
  customer: 'Client',
  customerBudgetDailyRate: 'Budget client - Taux journalier',
  deliveryLocation: 'Lieu de livraison',
  description: 'Description',
  durationInMonths: 'Durée (mois)',
  expectedAvailability: 'Disponibilité attendue par semaine en jours',
  externalPartners: 'Partenaires externes',
  finalRateForCandidate: 'Taux final pour le candidat',
  jobProfile: 'Profil de poste',
  jobTitle: 'Intitulé du poste',
  language: 'Langue',
  localResourceManagersEmails: 'Emails des gestionnaires de ressources locales',
  localResourceManagersEmailsEmpty: 'Aucune donnée',
  minYearsOfExperience: "Années d'expérience minimales dans un poste similaire",
  ocdEmployees: 'Employés OCD',
  opportunityName: "Nom de l'opportunité",
  opportunityOwner: "Responsable de l'opportunité",
  outOfOfficeHours: 'Heures hors bureau',
  requestType: 'Type de demande',
  requester: 'Demandeur',
  requestingCountry: 'Pays demandeur',
  requiredNoCandidatesSigned: 'Nombre de candidats requis',
  requiredSkills: 'Compétences / technologies requises',
  rfp: 'Demande de proposition',
  salesforceId: "ID de l'opportunité Salesforce",
  specialClearances: 'Autorisations spéciales',
  startDate: 'Date de début',
  status: 'Statut',
  targetMargin: 'Marge cible OCD',
  timeOnSite: '% De temps sur site',
};
