import { UserStatusDto, VacancyProjectStatusDto } from '@/api/generated';
import { CompanyDetailsTranslation } from '@/assets/locales/companyDetails/companyDetails.translation.en.ts';

const addCompany = 'Unternehmen hinzufügen';
const address = 'Hauptadresse';
const companyName = 'Unternehmensname';
const companyProfile = 'Profil';
const description = 'Beschreibung';
const email = 'Haupt email';
const firstName = 'Vorname';
const lastName = 'Nachname';
const location = 'Ort';
const operatingLocations = 'Einsatzorte';
const phoneNumber = 'Haupttelefonnummer';
const status = 'Status';

export const companyDetailsTranslation: CompanyDetailsTranslation = {
  modal: {
    title: {
      addCompany,
      editCompany: 'Unternehmen bearbeiten',
    },
    addMsa: 'MSA hinzufügen',
    addNda: 'NDA hinzufügen',
    address,
    companyName,
    companyProfile,
    defaultRole: 'Standard-Rolle',
    description,
    emailDomain: "E-Mail-Domäne des Unternehmens, z.B. 'gmail.com'",
    email,
    location,
    operatingLocations,
    orangeAffiliate: 'Orange Tochtergesellschaft',
    phoneNumber,
  },
  button: {
    addCompany,
  },
  details: {
    address,
    companyProfile,
    description,
    defaultRole: 'Standard-Rolle',
    domainName: 'Domäne des Unternehmens',
    email,
    location,
    msa: 'MSA',
    nda: 'NDA',
    operatingLocations,
    phoneNumber,
    status,
    button: {
      edit: 'Bearbeiten',
      deactivate: 'Deaktivieren',
      activate: 'Aktivieren',
    },
  },
  table: {
    noResults: 'Keine Ergebnisse',
    columns: {
      actions: 'Genehmigungen stehen noch aus',
      companyName,
      companyProfile,
      location,
    },
    filters: {
      companyName,
      companyProfile,
      employeeFirstName: 'Vorname des Arbeitsnehmers',
      employeeLastName: 'Nachname des Arbeitsnehmers',
      employeeStatus: 'Status des Arbeitsnehmers',
    },
  },
  orangeAffiliate: 'Orange Tochtergesellschaft',
  deactivate: 'Sind Sie sicher, dass Sie dieses Unternehmen deaktivieren möchten?',
  activate: 'Sind Sie sicher, dass Sie dieses Unternehmen aktivieren möchten?',
  editUser: 'Daten bearbeiten',
  representative: 'Agenturvertreter',
  agencyName: 'Agenturname',
  ordersCount: 'Anzahl der Bestellungen',
  goBack: 'Zurück zur Liste der Unternehmen',
  fullName: 'Name',
  employee: 'Arbeitsnehmer',
  employeeFirstName: 'Vorname des Arbeitsnehmers',
  employeeLastName: 'Nachname des Arbeitsnehmers',
  affiliates: 'Tochtergesellschaften',
  external: 'Äußere',
  internal: 'Innere',
  warning: 'Sind Sie sicher, dass Sie den Mitarbeiter löschen wollen?',
  phoneNo: 'Telefonnummer',
  editEmployee: 'Arbeitsnehmer bearbeiten',
  employeeStatus: {
    [UserStatusDto.Active]: 'Aktiv',
    [UserStatusDto.Inactive]: 'Untätig',
    [UserStatusDto.WaitingForApproval]: 'Warten auf Genehmigung',
  },
  projectStatus: {
    [VacancyProjectStatusDto.Active]: 'Aktiv',
    [VacancyProjectStatusDto.Archived]: 'Archiviert',
    [VacancyProjectStatusDto.ClosedLost]: 'Geschlossen',
    [VacancyProjectStatusDto.ClosedWon]: 'Geschlossen',
    [VacancyProjectStatusDto.NoSalesforceId]: 'Keine Salesforce ID',
    [VacancyProjectStatusDto.OnHold]: 'In Warteposition',
  },
  buttons: {
    selectAll: 'Alle auswählen',
    deselectAll: 'Alle abwählen',
  },
  companyProfile: {
    AFFILIATES: 'Tochtergesellschaften',
    EXTERNAL: 'Äußere',
    INTERNAL: 'Innere',
  },
  employeesTable: {
    column: {
      firstName,
      lastName,
      companyName,
      status,
    },
    filters: {
      firstName,
      lastName,
      status,
    },
  },
};
