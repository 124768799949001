import { skillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.de';
import { UserProfileProjectsSectionTranslation } from '@/assets/locales/views/userProfile/sections/projectsSection/userProfile.projectsSection.translation.en';

export const userProfileProjectsSectionTranslation: UserProfileProjectsSectionTranslation = {
  headerTitle: 'Projekte',
  content: {
    emptyItemsLabel: 'Projekte',
    button: {
      edit: 'Bearbeiten',
    },
  },
  form: {
    actions: 'Handlungen',
    anonymizedDescription: 'Anonyme Beschreibung',
    client: 'Name des Kunden',
    endDate: 'Enddatum',
    industry: 'Industrie',
    name: 'Name des Projekts',
    ongoing: 'Läuft es weiter?',
    publicDescription: 'Standardbeschreibung',
    role: 'Rolle',
    roleDescription: 'Rolle beschreiben',
    sector: 'Sektor',
    skills: 'Fähigkeiten',
    startDate: 'Startdatum',
    type: 'Typ',
    button: {
      save: 'Speichern',
    },
    present: 'aktuell',
    tabs: {
      main: {
        title: 'Details zum Projekt',
      },
      skills: {
        title: 'Fähigkeiten',
        form: {
          skillsDescriptions,
          skill: 'Fähigkeit',
          level: 'Niveau',
          actions: 'Handlungen',
          button: {
            add: 'Fähigkeit hinzufügen',
            save: 'Fähigkeit speichern',
            delete: 'Fähigkeit löschen',
          },
        },
      },
    },
  },
  modal: {
    title: 'Projekt hinzufügen',
  },
};
