import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ViewHeader from '@/views/viewHeader/ViewHeader.comp.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { PageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.en.ts';
import {
  ViewWrapperPaperStyles,
  ViewWrapperStyles,
} from '@/components/viewWrapper/viewWrapper.styles.ts';
import { SxProps, Theme } from '@mui/material';
import { usePageContext } from '@/contexts/pageContext/Page.context.ts';

type Props = {
  header?: {
    pageTitle?: keyof PageTitlesTranslation;
    customTitle?: string;
    display?: boolean;
  };
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  button?: React.ReactNode;
};

export const ViewWrapper: React.FC<Props> = props => {
  const { t } = useTranslationWithPrefix('pageTitles');
  const { children, header, isLoading, sx, button } = props;
  const { setPageDetails } = usePageContext();

  const pageTitle = header?.pageTitle ? t(header?.pageTitle) : header?.customTitle;

  useEffect(() => {
    if (header?.pageTitle) {
      setPageDetails({ title: header.pageTitle });
    }
  }, []);

  const mainStyles = { ...ViewWrapperStyles, ...(sx ?? {}) };

  if (isLoading) {
    return (
      <Container sx={mainStyles} maxWidth="xl" fixed>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Paper square sx={ViewWrapperPaperStyles}>
            <Box display="flex" justifyContent="center" my={10}>
              <CircularProgress />
            </Box>
          </Paper>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <Container sx={mainStyles} maxWidth="xl" fixed>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
          <Paper square sx={ViewWrapperPaperStyles}>
            {header && header.display && (
              <>
                <ViewHeader customTitle={pageTitle} button={button} />
              </>
            )}
            {!header?.display && <Box sx={{ textAlign: 'right' }}>{button}</Box>}
            <Box>{children}</Box>
          </Paper>
        </Grid>
      </Container>
    </>
  );
};
