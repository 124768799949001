import { useAiApiClient } from '@/api/util/useAiApiClient.util.ts';
import { useMutation } from '@tanstack/react-query';
import { AIUserProfileSearchQueryResultDto } from '@/api/generated';
import { AxiosError } from 'axios';
import { QueryClientMetaModel } from '@/config/QueryClientMeta.model.ts';

type Request = { chatId: string; searchId: string };

type Return = {
  aiSearchStateCheck: (req: Request) => Promise<AIUserProfileSearchQueryResultDto>;
  isCheckingState: boolean;
};

export const useAiUserProfileSearchCheckState = (): Return => {
  const { aiSearchUserProfile } = useAiApiClient();

  const { mutateAsync, isLoading } = useMutation<
    AIUserProfileSearchQueryResultDto,
    AxiosError,
    Request
  >({
    meta: {
      disableSuccessAlert: true,
    } as QueryClientMetaModel,
    mutationFn: async req => {
      const res = await aiSearchUserProfile.getSearchUserProfileState(req.chatId, req.searchId);
      return res.data;
    },
  });

  return {
    aiSearchStateCheck: mutateAsync,
    isCheckingState: isLoading,
  };
};
