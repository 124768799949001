import { useTranslation } from 'react-i18next';
import dictionaryTranslations from '@/utils/dictionaryTranslations';

export const useDictionaryTranslation = () => {
  const { t } = useTranslation();
  const dt = (dictionary: string, key?: string) => {
    if (!key) {
      return '';
    }
    const translationKey = dictionaryTranslations?.[dictionary]?.[key];
    return t(translationKey || `${dictionary}.${key}`);
  };
  return { dt };
};
