import ENDPOINTS from '../config/endpoints';
import { httpClient } from '@/api/avatar-api';

export const searchCandidates = payload => {
  return new Promise((resolve, reject) => {
    httpClient.post({
      payload,
      endpoint: `${ENDPOINTS.VACANCY_CANDIDATES}/list`,
      onSuccess: data => resolve(data),
      onError: error => reject(error)
    });
  });
};

export const getCsrfToken = () => {
  return new Promise((resolve, reject) => {
    httpClient.get({
      endpoint: '/api/v1/csrf',
      onSuccess: data => resolve(data.token),
      onError: error => reject(error)
    });
  });
};
