import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import France from '@/assets/icons/flags/france.png';
import Germany from '@/assets/icons/flags/germany.png';
import UnitedKingdom from '@/assets/icons/flags/united-kingdom.png';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { TopBarLanguageChangeLanguageIconStyles } from '@/layouts/private/topBar/components/TopBarLanguageChange/style/TopBarLanguageChange.style.ts';

type OptionType = {
  value: string;
  label: React.ReactNode;
};

export const TopBarLanguageChange: React.FC = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslationWithPrefix('layouts.topBar.language');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLanguageMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language: string) => async () => {
    await i18n.changeLanguage(language);
    handleChangeLanguageMenuClose();
  };

  const languageOptions: OptionType[] = [
    {
      value: 'en',
      label: (
        <>
          <Box
            src={UnitedKingdom}
            alt={t('english')}
            component="img"
            sx={{ mr: 1, width: '32px' }}
          />
          <Typography>{t('english')}</Typography>
        </>
      ),
    },
    {
      value: 'de',
      label: (
        <>
          <Box src={Germany} alt={t('german')} component="img" sx={{ mr: 1, width: '32px' }} />
          <Typography>{t('german')}</Typography>
        </>
      ),
    },
    {
      value: 'fr',
      label: (
        <>
          <Box src={France} alt={t('french')} component="img" sx={{ mr: 1, width: '32px' }} />
          <Typography>{t('french')}</Typography>
        </>
      ),
    },
  ];

  const returnSelectedLanguagesFlag = () => {
    switch (i18n.language) {
      case 'en':
        return UnitedKingdom;
      case 'de':
        return Germany;
      case 'fr':
        return France;
      default:
        return UnitedKingdom;
    }
  };

  return (
    <>
      <IconButton size="large" onClick={handleClick}>
        <Box sx={TopBarLanguageChangeLanguageIconStyles}>
          <Box
            src={returnSelectedLanguagesFlag()}
            alt={t('selectedLanguage')}
            component="img"
            sx={{ width: '22px' }}
          />
        </Box>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleChangeLanguageMenuClose}>
        {languageOptions.map(option => (
          <MenuItem key={option.value} onClick={handleLanguageChange(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
