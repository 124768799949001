import { useEffect } from 'react';
import { UserStatusDto } from '@/api/generated';
import { RouteEnum } from '@/layouts/routes/Route.model.ts';
import { FullScreenLoader } from '@/components/fullScreenLoader/FullScreenLoader.comp.tsx';
import { LanguageEnum } from '@/api/models/Language.enum.ts';
import { fetchCurrentUser } from '@/api/user-api.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { useGetUserAccountStatus } from '@/api/useGetUserAccountStatus.util.ts';
import { GeneralError } from '@/views/generalError/GeneralError.view.tsx';

export const WaitingForApprovalView = () => {
  const { t } = useTranslationWithPrefix('generalError.waitingForApproval');
  const { getUserAccountStatus, isLoading } = useGetUserAccountStatus();

  const init = async () => {
    try {
      const user = await fetchCurrentUser(LanguageEnum.EN);
      if (!user?.id) {
        return;
      }
      const userStatus = await getUserAccountStatus(user.id);
      if (userStatus === UserStatusDto.Active) {
        (window as Window).location = window.location.origin + RouteEnum.RECRUITMENT_BOARD;
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    void init();
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <GeneralError message={t('message')} subMessage={t('subMessage')} />;
};
