/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileProject1Dto } from '../models';
/**
 * UserProfileProjectApi - axios parameter creator
 * @export
 */
export const UserProfileProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} userProfileProject1Dto User Profile Project create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileProject: async (userProfileId: string, lang: LangDto, userProfileProject1Dto: UserProfileProject1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileProject', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileProject', 'lang', lang)
            // verify required parameter 'userProfileProject1Dto' is not null or undefined
            assertParamExists('createUserProfileProject', 'userProfileProject1Dto', userProfileProject1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/projects`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileProject1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileProject: async (userProfileId: string, userProfileProjectId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileProject', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileProjectId' is not null or undefined
            assertParamExists('deleteUserProfileProject', 'userProfileProjectId', userProfileProjectId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileProject', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/projects/{userProfileProjectId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileProjectId"}}`, encodeURIComponent(String(userProfileProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} [userProfileProject1Dto] User Profile Project update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileProject: async (userProfileId: string, userProfileProjectId: string, lang: LangDto, userProfileProject1Dto?: UserProfileProject1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileProject', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileProjectId' is not null or undefined
            assertParamExists('updateUserProfileProject', 'userProfileProjectId', userProfileProjectId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileProject', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/projects/{userProfileProjectId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileProjectId"}}`, encodeURIComponent(String(userProfileProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileProject1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileProjectApi - functional programming interface
 * @export
 */
export const UserProfileProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} userProfileProject1Dto User Profile Project create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileProject(userProfileId: string, lang: LangDto, userProfileProject1Dto: UserProfileProject1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileProject(userProfileId, lang, userProfileProject1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileProjectApi.createUserProfileProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileProject(userProfileId, userProfileProjectId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileProjectApi.deleteUserProfileProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} [userProfileProject1Dto] User Profile Project update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, userProfileProject1Dto?: UserProfileProject1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileProject(userProfileId, userProfileProjectId, lang, userProfileProject1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileProjectApi.updateUserProfileProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileProjectApi - factory interface
 * @export
 */
export const UserProfileProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} userProfileProject1Dto User Profile Project create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileProject(userProfileId: string, lang: LangDto, userProfileProject1Dto: UserProfileProject1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileProject(userProfileId, lang, userProfileProject1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileProject(userProfileId, userProfileProjectId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileProjectId 
         * @param {LangDto} lang 
         * @param {UserProfileProject1Dto} [userProfileProject1Dto] User Profile Project update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, userProfileProject1Dto?: UserProfileProject1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileProject(userProfileId, userProfileProjectId, lang, userProfileProject1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileProjectApi - interface
 * @export
 * @interface UserProfileProjectApi
 */
export interface UserProfileProjectApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileProject1Dto} userProfileProject1Dto User Profile Project create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApiInterface
     */
    createUserProfileProject(userProfileId: string, lang: LangDto, userProfileProject1Dto: UserProfileProject1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileProjectId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApiInterface
     */
    deleteUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileProjectId 
     * @param {LangDto} lang 
     * @param {UserProfileProject1Dto} [userProfileProject1Dto] User Profile Project update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApiInterface
     */
    updateUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, userProfileProject1Dto?: UserProfileProject1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileProjectApi - object-oriented interface
 * @export
 * @class UserProfileProjectApi
 * @extends {BaseAPI}
 */
export class UserProfileProjectApi extends BaseAPI implements UserProfileProjectApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileProject1Dto} userProfileProject1Dto User Profile Project create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApi
     */
    public createUserProfileProject(userProfileId: string, lang: LangDto, userProfileProject1Dto: UserProfileProject1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileProjectApiFp(this.configuration).createUserProfileProject(userProfileId, lang, userProfileProject1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileProjectId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApi
     */
    public deleteUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileProjectApiFp(this.configuration).deleteUserProfileProject(userProfileId, userProfileProjectId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileProjectId 
     * @param {LangDto} lang 
     * @param {UserProfileProject1Dto} [userProfileProject1Dto] User Profile Project update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileProjectApi
     */
    public updateUserProfileProject(userProfileId: string, userProfileProjectId: string, lang: LangDto, userProfileProject1Dto?: UserProfileProject1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileProjectApiFp(this.configuration).updateUserProfileProject(userProfileId, userProfileProjectId, lang, userProfileProject1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

