import { UserProfileEducationsSectionTranslation } from '@/assets/locales/views/userProfile/sections/educationsSection/userProfile.educationsSection.translation.en.ts';

export const userProfileEducationsSectionTranslation: UserProfileEducationsSectionTranslation = {
  headerTitle: 'Ausbildung',
  content: {
    emptyItemsLabel: 'Ausbildung Einträge',
    button: {
      edit: 'Bearbeiten',
    },
  },
  modal: {
    title: 'Ausbildung hinzufügen',
  },
  form: {
    actions: 'Handlungen',
    school: 'Schule',
    degree: 'Abschluss',
    fieldOfStudy: 'Studienfach',
    startDate: 'Anfangsdatum',
    endDate: 'Abschlussdatum',
    stillStudying: 'Ich studiere noch',
    activities: 'Aktivitäten',
    description: 'Beschreibung',
    button: {
      save: 'Speichern',
    },
    present: 'Noch im Studium',
  },
};
