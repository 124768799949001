/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UpdateUserProfileAvatarDto } from '../models';
// @ts-ignore
import type { UserProfileAvatar1Dto } from '../models';
/**
 * UserProfileAvatarApi - axios parameter creator
 * @export
 */
export const UserProfileAvatarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvatar1Dto} userProfileAvatar1Dto User Profile Avatar create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileAvatar: async (userProfileId: string, lang: LangDto, userProfileAvatar1Dto: UserProfileAvatar1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileAvatar', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileAvatar', 'lang', lang)
            // verify required parameter 'userProfileAvatar1Dto' is not null or undefined
            assertParamExists('createUserProfileAvatar', 'userProfileAvatar1Dto', userProfileAvatar1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/avatars`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileAvatar1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileAvatar: async (userProfileId: string, userProfileAvatarId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileAvatar', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileAvatarId' is not null or undefined
            assertParamExists('deleteUserProfileAvatar', 'userProfileAvatarId', userProfileAvatarId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileAvatar', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/avatars/{userProfileAvatarId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileAvatarId"}}`, encodeURIComponent(String(userProfileAvatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {UpdateUserProfileAvatarDto} updateUserProfileAvatarDto User Profile Avatar update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAvatar: async (userProfileId: string, userProfileAvatarId: string, lang: LangDto, updateUserProfileAvatarDto: UpdateUserProfileAvatarDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileAvatar', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileAvatarId' is not null or undefined
            assertParamExists('updateUserProfileAvatar', 'userProfileAvatarId', userProfileAvatarId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileAvatar', 'lang', lang)
            // verify required parameter 'updateUserProfileAvatarDto' is not null or undefined
            assertParamExists('updateUserProfileAvatar', 'updateUserProfileAvatarDto', updateUserProfileAvatarDto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/avatars/{userProfileAvatarId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileAvatarId"}}`, encodeURIComponent(String(userProfileAvatarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfileAvatarDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileAvatarApi - functional programming interface
 * @export
 */
export const UserProfileAvatarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileAvatarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvatar1Dto} userProfileAvatar1Dto User Profile Avatar create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileAvatar(userProfileId: string, lang: LangDto, userProfileAvatar1Dto: UserProfileAvatar1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileAvatar(userProfileId, lang, userProfileAvatar1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAvatarApi.createUserProfileAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileAvatar(userProfileId, userProfileAvatarId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAvatarApi.deleteUserProfileAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {UpdateUserProfileAvatarDto} updateUserProfileAvatarDto User Profile Avatar update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, updateUserProfileAvatarDto: UpdateUserProfileAvatarDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileAvatar(userProfileId, userProfileAvatarId, lang, updateUserProfileAvatarDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileAvatarApi.updateUserProfileAvatar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileAvatarApi - factory interface
 * @export
 */
export const UserProfileAvatarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileAvatarApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvatar1Dto} userProfileAvatar1Dto User Profile Avatar create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileAvatar(userProfileId: string, lang: LangDto, userProfileAvatar1Dto: UserProfileAvatar1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileAvatar(userProfileId, lang, userProfileAvatar1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileAvatar(userProfileId, userProfileAvatarId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileAvatarId 
         * @param {LangDto} lang 
         * @param {UpdateUserProfileAvatarDto} updateUserProfileAvatarDto User Profile Avatar update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, updateUserProfileAvatarDto: UpdateUserProfileAvatarDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileAvatar(userProfileId, userProfileAvatarId, lang, updateUserProfileAvatarDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileAvatarApi - interface
 * @export
 * @interface UserProfileAvatarApi
 */
export interface UserProfileAvatarApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAvatar1Dto} userProfileAvatar1Dto User Profile Avatar create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApiInterface
     */
    createUserProfileAvatar(userProfileId: string, lang: LangDto, userProfileAvatar1Dto: UserProfileAvatar1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAvatarId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApiInterface
     */
    deleteUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAvatarId 
     * @param {LangDto} lang 
     * @param {UpdateUserProfileAvatarDto} updateUserProfileAvatarDto User Profile Avatar update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApiInterface
     */
    updateUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, updateUserProfileAvatarDto: UpdateUserProfileAvatarDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileAvatarApi - object-oriented interface
 * @export
 * @class UserProfileAvatarApi
 * @extends {BaseAPI}
 */
export class UserProfileAvatarApi extends BaseAPI implements UserProfileAvatarApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAvatar1Dto} userProfileAvatar1Dto User Profile Avatar create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApi
     */
    public createUserProfileAvatar(userProfileId: string, lang: LangDto, userProfileAvatar1Dto: UserProfileAvatar1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileAvatarApiFp(this.configuration).createUserProfileAvatar(userProfileId, lang, userProfileAvatar1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAvatarId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApi
     */
    public deleteUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileAvatarApiFp(this.configuration).deleteUserProfileAvatar(userProfileId, userProfileAvatarId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileAvatarId 
     * @param {LangDto} lang 
     * @param {UpdateUserProfileAvatarDto} updateUserProfileAvatarDto User Profile Avatar update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileAvatarApi
     */
    public updateUserProfileAvatar(userProfileId: string, userProfileAvatarId: string, lang: LangDto, updateUserProfileAvatarDto: UpdateUserProfileAvatarDto, options?: RawAxiosRequestConfig) {
        return UserProfileAvatarApiFp(this.configuration).updateUserProfileAvatar(userProfileId, userProfileAvatarId, lang, updateUserProfileAvatarDto, options).then((request) => request(this.axios, this.basePath));
    }
}

