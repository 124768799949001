import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileAwardDto } from '@/api/generated-user-profile';

export type UserProfileAwardsSectionTranslation = SectionTranslationType<
  Omit<UserProfileAwardDto, 'userProfileId' | 'awardId' | 'contentLanguage'>
>;

export const userProfileAwardsSectionTranslation: UserProfileAwardsSectionTranslation = {
  headerTitle: 'Awards',
  content: {
    emptyItemsLabel: 'awards',
    button: {
      edit: 'Edit',
    },
  },
  modal: {
    title: 'Add award',
  },
  form: {
    actions: 'Actions',
    title: 'Title',
    associatedWith: 'Associated with',
    issuer: 'Issuer',
    issueDate: 'Issue date',
    description: 'Description',
    button: {
      save: 'Save',
    },
  },
};
