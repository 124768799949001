export interface ProfileTranslation {
  profileSkills: {
    addTechnology: string;
  };
  profileProjects: {
    project: string;
    client: string;
  };
  skills: string;
  onboarding: string;
  readMore: string;
  aboutMe: string;
  linkedIn: string;
  edit: string;
  delete: string;
  set: string;
  aboutMeDescription: string;
  department: string;
  supervisor: string;
  accomplishments: string;
  additionalInformation: string;
  avatar: string;
  avatarDescription: string;
  avatarInfo: string;
  awards: string;
  awardsDescription: string;
  basicInfo: string;
  certificates: string;
  contact: string;
  copied: string;
  courses: string;
  coursesDescription: string;
  downloadFiles: string;
  download: string;
  editProfile: string;
  education: string;
  entryIncomplete: string;
  experiences: string;
  experience: string;
  forms: {
    dialog: {
      areYouSure: string;
      warning: string;
    };
    availability: {
      title: string;
      date: string;
      '1month': string;
      months: string;
      months2: string;
      indefinite: string;
      note: string;
      period: string;
      available: string;
      changePosition: string;
      departmentOnly: string;
      positionTooltip: string;
      tooltip: string;
      sendNotification: string;
    };
    avatar: {
      zoomSlider: string;
      header: string;
      buttonAdd: string;
      buttonDelete: string;
    };
    award: {
      addAward: string;
      associatedWith: string;
      header: string;
      issueDate: string;
      issuer: string;
      title: string;
    };
    basicInfo: {
      location: string;
      firstName: string;
      header: string;
      lastName: string;
      mobile: string;
      photoError: string;
      position: string;
    };
    buttonDelete: string;
    buttonSave: string;
    buttonSaveAndSend: string;
    buttonSend: string;
    buttonConfirm: string;
    buttonEnd: string;
    buttonCancel: string;
    buttonClose: string;
    buttonNext: string;
    buttonEdit: string;
    certificate: {
      addCertificate: string;
      create: string;
      credentialId: string;
      credentialUrl: string;
      expirationDate: string;
      header: string;
      issued: string;
      issueDate: string;
      name: string;
      noData: string;
      nonExpiring: string;
      organization: string;
      validIndefinitely: string;
      validUntil: string;
      viewCredential: string;
    };
    course: {
      addCourse: string;
      courseLink: string;
      date: string;
      header: string;
      name: string;
      organizer: string;
      url: string;
    };
    description: string;
    dropzone: string;
    dropzoneFormatError: string;
    dropzoneSizeError: string;
    dropzoneFile: string;
    education: {
      addEducation: string;
      activities: string;
      degree: string;
      endDate: string;
      fieldOfStudy: string;
      header: string;
      school: string;
      startDate: string;
      stillStudying: string;
    };
    experience: {
      addExperience: string;
      company: string;
      currentPosition: string;
      endDate: string;
      header: string;
      location: string;
      position: string;
      startDate: string;
    };
    evaluation: {
      instruction: string;
      title: string;
      delegate: string;
      selfEvaluation: string;
      yes: string;
      no: string;
    };
    formError: string;
    language: {
      advanced: string;
      basic: string;
      bilingual: string;
      header: string;
      intermediate: string;
      language: string;
      professional: string;
      proficiency: string;
    };
    pdfFile: string;
    project: {
      associatedWith: string;
      addProject: string;
      client: string;
      endDate: string;
      header: string;
      name: string;
      ongoing: string;
      projectLink: string;
      role: string;
      startDate: string;
      urlAddress: string;
    };
    recommendation: {
      author: string;
      description: string;
      header: string;
      recommendationLink: string;
      viewOriginal: string;
    };
    salary: {
      title: string;
      employee: string;
      type: string;
      newContractType: string;
      date: string;
      rate: string;
      companies: string;
      noticePeriod: string;
      '1month': string;
      months: string;
      showHistory: string;
      monthly: string;
      hourly: string;
      rateKind: string;
      rateType: string;
      currency: string;
      indefinite: string;
      unknown: string;
      position: string;
      changePosition: string;
      changeNoticePeriod: string;
      changeCustomers: string;
      changeContractType: string;
      requestTooltip: string;
    };
    skill: {
      add: string;
      addAnother: string;
      buttonPropose: string;
      endorsementBy: string;
      footer1: string;
      footer2: string;
      header: string;
      editHeader: string;
      people: string;
      people2: string;
      person: string;
      proposeHeader: string;
      proposed: string;
      proposedBy: string;
      instruction: string;
      search: string;
      skills: string;
      rate: {
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
      };
      description: {
        '1a': string;
        '1b': string;
        '1c': string;
        '2a': string;
        '2b': string;
        '2c': string;
        '3a': string;
        '3b': string;
        '3c': string;
        '4a': string;
        '4b': string;
        '4c': string;
        '5a': string;
        '5b': string;
        '5c': string;
        '5d': string;
      };
    };
    switchLabel: string;
  };
  generateCV: string;
  askForFeedback: string;
  languages: string;
  languagesDescription: string;
  managersNote: string;
  present: string;
  projects: string;
  publications: string;
  skillsDescription: string;
  viewFile: string;
  viewLink: string;
  showAllButton: string;
  evaluations: {
    type: string;
    date: string;
  };
}

export const profileTranslation: ProfileTranslation = {
  profileSkills: {
    addTechnology: 'Please provide skill name',
  },
  profileProjects: {
    project: 'Project',
    client: 'Client',
  },
  skills: 'Skills & Technologies',
  onboarding: 'Onboarding',
  readMore: 'Read more',
  aboutMe: 'About me',
  linkedIn: 'LinkedIn profile',
  edit: 'Edit',
  delete: 'Delete',
  set: 'Set',
  aboutMeDescription: 'A few words about yourself',
  department: 'department',
  supervisor: 'supervisor',
  accomplishments: 'Accomplishments',
  additionalInformation: 'Additional information',
  avatar: 'Profile picture',
  avatarDescription: 'Add and edit profile picture',
  avatarInfo:
    "Can't see your photo? Show your good side - complete your profile up to 95% to change your avatar.",
  awards: 'Honours and awards',
  awardsDescription: "Show how much you're appreciated",
  basicInfo: 'Basic information',
  certificates: 'Certificates',
  contact: 'Contact',
  copied: 'Link copied to clipboard',
  courses: 'Courses',
  coursesDescription: 'Add ongoing or completed courses',
  downloadFiles: 'Download files',
  download: 'Download',
  editProfile: 'Edit profile',
  education: 'Education',
  entryIncomplete: 'The entry needs to be completed.',
  experiences: 'Work experience',
  experience: 'Work experience',
  forms: {
    dialog: {
      areYouSure: 'Are you sure you want to delete this entry?',
      warning: 'Once approved, it will not be possible to restore this data!',
    },
    availability: {
      title: 'Set employee availability',
      date: 'Start date',
      '1month': '1 month',
      months: 'months',
      months2: 'months',
      indefinite: 'Unspecified',
      note: 'Additional information',
      period: 'Availability period',
      available: 'Employee available',
      changePosition: 'Change desired position',
      departmentOnly: 'Only my department',
      positionTooltip: 'Check if the employee is looking to change positions.',
      tooltip:
        'Check the checkbox and complete the form to let others in the company know that your employee may be available for their projects. Remember to update this checkmark when the employee has an allocation.',
      sendNotification: 'Send notification',
    },
    avatar: {
      zoomSlider: 'Zoom',
      header: 'Profile picture',
      buttonAdd: 'Change or add picture',
      buttonDelete: 'Delete picture',
    },
    award: {
      addAward: 'Add award',
      associatedWith: 'Associated with',
      header: 'Honours and awards',
      issueDate: 'Issue date',
      issuer: 'Issuer',
      title: 'Title',
    },
    basicInfo: {
      location: 'Location',
      firstName: 'First Name',
      header: 'Edit basic information',
      lastName: 'Last name',
      mobile: 'Phone number',
      photoError: 'Wrong file format! Upload the JPG or PNG file.',
      position: 'Position',
    },
    buttonDelete: 'Delete',
    buttonSave: 'Save',
    buttonSaveAndSend: 'Save and Send',
    buttonSend: 'Send',
    buttonConfirm: 'Confirm',
    buttonEnd: 'Close',
    buttonCancel: 'Cancel',
    buttonClose: 'Close',
    buttonNext: 'Next',
    buttonEdit: 'Edit',
    certificate: {
      addCertificate: 'Add certificate',
      create: 'Create:',
      credentialId: 'Credential ID',
      credentialUrl: 'Credential URL',
      expirationDate: 'Expiration date',
      header: 'Certification',
      issued: 'Issued',
      issueDate: 'Issue date',
      name: 'Name',
      noData: 'No data avaiable',
      nonExpiring: 'This credential does not expire',
      organization: 'Issuing organization',
      validIndefinitely: 'Valid indefinitely',
      validUntil: 'Valid until',
      viewCredential: 'View Credential ID',
    },
    course: {
      addCourse: 'Add course',
      courseLink: 'Course link',
      date: 'Course completion date',
      header: 'Course',
      name: 'Course name',
      organizer: 'Organizer',
      url: 'Course URL',
    },
    description: 'Description',
    dropzone: 'Click or drag the file here...',
    dropzoneFormatError: 'Wrong file format!',
    dropzoneSizeError: 'Uploaded file is too big!',
    dropzoneFile: 'Added file:',
    education: {
      addEducation: 'Add education',
      activities: 'Activities and societies',
      degree: 'Degree',
      endDate: 'End date',
      fieldOfStudy: 'Field of study',
      header: 'Education',
      school: 'School',
      startDate: 'Start date',
      stillStudying: 'I am still studying',
    },
    experience: {
      addExperience: 'Add experience',
      company: 'Company',
      currentPosition: 'I am currently working in this role',
      endDate: 'End date',
      header: 'Work experience',
      location: 'Location',
      position: 'Position',
      startDate: 'Start date',
    },
    evaluation: {
      instruction:
        'Select the appropriate options below if you want to delegate the evaluation or if you do not want the employee to self-evaluate.',
      title: 'You are starting the evaluation process',
      delegate: 'Do you want to delegate Evaluation?',
      selfEvaluation: 'Do you want the employee {{name}} to self-evaluate?',
      yes: 'Yes',
      no: 'No',
    },
    formError: 'This field is required',
    language: {
      advanced: 'Advanced',
      basic: 'Basic',
      bilingual: 'Native or bilingual',
      header: 'Add language',
      intermediate: 'Intermediate',
      language: 'Language',
      professional: 'Professional',
      proficiency: 'Proficiency',
    },
    pdfFile: 'PDF File',
    project: {
      associatedWith: 'Associated with',
      addProject: 'Add project',
      client: 'Client',
      endDate: 'End date',
      header: 'Project',
      name: 'Project name',
      ongoing: 'I am currently working on this project',
      projectLink: 'Project link',
      role: 'Role',
      startDate: 'Start date',
      urlAddress: 'Project URL',
    },
    recommendation: {
      author: 'Author',
      description: 'Content',
      header: 'Endorsement',
      recommendationLink: 'Endorsement link',
      viewOriginal: 'View the original recommendation',
    },
    salary: {
      title: 'Request to change the rate',
      employee: 'Employee',
      type: 'Contract type',
      newContractType: 'New contract type',
      date: 'Start date',
      rate: 'Rate',
      companies: 'Clients',
      noticePeriod: 'Notice period',
      '1month': '1 month',
      months: 'months',
      showHistory: 'Show history',
      monthly: 'Monthly',
      hourly: 'Hour',
      rateKind: 'Kind of rate',
      rateType: 'Rate type',
      currency: 'Currency',
      indefinite: 'Indefinite period',
      unknown: 'Unknown',
      position: 'Position',
      changePosition: 'Change position',
      changeNoticePeriod: 'Change notice period',
      changeCustomers: 'Change customer list',
      changeContractType: 'Change contract type',
      requestTooltip: 'The request has expired, change the date or reject the request.',
    },
    skill: {
      add: 'Select a skill or add a new one',
      addAnother: 'Add another skill',
      buttonPropose: 'Propose skill',
      endorsementBy: 'Endorsed by',
      footer1: "You don't see the skill",
      footer2: 'has?',
      header: 'Add skill',
      editHeader: 'Edit skill',
      people: 'people',
      people2: 'people',
      person: 'person',
      proposeHeader: 'Propose skills',
      proposed: 'Skills proposed by other users:',
      proposedBy: 'Proposed by: ',
      instruction:
        'If you want to add a skill that is not in the suggestions, confirm by pressing Enter.',
      search: 'Search...',
      skills: 'Skills',
      rate: {
        1: 'Novice',
        2: 'Advanced Beginner',
        3: 'Competent',
        4: 'Proficient',
        5: 'Expert',
      },
      description: {
        '1a': 'You have little to no experience with the technology or skill',
        '1b': 'You need clear instructions on how to do something in order to do it',
        '1c': 'You don’t have yet an intuitive understanding of the skill / technology',
        '2a': 'You have some experience with the skill or technology',
        '2b': 'You are able to start to troubleshoot basic problems you encounter',
        '2c': 'You still need significant support from more experienced teammates',
        '3a': 'You have some direct experience - had used the technology or skill in the past',
        '3b': 'You are able to perform basic tasks on your own',
        '3c': 'You need others help from time to time',
        '4a': 'You are capable and experienced in using the technology / skill',
        '4b': 'You demonstrate proficiency in using skill/technology',
        '4c': 'You are able to work independently',
        '5a': 'You are fully capable and experienced in using the technology / skill to solve practical problems',
        '5b': "You need no assistance to complete tasks, it's rather you who is being asked for help",
        '5c': 'You demonstrate the ability to lead and train others',
        '5d': 'You are recognized by others as a subject matter expert',
      },
    },
    switchLabel: 'Show content in Polish',
  },
  generateCV: 'Generate CV',
  askForFeedback: 'Request Feedback',
  languages: 'Languages',
  languagesDescription: 'Information on the languages you speak',
  managersNote: "Managers' notes",
  present: 'present',
  projects: 'Projects',
  publications: 'Publications',
  skillsDescription: 'Showcase your strenghts',
  viewFile: 'View the attached document',
  viewLink: 'View the link provided',
  showAllButton: 'Show all',
  evaluations: {
    type: 'Evaluation type',
    date: 'Evaluation date',
  },
};
