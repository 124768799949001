export type UserDetailsTranslation = {
  modal: {
    title: {
      edit: string;
    };
    role: string;
    location: string;
    operatingLocations: string;
    status: string;
    phoneNumber: string;
    email: string;
    buttons: {
      selectAll: string;
      deselectAll: string;
    };
  };
  content: {
    companyName: string;
    email: string;
    lastLogin: string;
    location: string;
    operatingLocations: string;
    phoneNumber: string;
    registeredAt: string;
    role: string;
    status: string;
    button: {
      edit: string;
    };
  };
};

export const userDetailsTranslation: UserDetailsTranslation = {
  modal: {
    title: {
      edit: 'Edit Data',
    },
    role: 'Role',
    location: 'Location',
    operatingLocations: 'Operating Locations',
    status: 'Status',
    email: 'Email',
    phoneNumber: 'Phone Number',
    buttons: {
      selectAll: 'Select All',
      deselectAll: 'Deselect All',
    },
  },
  content: {
    companyName: 'Company name',
    email: 'Main email',
    registeredAt: 'Registered at',
    role: 'Role',
    status: 'Status',
    lastLogin: 'Last login',
    location: 'Location',
    operatingLocations: 'Operating locations',
    phoneNumber: 'Main phone number',
    button: {
      edit: 'Edit data',
    },
  },
};
