import { DRAWER_WIDTH } from '../Layout.tsx';
import { SxProps, Theme } from '@mui/material';
import { colors } from '@/utils/colors.ts';

export const SideBarDrawerClasses: SxProps<Theme> = {
  width: DRAWER_WIDTH,
  background: colors.black,
  color: 'primary.white',
  position: 'sticky',
  top: 0,
  '.MuiDrawer-paper': {
    width: DRAWER_WIDTH,
    background: colors.black,
    color: 'primary.white',
  },
  '.Mui-selected': {
    background: 'none',
    color: 'primary.turquoise',
    borderLeft: '4px solid',
    borderLeftColor: 'primary.turquoise',
  },
};

export const SideBarListItemButtonStyles: SxProps<Theme> = {
  pl: 6,
  mb: 3,
};

export const SideBarListItemBadgeStyles: SxProps<Theme> = {
  ml: 1,
};
