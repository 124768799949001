import { VacancyDetailsContentTranslation } from '@/assets/locales/views/vacancyDetails/content/vacancyDetails.content.translation.en.ts';

export const vacancyDetailsContentTranslation: VacancyDetailsContentTranslation = {
  section: {
    mandatory: 'Obligatorisch',
    nonMandatory: 'Nicht-obligatorisch',
    routing: 'Weiterleitung',
  },
  accountManager: 'Inhaber der Opportunity',
  addJobProfile: 'Job-Profil hinzufügen',
  addRfp: 'RFP hinzufügen',
  affiliates: 'Tochtergesellschaften',
  applicationStartDate: 'Startdatum des Antrags',
  closureReason: 'Grund der Schließung',
  creationDate: 'Erstellungsdatum',
  currency: 'Währung',
  customer: 'Kunde',
  customerBudgetDailyRate: 'Kundenbudget-Tagessatz',
  deliveryLocation: 'Lieferungsort',
  description: 'Beschreibung',
  durationInMonths: 'Dauer (Monate)',
  expectedAvailability: 'Erwartete Verfügbarkeit pro Woche in Tagen',
  externalPartners: 'Externe Partner',
  finalRateForCandidate: 'Endgültiger Satz für Kandidat',
  jobProfile: 'Stellenprofil',
  jobTitle: 'Titel der Stelle',
  language: 'Sprache',
  localResourceManagersEmails: 'E-Mails der lokalen Ressourcenmanager',
  localResourceManagersEmailsEmpty: 'Keine Daten',
  minYearsOfExperience: 'Min. Jahre Erfahrung in einer ähnlichen Position',
  ocdEmployees: 'OCD-Mitarbeiter',
  opportunityName: 'Name der Opportunity',
  opportunityOwner: 'Inhaber der Opportunity',
  outOfOfficeHours: 'Außerhalb der Bürozeiten',
  requestType: 'Anfrage-Typ',
  requester: 'Antragsteller',
  requestingCountry: 'Anfragendes Land',
  requiredNoCandidatesSigned: 'Erforderliche Anzahl von Kandidaten',
  requiredSkills: 'Erforderliche Technologien/ Fähigkeiten',
  rfp: 'RFP',
  salesforceId: 'Salesforce Opportunity ID',
  specialClearances: 'Besondere Freigaben',
  startDate: 'Startdatum',
  status: 'Status',
  targetMargin: 'OCD Zielmarge',
  timeOnSite: '% der Zeit vor der Webseite',
};
