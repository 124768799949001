import { UserProfileExperiencesSectionTranslation } from '@/assets/locales/views/userProfile/sections/experiencesSection/userProfile.experiencesSection.translation.en.ts';

export const userProfileExperiencesSectionTranslation: UserProfileExperiencesSectionTranslation = {
  headerTitle: 'Berufserfahrung',
  content: {
    emptyItemsLabel: 'Berufserfahrung',
    button: {
      edit: 'bearbeiten',
    },
  },
  modal: {
    title: 'Berufserfahrung hinzufügen',
  },
  form: {
    actions: 'Handlungen',
    position: 'Stelle',
    company: 'Unternehmen',
    location: 'Ort',
    startDate: 'Startdatum',
    endDate: 'Endedatum',
    currentPosition: 'Derzeitige Position?',
    description: 'Beschreibung',
    button: {
      save: 'Speichern',
    },
    present: 'Derzeitige Position',
  },
};
