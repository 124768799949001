import { Component, ErrorInfo, ReactNode } from 'react';
import { FallbackUI } from './fallbackUI/FallbackUI.comp.tsx';
import { Alert } from '@/components/ui/alerts/Alert.comp.tsx';
import { DataOperationsErrorContext } from './DataOperationsErrorContext';
import { DataOperationsError } from '@/errorHandling/DataOperationsError.util.tsx';

type Props = { children: ReactNode };
type State = {
  isCorrupted: boolean;
  fallbackUIErrorMessage: string | null;
  fallbackUIErrorDetails: ReactNode | null;
  isMinorError: boolean;
  minorErrorMessage: string | null;
  isSuccess: boolean;
  successMessage: string | null;
  context: {
    handleDataOperationError: (error: DataOperationsError) => void;
    handleMinorError: (message: string) => void;
    handleOperationSuccess: (message?: string) => void;
  };
};

const IGNORED_ERRORS = ['undefined has no properties', 'is undefined'];

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCorrupted: false,
      isMinorError: false,
      isSuccess: false,
      fallbackUIErrorMessage: null,
      fallbackUIErrorDetails: null,
      minorErrorMessage: null,
      successMessage: null,
      context: {
        handleDataOperationError: this.handleDataOperationError,
        handleMinorError: this.handleMinorError,
        handleOperationSuccess: this.handleOperationSuccess,
      },
    };
  }

  componentDidCatch(error: DataOperationsError, errorInfo: ErrorInfo) {
    this.setState({
      isCorrupted: true,
      fallbackUIErrorMessage: error.message,
      fallbackUIErrorDetails: errorInfo.componentStack,
    });
  }

  handleDataOperationError = (error: DataOperationsError) => {
    this.setState({
      isCorrupted: true,
      fallbackUIErrorMessage: error.message,
      fallbackUIErrorDetails: toDataOperationsFallbackUIMessage(error),
    });
  };

  resetMinorErrorState = () => {
    this.setState({
      isMinorError: false,
      minorErrorMessage: null,
    });
  };

  handleMinorError = (message: string) => {
    if (IGNORED_ERRORS.some(error => message.toLowerCase().includes(error))) {
      return;
    }
    this.setState({
      isMinorError: true,
      minorErrorMessage: message,
    });
  };

  resetSuccessState = () => {
    this.setState({
      isSuccess: false,
      successMessage: null,
    });
  };

  handleOperationSuccess = (message?: string) => {
    this.setState({
      isSuccess: true,
      successMessage: message ?? null,
    });
  };

  render() {
    const {
      isCorrupted,
      fallbackUIErrorMessage,
      fallbackUIErrorDetails,
      isMinorError,
      minorErrorMessage,
      isSuccess,
      successMessage,
    } = this.state;
    if (isCorrupted) {
      return (
        <FallbackUI errorMessage={fallbackUIErrorMessage} errorDetails={fallbackUIErrorDetails} />
      );
    }
    return (
      <DataOperationsErrorContext.Provider value={this.state.context}>
        {this.props.children}
        {isMinorError && (
          <Alert
            message={minorErrorMessage}
            handleAlertShown={this.resetMinorErrorState}
            type="error"
          />
        )}
        {isSuccess && (
          <Alert
            message={successMessage}
            handleAlertShown={this.resetSuccessState}
            type="success"
          />
        )}
      </DataOperationsErrorContext.Provider>
    );
  }
}

const toDataOperationsFallbackUIMessage = (error: DataOperationsError): ReactNode => {
  const { timestamp, code, url, method } = error;
  return (
    <>
      {!!timestamp && <>Timestamp: {timestamp}</>}
      <br />
      {!!code && <>Code: {code}</>}
      <br />
      {!!url && <>Url: {url}</>}
      <br />
      {!!method && <>Method: {method}</>}
    </>
  );
};
