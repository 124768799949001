import { skillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.fr';
import { UserProfileProjectsSectionTranslation } from '@/assets/locales/views/userProfile/sections/projectsSection/userProfile.projectsSection.translation.en';

export const userProfileProjectsSectionTranslation: UserProfileProjectsSectionTranslation = {
  headerTitle: 'Projets',
  content: {
    emptyItemsLabel: 'projets',
    button: {
      edit: 'Modifier',
    },
  },
  form: {
    actions: 'Actions',
    anonymizedDescription: 'Description anonymisée',
    client: 'Nom du client',
    endDate: 'Date de fin',
    name: 'Nom du projet',
    type: 'Taper',
    industry: 'Industrie',
    ongoing: 'Est-ce en cours ?',
    publicDescription: 'Description standard',
    role: 'Rôle',
    roleDescription: 'Description du rôle',
    sector: 'Secteur',
    skills: 'Compétences',
    startDate: 'Date de début',
    button: {
      save: 'Enregistrer',
    },
    present: 'Présent',
    tabs: {
      main: {
        title: 'Détails du projet',
      },
      skills: {
        title: 'Compétences',
        form: {
          skillsDescriptions,
          skill: 'Compétence',
          level: 'Niveau',
          actions: 'Actions',
          button: {
            add: 'Ajouter une compétence',
            save: 'Enregistrer la compétence',
            delete: 'Supprimer la compétence',
          },
        },
      },
    },
  },
  modal: {
    title: 'Ajouter un projet',
  },
};
