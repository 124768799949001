import { GeneralErrorTranslation } from '@/assets/locales/generalError/generalError.translation.en.ts';

export const generalErrorTranslation: GeneralErrorTranslation = {
  goBack: 'Zurückgehen',
  logIn: 'Anmelden',
  title: 'Ooops! Etwas ist schief gelaufen',
  notFound: {
    message: 'Die Seite existiert nicht!',
  },
  forbidden: {
    message:
      'Sie sind nicht berechtigt, auf diese Website zuzugreifen, oder Ihre Sitzung ist abgelaufen.',
    subMessage:
      'Wenn Sie der Meinung sind, dass Sie dazu berechtigt sein sollten, bitten Sie Ihren Manager oder Vorgesetzten, Ihnen Zugang zu gewähren.',
  },
  notAuthorized: {
    message: 'Zugang verweigert',
    subMessage:
      'Sie verfügen nicht über die erforderlichen Berechtigungen, um sich am KnowMe-System anzumelden. Wenn Sie glauben, dass dies ein Fehler ist, oder wenn Sie diese Berechtigungen besitzen sollten, wenden Sie sich bitte an Orange Cyberdefense, um weitere Unterstützung zu erhalten.',
  },
  waitingForApproval: {
    message: 'Ihr Konto muss noch genehmigt werden',
    subMessage: 'Wir werden Sie benachrichtigen, sobald es genehmigt ist.',
  },
};
