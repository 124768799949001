type ErrorTranslation = {
  message: string;
  subMessage?: string;
};

export type GeneralErrorTranslation = {
  goBack: string;
  logIn: string;
  title: string;
  forbidden: ErrorTranslation;
  notAuthorized: ErrorTranslation;
  notFound: ErrorTranslation;
  waitingForApproval: ErrorTranslation;
};

export const generalErrorTranslation: GeneralErrorTranslation = {
  goBack: 'Go back',
  logIn: 'Log in',
  title: 'Ooops! Something went wrong',
  notFound: {
    message: 'The site does not exist!',
  },
  forbidden: {
    message: 'You are not authorized to access this site or your session has expired.',
    subMessage:
      'If you think that you should be authorized, ask your manager or supervisor to grant you access.',
  },
  notAuthorized: {
    message: 'Access Denied',
    subMessage:
      'You do not have the required permissions to login to the KnowMe system. If you believe this to be an error or if you should possess these permissions, please contact Orange Cyberdefense for further assistance.',
  },
  waitingForApproval: {
    message: 'Your account is pending approval',
    subMessage: 'We will notify you once it is approved.',
  },
};
