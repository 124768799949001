import React, { FormEventHandler, useEffect } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { TextFormField } from '@/components/form/TextFormField.comp.tsx';
import { SelectSearchFormField } from '@/components/form/SelectSearchFormField.comp.tsx';
import { Box } from '@mui/material';
import {
  ProfileSearchAddItemType,
  ProfileSearchFormModel,
} from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { SelectOption } from '@/components/form/model/SelectOption.model.ts';
import { CheckboxFormField } from '@/components/form/CheckboxFormField.comp.tsx';
import { colors } from '@/utils/colors.ts';
import {
  PROFILE_SEARCH_MODE,
  ProfileSearchModeEnum,
} from '@/views/profileSearch/model/profileSearchMode.model.ts';
import { HandleSubmitSearchType } from '@/views/profileSearch/util/useHandleSearchProfile.util.ts';
import { profileSearchClearFilters } from '@/views/profileSearch/util/profileSearchClearFilters.util.ts';
import Button from '@mui/material/Button';
import { ButtonSmallStyles } from '@/styles/Button.styles.ts';
import { ProfileSearchFilterType } from '@/views/profileSearch/model/ProfileSearchFilterType.model.ts';

type Props = {
  addType: { value: ProfileSearchFilterType; label: string } | null;
  formMethods: UseFormReturn<ProfileSearchFormModel>;
  handleSubmitSearch: HandleSubmitSearchType;
};

const FULL_TEXT_SEARCH: keyof ProfileSearchFormModel = 'fullTextSearch';
const FullTextSearchFields: string[] = [FULL_TEXT_SEARCH];

export const ProfileSearchForm: React.FC<Props> = props => {
  const { addType, formMethods, handleSubmitSearch } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.form');

  const isNotRegularMode =
    PROFILE_SEARCH_MODE.value && PROFILE_SEARCH_MODE.value !== ProfileSearchModeEnum.REGULAR;

  const isNotFullTextMode =
    PROFILE_SEARCH_MODE.value && PROFILE_SEARCH_MODE.value !== ProfileSearchModeEnum.FULL_TEXT;

  const handleSubmit: SubmitHandler<ProfileSearchFormModel> = async form => {
    formMethods.setValue('section', undefined);
    await handleSubmitSearch({ form, initializeValues: true });
  };

  const handleClearFilters = () => {
    profileSearchClearFilters(formMethods);
  };

  const addTypeOptions: SelectOption<string>[] = Object.keys(ProfileSearchAddItemType).map(key => ({
    id: key,
    value: t(`addTypeOption.${key}`),
  }));

  useEffect(() => {
    if (addType?.value !== undefined) {
      handleFormChangeHelper('addType');
    }
  }, [addType?.value]);

  const handleFormChangeHelper = (name: string) => {
    if (PROFILE_SEARCH_MODE.value !== undefined) {
      return;
    }
    if (FullTextSearchFields.includes(name)) {
      PROFILE_SEARCH_MODE.value = ProfileSearchModeEnum.FULL_TEXT;
    } else {
      PROFILE_SEARCH_MODE.value = ProfileSearchModeEnum.REGULAR;
    }
  };

  const handleFormChange: FormEventHandler<HTMLFormElement> = event => {
    handleFormChangeHelper((event.target as unknown as { name: string }).name);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)} onChange={handleFormChange}>
          <Grid container spacing={2} alignContent="center" alignItems="flex-start">
            <Grid item xs={9}>
              <Grid container direction="column" spacing={4}>
                {!isNotRegularMode && (
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextFormField name="firstName" label={t('firstName')} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextFormField name="lastName" label={t('lastName')} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <SelectSearchFormField
                          isSearchable={false}
                          isCreatable={false}
                          name="addType"
                          label={t('addType')}
                          options={addTypeOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {!isNotFullTextMode && (
                  <Grid item>
                    <TextFormField name={FULL_TEXT_SEARCH} label={t(FULL_TEXT_SEARCH)} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container direction="column" spacing={2} alignItems="center" sx={{ pt: 2 }}>
                <Grid item>
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{ ...ButtonSmallStyles, mr: 2 }}
                      disabled={addType !== null || PROFILE_SEARCH_MODE.value === undefined}
                    >
                      {t('button.search')}
                    </Button>
                    <Button sx={ButtonSmallStyles} onClick={handleClearFilters}>
                      {t('button.clearFilters')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={2} style={{ color: colors.black_65 }}>
                  <CheckboxFormField name="availableOnly" label={t('availableOnly')} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
