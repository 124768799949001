import { PageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.en.ts';

export const pageTitlesTranslation: PageTitlesTranslation = {
  addVacancy: 'Demander une ressource',
  aiSearch: 'AI search',
  candidateDetails: 'Détails du candidat',
  candidates: 'Candidats',
  companies: 'Entreprises',
  companyDetails: "Détails de l'entreprise",
  companyEmployee: "Détails de l'employé",
  copyVacancy: 'Copier la demande',
  editVacancy: 'Modifier la demande',
  eraCandidate: 'Nouveau candidat',
  initializeAccount: 'Initialiser votre compte',
  knowMe: 'KnowMe',
  profileSearch: 'Recherche de profils',
  projects: 'Projets',
  recruitmentBoard: 'Suivi des demandes',
  recruitmentCandidates: 'Candidats',
  reports: 'Rapports',
  settingsDictionaries: 'Dictionnaires',
  userProfile: 'Profil',
  vacancyCandidateTimeline: 'Chronologie du candidat',
  vacancyDetails: 'Détails de la demande',
  signPrivacyPolicy: 'Signer la politique de confidentialité',
  signUpdatedPrivacyPolicy: 'Signer la politique de confidentialité mise à jour',
};
