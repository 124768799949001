/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { UserPrivacyPolicyDto } from '../models';
// @ts-ignore
import type { UserPrivacyPolicySignDto } from '../models';
/**
 * UserPrivacyPolicyApi - axios parameter creator
 * @export
 */
export const UserPrivacyPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivacyPolicyStatus: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserPrivacyPolicyStatus', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/privacy-policy`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserPrivacyPolicySignDto} userPrivacyPolicySignDto User privacy policy sign request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUserPrivacyPolicy: async (userId: string, userPrivacyPolicySignDto: UserPrivacyPolicySignDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('signUserPrivacyPolicy', 'userId', userId)
            // verify required parameter 'userPrivacyPolicySignDto' is not null or undefined
            assertParamExists('signUserPrivacyPolicy', 'userPrivacyPolicySignDto', userPrivacyPolicySignDto)
            const localVarPath = `/api/v1/users/{userId}/privacy-policy`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPrivacyPolicySignDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPrivacyPolicyApi - functional programming interface
 * @export
 */
export const UserPrivacyPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPrivacyPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPrivacyPolicyStatus(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrivacyPolicyDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPrivacyPolicyStatus(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPrivacyPolicyApi.getUserPrivacyPolicyStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserPrivacyPolicySignDto} userPrivacyPolicySignDto User privacy policy sign request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUserPrivacyPolicy(userId: string, userPrivacyPolicySignDto: UserPrivacyPolicySignDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUserPrivacyPolicy(userId, userPrivacyPolicySignDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPrivacyPolicyApi.signUserPrivacyPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPrivacyPolicyApi - factory interface
 * @export
 */
export const UserPrivacyPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPrivacyPolicyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPrivacyPolicyStatus(userId: string, options?: any): AxiosPromise<UserPrivacyPolicyDto> {
            return localVarFp.getUserPrivacyPolicyStatus(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserPrivacyPolicySignDto} userPrivacyPolicySignDto User privacy policy sign request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUserPrivacyPolicy(userId: string, userPrivacyPolicySignDto: UserPrivacyPolicySignDto, options?: any): AxiosPromise<void> {
            return localVarFp.signUserPrivacyPolicy(userId, userPrivacyPolicySignDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPrivacyPolicyApi - interface
 * @export
 * @interface UserPrivacyPolicyApi
 */
export interface UserPrivacyPolicyApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivacyPolicyApiInterface
     */
    getUserPrivacyPolicyStatus(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<UserPrivacyPolicyDto>;

    /**
     * 
     * @param {string} userId 
     * @param {UserPrivacyPolicySignDto} userPrivacyPolicySignDto User privacy policy sign request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivacyPolicyApiInterface
     */
    signUserPrivacyPolicy(userId: string, userPrivacyPolicySignDto: UserPrivacyPolicySignDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserPrivacyPolicyApi - object-oriented interface
 * @export
 * @class UserPrivacyPolicyApi
 * @extends {BaseAPI}
 */
export class UserPrivacyPolicyApi extends BaseAPI implements UserPrivacyPolicyApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivacyPolicyApi
     */
    public getUserPrivacyPolicyStatus(userId: string, options?: RawAxiosRequestConfig) {
        return UserPrivacyPolicyApiFp(this.configuration).getUserPrivacyPolicyStatus(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UserPrivacyPolicySignDto} userPrivacyPolicySignDto User privacy policy sign request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivacyPolicyApi
     */
    public signUserPrivacyPolicy(userId: string, userPrivacyPolicySignDto: UserPrivacyPolicySignDto, options?: RawAxiosRequestConfig) {
        return UserPrivacyPolicyApiFp(this.configuration).signUserPrivacyPolicy(userId, userPrivacyPolicySignDto, options).then((request) => request(this.axios, this.basePath));
    }
}

