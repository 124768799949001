import { SectionTranslationType } from '@/assets/locales/views/userProfile/sections/SectionTranslation.type.ts';
import { UserProfileExperienceDto } from '@/api/generated-user-profile';

export type UserProfileExperiencesSectionTranslation = SectionTranslationType<
  Omit<UserProfileExperienceDto, 'userProfileId' | 'experienceId' | 'contentLanguage'>
>;

export const userProfileExperiencesSectionTranslation: UserProfileExperiencesSectionTranslation = {
  headerTitle: 'Work Experience',
  content: {
    emptyItemsLabel: 'work experiences',
    button: {
      edit: 'Edit',
    },
  },
  modal: {
    title: 'Add experience',
  },
  form: {
    actions: 'Actions',
    position: 'Position',
    company: 'Company',
    location: 'Location',
    startDate: 'Start date',
    endDate: 'End date',
    currentPosition: 'Current position?',
    description: 'Description',
    button: {
      save: 'Save',
    },
    present: 'Current position',
  },
};
