import { EditDeleteTranslation } from '@/assets/locales/views/userProfile/editDelete.translation.en.ts';

export const editDeleteTranslation: EditDeleteTranslation = {
  edit: 'Modifier',
  delete: 'Supprimer',
  modal: {
    title: 'Veuillez confirmer',
    description: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    cancel: 'Annuler',
    confirm: 'Confirmer',
  },
};
