import { SxProps, Theme } from '@mui/material';

export const ViewWrapperStyles: SxProps<Theme> = {
  py: 6,
  width: '100%',
};

export const ViewWrapperPaperStyles: SxProps<Theme> = {
  padding: '3% 50px',
};
