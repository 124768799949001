import React, { LazyExoticComponent } from 'react';
import { RoleTypeDto } from '@/api/generated';
import { RouteStateModel } from '@/model/RouteState.model.ts';

export enum RouteEnum {
  ROOT = '/',
  LOGIN = '/login',

  AI_SEARCH = '/ai-search',
  ADD_VACANCY = '/request-resource',
  CANDIDATES = '/candidates',
  CANDIDATES_ID = '/candidates/:candidateId',
  COMPANIES = '/companies',
  COMPANIES_ID = '/companies/:id',
  COMPANY_EMPLOYEE = '/company/:companyId/employee/:employeeId',
  DICTIONARIES = '/dictionaries',
  EDIT_VACANCY = '/edit-resource/:vacancyId',
  MY_PROFILE = '/my-profile',
  PROFILE_SEARCH = '/search',
  PROJECTS = '/projects',
  PROJECT_DETAILS = '/projects/:id',
  RECRUITMENT_BOARD = '/request-tracker/',
  REPORTS = '/reports',
  USER_PROFILE = '/user-profile',
  VACANCIES_CANDIDATE = '/vacancies/candidates/:candidateId',
  VACANCY_ID = '/vacancies/:id',

  FORBIDDEN = '/forbidden',
  LOGIN_SUCCESSFUL = '/login-successful',
  NOT_AUTHORIZED = '/not-authorized',
  WAITING_FOR_APPROVAL = '/waiting-for-approval',
  NOT_FOUND = '/not-found',
  USERS_INITIALIZE = '/users/initialize',
  USERS_SIGN_PRIVACY_POLICY = '/users/sign-privacy-policy',
}

export interface RouteModel {
  path: RouteEnum;
  component:
    | React.FC<{ route: RouteStateModel }>
    | LazyExoticComponent<React.FC<{ route: RouteStateModel }>>;
  roles: RoleTypeDto[];
}
