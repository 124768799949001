/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const UserProfileSectionDto = {
    Skill: 'SKILL',
    Certificate: 'CERTIFICATE',
    Project: 'PROJECT',
    Language: 'LANGUAGE',
    Experience: 'EXPERIENCE',
    Course: 'COURSE',
    Award: 'AWARD'
} as const;

export type UserProfileSectionDto = typeof UserProfileSectionDto[keyof typeof UserProfileSectionDto];



