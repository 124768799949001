import { useRef } from 'react';
import axios, { AxiosResponse } from 'axios';

import { useGetErrorResponseInterceptor } from './useGetErrorResponseInterceptor.util';
import { getCsrfToken } from '@/api/candidates-api';
import { HTTP_STATUS } from './ErrorCode.model';
import { HTTP_HEADER } from './httpHeaders.model';
import { HttpMethod } from './HttpMethod.model';

const useSuccessfulResponseHandler = () => {
  return (response: AxiosResponse): AxiosResponse => {
    if (response.status === HTTP_STATUS.REDIRECT) {
      delete response.headers[HTTP_HEADER['X-CSRF-Token']];
    }

    return response;
  };
};

type Return = ReturnType<typeof axios.create>;

export const useAxiosInstance = (): Return => {
  const isErrorInterceptorAddedRef = useRef(false);
  const errorResponseInterceptor = useGetErrorResponseInterceptor();
  const successfulResponseHandler = useSuccessfulResponseHandler();

  const axiosInstance = axios.create();

  if (!isErrorInterceptorAddedRef.current) {
    isErrorInterceptorAddedRef.current = true;
    axiosInstance.interceptors.response.use(successfulResponseHandler, errorResponseInterceptor);
  }

  axiosInstance.interceptors.request.use(
    async config => {
      if (
        config.method &&
        [HttpMethod.PUT, HttpMethod.POST, HttpMethod.PATCH, HttpMethod.DELETE].some(
          method => method === config.method,
        )
      ) {
        config.headers[HTTP_HEADER['X-CSRF-Token']] = await getCsrfToken();
      } else {
        delete config.headers[HTTP_HEADER['X-CSRF-Token']];
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};
