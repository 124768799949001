import {
  Configuration,
  SearchUserProfileApiFactory,
  UserProfileApiFactory,
  UserProfileAvatarApiFactory,
  UserProfileAwardApiFactory,
  UserProfileCertificateApiFactory,
  UserProfileCourseApiFactory,
  UserProfileCvApiFactory,
  UserProfileEducationApiFactory,
  UserProfileExperienceApiFactory,
  UserProfileLanguageApiFactory,
  UserProfileProjectApiFactory,
  UserProfileSkillApiFactory,
} from '@/api/generated-user-profile';
import { useMemo } from 'react';
import { useAxiosInstance } from './useAxiosInstance.util';
import CONFIG from '@/config/config';

interface Return {
  searchUserProfileApi: ReturnType<typeof SearchUserProfileApiFactory>;
  userProfileApi: ReturnType<typeof UserProfileApiFactory>;
  userProfileAvatarApi: ReturnType<typeof UserProfileAvatarApiFactory>;
  userProfileAwardApi: ReturnType<typeof UserProfileAwardApiFactory>;
  userProfileCertificateApi: ReturnType<typeof UserProfileCertificateApiFactory>;
  userProfileCourseApi: ReturnType<typeof UserProfileCourseApiFactory>;
  userProfileCvApi: ReturnType<typeof UserProfileCvApiFactory>;
  userProfileEducationApi: ReturnType<typeof UserProfileEducationApiFactory>;
  userProfileExperienceApi: ReturnType<typeof UserProfileExperienceApiFactory>;
  userProfileLanguageApi: ReturnType<typeof UserProfileLanguageApiFactory>;
  userProfileProjectApi: ReturnType<typeof UserProfileProjectApiFactory>;
  userProfileSkillApi: ReturnType<typeof UserProfileSkillApiFactory>;
}

export const useUserProfileApiClient = (): Return => {
  const basePath = CONFIG.HOST;
  const configuration = getConfiguration(basePath);
  const axiosInstance = useAxiosInstance();

  return useMemo(() => {
    return {
      searchUserProfileApi: SearchUserProfileApiFactory(configuration, undefined, axiosInstance),
      userProfileApi: UserProfileApiFactory(configuration, undefined, axiosInstance),
      userProfileCertificateApi: UserProfileCertificateApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
      userProfileCourseApi: UserProfileCourseApiFactory(configuration, undefined, axiosInstance),
      userProfileCvApi: UserProfileCvApiFactory(configuration, undefined, axiosInstance),
      userProfileAvatarApi: UserProfileAvatarApiFactory(configuration, undefined, axiosInstance),
      userProfileAwardApi: UserProfileAwardApiFactory(configuration, undefined, axiosInstance),
      userProfileEducationApi: UserProfileEducationApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
      userProfileExperienceApi: UserProfileExperienceApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
      userProfileLanguageApi: UserProfileLanguageApiFactory(
        configuration,
        undefined,
        axiosInstance,
      ),
      userProfileProjectApi: UserProfileProjectApiFactory(configuration, undefined, axiosInstance),
      userProfileSkillApi: UserProfileSkillApiFactory(configuration, undefined, axiosInstance),
    };
  }, [axiosInstance]);
};

const getConfiguration = (basePath: string): Configuration => ({
  basePath,
  isJsonMime(_mime: string): boolean {
    return true;
  },
});
