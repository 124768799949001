import { DictionaryTypeDto, OcdReportTypeDto, ReportRangeTypeDto } from '@/api/generated';

import { DictionaryTranslations } from '@/assets/locales/dictionaries/dictionaries.translation.en.ts';

export const dictionariesTranslation: DictionaryTranslations = {
  dictionaryType: {
    [DictionaryTypeDto.Certificate]: 'Zertifikat',
    [DictionaryTypeDto.Language]: 'Sprache',
    [DictionaryTypeDto.Currency]: 'Währung',
    [DictionaryTypeDto.Skill]: 'Fähigkeit',
    [DictionaryTypeDto.Location]: 'Standort',
    [DictionaryTypeDto.Sector]: 'Sektor',
  },
  reportType: {
    [OcdReportTypeDto.AverageDayRateAndMargin]: 'Durchschnittlicher Tagessatz und Marge',
    [OcdReportTypeDto.Certificates]: 'Zertifikate',
    [OcdReportTypeDto.CvDownloads]: 'Downloads von Dateien fortsetzen',
    [OcdReportTypeDto.LandscapeOfTechnology]: 'Landschaft der Technik',
    [OcdReportTypeDto.NumberOfRequestsPerPartner]: 'Anzahl der Anfragen pro Partner',
    [OcdReportTypeDto.TimeToSignCandidate]: 'Zeit für die Unterzeichnung des ersten Kandidaten',
    [OcdReportTypeDto.TimeToFindCandidate]: 'Zeit für die Suche nach dem ersten Kandidaten',
    [OcdReportTypeDto.TopReasonForLoosing]: 'Wichtigster Grund für den Verlust',
    [OcdReportTypeDto.WinLossRatio]: 'Gewinn-Verlust-Verhältnis',
  },
  reportRange: {
    [ReportRangeTypeDto.Global]: 'Global',
    [ReportRangeTypeDto.Country]: 'Land',
  },
};
