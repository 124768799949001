import React, { useCallback } from 'react';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { TextError } from '@/components/form/TextError.comp.tsx';
import FormControl from '@mui/material/FormControl';
import { FormPropsModel } from '@/components/form/model/FormProps.model.ts';
import { Slider } from '@mui/material';
import { Mark } from '@mui/base';
import { FormFieldLabel } from '@/components/form/FormFieldLabel.comp.tsx';
import { isFormFieldRequired } from '@/utils/form/isFormFieldRequired.util.ts';
import { useFormProviderKnowMePropsContext } from '@/components/form/FormProviderKnowMe.comp.tsx';

interface Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends FormPropsModel<TName> {
  defaultValue?: number;
  step?: number;
  marks?: boolean | Mark[];
  max?: number;
  min?: number;
}

export const SliderFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
): React.ReactElement<Props<TFieldValues, TName>> => {
  const { name, label, disabled, ...rest } = props;
  const { control } = useFormContext<TFieldValues>();
  const { validation } = useFormProviderKnowMePropsContext();

  const handleChangeProp = useCallback(
    (handleChangeFn: (value: number | number[]) => void) =>
      (_event: unknown, val: number | number[]) => {
        handleChangeFn(val);
      },
    [],
  );

  const render: ControllerProps<TFieldValues, TName>['render'] = ({
    field: { ref, onChange: handleChange, value },
    fieldState: { error },
  }) => {
    const isRequired = isFormFieldRequired(name, validation);

    return (
      <FormControl fullWidth style={{ paddingTop: 10 }}>
        <FormFieldLabel
          label={label}
          isRequired={isRequired}
          isError={Boolean(error)}
          sx={{ top: -14 }}
        />
        <Slider
          {...rest}
          name={name}
          value={value}
          disabled={disabled}
          onChange={handleChangeProp(handleChange)}
          ref={ref}
        />
        <TextError error={error} />
      </FormControl>
    );
  };

  return (
    <>
      <Controller control={control} name={name} render={render} />
    </>
  );
};
