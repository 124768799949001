import { UserProfileCertificatesSectionTranslation } from '@/assets/locales/views/userProfile/sections/certificatesSection/userProfile.certificatesSection.translation.en.ts';

export const userProfileCertificatesSectionTranslation: UserProfileCertificatesSectionTranslation =
  {
    headerTitle: 'Certificats',
    content: {
      emptyItemsLabel: 'certificats',
      button: {
        edit: 'Modifier',
      },
    },
    modal: {
      title: 'Ajouter un certificat',
    },
    form: {
      actions: 'Actions',
      certificate: 'Certificat',
      credentialId: "Identifiant d'identification",
      credentialUrl: "URL des informations d'identification",
      expirationDate: "Date d'expiration",
      issueDate: "Date d'émission",
      nonDictionaryCertificateValue: 'Valeur de certificat non répertoriée',
      nonExpiring: "Ce certificat n'expire pas",
      organization: 'Organisation',
      button: {
        save: 'Enregistrer',
      },
      present: "Sans date d'expiration",
    },
  };
