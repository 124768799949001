export const variables = {
  basicTransition: 'all .2s ease-in-out',

  // box-shadows
  arrowOutline: '0 2px 7px 0 rgba(162, 170, 173, 0.4)',
  divider: '0 3px 24px 0 rgba(208, 211, 212, 0.4)',
  boxShadowMenu: '0 3px 24px 0 rgba(208, 211, 212, 0.4)',
  boxShadowLabel: '0 5px 23px 0 #d0d3d4',

  // borders
  basicBorder: '1px solid rgba(208, 211, 212, 0.7)'
};
