import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { emptyFunction } from '@/utils/emptyFunction.util.ts';
import { PageTitlesTranslation } from '@/assets/locales/pageTitles/pageTitles.translation.en.ts';

export type PageDetailsModel = {
  title: keyof PageTitlesTranslation;
};

export interface PageContextProps {
  pageDetails: PageDetailsModel;
  setPageDetails: Dispatch<SetStateAction<PageDetailsModel>>;
}

export const PageContextDefaultValues: PageContextProps = {
  pageDetails: { title: 'knowMe' },
  setPageDetails: emptyFunction,
};
export const PageContext = createContext<PageContextProps>(PageContextDefaultValues);

export const usePageContext = (): PageContextProps => {
  return useContext<PageContextProps>(PageContext);
};
