import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, NAMESPACE } from '@/assets/locales/en.ts';
import { de } from '@/assets/locales/de.ts';
import { fr } from '@/assets/locales/fr.ts';

const resources = {
  en,
  de,
  fr,
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<never>({
    resources,
    fallbackLng: 'en',
    ns: [NAMESPACE],
    defaultNS: NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
  });
