import React, { useEffect, useState } from 'react';
import { useGetVacancyDetailsForSearch } from '@/views/profileSearch/util/useGetVacancyDetailsForSearch.util.ts';
import { SearchParamsEnum } from '@/model/SearchParams.enum.ts';
import { UseFormReturn } from 'react-hook-form';
import { HandleSubmitSearchType } from '@/views/profileSearch/util/useHandleSearchProfile.util.ts';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { useSearchParams } from 'react-router-dom';
import {
  PROFILE_SEARCH_MODE,
  ProfileSearchModeEnum,
} from '@/views/profileSearch/model/profileSearchMode.model.ts';
import { VacancyDetailsForSearch } from '@/views/profileSearch/model/vacancyDetailsForSearch.model.ts';
import { VacancyDetailsForSearchDto } from '@/api/generated';
import {
  initialProfileSearchResults,
  ProfileSearchResultsModel,
} from '@/views/profileSearch/model/ProfileSearchResults.model.ts';

type Props = {
  formMethods: UseFormReturn<ProfileSearchFormModel>;
  onSubmitSearch: HandleSubmitSearchType;
};

export const ProfileSearchController: React.FC<Props> = props => {
  const { formMethods, onSubmitSearch: handleSubmitSearch } = props;
  const [searchParams] = useSearchParams();
  const vacancyIdParam = searchParams.get(SearchParamsEnum.VACANCY_ID);
  const [initialSearch, setInitialSearch] = useState(true);

  const setFiltersForVacancy = async (data: VacancyDetailsForSearchDto | null | undefined) => {
    if (!data) {
      return;
    }
    if (initialSearch) {
      formMethods.setValue(
        'skills',
        data.skillsIds.map(item => ({
          value: item,
          level: 0,
          experienceInMonths: 0,
        })),
      );
      formMethods.setValue(
        'languages',
        data.languagesIds.map(item => ({ value: item })),
      );
      setInitialSearch(false);
    }
    await handleSubmitSearch({ form: formMethods.getValues() });
  };

  useGetVacancyDetailsForSearch({
    vacancyId: vacancyIdParam,
    onSuccess: setFiltersForVacancy,
  });

  useEffect(() => {
    return () => {
      PROFILE_SEARCH_MODE.value = VacancyDetailsForSearch.value
        ? ProfileSearchModeEnum.REGULAR
        : undefined;
      VacancyDetailsForSearch.value = null;
      ProfileSearchResultsModel.value = structuredClone(initialProfileSearchResults);
    };
  }, []);

  return null;
};
