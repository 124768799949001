import React, { ReactElement, useCallback } from 'react';
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { FormPropsModel } from './model/FormProps.model';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TextError } from './TextError.comp';
import { isFormFieldRequired } from '@/utils/form/isFormFieldRequired.util.ts';
import { useFormProviderKnowMePropsContext } from '@/components/form/FormProviderKnowMe.comp.tsx';

interface Props<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>
  extends FormPropsModel<TName> {
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
  maxLength?: number;
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const TextFormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
): ReactElement<Props<TFieldValues, TName>> => {
  const { control } = useFormContext<TFieldValues>();
  const {
    StartIcon,
    EndIcon,
    fullWidth,
    label,
    maxLength,
    maxRows,
    minRows,
    name,
    onChange,
    placeholder,
    ...rest
  } = props;
  const { validation } = useFormProviderKnowMePropsContext();
  const render: ControllerProps<TFieldValues, TName>['render'] = useCallback(
    ({ field, fieldState: { error } }) => {
      const isRequired = isFormFieldRequired(field.name, validation);

      return (
        <FormControl fullWidth={fullWidth ?? true}>
          <TextField
            {...field}
            {...rest}
            {...(label && {
              InputLabelProps: {
                disableAnimation: false,
                shrink: true,
                required: isRequired,
              },
              label,
              sx: {
                ...rest.sx,
                fieldset: {
                  background: 'none',
                },
              },
            })}
            onChange={event => {
              if (onChange) {
                onChange(event);
              } else {
                field.onChange(event);
              }
            }}
            key={name}
            variant="standard"
            hiddenLabel={!label}
            error={Boolean(error)}
            minRows={minRows}
            maxRows={maxRows ?? (minRows ?? 0) + 2}
            InputProps={{
              startAdornment: !StartIcon ? undefined : (
                <InputAdornment position="start">{StartIcon}</InputAdornment>
              ),
              endAdornment: !EndIcon ? undefined : (
                <InputAdornment position="end">{EndIcon}</InputAdornment>
              ),
            }}
            size={'small'}
            placeholder={placeholder ?? String(label)}
          />
          <TextError error={error} />
        </FormControl>
      );
    },
    [
      validation,
      fullWidth,
      rest,
      label,
      name,
      minRows,
      maxRows,
      StartIcon,
      EndIcon,
      placeholder,
      onChange,
    ],
  );

  return (
    <>
      <Controller<TFieldValues, TName>
        control={control}
        name={name}
        rules={{
          maxLength,
        }}
        render={render}
      />
    </>
  );
};
