import { UserProfileSearchRequestDto, UserProfileSectionDto } from '@/api/generated-user-profile';

export enum ProfileSearchAddItemType {
  'skills' = 'skills',
  'certificates' = 'certificates',
  'languages' = 'languages',
}

export type ProfileSearchFormModel = UserProfileSearchRequestDto & {
  addType: { value: ProfileSearchAddItemType; label: string } | null;
  fullTextSearch: string | undefined;
  section: UserProfileSectionDto | undefined;
};
