import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { GeneralError } from '../GeneralError.view';

const NotAuthorized = () => {
  const { t } = useTranslationWithPrefix('generalError.notAuthorized');

  return <GeneralError message={t('message')} subMessage={t('subMessage')} />;
};

export default NotAuthorized;
