/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { GetFilteredSortedProjectsDto } from '../models';
// @ts-ignore
import type { VacancyProjectDetailsDto } from '../models';
/**
 * VacancyProjectsListApi - axios parameter creator
 * @export
 */
export const VacancyProjectsListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get filtered & sorted projects
         * @param {GetFilteredSortedProjectsDto} [getFilteredSortedProjectsDto] Get filtered and sorted projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSortedVacancyProjects: async (getFilteredSortedProjectsDto?: GetFilteredSortedProjectsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vacancyProjects/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilteredSortedProjectsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VacancyProjectsListApi - functional programming interface
 * @export
 */
export const VacancyProjectsListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VacancyProjectsListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get filtered & sorted projects
         * @param {GetFilteredSortedProjectsDto} [getFilteredSortedProjectsDto] Get filtered and sorted projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto?: GetFilteredSortedProjectsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VacancyProjectDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VacancyProjectsListApi.getFilteredSortedVacancyProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VacancyProjectsListApi - factory interface
 * @export
 */
export const VacancyProjectsListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VacancyProjectsListApiFp(configuration)
    return {
        /**
         * 
         * @summary Get filtered & sorted projects
         * @param {GetFilteredSortedProjectsDto} [getFilteredSortedProjectsDto] Get filtered and sorted projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto?: GetFilteredSortedProjectsDto, options?: any): AxiosPromise<Array<VacancyProjectDetailsDto>> {
            return localVarFp.getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VacancyProjectsListApi - interface
 * @export
 * @interface VacancyProjectsListApi
 */
export interface VacancyProjectsListApiInterface {
    /**
     * 
     * @summary Get filtered & sorted projects
     * @param {GetFilteredSortedProjectsDto} [getFilteredSortedProjectsDto] Get filtered and sorted projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsListApiInterface
     */
    getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto?: GetFilteredSortedProjectsDto, options?: RawAxiosRequestConfig): AxiosPromise<Array<VacancyProjectDetailsDto>>;

}

/**
 * VacancyProjectsListApi - object-oriented interface
 * @export
 * @class VacancyProjectsListApi
 * @extends {BaseAPI}
 */
export class VacancyProjectsListApi extends BaseAPI implements VacancyProjectsListApiInterface {
    /**
     * 
     * @summary Get filtered & sorted projects
     * @param {GetFilteredSortedProjectsDto} [getFilteredSortedProjectsDto] Get filtered and sorted projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VacancyProjectsListApi
     */
    public getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto?: GetFilteredSortedProjectsDto, options?: RawAxiosRequestConfig) {
        return VacancyProjectsListApiFp(this.configuration).getFilteredSortedVacancyProjects(getFilteredSortedProjectsDto, options).then((request) => request(this.axios, this.basePath));
    }
}

