import React from 'react';
import { ViewWrapper } from '@/components/viewWrapper/ViewWrapper.comp.tsx';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { Box } from '@mui/material';
import { profileSearchStyles } from '@/views/profileSearch/styles/profileSearch.styles.ts';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { UseFormReturn } from 'react-hook-form';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { ProfileSearchResultsModel } from '@/views/profileSearch/model/ProfileSearchResults.model.ts';
import {
  PROFILE_SEARCH_MODE,
  ProfileSearchModeEnum,
} from '@/views/profileSearch/model/profileSearchMode.model.ts';
import { profileSearchClearFilters } from '@/views/profileSearch/util/profileSearchClearFilters.util.ts';
import { useDictionariesContext } from '@/contexts/dictionaries/Dictionaries.context.ts';
import { HandleSubmitSearchType } from '@/views/profileSearch/util/useHandleSearchProfile.util.ts';

type Props = {
  formMethods: UseFormReturn<ProfileSearchFormModel>;
  onSubmitSearch: HandleSubmitSearchType;
};

export const ProfileFullTextSearchSkillsPropositions: React.FC<Props> = props => {
  const { formMethods, onSubmitSearch } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.fullText.skillsPropositions');
  const classes = profileSearchStyles();
  const { value: valueResults } = ProfileSearchResultsModel;
  const { skills } = useDictionariesContext();

  if (valueResults.skills.length === 0) {
    return null;
  }

  const handleSelectSkillClick = (skill: string) => async () => {
    const skillIndex = skills.findIndex(x => x.value === skill);
    if (skillIndex < 0) {
      return;
    }
    profileSearchClearFilters(formMethods);
    PROFILE_SEARCH_MODE.value = ProfileSearchModeEnum.REGULAR;
    formMethods.setValue('skills', [
      { value: skills[skillIndex].id, level: 0, experienceInMonths: 0 },
    ]);
    const form = formMethods.getValues();
    await onSubmitSearch({ form });
  };

  return (
    <>
      <ViewWrapper sx={{ pt: 0 }}>
        <Box className={classes.fullTextCategoriesHeader}>{t('header')}</Box>
        <Grid container spacing={1}>
          {valueResults.skills.map(item => (
            <Grid item key={item.skillId}>
              <Chip
                className={classes.skillsPropositionsItem}
                onClick={handleSelectSkillClick(item.value)}
                label={item.value}
                clickable
                color="primary"
              />
            </Grid>
          ))}
        </Grid>
      </ViewWrapper>
    </>
  );
};
