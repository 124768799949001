import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';
import { UserProfileSectionDto } from '@/api/generated-user-profile';
import { skillsDescriptions } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.fr';
import { ProfileSearchTranslation } from '@/assets/locales/views/profileSearch/profileSearch.translation.en';

export const profileSearchTranslation: ProfileSearchTranslation = {
  content: {
    vacancyTitle: 'Trouver des candidats pour {{vacancy}}',
    activeSkillsFilters: 'Filtres de compétences actifs',
    activeCertificateFilters: 'Filtres de certificats actifs',
    activeLanguageFilters: 'Filtres de langues actifs',
    searchResults: 'Résultats de la recherche',
    searching: 'Recherche en cours...',
    noResults: "La recherche n'a donné aucun résultat",
    results: {
      assignedToVacancy: 'Assigné',
      assignToVacancy: 'Assigner au poste',
      menu: {
        open: 'Ouvrir',
        openInNewTab: 'Ouvrir dans un nouvel onglet',
      },
    },
    button: {
      addAnother: 'Ajouter un autre',
    },
  },
  form: {
    firstName: 'Prénom',
    lastName: 'Nom',
    addType: 'Ajouter un filtre',
    availableOnly: 'Disponible uniquement',
    fullTextSearch: 'Rechercher dans les compétences, projets, certificats, langues, etc.',
    addTypeOption: {
      [ProfileSearchAddItemType.skills]: 'Compétence',
      [ProfileSearchAddItemType.certificates]: 'Certificat',
      [ProfileSearchAddItemType.languages]: 'Langue',
    },
    addSkillFilter: {
      skill: 'Compétence',
      level: 'Niveau',
      experienceInYears: 'Expérience en années',
      skillsDescriptions,
    },
    addCertificateFilter: {
      certificate: 'Certificat',
    },
    addLanguageFilter: {
      language: 'Langue',
    },
    button: {
      search: 'Rechercher',
      clearFilters: 'Effacer les filtres',
    },
  },
  fullText: {
    categories: {
      header:
        'Voici une liste de résultats pour votre phrase de recherche dans toutes les catégories. Veuillez sélectionner la catégorie que vous souhaitez explorer pour des résultats plus spécifiques.',
      section: {
        [UserProfileSectionDto.Award]: 'Récompenses',
        [UserProfileSectionDto.Course]: 'Cours',
        [UserProfileSectionDto.Certificate]: 'Certificats',
        [UserProfileSectionDto.Project]: 'Projets',
        [UserProfileSectionDto.Experience]: 'Expérience professionnelle',
        [UserProfileSectionDto.Language]: 'Langues',
        [UserProfileSectionDto.Skill]: 'Compétences',
      },
    },
    skillsPropositions: {
      header:
        'Voici des compétences existantes similaires à votre phrase de recherche. Veuillez sélectionner la compétence que vous souhaitez explorer pour des résultats plus spécifiques.',
    },
  },
};
