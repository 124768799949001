import { VacancyDetailsFooterTranslation } from '@/assets/locales/views/vacancyDetails/footer/vacancyDetails.footer.translation.en.ts';

export const vacancyDetailsFooterTranslation: VacancyDetailsFooterTranslation = {
  dialog: {
    archive: {
      message: 'Sind Sie sicher, dass Sie diese Anfrage archivieren möchten?',
      button: 'Archivierungsanfrage',
    },
    close: {
      message: 'Sind Sie sicher, dass Sie diese Anfrage schließen wollen?',
      button: 'Anfrage schließen',
    },
    onHold: {
      message: 'Sind Sie sicher, dass Sie diese Anfrage zurückstellen wollen?',
      button: 'Anfrage in die Warteschleife stellen',
    },
    open: {
      message: 'Sind Sie sicher, dass Sie diesen Antrag erneut stellen wollen?',
      button: 'Antrag auf Wiedereröffnung',
    },
  },
  button: {
    archive: 'Archivierungsanfrage',
    close: 'Anfrage schließen',
    reopen: 'Antrag auf Wiedereröffnung',
    onHold: 'Anfrage in die Warteschleife stellen',
  },
};
