export interface UserInitializeTranslation {
  form: {
    buttons: {
      submit: string;
    };
    tooltips: {
      agreeToPrivacyPolicy: string;
    };
    fields: {
      company: string;
      location: string;
      phone: string;
      agreeToPrivacyPolicy1: string;
      agreeToPrivacyPolicy2: string;
      agreeToPrivacyPolicy3: string;
    };
    error: {
      initializingUserAccount: string;
    };
  };
}

export const userInitialize: UserInitializeTranslation = {
  form: {
    buttons: {
      submit: 'Submit',
    },
    tooltips: {
      agreeToPrivacyPolicy: 'Please open the privacy policy link and click the checkbox to submit the form',
    },
    fields: {
      company: 'Company',
      location: 'Location',
      phone: 'Phone',
      agreeToPrivacyPolicy1: 'By entering this system, you agree to the Orange Cyberdefense KnowMe privacy policy which can be found here: ',
      agreeToPrivacyPolicy2: 'click here',
      agreeToPrivacyPolicy3: 'Please contact your local SPOC should you have any questions on how Orange Cyberdefense handles data privacy for the KnowMe portal',
    },
    error: {
      initializingUserAccount: 'Error while initializing the user account. Please try again later.',
    },
  },
};
