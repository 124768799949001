/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CompanyEmployeeDetailsDto } from '../models';
// @ts-ignore
import type { UpdateEmployeeRequestDto } from '../models';
/**
 * UsersCompanyDataApi - axios parameter creator
 * @export
 */
export const UsersCompanyDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanyData: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserCompanyData', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/company-data`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyEmployee: async (userId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCompanyEmployee', 'userId', userId)
            // verify required parameter 'updateEmployeeRequestDto' is not null or undefined
            assertParamExists('updateCompanyEmployee', 'updateEmployeeRequestDto', updateEmployeeRequestDto)
            const localVarPath = `/api/v1/users/{userId}/company-data`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersCompanyDataApi - functional programming interface
 * @export
 */
export const UsersCompanyDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersCompanyDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCompanyData(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEmployeeDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCompanyData(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersCompanyDataApi.getUserCompanyData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyEmployee(userId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyEmployee(userId, updateEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersCompanyDataApi.updateCompanyEmployee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersCompanyDataApi - factory interface
 * @export
 */
export const UsersCompanyDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersCompanyDataApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCompanyData(userId: string, options?: any): AxiosPromise<CompanyEmployeeDetailsDto> {
            return localVarFp.getUserCompanyData(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyEmployee(userId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompanyEmployee(userId, updateEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersCompanyDataApi - interface
 * @export
 * @interface UsersCompanyDataApi
 */
export interface UsersCompanyDataApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersCompanyDataApiInterface
     */
    getUserCompanyData(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<CompanyEmployeeDetailsDto>;

    /**
     * 
     * @param {string} userId 
     * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersCompanyDataApiInterface
     */
    updateCompanyEmployee(userId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UsersCompanyDataApi - object-oriented interface
 * @export
 * @class UsersCompanyDataApi
 * @extends {BaseAPI}
 */
export class UsersCompanyDataApi extends BaseAPI implements UsersCompanyDataApiInterface {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersCompanyDataApi
     */
    public getUserCompanyData(userId: string, options?: RawAxiosRequestConfig) {
        return UsersCompanyDataApiFp(this.configuration).getUserCompanyData(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UpdateEmployeeRequestDto} updateEmployeeRequestDto Company employee update request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersCompanyDataApi
     */
    public updateCompanyEmployee(userId: string, updateEmployeeRequestDto: UpdateEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return UsersCompanyDataApiFp(this.configuration).updateCompanyEmployee(userId, updateEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

