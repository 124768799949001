export type VacancyDetailsHeaderTranslation = {
  button: {
    copy: string;
    edit: string;
    addCandidate: string;
    findCandidates: string;
  };
};

export const vacancyDetailsHeaderTranslation: VacancyDetailsHeaderTranslation = {
  button: {
    copy: 'Copy request',
    edit: 'Edit request',
    addCandidate: 'Add candidate',
    findCandidates: 'Find candidates',
  },
};
