import { initialProfileSearchRequestModel } from '@/views/profileSearch/model/InitialProfileSearchRequest.model.ts';
import { PROFILE_SEARCH_MODE } from '@/views/profileSearch/model/profileSearchMode.model.ts';
import {
  initialProfileSearchResults,
  ProfileSearchResultsModel,
} from '@/views/profileSearch/model/ProfileSearchResults.model.ts';
import { UseFormReturn } from 'react-hook-form';
import { ProfileSearchFormModel } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';

export const profileSearchClearFilters = (formMethods: UseFormReturn<ProfileSearchFormModel>) => {
  formMethods.reset(initialProfileSearchRequestModel);
  PROFILE_SEARCH_MODE.value = undefined;
  ProfileSearchResultsModel.value = initialProfileSearchResults;
};
