/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CompaniesListDto } from '../models';
// @ts-ignore
import type { CompanyEmployeeListDto } from '../models';
// @ts-ignore
import type { DictionaryEntryDto } from '../models';
// @ts-ignore
import type { DictionaryEnumDto } from '../models';
// @ts-ignore
import type { DictionaryTypeDto } from '../models';
// @ts-ignore
import type { ExtendedDictionaryEntryDto } from '../models';
// @ts-ignore
import type { FindCompanyEmployeeRequestDto } from '../models';
// @ts-ignore
import type { PartialUpdateDictionaryDto } from '../models';
// @ts-ignore
import type { RoleTypeDto } from '../models';
// @ts-ignore
import type { UsersShortsDto } from '../models';
// @ts-ignore
import type { VacancyCompaniesRequestDto } from '../models';
/**
 * DictionariesApi - axios parameter creator
 * @export
 */
export const DictionariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new dictionary entry
         * @param {DictionaryEntryDto} dictionaryEntryDto Data needed to create dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictionaryEntry: async (dictionaryEntryDto: DictionaryEntryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryEntryDto' is not null or undefined
            assertParamExists('createDictionaryEntry', 'dictionaryEntryDto', dictionaryEntryDto)
            const localVarPath = `/api/v1/dictionaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryEntryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Companies dictionary list
         * @param {VacancyCompaniesRequestDto} vacancyCompaniesRequestDto Find companies request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesList: async (vacancyCompaniesRequestDto: VacancyCompaniesRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vacancyCompaniesRequestDto' is not null or undefined
            assertParamExists('getCompaniesList', 'vacancyCompaniesRequestDto', vacancyCompaniesRequestDto)
            const localVarPath = `/api/v1/companies/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vacancyCompaniesRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} companyId 
         * @param {FindCompanyEmployeeRequestDto} findCompanyEmployeeRequestDto Find company employees request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployeesList: async (companyId: string, findCompanyEmployeeRequestDto: FindCompanyEmployeeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyEmployeesList', 'companyId', companyId)
            // verify required parameter 'findCompanyEmployeeRequestDto' is not null or undefined
            assertParamExists('getCompanyEmployeesList', 'findCompanyEmployeeRequestDto', findCompanyEmployeeRequestDto)
            const localVarPath = `/api/v1/companies/{companyId}/employees/filter`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findCompanyEmployeeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List dictionaries
         * @param {DictionaryTypeDto} [type] 
         * @param {boolean} [approved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaries: async (type?: DictionaryTypeDto, approved?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (approved !== undefined) {
                localVarQueryParameter['approved'] = approved;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return array of dictionaries types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionaries/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enums list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnums: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionaries/enums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHRs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionary/hrs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hrbps dictionary list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHrbpsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionary/hrbps`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dictionary/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<RoleTypeDto>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByRole: async (roles: Array<RoleTypeDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('getUsersByRole', 'roles', roles)
            const localVarPath = `/api/v1/dictionary/users/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roles) {
                localVarQueryParameter['roles'] = roles.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manage dictionary entries
         * @param {PartialUpdateDictionaryDto} partialUpdateDictionaryDto Data needed to perform operations on the dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageDictionaryEntries: async (partialUpdateDictionaryDto: PartialUpdateDictionaryDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partialUpdateDictionaryDto' is not null or undefined
            assertParamExists('manageDictionaryEntries', 'partialUpdateDictionaryDto', partialUpdateDictionaryDto)
            const localVarPath = `/api/v1/dictionaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialUpdateDictionaryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionariesApi - functional programming interface
 * @export
 */
export const DictionariesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionariesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new dictionary entry
         * @param {DictionaryEntryDto} dictionaryEntryDto Data needed to create dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDictionaryEntry(dictionaryEntryDto: DictionaryEntryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDictionaryEntry(dictionaryEntryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.createDictionaryEntry']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Companies dictionary list
         * @param {VacancyCompaniesRequestDto} vacancyCompaniesRequestDto Find companies request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesList(vacancyCompaniesRequestDto: VacancyCompaniesRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesList(vacancyCompaniesRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getCompaniesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} companyId 
         * @param {FindCompanyEmployeeRequestDto} findCompanyEmployeeRequestDto Find company employees request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyEmployeesList(companyId: string, findCompanyEmployeeRequestDto: FindCompanyEmployeeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyEmployeeListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyEmployeesList(companyId, findCompanyEmployeeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getCompanyEmployeesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List dictionaries
         * @param {DictionaryTypeDto} [type] 
         * @param {boolean} [approved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaries(type?: DictionaryTypeDto, approved?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendedDictionaryEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaries(type, approved, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getDictionaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Return array of dictionaries types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDictionaryTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DictionaryTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDictionaryTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getDictionaryTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Enums list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnums(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DictionaryEnumDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnums(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getEnums']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHRs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersShortsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHRs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getHRs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hrbps dictionary list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHrbpsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersShortsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHrbpsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getHrbpsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManagers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersShortsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManagers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getManagers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<RoleTypeDto>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersByRole(roles: Array<RoleTypeDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersShortsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersByRole(roles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.getUsersByRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Manage dictionary entries
         * @param {PartialUpdateDictionaryDto} partialUpdateDictionaryDto Data needed to perform operations on the dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manageDictionaryEntries(partialUpdateDictionaryDto: PartialUpdateDictionaryDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DictionaryEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manageDictionaryEntries(partialUpdateDictionaryDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DictionariesApi.manageDictionaryEntries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DictionariesApi - factory interface
 * @export
 */
export const DictionariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionariesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new dictionary entry
         * @param {DictionaryEntryDto} dictionaryEntryDto Data needed to create dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDictionaryEntry(dictionaryEntryDto: DictionaryEntryDto, options?: any): AxiosPromise<void> {
            return localVarFp.createDictionaryEntry(dictionaryEntryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Companies dictionary list
         * @param {VacancyCompaniesRequestDto} vacancyCompaniesRequestDto Find companies request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesList(vacancyCompaniesRequestDto: VacancyCompaniesRequestDto, options?: any): AxiosPromise<CompaniesListDto> {
            return localVarFp.getCompaniesList(vacancyCompaniesRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} companyId 
         * @param {FindCompanyEmployeeRequestDto} findCompanyEmployeeRequestDto Find company employees request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyEmployeesList(companyId: string, findCompanyEmployeeRequestDto: FindCompanyEmployeeRequestDto, options?: any): AxiosPromise<CompanyEmployeeListDto> {
            return localVarFp.getCompanyEmployeesList(companyId, findCompanyEmployeeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List dictionaries
         * @param {DictionaryTypeDto} [type] 
         * @param {boolean} [approved] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaries(type?: DictionaryTypeDto, approved?: boolean, options?: any): AxiosPromise<Array<ExtendedDictionaryEntryDto>> {
            return localVarFp.getDictionaries(type, approved, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return array of dictionaries types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDictionaryTypes(options?: any): AxiosPromise<Array<DictionaryTypeDto>> {
            return localVarFp.getDictionaryTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enums list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnums(options?: any): AxiosPromise<Array<DictionaryEnumDto>> {
            return localVarFp.getEnums(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHRs(options?: any): AxiosPromise<UsersShortsDto> {
            return localVarFp.getHRs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hrbps dictionary list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHrbpsList(options?: any): AxiosPromise<UsersShortsDto> {
            return localVarFp.getHrbpsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManagers(options?: any): AxiosPromise<UsersShortsDto> {
            return localVarFp.getManagers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<RoleTypeDto>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersByRole(roles: Array<RoleTypeDto>, options?: any): AxiosPromise<UsersShortsDto> {
            return localVarFp.getUsersByRole(roles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Manage dictionary entries
         * @param {PartialUpdateDictionaryDto} partialUpdateDictionaryDto Data needed to perform operations on the dictionary entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manageDictionaryEntries(partialUpdateDictionaryDto: PartialUpdateDictionaryDto, options?: any): AxiosPromise<Array<DictionaryEntryDto>> {
            return localVarFp.manageDictionaryEntries(partialUpdateDictionaryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionariesApi - interface
 * @export
 * @interface DictionariesApi
 */
export interface DictionariesApiInterface {
    /**
     * 
     * @summary Create new dictionary entry
     * @param {DictionaryEntryDto} dictionaryEntryDto Data needed to create dictionary entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    createDictionaryEntry(dictionaryEntryDto: DictionaryEntryDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Companies dictionary list
     * @param {VacancyCompaniesRequestDto} vacancyCompaniesRequestDto Find companies request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getCompaniesList(vacancyCompaniesRequestDto: VacancyCompaniesRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<CompaniesListDto>;

    /**
     * 
     * @param {string} companyId 
     * @param {FindCompanyEmployeeRequestDto} findCompanyEmployeeRequestDto Find company employees request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getCompanyEmployeesList(companyId: string, findCompanyEmployeeRequestDto: FindCompanyEmployeeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<CompanyEmployeeListDto>;

    /**
     * 
     * @summary List dictionaries
     * @param {DictionaryTypeDto} [type] 
     * @param {boolean} [approved] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getDictionaries(type?: DictionaryTypeDto, approved?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<ExtendedDictionaryEntryDto>>;

    /**
     * 
     * @summary Return array of dictionaries types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getDictionaryTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<DictionaryTypeDto>>;

    /**
     * 
     * @summary Enums list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getEnums(options?: RawAxiosRequestConfig): AxiosPromise<Array<DictionaryEnumDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getHRs(options?: RawAxiosRequestConfig): AxiosPromise<UsersShortsDto>;

    /**
     * 
     * @summary Hrbps dictionary list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getHrbpsList(options?: RawAxiosRequestConfig): AxiosPromise<UsersShortsDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getManagers(options?: RawAxiosRequestConfig): AxiosPromise<UsersShortsDto>;

    /**
     * 
     * @param {Array<RoleTypeDto>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    getUsersByRole(roles: Array<RoleTypeDto>, options?: RawAxiosRequestConfig): AxiosPromise<UsersShortsDto>;

    /**
     * 
     * @summary Manage dictionary entries
     * @param {PartialUpdateDictionaryDto} partialUpdateDictionaryDto Data needed to perform operations on the dictionary entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApiInterface
     */
    manageDictionaryEntries(partialUpdateDictionaryDto: PartialUpdateDictionaryDto, options?: RawAxiosRequestConfig): AxiosPromise<Array<DictionaryEntryDto>>;

}

/**
 * DictionariesApi - object-oriented interface
 * @export
 * @class DictionariesApi
 * @extends {BaseAPI}
 */
export class DictionariesApi extends BaseAPI implements DictionariesApiInterface {
    /**
     * 
     * @summary Create new dictionary entry
     * @param {DictionaryEntryDto} dictionaryEntryDto Data needed to create dictionary entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public createDictionaryEntry(dictionaryEntryDto: DictionaryEntryDto, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).createDictionaryEntry(dictionaryEntryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Companies dictionary list
     * @param {VacancyCompaniesRequestDto} vacancyCompaniesRequestDto Find companies request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getCompaniesList(vacancyCompaniesRequestDto: VacancyCompaniesRequestDto, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getCompaniesList(vacancyCompaniesRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} companyId 
     * @param {FindCompanyEmployeeRequestDto} findCompanyEmployeeRequestDto Find company employees request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getCompanyEmployeesList(companyId: string, findCompanyEmployeeRequestDto: FindCompanyEmployeeRequestDto, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getCompanyEmployeesList(companyId, findCompanyEmployeeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List dictionaries
     * @param {DictionaryTypeDto} [type] 
     * @param {boolean} [approved] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getDictionaries(type?: DictionaryTypeDto, approved?: boolean, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getDictionaries(type, approved, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return array of dictionaries types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getDictionaryTypes(options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getDictionaryTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enums list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getEnums(options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getEnums(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getHRs(options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getHRs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hrbps dictionary list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getHrbpsList(options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getHrbpsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getManagers(options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getManagers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<RoleTypeDto>} roles 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public getUsersByRole(roles: Array<RoleTypeDto>, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).getUsersByRole(roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Manage dictionary entries
     * @param {PartialUpdateDictionaryDto} partialUpdateDictionaryDto Data needed to perform operations on the dictionary entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionariesApi
     */
    public manageDictionaryEntries(partialUpdateDictionaryDto: PartialUpdateDictionaryDto, options?: RawAxiosRequestConfig) {
        return DictionariesApiFp(this.configuration).manageDictionaryEntries(partialUpdateDictionaryDto, options).then((request) => request(this.axios, this.basePath));
    }
}

