import React, { useEffect, useMemo, useState } from 'react';
import { DictionariesContext, DictionariesContextProps } from './Dictionaries.context';
import { useGetEnums } from '@/api/util/requests/useGetEnums.util.ts';
import { Signal, useSignal } from '@preact/signals-react';
import { DictionaryEnumDto, DictionaryTypeDto, ExtendedDictionaryEntryDto } from '@/api/generated';
import FullPageSpinner from '@/components/ui/fullPageSpinner/FullPageSpinner';
import { useGetAllDictionaries } from '@/views/dictionaries/components/dictionariesTable/util/useGetAllDictionaries.util.ts';
import { useUserContext } from '@/contexts/userContext/User.context.ts';

const DictionariesFlags = new Signal(0);

const emptyDictionaries: Omit<DictionariesContextProps, 'dictionaryEnums'> = {
  certificates: [],
  currencies: [],
  languages: [],
  locations: [],
  sectors: [],
  skills: [],
};

const DictionaryTypeMapping: Record<
  DictionaryTypeDto,
  keyof Omit<DictionariesContextProps, 'dictionaryEnums'>
> = {
  [DictionaryTypeDto.Certificate]: 'certificates',
  [DictionaryTypeDto.Currency]: 'currencies',
  [DictionaryTypeDto.Language]: 'languages',
  [DictionaryTypeDto.Location]: 'locations',
  [DictionaryTypeDto.Sector]: 'sectors',
  [DictionaryTypeDto.Skill]: 'skills',
};

export const DictionariesContextProvider: React.FC = props => {
  const dictionaryEnums = useSignal<DictionaryEnumDto[]>([]);
  const [groupedDictionaries, setGroupedDictionaries] = useState<
    Omit<DictionariesContextProps, 'dictionaryEnums'>
  >({ ...emptyDictionaries });
  const { value } = useUserContext();

  const parseDictionaries = (dicts: ExtendedDictionaryEntryDto[]) => {
    const newDict = structuredClone(emptyDictionaries);
    dicts.forEach(dict => {
      newDict[DictionaryTypeMapping[dict.type]].push(dict as Required<ExtendedDictionaryEntryDto>);
    });
    setGroupedDictionaries(newDict);
    DictionariesFlags.value |= 1 << 1;
  };

  const { getAllDictionaries } = useGetAllDictionaries({
    disabled: true,
    onSuccess: parseDictionaries,
  });
  const { getEnums } = useGetEnums();

  const fetchDictionaryEnums = async () => {
    await getAllDictionaries();
    dictionaryEnums.value = await getEnums();
    DictionariesFlags.value |= 1 << 0;
  };

  useEffect(() => {
    if (!value.dataReady) {
      return;
    }
    void fetchDictionaryEnums();
  }, [value.dataReady]);

  const context: DictionariesContextProps = useMemo(() => {
    return {
      ...groupedDictionaries,
      dictionaryEnums,
    };
  }, [dictionaryEnums, groupedDictionaries]);

  if (!value.dataReady || (DictionariesFlags.value < 3 && value.initializationDone)) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <DictionariesContext.Provider value={context}>{props.children}</DictionariesContext.Provider>
    </>
  );
};
