import { ObjectSchema, SchemaDescription, SchemaObjectDescription } from 'yup';

export const isFormFieldRequired = (
  name: string,
  schema?: ObjectSchema<object> | undefined,
): boolean => {
  if (!schema) {
    return false;
  }
  const parsedName = name.split('.')[0];
  const fields = schema?.describe().fields;
  const currentField = fields[parsedName] as SchemaDescription;

  const isRequired = testField(currentField);
  if (isRequired) {
    return true;
  }
  if (fields[parsedName]?.type === 'object') {
    const field = fields[parsedName] as SchemaObjectDescription;
    if (field.optional) {
      return false;
    }
    const innerFields = field.fields;
    return Object.keys(innerFields).some(key => testField(innerFields[key] as SchemaDescription));
  }
  return false;
};

const testField = (field?: SchemaDescription) => {
  if (!field) {
    return false;
  }
  return Boolean(field.tests.find(t => t.name === 'required')) || !field.optional;
};
