import { UserDetailsTranslation } from '@/assets/locales/userDetails/userDetails.translation.en.ts';

export const userDetailsTranslation: UserDetailsTranslation = {
  modal: {
    title: {
      edit: 'Modifier les données',
    },
    role: 'Rôle',
    location: 'Emplacement',
    operatingLocations: "Lieux d'exploitation",
    status: 'Statut',
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    buttons: {
      selectAll: 'Tout sélectionner',
      deselectAll: 'Désélectionner tout',
    },
  },
  content: {
    companyName: "Nom de l'entreprise",
    email: 'Email principal',
    registeredAt: 'Enregistré le',
    role: 'Rôle',
    status: 'Statut',
    lastLogin: 'Dernière connexion',
    location: 'Emplacement',
    operatingLocations: "Lieux d'exploitation",
    phoneNumber: 'Numéro de téléphone principal',
    button: {
      edit: 'Modifier les données',
    },
  },
};
