import { CompanyProfileTypeDto, UserStatusDto, VacancyProjectStatusDto } from '@/api/generated';

export type CompanyDetailsTranslation = Record<string, unknown> & {
  modal: {
    title: {
      addCompany: string;
      editCompany: string;
    };
    addMsa: string;
    addNda: string;
    address: string;
    companyName: string;
    companyProfile: string;
    defaultRole: string;
    description: string;
    emailDomain: string;
    email: string;
    location: string;
    operatingLocations: string;
    orangeAffiliate: string;
    phoneNumber: string;
  };
  button: {
    addCompany: string;
  };
  table: {
    noResults: string;
    columns: {
      actions: string;
      companyName: string;
      companyProfile: string;
      location: string;
    };
    filters: {
      companyName: string;
      companyProfile: string;
      employeeFirstName: string;
      employeeLastName: string;
      employeeStatus: string;
    };
  };
  details: {
    address: string;
    companyProfile: string;
    description: string;
    defaultRole: string;
    domainName: string;
    email: string;
    location: string;
    msa: string;
    nda: string;
    operatingLocations: string;
    phoneNumber: string;
    status: string;
    button: {
      edit: string;
      deactivate: string;
      activate: string;
    };
  };
  projectStatus: Record<
    (typeof VacancyProjectStatusDto)[keyof typeof VacancyProjectStatusDto],
    string
  >;
  employeeStatus: Record<(typeof UserStatusDto)[keyof typeof UserStatusDto], string>;
  companyProfile: Record<
    (typeof CompanyProfileTypeDto)[keyof typeof CompanyProfileTypeDto],
    string
  >;
  employeesTable: {
    filters: {
      firstName: string;
      lastName: string;
      status: string;
    };
    column: {
      firstName: string;
      lastName: string;
      companyName: string;
      status: string;
    };
  };
};

const addCompany = 'Add company';
const address = 'Main address';
const companyName = 'Company name';
const companyProfile = 'Profile';
const description = 'Description';
const email = 'Main email';
const firstName = 'First name';
const lastName = 'Last name';
const location = 'Location';
const operatingLocations = 'Operating locations';
const phoneNumber = 'Main phone number';
const status = 'Status';

export const companyDetailsTranslation: CompanyDetailsTranslation = {
  modal: {
    title: {
      addCompany,
      editCompany: 'Edit company',
    },
    addMsa: 'Add MSA',
    addNda: 'Add NDA',
    address,
    companyName,
    companyProfile,
    defaultRole: 'Default role',
    description,
    emailDomain: "Company's e-mail domain, e.g. 'gmail.com'",
    email,
    location,
    operatingLocations,
    orangeAffiliate: 'Orange affiliate',
    phoneNumber,
  },
  button: {
    addCompany,
  },
  details: {
    address,
    companyProfile,
    description,
    defaultRole: 'Default role',
    domainName: "Company's domain",
    email,
    location,
    msa: 'MSA',
    nda: 'NDA',
    operatingLocations,
    phoneNumber,
    status,
    button: {
      edit: 'Edit',
      deactivate: 'Deactivate',
      activate: 'Activate',
    },
  },
  table: {
    noResults: 'No results',
    columns: {
      actions: 'Approvals awaiting',
      companyName,
      companyProfile,
      location,
    },
    filters: {
      companyName,
      companyProfile,
      employeeFirstName: "Employee's first name",
      employeeLastName: "Employee's last name",
      employeeStatus: "Employee's status",
    },
  },
  orangeAffiliate: 'Orange affiliate',
  deactivate: 'Are you sure you want to deactivate this company?',
  activate: 'Are you sure you want to activate this company?',
  editUser: 'Edit data',
  representative: 'Agency representative',
  agencyName: 'Agency name',
  ordersCount: 'Orders count',
  goBack: 'Go back to companies list',
  fullName: 'Name',
  employee: 'Employee',
  employeeFirstName: "Employee's first name",
  employeeLastName: "Employee's last name",
  affiliates: 'Affiliates',
  external: 'External',
  internal: 'Internal',
  warning: 'Are you sure you want to delete employee',
  phoneNo: 'Phone number',
  editEmployee: 'Edit employee',
  employeeStatus: {
    [UserStatusDto.Active]: 'Active',
    [UserStatusDto.Inactive]: 'Inactive',
    [UserStatusDto.WaitingForApproval]: 'Waiting for approval',
  },
  projectStatus: {
    [VacancyProjectStatusDto.Active]: 'Active',
    [VacancyProjectStatusDto.Archived]: 'Archived',
    [VacancyProjectStatusDto.ClosedLost]: 'Closed - Lost',
    [VacancyProjectStatusDto.ClosedWon]: 'Closed - Won',
    [VacancyProjectStatusDto.NoSalesforceId]: 'No Salesforce ID',
    [VacancyProjectStatusDto.OnHold]: 'On Hold',
  },
  buttons: {
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
  },
  companyProfile: {
    AFFILIATES: 'Affiliates',
    EXTERNAL: 'External',
    INTERNAL: 'Internal',
  },
  employeesTable: {
    column: {
      firstName,
      lastName,
      companyName: 'Company name',
      status,
    },
    filters: {
      firstName,
      lastName,
      status,
    },
  },
};
