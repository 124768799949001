/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const VacancyCandidateStatusEnumDto = {
    New: 'NEW',
    RequestForInterview: 'REQUEST_FOR_INTERVIEW',
    Interviewed: 'INTERVIEWED',
    CandidateRejected: 'CANDIDATE_REJECTED',
    CandidateSelected: 'CANDIDATE_SELECTED',
    OfferSent: 'OFFER_SENT',
    OfferSigned: 'OFFER_SIGNED'
} as const;

export type VacancyCandidateStatusEnumDto = typeof VacancyCandidateStatusEnumDto[keyof typeof VacancyCandidateStatusEnumDto];



