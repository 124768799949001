import { interviewNoteRequest } from '@/assets/locales/vacancyCandidate/interviewNoteRequest/interviewNoteRequest.fr.ts';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';
import { VacancyCandidateTranslation } from '@/assets/locales/vacancyCandidate/vacancyCandidate.translation.en.ts';

export const vacancyCandidateTranslation: VacancyCandidateTranslation = {
  status: {
    [VacancyCandidateStatusEnumDto.New]: 'Nouveau',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Entretien demandé',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewé',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Candidat rejeté',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Candidat sélectionné',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Offre signée',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offre envoyée',
  },
  transition: {
    [VacancyCandidateStatusEnumDto.New]: 'Nouveau',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: "Demande d'entretien",
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Interviewé',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Rejeter le candidat',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Sélectionner le candidat',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Offre signée',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Offre envoyée',
  },
  interviewNoteRequest,
  downloadCV: 'Télécharger le CV',
  approve: 'Accepter',
  reject: 'Rejeter',
  existsInDatabase: 'Existe dans la base de données',
  notExistInDatabase: "N'existe pas dans la base de données",
  lastContactDate: 'Date du dernier contact',
  headline: 'Sélectionner la date du dernier contact',
  instructions: 'Choisissez la date du dernier contact par votre entreprise avec le candidat',
  addComment: 'Ajouter un commentaire pour le candidat',
  comment: 'Commentaire',
  buttonSave: 'Sauvegarder',
  interview: {
    title: 'Entretien',
    summary: 'Résumé',
    date: 'Date',
    technicalInterviewer: 'Intervieweur technique',
    maxDateMessage: 'La date ne doit pas être dans le futur',
    minDateMessage: 'La date ne doit pas être dans le passé',
  },
  addInterview: 'Ajouter un entretien',
  selectVacancy: 'Sélectionner une demande',
  selectVacancyToAssign: 'Sélectionner la demande à attribuer au candidat.',
  noVacancy: 'Aucune demande',
  vacancy: 'Demande',
  changeVacancy: 'Changer la demande',
  addCommentBtn: 'Ajouter un commentaire',
  editCandidate: 'Modifier les données du candidat',
  timeline: {
    readMore: 'Lire la suite',
    readLess: 'Réduire',
    statusChange: 'Changement de statut :',
    meetingSummary: 'Retour après la réunion :',
    meetingStatus: "Statut de l'entretien :",
    activeStateChange: "Changement d'état du candidat :",
  },
  closeCandidate: 'Clore le candidat',
  statusChange: {
    closed: 'Clôture du candidat',
    rejected: 'Rejet du candidat',
    closedReason: 'Raison de la clôture',
    closedDescription: 'Description',
    rejectedReason: 'Raison du rejet',
    rejectedDescription: 'Description',
    closedInstruction: 'Choisissez la raison de la clôture du candidat',
    rejectedInstruction: 'Choisissez la raison du rejet du candidat',
    closedDetails: 'Détails de la clôture',
    rejectedDetails: 'Détails du rejet',
  },
  notesList: {
    header: 'Notes des entretiens',
    expand: 'Développer',
    collapse: 'Réduire',
    table: {
      date: 'Date de la réunion',
      author: 'Auteur de la note',
      vacancy: 'Demande',
    },
  },
  buttons: {
    activate: 'Activer',
    deactivate: 'Désactiver',
  },
  candidateInactiveBanner: 'Le candidat est inactif.',
  goToVacancy: 'Aller à la demande',
  content: {
    availability: 'Disponibilité',
    currency: 'Devise',
    cvSource: 'Source du CV',
    cvUploadedBy: 'CV téléchargé par',
    expectedDailyRate: 'Taux journalier attendu',
    firstName: 'Prénom',
    languages: 'Langues',
    lastName: 'Nom',
    location: 'Lieu',
    position: 'Poste',
    resume: 'CV',
    title: 'Détails du candidat',
    button: {
      addComment: 'Ajouter un commentaire',
      editCandidate: 'Modifier le candidat',
      goToUserProfile: 'Aller au profil du candidat',
    },
  },
};
