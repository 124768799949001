import { useApiClient } from '@/api/util/useApiClient.util.ts';
import { UserBasicDto } from '@/api/generated';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { QueryKeyEnum } from '@/api/model/QueryKey.enum.ts';
import { RefetchModel } from '@/api/model/Refetch.model.ts';

type Return = {
  getCurrentUser: RefetchModel<UserBasicDto>;
  isGettingCurrentUser: boolean;
};

type Params = {
  onSuccess: (data: UserBasicDto) => void;
};

export const useGetCurrentUser = (params: Params): Return => {
  const { usersApi } = useApiClient();
  const { isLoading, refetch, fetchStatus } = useQuery<UserBasicDto, AxiosError>({
    enabled: false,
    queryFn: async () => {
      const response = await usersApi.getCurrentUser();
      return response?.data;
    },
    queryKey: [QueryKeyEnum.CURRENT_USER_GET],
    onSuccess: data => {
      params.onSuccess(data);
    },
  });
  return {
    getCurrentUser: refetch,
    isGettingCurrentUser: fetchStatus !== 'idle' && isLoading,
  };
};
