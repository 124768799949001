import { UserProfileTranslation } from '@/assets/locales/views/userProfile/userProfile.translation.en';

import { userProfileMainSectionTranslation } from '@/assets/locales/views/userProfile/sections/mainSection/userProfile.mainSection.translation.de';
import { userProfileLanguagesSectionTranslation } from '@/assets/locales/views/userProfile/sections/languagesSection/userProfile.languagesSection.translation.de';
import { userProfileSkillsSectionTranslation } from '@/assets/locales/views/userProfile/sections/skillsSection/userProfile.skillsSection.translation.de';
import { userProfileProjectsSectionTranslation } from '@/assets/locales/views/userProfile/sections/projectsSection/userProfile.projectsSection.translation.de';
import { userProfileEducationsSectionTranslation } from '@/assets/locales/views/userProfile/sections/educationsSection/userProfile.educationsSection.translation.de';
import { userProfileCertificatesSectionTranslation } from '@/assets/locales/views/userProfile/sections/certificatesSection/userProfile.certificatesSection.translation.de';
import { userProfileAwardsSectionTranslation } from '@/assets/locales/views/userProfile/sections/awardsSection/userProfile.awardsSection.translation.de.ts';
import { userProfileCoursesSectionTranslation } from '@/assets/locales/views/userProfile/sections/coursesSection/userProfile.coursesSection.translation.de';
import { userProfileExperiencesSectionTranslation } from '@/assets/locales/views/userProfile/sections/experiencesSection/userProfile.experiencesSection.translation.de';
import { editDeleteTranslation } from '@/assets/locales/views/userProfile/editDelete.translation.de.ts';
import { userProfileSectionWrapperTranslation } from './sections/sectionWrapper/userProfile.sectionWrapper.translation.de';
import { userProfileErrorsTranslation } from '@/assets/locales/views/userProfile/errors/userProfile.errors.translation.de.ts';
import { userProfileEmptySectionTranslation } from '@/assets/locales/views/userProfile/sections/emptySection/userProfile.emptySection.translation.de';
import { userProfileCareerPathSectionTranslation } from '@/assets/locales/views/userProfile/sections/userProfile.growthPathSection.translation.de.ts';

export const userProfileTranslation: UserProfileTranslation = {
  awardsSection: userProfileAwardsSectionTranslation,
  careerPathSection: userProfileCareerPathSectionTranslation,
  certificatesSection: userProfileCertificatesSectionTranslation,
  coursesSection: userProfileCoursesSectionTranslation,
  editDelete: editDeleteTranslation,
  educationsSection: userProfileEducationsSectionTranslation,
  emptySection: userProfileEmptySectionTranslation,
  errors: userProfileErrorsTranslation,
  experiencesSection: userProfileExperiencesSectionTranslation,
  languagesSection: userProfileLanguagesSectionTranslation,
  mainSection: userProfileMainSectionTranslation,
  projectsSection: userProfileProjectsSectionTranslation,
  sectionWrapper: userProfileSectionWrapperTranslation,
  skillsSection: userProfileSkillsSectionTranslation,
  tooltip: {
    customItem:
      'Dieses Element wurde vom Benutzer erstellt und wird nicht in das Suchmodul aufgenommen.',
  },
};
