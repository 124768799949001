import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';
import TextField from '@mui/material/TextField';
import CloseIcon from '@/assets/icons/CloseIcon';
import Button from '@mui/material/Button';
import { colors } from '@/utils/colors.ts';
import { SxProps } from '@mui/material';

type Props = {
  isSearching: boolean;
  searchValue: string;
  handleUpdateSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitSearch: () => void;
};

export const AiSearchSearch: React.FC<Props> = props => {
  const { isSearching, handleSubmitSearch, searchValue, handleUpdateSearch } = props;

  const handleClearSearch = () => {
    handleUpdateSearch({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <Box textAlign="center">
        <TextField
          fullWidth
          label="Search"
          onChange={handleUpdateSearch}
          value={searchValue}
          multiline
          disabled={isSearching}
          variant="outlined"
          sx={AiTextFieldStyles}
          InputProps={{
            endAdornment: <AiCloseIcon onClick={handleClearSearch} />,
          }}
        />
      </Box>
      <Box textAlign="center" pt={2}>
        <AiButton onClick={handleSubmitSearch} disabled={searchValue.length < 3 || isSearching}>
          Search
        </AiButton>
      </Box>
    </>
  );
};

const AiTextFieldStyles: SxProps = {
  background: 'white',
  borderRadius: '50px',
  maxWidth: '600px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
  },
  '& .MuiFormLabel-root': {
    top: '-15px',
  },
};

const AiCloseIcon = styled(CloseIcon)({
  color: colors.silver_70,
  cursor: 'pointer',
  '&:hover': {
    color: colors.black_80,
  },
});

const AiButton = styled(Button)({
  background: '#006FBA',
  color: 'white',
  padding: '8px 20px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  '&:hover': {
    background: '#005A8C',
  },
  '&:disabled': {
    background: 'rgba(0,111,186,0.49)',
  },
});
