export type VacancyDetailsFooterTranslation = {
  button: {
    archive: string;
    close: string;
    reopen: string;
    onHold: string;
  };
  dialog: {
    archive: {
      message: string;
      button: string;
    };
    close: {
      message: string;
      button: string;
    };
    onHold: {
      message: string;
      button: string;
    };
    open: {
      message: string;
      button: string;
    };
  };
};

export const vacancyDetailsFooterTranslation: VacancyDetailsFooterTranslation = {
  dialog: {
    archive: {
      message: 'Are you sure you want to archive this request?',
      button: 'Archive request',
    },
    close: {
      message: 'Are you sure you want to close this request?',
      button: 'Close request',
    },
    onHold: {
      message: 'Are you sure you want to put this request on hold?',
      button: 'Put request on hold',
    },
    open: {
      message: 'Are you sure you want to reopen this request?',
      button: 'Reopen request',
    },
  },
  button: {
    archive: 'Archive request',
    close: 'Close request',
    reopen: 'Reopen request',
    onHold: 'Put request On hold',
  },
};
