import { ReportsTranslation } from '@/assets/locales/views/reports/reports.translation.en';

export const reportsTranslation: ReportsTranslation = {
  form: {
    reportType: 'Type de rapport',
    reportRange: 'Plage du rapport',
    location: 'Localisation',
    dateFrom: 'Date de début',
    dateTo: 'Date de fin',
    button: {
      generate: 'Générer',
    },
  },
};
