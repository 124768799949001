import { ValidationTranslation } from '@/assets/locales/validation/validation.translation.en.ts';

export const validationTranslation: ValidationTranslation = {
  charactersLimit: 'Die maximale Anzahl von Zeichen ist {{noOfChars}}',
  date: {
    maxCurrentDate: 'Das Datum darf nicht in der Zukunft liegen',
    maxDate: 'Das Datum kann nicht später sein als {{maxDate}}',
    minCurrentDate: 'Datum kann nicht in der Vergangenheit liegen',
    notEarlierThen: '{{to}} kann nicht früher sein als {{from}}',
  },
  email: 'E-Mail ist nicht gültig',
  general: { required: 'Feld ist erforderlich', wrongFormat: 'Falsches Format angegeben' },
  iban: 'Die IBAN-Nummer ist falsch',
  incorrectFileExtension:
    'Falsche Dateierweiterung. Unterstützte Erweiterungen: {{correctExtensions}}',
  integerNumber: 'Feld muss eine ganze Zahl sein',
  isNumber: 'Das Feld muss eine Zahl sein',
  length: 'Feld muss {{length}} lang sein',
  max: 'Der maximale Wert ist {{howMany}}',
  maxLength: 'Das Feld darf höchstens {{max}} Zeichen lang sein',
  maxLengthOfMessage: 'Die maximale Länge der Nachricht beträgt {{max}} Zeichen',
  maxStringLength: 'Die maximale Länge für dieses Feld ist: {{max}}',
  min: 'Mindestwert ist {{howMany}}',
  minLength: 'Das Feld muss mindestens {{min}} Zeichen lang sein',
  minNumber: 'Der Mindestwert für dieses Feld ist: {{minNumber}}',
  minStringLength: 'Die Mindestlänge für dieses Feld ist: {{min}}',
  mobile: 'Telefonnummer ist ungültig',
  nip: 'Ungültiger NIP-Wert',
  noResults: 'Keine Ergebnisse',
  passwordMustMatch: 'Passwörter müssen übereinstimmen',
  passwordRequirements:
    'Passwörter müssen eine Ziffer, einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten. Die Länge des Passworts muss zwischen 8 und 100 Zeichen liegen.',
  pesel: 'Die PESEL-Nummer ist falsch',
  phone: 'Telefonnummer ist ungültig (XXX-XXX-XXX)',
  pinsMustMatch: 'Pins müssen übereinstimmen',
  rating: 'Bitte wählen Sie Ihre Bewertung',
  regon: 'Ungültiger REGON-Wert',
  required: 'Dieses Feld ist erforderlich',
  stringLength: 'Dieses Feld muss {{Länge}} Zeichen lang sein',
  tooLargeFileSize: 'Die Datei ist zu groß. Die maximale Dateigröße ist: {{maxFileSize}}',
};
