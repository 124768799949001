import { LanguageProficiencyDto } from '@/api/generated-user-profile';
import { UserProfileLanguagesSectionTranslation } from '@/assets/locales/views/userProfile/sections/languagesSection/userProfile.languagesSection.translation.en.ts';

const proficiencyLevels = {
  [LanguageProficiencyDto.Basic]: 'De base',
  [LanguageProficiencyDto.Intermediate]: 'Intermédiaire',
  [LanguageProficiencyDto.Advanced]: 'Avancé',
  [LanguageProficiencyDto.Professional]: 'Professionnel',
  [LanguageProficiencyDto.Bilingual]: 'Bilingue',
};

export const userProfileLanguagesSectionTranslation: UserProfileLanguagesSectionTranslation = {
  headerTitle: 'Langues',
  content: {
    emptyItemsLabel: 'langues',
    tableHeader: {
      language: 'Langue',
      level: 'Niveau de maîtrise',
      actions: 'Actions',
    },
    proficiencyLevels,
    button: {
      edit: 'Modifier',
    },
  },
  form: {
    actions: 'Actions',
    language: 'Langue',
    proficiencyLevel: 'Niveau de maîtrise',
    proficiencyLevels,
    button: {
      save: 'Enregistrer',
    },
  },
  modal: {
    title: 'Ajouter une langue',
  },
};
