import { SvgIcon } from '@mui/material';

const DeleteIcon = props => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g stroke="#fff" strokeLinecap="round" strokeWidth="1.5">
              <path
                d="M-1.912 6L13.912 6M6-1.912L6 13.912"
                transform="translate(-1183 -261) translate(183 225) translate(1000 36) translate(4 4) rotate(45 6 6)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default DeleteIcon;
