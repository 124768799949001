/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileAvailabilityDto } from '../models';
// @ts-ignore
import type { UserProfileDto } from '../models';
// @ts-ignore
import type { UserProfileGeneralData1Dto } from '../models';
// @ts-ignore
import type { UserProfileGeneralDataCreatedDto } from '../models';
/**
 * UserProfileApi - axios parameter creator
 * @export
 */
export const UserProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileGeneralData: async (lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileGeneralData', 'lang', lang)
            // verify required parameter 'userProfileGeneralData1Dto' is not null or undefined
            assertParamExists('createUserProfileGeneralData', 'userProfileGeneralData1Dto', userProfileGeneralData1Dto)
            const localVarPath = `/api/v1/user-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileGeneralData1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile: async (userProfileId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('getUserProfile', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUserProfile', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LangDto} lang 
         * @param {string} [email] 
         * @param {string} [userIdQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileByUserId: async (lang: LangDto, email?: string, userIdQuery?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUserProfileByUserId', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (userIdQuery !== undefined) {
                localVarQueryParameter['userIdQuery'] = userIdQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvailabilityDto} userProfileAvailabilityDto User profile availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAvailability: async (userProfileId: string, lang: LangDto, userProfileAvailabilityDto: UserProfileAvailabilityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileAvailability', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileAvailability', 'lang', lang)
            // verify required parameter 'userProfileAvailabilityDto' is not null or undefined
            assertParamExists('updateUserProfileAvailability', 'userProfileAvailabilityDto', userProfileAvailabilityDto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/availability`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileAvailabilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileGeneralData: async (userProfileId: string, lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileGeneralData', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileGeneralData', 'lang', lang)
            // verify required parameter 'userProfileGeneralData1Dto' is not null or undefined
            assertParamExists('updateUserProfileGeneralData', 'userProfileGeneralData1Dto', userProfileGeneralData1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/general-data`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileGeneralData1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileApi - functional programming interface
 * @export
 */
export const UserProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileGeneralData(lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileGeneralDataCreatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileGeneralData(lang, userProfileGeneralData1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.createUserProfileGeneralData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfile(userProfileId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(userProfileId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.getUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LangDto} lang 
         * @param {string} [email] 
         * @param {string} [userIdQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserProfileByUserId(lang: LangDto, email?: string, userIdQuery?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfileByUserId(lang, email, userIdQuery, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.getUserProfileByUserId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvailabilityDto} userProfileAvailabilityDto User profile availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileAvailability(userProfileId: string, lang: LangDto, userProfileAvailabilityDto: UserProfileAvailabilityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileAvailability(userProfileId, lang, userProfileAvailabilityDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.updateUserProfileAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileGeneralData(userProfileId: string, lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileGeneralData(userProfileId, lang, userProfileGeneralData1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileApi.updateUserProfileGeneralData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileApi - factory interface
 * @export
 */
export const UserProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileGeneralData(lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: any): AxiosPromise<UserProfileGeneralDataCreatedDto> {
            return localVarFp.createUserProfileGeneralData(lang, userProfileGeneralData1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfile(userProfileId: string, lang: LangDto, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.getUserProfile(userProfileId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LangDto} lang 
         * @param {string} [email] 
         * @param {string} [userIdQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserProfileByUserId(lang: LangDto, email?: string, userIdQuery?: string, options?: any): AxiosPromise<UserProfileDto> {
            return localVarFp.getUserProfileByUserId(lang, email, userIdQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileAvailabilityDto} userProfileAvailabilityDto User profile availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileAvailability(userProfileId: string, lang: LangDto, userProfileAvailabilityDto: UserProfileAvailabilityDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileAvailability(userProfileId, lang, userProfileAvailabilityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileGeneralData(userProfileId: string, lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileGeneralData(userProfileId, lang, userProfileGeneralData1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileApi - interface
 * @export
 * @interface UserProfileApi
 */
export interface UserProfileApiInterface {
    /**
     * 
     * @param {LangDto} lang 
     * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    createUserProfileGeneralData(lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileGeneralDataCreatedDto>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    getUserProfile(userProfileId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * 
     * @param {LangDto} lang 
     * @param {string} [email] 
     * @param {string} [userIdQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    getUserProfileByUserId(lang: LangDto, email?: string, userIdQuery?: string, options?: RawAxiosRequestConfig): AxiosPromise<UserProfileDto>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAvailabilityDto} userProfileAvailabilityDto User profile availability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    updateUserProfileAvailability(userProfileId: string, lang: LangDto, userProfileAvailabilityDto: UserProfileAvailabilityDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApiInterface
     */
    updateUserProfileGeneralData(userProfileId: string, lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileApi - object-oriented interface
 * @export
 * @class UserProfileApi
 * @extends {BaseAPI}
 */
export class UserProfileApi extends BaseAPI implements UserProfileApiInterface {
    /**
     * 
     * @param {LangDto} lang 
     * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public createUserProfileGeneralData(lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).createUserProfileGeneralData(lang, userProfileGeneralData1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUserProfile(userProfileId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUserProfile(userProfileId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LangDto} lang 
     * @param {string} [email] 
     * @param {string} [userIdQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public getUserProfileByUserId(lang: LangDto, email?: string, userIdQuery?: string, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).getUserProfileByUserId(lang, email, userIdQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileAvailabilityDto} userProfileAvailabilityDto User profile availability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public updateUserProfileAvailability(userProfileId: string, lang: LangDto, userProfileAvailabilityDto: UserProfileAvailabilityDto, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).updateUserProfileAvailability(userProfileId, lang, userProfileAvailabilityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileGeneralData1Dto} userProfileGeneralData1Dto User general data update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileApi
     */
    public updateUserProfileGeneralData(userProfileId: string, lang: LangDto, userProfileGeneralData1Dto: UserProfileGeneralData1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileApiFp(this.configuration).updateUserProfileGeneralData(userProfileId, lang, userProfileGeneralData1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

