import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { colors } from '@/utils/colors.ts';
import { TOP_BAR_HEIGHT } from '@/layouts/private/topBar/TopBar.styles.ts';

export const PageLoading: React.FC = () => {
  return (
    <>
      <Grid
        container
        sx={{ height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`, width: '100%' }}
        alignContent="center"
        justifyContent="center"
      >
        <CircularProgress sx={{ color: colors.turquoiseBlue }} size="80px" />
      </Grid>
    </>
  );
};
