import { recruitmentBoardSearchToolbar } from './searchToolbar/recruitmentBoardSearchToolbar.translation.de';
import { VacancyCandidateStatusEnumDto } from '@/api/generated';
import { RecruitmentBoardTranslation } from '@/assets/locales/views/recruitmentBoard/recruitmentBoard.translation.en';

export const recruitmentBoardTranslation: RecruitmentBoardTranslation = {
  columns: {
    [VacancyCandidateStatusEnumDto.New]: 'Neu',
    [VacancyCandidateStatusEnumDto.RequestForInterview]: 'Antrag auf Vorstellungsgespräch',
    [VacancyCandidateStatusEnumDto.Interviewed]: 'Vorstellungsgespräch',
    [VacancyCandidateStatusEnumDto.CandidateRejected]: 'Abgelehnt',
    [VacancyCandidateStatusEnumDto.CandidateSelected]: 'Ausgewählt',
    [VacancyCandidateStatusEnumDto.OfferSent]: 'Angeboten',
    [VacancyCandidateStatusEnumDto.OfferSigned]: 'Unterzeichnet',
  },
  agencyName: 'Externe Personalvermittlungsagentur',
  assignedHr: 'Zugewiesener Personalvermittler',
  assignedHrbp: 'Zugewiesen HRBP',
  availability: {
    available: 'Verfügbar',
    availableFrom: 'Verfügbar von',
    no: 'Nicht verfügbar',
    noData: 'Keine Daten',
    yes: 'Verfügbar',
  },
  expectedDailyRate: 'Erwarteter Tagessatz',
  hiringManager: 'Einstellungsmanager',
  locations: 'Orte',
  position: 'Stelle',
  sharedWithEra: 'Gemeinsam mit externen Personalvermittlungsagenturen',
  startingDate: 'Startdatum',
  unassignedCandidates: 'Nicht zugewiesene Kandidaten',
  yearsOfExperience: 'Jahre der Erfahrung',
  searchToolbar: {
    ...recruitmentBoardSearchToolbar,
  },
  vacancy: {
    opportunityOwner: 'Inhaber der Opportunity',
    finalRateForCandidate: 'Endgültiger Satz für Kandidaten',
    targetMargin: 'Zielmarge',
  },
};
