import { RoleTypeDto, VacancyStatusEnumDto } from '@/api/generated';
import { RecruitmentBoardSearchToolbarTranslation } from '@/assets/locales/views/recruitmentBoard/searchToolbar/recruitmentBoardSearchToolbar.translation.en';

export const recruitmentBoardSearchToolbar: RecruitmentBoardSearchToolbarTranslation = {
  categories: {
    opportunityName: "Nom de l'opportunité",
    opportunityOwner: "Propriétaire de l'opportunité",
    requesters: 'Demandeurs',
    requestingCountry: 'Pays demandeur',
    status: 'Statut',
  },
  statuses: {
    [VacancyStatusEnumDto.Archived]: 'Archivé',
    [VacancyStatusEnumDto.Open]: 'Ouvert',
    [VacancyStatusEnumDto.Closed]: 'Fermé',
    [VacancyStatusEnumDto.OnHold]: 'En attente',
  },
  roles: {
    [RoleTypeDto.Admin]: 'Administrateur',
    [RoleTypeDto.Employee]: 'Employé',
    [RoleTypeDto.ExternalPartner]: 'Partenaire externe',
    [RoleTypeDto.GroupExternalPartner]: 'Partenaire externe du groupe',
    [RoleTypeDto.LocalResourceManager]: 'Responsable des ressources locales',
    [RoleTypeDto.PreSales]: 'Pré-vente',
    [RoleTypeDto.Requester]: 'Demandeur',
  },
};
