import React, { Dispatch, SetStateAction } from 'react';
import { TabPanel } from '@/components/ui/tabs/TabPanel.comp.tsx';
import {
  AiSearchParameterControlDirection,
  AiSearchParametersModel,
  normalAiSearchParameters,
  upSkillAiSearchParameters,
} from '@/views/aiSearch/model/AiSearchParameters.model.ts';
import { AiSearchParameterControl } from '@/views/aiSearch/components/parameters/components/AiSearchParameterControl.comp.tsx';
import { multiplyWeights } from '@/views/aiSearch/util/operationsOnAiWeights.util.ts';
import { SearchForceModeDto } from '@/api/generated';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import {
  AiSearchParametersIndicatorStyles,
  AiSearchParametersTabPanelStyles,
  AiSearchParametersTabsPanelsStyles,
  AiSearchParametersTabsSwitcherStyles,
} from './styles/AiSearchParameters.styles';

type Props = {
  parameters: AiSearchParametersModel;
  setParameters: Dispatch<SetStateAction<AiSearchParametersModel>>;
  mode: SearchForceModeDto;
  setMode: Dispatch<SetStateAction<SearchForceModeDto>>;
};

export const AiSearchParameters: React.FC<Props> = props => {
  const { parameters, setParameters, mode, setMode } = props;

  const handleChangeTab = (_event: React.ChangeEvent<object>, newValue: SearchForceModeDto) => {
    if (newValue === SearchForceModeDto.Default) {
      setParameters(multiplyWeights(normalAiSearchParameters));
    } else if (newValue === SearchForceModeDto.Upskill) {
      setParameters(multiplyWeights(upSkillAiSearchParameters));
    }
    setMode(newValue);
  };

  const handleChangeParamValue =
    (key: keyof AiSearchParametersModel) =>
    (direction: AiSearchParameterControlDirection) =>
    () => {
      const newParameters = structuredClone(parameters);
      const value = 0.05;
      if (direction === AiSearchParameterControlDirection.increase) {
        newParameters[key] = Number((newParameters[key] + value).toFixed(2));
        if (newParameters[key] > 10) {
          newParameters[key] = 10;
        }
      } else {
        newParameters[key] = Number((newParameters[key] - value).toFixed(2));
        if (newParameters[key] < 0) {
          newParameters[key] = 0;
        }
      }
      setParameters(newParameters);
    };

  return (
    <>
      <Box style={{ paddingBottom: '32px' }}>
        <Tabs
          value={mode}
          onChange={handleChangeTab}
          variant="fullWidth"
          TabIndicatorProps={{ sx: AiSearchParametersIndicatorStyles }}
          sx={{
            ...AiSearchParametersTabsSwitcherStyles,
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          }}
        >
          <Tab value={SearchForceModeDto.Default} label="Normal" />
          <Tab value={SearchForceModeDto.Upskill} label="Up Skill" />
          <Tab value={SearchForceModeDto.Client} label="Custom" />
        </Tabs>
        <Box sx={AiSearchParametersTabsPanelsStyles}>
          <TabPanel
            sx={AiSearchParametersTabPanelStyles}
            value={mode}
            index={SearchForceModeDto.Default}
          >
            These are default parameters for standard search
          </TabPanel>
          <TabPanel
            sx={AiSearchParametersTabPanelStyles}
            value={mode}
            index={SearchForceModeDto.Upskill}
          >
            These are parameters when you&apos;re searching for a candidate to upskill
          </TabPanel>
          <TabPanel
            sx={AiSearchParametersTabPanelStyles}
            value={mode}
            index={SearchForceModeDto.Client}
          >
            Fell free to fine tune your search
          </TabPanel>
        </Box>
        <Grid container justifyContent="center" spacing={5}>
          {Object.keys(parameters).map(key => {
            const parsedKey = key as keyof AiSearchParametersModel;
            return (
              <Grid item key={parsedKey}>
                <AiSearchParameterControl
                  title={parsedKey}
                  value={parameters[parsedKey]}
                  onChange={handleChangeParamValue(parsedKey)}
                  isIncreaseDisabled={parameters[parsedKey] >= 10}
                  isDecreaseDisabled={parameters[parsedKey] <= 0}
                  isDisabled={mode !== SearchForceModeDto.Client}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
