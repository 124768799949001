import { UserProfileAwardsSectionTranslation } from '@/assets/locales/views/userProfile/sections/awardsSection/userProfile.awardsSection.translation.en';

export const userProfileAwardsSectionTranslation: UserProfileAwardsSectionTranslation = {
  headerTitle: 'Prix',
  content: {
    emptyItemsLabel: 'prix',
    button: {
      edit: 'Modifier',
    },
  },
  modal: {
    title: 'Ajouter un prix',
  },
  form: {
    actions: 'Actions',
    title: 'Titre',
    associatedWith: 'Associé à',
    issuer: 'Émetteur',
    issueDate: "Date d'émission",
    description: 'Description',
    button: {
      save: 'Enregistrer',
    },
  },
};
