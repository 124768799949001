/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OcdReportTypeDto = {
    AverageDayRateAndMargin: 'AVERAGE_DAY_RATE_AND_MARGIN',
    Certificates: 'CERTIFICATES',
    CvDownloads: 'CV_DOWNLOADS',
    LandscapeOfTechnology: 'LANDSCAPE_OF_TECHNOLOGY',
    NumberOfRequestsPerPartner: 'NUMBER_OF_REQUESTS_PER_PARTNER',
    TimeToFindCandidate: 'TIME_TO_FIND_CANDIDATE',
    TimeToSignCandidate: 'TIME_TO_SIGN_CANDIDATE',
    TopReasonForLoosing: 'TOP_REASON_FOR_LOOSING',
    WinLossRatio: 'WIN_LOSS_RATIO'
} as const;

export type OcdReportTypeDto = typeof OcdReportTypeDto[keyof typeof OcdReportTypeDto];



