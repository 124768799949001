import { makeStyles } from '@mui/styles';

export const fullScreenLoaderStyles = makeStyles(theme => {
  return {
    paper: {
      textAlign: 'center',
      padding: theme.spacing(4, 6),
      borderRadius: theme.spacing(0.75),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      boxShadow: theme.variables.boxShadowMenu,
    },
    main: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapper: {
      textAlign: 'center',
      m: 6,
    },
  };
});
