import React from 'react';
import { SkillSearchRequestDto } from '@/api/generated-user-profile';
import { ProfileSearchAddSkillFilter } from '@/views/profileSearch/components/filters/skill/ProfileSearchAddSkillFilter.comp.tsx';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import { ProfileSearchContentWrapper } from '@/views/profileSearch/components/ProfileSearchContentWrapper.comp.tsx';
import { Box } from '@mui/material';
import { ProfileSearchFilterType } from '@/views/profileSearch/model/ProfileSearchFilterType.model.ts';
import { ProfileSearchAddFilterMode } from '@/views/profileSearch/components/filters/model/ProfileSearchAddFilterProps.model.ts';
import { ProfileSearchAddItemType } from '@/views/profileSearch/model/ProfileSearchForm.model.ts';

type Props = {
  skills: SkillSearchRequestDto[];
  onRemove: (item: { value: number }, prop: ProfileSearchFilterType) => () => void;
  onEdit: (
    prop: ProfileSearchFilterType,
  ) => (params: { item: { value: number }; oldItem: { value: number } | undefined }) => void;
  onAddAnother: (type: ProfileSearchAddItemType) => () => void;
};

export const ProfileSearchSkillsFilters: React.FC<Props> = props => {
  const { skills, onRemove, onEdit, onAddAnother } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.content');

  if (skills.length === 0) {
    return null;
  }

  return (
    <>
      <ProfileSearchContentWrapper
        title={t('activeSkillsFilters')}
        onAddAnotherClick={onAddAnother(ProfileSearchAddItemType.skills)}
      >
        {skills
          .sort((a, b) => b.level - a.level)
          .map(item => (
            <Box py={1} key={item.value + '_' + item.level}>
              <ProfileSearchAddSkillFilter
                mode={ProfileSearchAddFilterMode.EDIT}
                initValue={item}
                onReset={onRemove(item, 'skills')}
                onEdit={onEdit('skills')}
                currentItems={[]}
              />
            </Box>
          ))}
      </ProfileSearchContentWrapper>
    </>
  );
};
