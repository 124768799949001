import { EditDeleteTranslation } from '@/assets/locales/views/userProfile/editDelete.translation.en.ts';

export const editDeleteTranslation: EditDeleteTranslation = {
  edit: 'Bearbeiten',
  delete: 'Löschen',
  modal: {
    title: 'Bestätigen Sie bitte',
    description: 'Sind Sie sicher, dass Sie diesen Artikel löschen möchten?',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
  },
};
