import React from 'react';
import { Box, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';

type Props = {
  title: string;
  onAddAnotherClick: () => void;
};

export const ProfileSearchContentWrapper: React.FC<Props> = props => {
  const { title, onAddAnotherClick } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.content');

  return (
    <Box mt={2}>
      <Paper style={{ padding: 8 }}>
        <Stack direction="row" justifyContent="space-between">
          <Box pb={2}>{title}</Box>
          <Button variant="outlined" onClick={onAddAnotherClick}>
            {t('button.addAnother')}
          </Button>
        </Stack>
        {props.children}
      </Paper>
    </Box>
  );
};
