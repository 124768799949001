/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const LanguageProficiencyDto = {
    Basic: 'BASIC',
    Intermediate: 'INTERMEDIATE',
    Advanced: 'ADVANCED',
    Professional: 'PROFESSIONAL',
    Bilingual: 'BILINGUAL'
} as const;

export type LanguageProficiencyDto = typeof LanguageProficiencyDto[keyof typeof LanguageProficiencyDto];



