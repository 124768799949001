import { AxiosError } from 'axios';

export class DataOperationsError extends Error {
  private readonly _timestamp: string;
  private readonly _url: string | null;
  private readonly _method: string | null;
  private readonly _code: number | null;

  constructor(error: AxiosError) {
    const { message, response } = error;
    super(message);
    this._timestamp = new Date().toISOString();
    this._url = response?.config?.url ?? null;
    this._method = response?.config?.method ?? null;
    this._code = response?.status ?? null;
    Object.setPrototypeOf(this, DataOperationsError.prototype);
  }

  get timestamp(): string {
    return this._timestamp;
  }

  get url(): string | null {
    return this._url;
  }

  get method(): string | null {
    return this._method;
  }

  get code(): number | null {
    return this._code;
  }
}
