import { UserProfileCoursesSectionTranslation } from '@/assets/locales/views/userProfile/sections/coursesSection/userProfile.coursesSection.translation.en.ts';

export const userProfileCoursesSectionTranslation: UserProfileCoursesSectionTranslation = {
  headerTitle: 'Kurse',
  content: {
    emptyItemsLabel: 'Kurse',
    button: {
      edit: 'Bearbeiten',
    },
  },
  modal: {
    title: 'Kurs hinzufügen',
  },
  form: {
    actions: 'Handlungen',
    name: 'Name',
    date: 'Datum des Abschlusses',
    description: 'Beschreibung',
    organizer: 'Veranstalter',
    url: 'Url',
    button: {
      save: 'Speichern',
    },
  },
};
