import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '@/layouts/routes/routes';
import { RouteGuard } from './RouteGuard.comp';
import { NotFound } from '@/views/generalError/notAuthorized/NotFound.comp';
import { Box } from '@mui/material';

export const AppRoutes = () => {
  return (
    <Box component="main">
      <Routes>
        <Route path="*" element={<NotFound />} />
        {ROUTES.map(({ component, path, roles }) => (
          <Route
            key={path}
            path={path}
            element={<RouteGuard rolesWithAccess={roles}>{component}</RouteGuard>}
          />
        ))}
      </Routes>
    </Box>
  );
};
