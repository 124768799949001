import { createContext, useContext } from 'react';
import { PrivacyPolicySignStatusDto, UserBasicDto } from '@/api/generated';
import { Signal } from '@preact/signals-react';
import { signal } from '@preact/signals';

export const UserDefaultValues: UserContextProps = {
  userId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  initializationDone: false,
  companyName: undefined,
  userProfileId: undefined,
  privacyPolicyStatus: PrivacyPolicySignStatusDto.NotSigned,
  dataReady: false,
};

export type UserContextProps = Omit<UserBasicDto, 'id'> & {
  userId: string;
  fullName: string;
  userProfileId?: string;
  dataReady: boolean;
};
export const UserContext = createContext<Signal<UserContextProps>>(signal(UserDefaultValues));

export const useUserContext = (): Signal<UserContextProps> => {
  return useContext(UserContext);
};
