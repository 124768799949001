import {
  vacancyCandidateTranslation,
  VacancyCandidateTranslation,
} from './vacancyCandidate/vacancyCandidate.translation.en';
import {
  ValidationTranslation,
  validationTranslation,
} from './validation/validation.translation.en';
import {
  userInitialize,
  UserInitializeTranslation,
} from '@/assets/locales/userInitialize/userInitialize.translation.en';
import { vacanciesTranslation, VacanciesTranslation } from './vacancies/vacancies.translation.en';
import { MenuTranslation, menuTranslation } from './menu/menu.translation.en';
import {
  companyDetailsTranslation,
  CompanyDetailsTranslation,
} from './companyDetails/companyDetails.translation.en';
import { RoleTypeDto } from '@/api/generated';
import {
  userDetailsTranslation,
  UserDetailsTranslation,
} from '@/assets/locales/userDetails/userDetails.translation.en.ts';
import {
  PageTitlesTranslation,
  pageTitlesTranslation,
} from '@/assets/locales/pageTitles/pageTitles.translation.en.ts';
import { viewsTranslation, ViewsTranslation } from '@/assets/locales/views/views.translation.en.ts';
import {
  componentsTranslation,
  ComponentsTranslation,
} from '@/assets/locales/components/components.translation.en.ts';
import {
  generalErrorTranslation,
  GeneralErrorTranslation,
} from '@/assets/locales/generalError/generalError.translation.en.ts';
import {
  layoutsTranslation,
  LayoutsTranslation,
} from '@/assets/locales/layouts/layouts.translation.en';
import {
  profileTranslation,
  ProfileTranslation,
} from '@/assets/locales/profile/profile.translation.en';
import {
  dictionariesTranslation,
  DictionaryTranslations,
} from '@/assets/locales/dictionaries/dictionaries.translation.en';
import {
  SearchTranslation,
  searchTranslation,
} from '@/assets/locales/search/search.translation.en.ts';

export const NAMESPACE = 'ocd';

export interface Translations {
  [NAMESPACE: string]: {
    alerts: Record<string, unknown>;
    companyDetails: CompanyDetailsTranslation;
    components: ComponentsTranslation;
    defaultRoles: Record<RoleTypeDto, string>;
    dictionaries: Record<string, unknown> & DictionaryTranslations;
    fullText: Record<string, unknown>;
    generalError: GeneralErrorTranslation;
    layouts: LayoutsTranslation;
    meetings: Record<string, unknown>;
    menu: MenuTranslation;
    pageTitles: PageTitlesTranslation;
    search: SearchTranslation;
    sortIcons: Record<string, unknown>;
    userDetails: UserDetailsTranslation;
    userInitialize: UserInitializeTranslation;
    utils: Record<string, unknown>;
    vacancies: VacanciesTranslation;
    vacancyCandidate: VacancyCandidateTranslation;
    validation: ValidationTranslation;
    views: ViewsTranslation;
    profile: ProfileTranslation;
  };
}

export const en: Translations = {
  [NAMESPACE]: {
    userDetails: userDetailsTranslation,
    pageTitles: pageTitlesTranslation,
    alerts: {
      success: 'Operation completed successfully.',
      error: 'The operation has failed, try again or contact the application administrator.',
    },
    dictionaries: {
      ...dictionariesTranslation,
      active: 'Active',
      addButton: 'Add entry',
      approve: 'Approvement',
      archive: 'Archiving',
      buttonArchive: 'Archive',
      choose: 'Choose',
      date: 'Date',
      dictionary: 'Dictionary',
      entries: 'Entries',
      edit: 'Edit dictionary entry',
      history: 'Operations history',
      maxRows: 'Maximum rows per page',
      merge: 'Merge',
      mergeResult: 'Merge Result',
      modalHeader: 'Add entry to the dictionary',
      name: 'Name',
      nextPage: 'Next page',
      operation: 'Operation',
      previousPage: 'Previous page',
      result: 'Result',
      reverse: 'Reverse the change',
      selectedItems: 'Selected items:',
      search: 'Search...',
      title: 'Dictionaries',
    },
    components: componentsTranslation,
    meetings: {
      employeeView: "Employee's view",
      managerView: "Manager's view",
      meetingNotes: 'Meeting notes',
      addNote: 'Add a note',
      editNote: 'Edit note',
      addComment: 'Add comment',
      editComment: 'Edit comment',
      goBack: 'Go back to notes list',
      note: 'Meeting note',
      meetingDate: 'Meeting date',
      meetingTitle: 'Summary',
      modal: {
        meetingType: 'Meeting type',
        title: 'Title',
        date: 'Date',
        nextMeetingDate: 'Next meeting date',
        buttonSave: 'Save',
        formError: 'This field is required',
        buttonCancel: 'Cancel',
        addParticipant: 'Add participant *',
        textEditor: 'Write a note',
        editNextMeeting: 'Change next meeting date',
        noNextDate: 'no date was set',
      },
      comments: 'Comments',
      noComments: 'There are no comments',
      comment: 'Comment',
      buttonDelete: 'Delete',
      modified: 'Modified',
      added: 'Added',
      areYouSure: 'Are you sure you want to delete this note?',
      warning: 'Once approved, it will not be possible to restore this note!',
      evaluation: 'Employee evaluation',
      salary: 'Salary negotiation',
      development: 'Development plan',
      other: 'Other',
      filter: 'Filter',
      participant: 'Participant',
      startDate: 'Choose date - from',
      endDate: 'Choose date - to',
      buttonClear: 'Clear all filters',
    },
    menu: menuTranslation,
    search: searchTranslation,
    fullText: {
      goBack: 'Go back',
      resultCount: 'Search results: ',
      more: 'more...',
      missing: 'Missing:',
      all: 'All',
      employees: 'Employees',
      projects: 'Projects',
      publications: 'Publications',
      maxResults: 'Maximum results per page',
      employeesTab: {
        position: 'Position:',
        location: 'Location:',
        projects: 'Projects:',
        experience: 'Experience:',
        certificates: 'Certificates:',
        courses: 'Courses:',
      },
    },
    generalError: generalErrorTranslation,
    sortIcons: {
      name: 'Name',
      manDays: 'Man-days reported',
    },
    vacancies: vacanciesTranslation,
    validation: validationTranslation,
    companyDetails: companyDetailsTranslation,
    vacancyCandidate: vacancyCandidateTranslation,
    utils: {
      addFile: 'Add file',
    },
    defaultRoles: {
      [RoleTypeDto.Admin]: 'Admin',
      [RoleTypeDto.Employee]: 'Employee',
      [RoleTypeDto.ExternalPartner]: 'External Partner',
      [RoleTypeDto.GroupExternalPartner]: 'Group External Partner',
      [RoleTypeDto.LocalResourceManager]: 'Local Resource Manager',
      [RoleTypeDto.PreSales]: 'Pre-Sales',
      [RoleTypeDto.Requester]: 'Requester',
    },
    userInitialize,
    views: viewsTranslation,
    layouts: layoutsTranslation,
    profile: profileTranslation,
  },
};
