export type ProjectDetails = {
  salesforceId: string;
  customerName: string;
  requestingCountry: string;
  opportunityOwner: string;
  opportunityName: string;
  status: string;
};

export type ProjectsTranslation = {
  search: {
    filters: ProjectDetails;
  };
  table: {
    columns: ProjectDetails;
  };
  details: ProjectDetails & {
    description: string;
    button: {
      edit: string;
      archive: string;
      goBack: string;
    };
    modal: ProjectDetails & {
      description: string;
      header: {
        add: string;
        edit: string;
      };
    };
  };
};

const projectColumns: ProjectDetails = {
  salesforceId: 'Salesforce Opportunity ID',
  customerName: 'Customer name',
  requestingCountry: 'Requesting country',
  opportunityOwner: 'Opportunity owner',
  opportunityName: 'Opportunity name',
  status: 'Status',
};

export const projectsTranslation: ProjectsTranslation = {
  search: {
    filters: projectColumns,
  },
  table: {
    columns: projectColumns,
  },
  details: {
    ...projectColumns,
    description: 'Description',
    button: {
      edit: 'Edit project',
      archive: 'Archive project',
      goBack: 'Go back to projects list',
    },
    modal: {
      ...projectColumns,
      description: 'Description',
      header: {
        add: 'Add project',
        edit: 'Edit project',
      },
    },
  },
};
