import React from 'react';
import { UserProfileItemDto } from '@/api/generated-user-profile';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslationWithPrefix } from '@/utils/useTranslationWithPrefix.ts';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAssignProfileToVacancy } from '@/views/profileSearch/util/useAssignProfileToVacancy.util.ts';
import { VacancyDetailsForSearch } from '@/views/profileSearch/model/vacancyDetailsForSearch.model.ts';
import Button from '@mui/material/Button';
import { SxProps } from '@mui/material';

type Props = {
  item: UserProfileItemDto;
};

const buttonStyles: SxProps = {
  mt: 2,
};

export const ProfileSearchResultItemVacancyButtons: React.FC<Props> = props => {
  const { item } = props;
  const { t } = useTranslationWithPrefix('views.profileSearch.content.results');
  const { assignProfileToVacancy, isAssigningProfileToVacancy } = useAssignProfileToVacancy();

  if (!VacancyDetailsForSearch.value) {
    return null;
  }

  const alreadyAssignedToVacancy = VacancyDetailsForSearch.value.profilesAlreadyInVacancy.includes(
    item.userProfileId,
  );

  const handleClick = async () => {
    if (!VacancyDetailsForSearch.value) {
      return;
    }
    await assignProfileToVacancy({
      vacancyId: VacancyDetailsForSearch.value.vacancyId,
      userProfile: {
        userProfileId: item.userProfileId,
      },
    });
  };

  if (alreadyAssignedToVacancy) {
    return (
      <>
        <Button variant="contained" disabled endIcon={<CheckIcon />} sx={buttonStyles}>
          {t('assignedToVacancy')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        sx={buttonStyles}
        variant="outlined"
        endIcon={<AddOutlinedIcon />}
        onClick={handleClick}
        disabled={isAssigningProfileToVacancy}
      >
        {t('assignToVacancy')}
      </Button>
    </>
  );
};
