export enum QueryKeyEnum {
  COMPANY_DETAILS_GET = 'COMPANY_DETAILS_GET',
  CURRENT_USER_GET = 'CURRENT_USER_GET',
  DICTIONARIES = 'DICTIONARIES',
  DICTIONARY_BY_TYPE_GET = 'DICTIONARY_BY_TYPE_GET',
  PRIVACY_POLICY_GET = 'PRIVACY_POLICY_GET',
  SESSION_GET = 'SESSION_GET',
  USERS_SHORT_GET = 'USERS_SHORT_GET',
  USER_PROFILE_GET = 'USER_PROFILE_GET',
  VACANCY_CREATE = 'VACANCY_CREATE',
  VACANCY_DETAILS_GET = 'VACANCY_DETAILS_GET',
  VACANCY_GET = 'VACANCY_GET',
}
