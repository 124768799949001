import { ValidationTranslation } from '@/assets/locales/validation/validation.translation.en.ts';

export const validationTranslation: ValidationTranslation = {
  charactersLimit: 'Le nombre maximum de caractères est {{noOfChars}}',
  date: {
    maxCurrentDate: 'La date ne peut pas être dans le futur',
    maxDate: 'La date ne peut pas être postérieure à {{maxDate}}',
    minCurrentDate: 'La date ne peut pas être dans le passé',
    notEarlierThen: '{{to}} ne peut pas être antérieure à {{from}}',
  },
  email: "L'e-mail n'est pas valide",
  general: { required: 'Le champ est requis', wrongFormat: 'Format incorrect fourni' },
  iban: 'Le numéro IBAN est incorrect',
  incorrectFileExtension:
    'Extension de fichier incorrecte. Extensions prises en charge : {{correctExtensions}}',
  integerNumber: 'Le champ doit être un entier',
  isNumber: 'Le champ doit être un nombre',
  length: 'Le champ doit être de {{length}} caractères',
  max: 'La valeur maximale est {{howMany}}',
  maxLength: 'Le champ doit comporter au maximum {{max}} caractères',
  maxLengthOfMessage: 'La longueur maximale du message est de {{max}} caractères',
  maxStringLength: 'La longueur maximale pour ce champ est : {{max}}',
  min: 'La valeur minimale est {{howMany}}',
  minLength: 'Le champ doit comporter au moins {{min}} caractères',
  minNumber: 'La valeur minimale pour ce champ est : {{minNumber}}',
  minStringLength: 'La longueur minimale pour ce champ est : {{min}}',
  mobile: "Le numéro de téléphone n'est pas valide",
  nip: 'Valeur NIP invalide',
  noResults: 'Aucun résultat',
  passwordMustMatch: 'Les mots de passe doivent correspondre',
  passwordRequirements:
    'Les mots de passe doivent contenir un chiffre, une lettre minuscule, une lettre majuscule et un caractère spécial. La longueur du mot de passe doit être comprise entre 8 et 100 caractères.',
  pesel: 'Le numéro PESEL est incorrect',
  phone: "Le numéro de téléphone n'est pas valide (XXX-XXX-XXX)",
  pinsMustMatch: 'Les codes PIN doivent correspondre',
  rating: 'Veuillez sélectionner votre évaluation',
  regon: 'Valeur REGON invalide',
  required: 'Ce champ est requis',
  stringLength: 'Ce champ doit comporter {{length}} caractères',
  tooLargeFileSize:
    'La taille du fichier est trop grande. La taille maximale du fichier est : {{maxFileSize}}',
};
