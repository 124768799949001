import {
  ProjectDetails,
  ProjectsTranslation,
} from '@/assets/locales/views/projects/projects.translation.en.ts';

const projectColumns: ProjectDetails = {
  salesforceId: "ID d'opportunité Salesforce",
  customerName: 'Nom du client',
  requestingCountry: 'Pays demandeur',
  opportunityOwner: "Responsable de l'opportunité",
  opportunityName: "Nom de l'opportunité",
  status: 'Statut',
};

export const projectsTranslation: ProjectsTranslation = {
  search: {
    filters: projectColumns,
  },
  table: {
    columns: projectColumns,
  },
  details: {
    ...projectColumns,
    description: 'Description',
    button: {
      edit: 'Modifier le projet',
      archive: 'Archiver le projet',
      goBack: 'Retourner à la liste des projets',
    },
    modal: {
      ...projectColumns,
      description: 'Description',
      header: {
        add: 'Ajouter un projet',
        edit: 'Modifier un projet',
      },
    },
  },
};
