import { NotificationsListDto, NotificationTypeDto } from '@/api/generated';
import { useApiClient } from '@/api/util/useApiClient.util';
import { useState } from 'react';

interface Return {
  getNotifications: (
    notificationType?: Array<NotificationTypeDto>,
  ) => Promise<NotificationsListDto>;
  isFetchingGetNotifications: boolean;
}

export const useGetNotifications = (): Return => {
  const { notificationApi } = useApiClient();
  const [isLoading, setIsLoading] = useState(false);
  return {
    getNotifications: async request => {
      setIsLoading(true);
      const notifications: NotificationsListDto = { notifications: [] };
      try {
        const response = await notificationApi.getNotifications(request);
        if (response?.data) {
          notifications['notifications'] = response.data.notifications;
        }
      } finally {
        setIsLoading(false);
      }

      return notifications;
    },
    isFetchingGetNotifications: isLoading,
  };
};
