import { useContext } from 'react';
import { httpErrorHandler } from './httpErrorHandler.util';
import { DataOperationsErrorContext } from '@/errorHandling/DataOperationsErrorContext';

type Return = (error: unknown) => Promise<unknown> | undefined;

export const useGetErrorResponseInterceptor = (): Return => {
  const { handleMinorError } = useContext(DataOperationsErrorContext);

  return async error => {
    httpErrorHandler(error, handleMinorError);
  };
};
