/* tslint:disable */
/* eslint-disable */
/**
 * API
 * API
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { LangDto } from '../models';
// @ts-ignore
import type { UserProfileLanguage1Dto } from '../models';
/**
 * UserProfileLanguageApi - axios parameter creator
 * @export
 */
export const UserProfileLanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileLanguage: async (userProfileId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('createUserProfileLanguage', 'userProfileId', userProfileId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUserProfileLanguage', 'lang', lang)
            // verify required parameter 'userProfileLanguage1Dto' is not null or undefined
            assertParamExists('createUserProfileLanguage', 'userProfileLanguage1Dto', userProfileLanguage1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/languages`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileLanguage1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileLanguage: async (userProfileId: string, userProfileLanguageId: string, lang: LangDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('deleteUserProfileLanguage', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileLanguageId' is not null or undefined
            assertParamExists('deleteUserProfileLanguage', 'userProfileLanguageId', userProfileLanguageId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUserProfileLanguage', 'lang', lang)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/languages/{userProfileLanguageId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileLanguageId"}}`, encodeURIComponent(String(userProfileLanguageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileLanguage: async (userProfileId: string, userProfileLanguageId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileId' is not null or undefined
            assertParamExists('updateUserProfileLanguage', 'userProfileId', userProfileId)
            // verify required parameter 'userProfileLanguageId' is not null or undefined
            assertParamExists('updateUserProfileLanguage', 'userProfileLanguageId', userProfileLanguageId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUserProfileLanguage', 'lang', lang)
            // verify required parameter 'userProfileLanguage1Dto' is not null or undefined
            assertParamExists('updateUserProfileLanguage', 'userProfileLanguage1Dto', userProfileLanguage1Dto)
            const localVarPath = `/api/v1/user-profiles/{userProfileId}/languages/{userProfileLanguageId}`
                .replace(`{${"userProfileId"}}`, encodeURIComponent(String(userProfileId)))
                .replace(`{${"userProfileLanguageId"}}`, encodeURIComponent(String(userProfileLanguageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileLanguage1Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileLanguageApi - functional programming interface
 * @export
 */
export const UserProfileLanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileLanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserProfileLanguage(userProfileId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserProfileLanguage(userProfileId, lang, userProfileLanguage1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileLanguageApi.createUserProfileLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserProfileLanguage(userProfileId, userProfileLanguageId, lang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileLanguageApi.deleteUserProfileLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfileLanguage(userProfileId, userProfileLanguageId, lang, userProfileLanguage1Dto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileLanguageApi.updateUserProfileLanguage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileLanguageApi - factory interface
 * @export
 */
export const UserProfileLanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileLanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userProfileId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserProfileLanguage(userProfileId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.createUserProfileLanguage(userProfileId, lang, userProfileLanguage1Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserProfileLanguage(userProfileId, userProfileLanguageId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userProfileId 
         * @param {string} userProfileLanguageId 
         * @param {LangDto} lang 
         * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserProfileLanguage(userProfileId, userProfileLanguageId, lang, userProfileLanguage1Dto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileLanguageApi - interface
 * @export
 * @interface UserProfileLanguageApi
 */
export interface UserProfileLanguageApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApiInterface
     */
    createUserProfileLanguage(userProfileId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileLanguageId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApiInterface
     */
    deleteUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileLanguageId 
     * @param {LangDto} lang 
     * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApiInterface
     */
    updateUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UserProfileLanguageApi - object-oriented interface
 * @export
 * @class UserProfileLanguageApi
 * @extends {BaseAPI}
 */
export class UserProfileLanguageApi extends BaseAPI implements UserProfileLanguageApiInterface {
    /**
     * 
     * @param {string} userProfileId 
     * @param {LangDto} lang 
     * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApi
     */
    public createUserProfileLanguage(userProfileId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileLanguageApiFp(this.configuration).createUserProfileLanguage(userProfileId, lang, userProfileLanguage1Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileLanguageId 
     * @param {LangDto} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApi
     */
    public deleteUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, options?: RawAxiosRequestConfig) {
        return UserProfileLanguageApiFp(this.configuration).deleteUserProfileLanguage(userProfileId, userProfileLanguageId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userProfileId 
     * @param {string} userProfileLanguageId 
     * @param {LangDto} lang 
     * @param {UserProfileLanguage1Dto} userProfileLanguage1Dto User Profile Language update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileLanguageApi
     */
    public updateUserProfileLanguage(userProfileId: string, userProfileLanguageId: string, lang: LangDto, userProfileLanguage1Dto: UserProfileLanguage1Dto, options?: RawAxiosRequestConfig) {
        return UserProfileLanguageApiFp(this.configuration).updateUserProfileLanguage(userProfileId, userProfileLanguageId, lang, userProfileLanguage1Dto, options).then((request) => request(this.axios, this.basePath));
    }
}

