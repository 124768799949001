import { SxProps } from '@mui/material';

const common = {
  width: '600px',
  margin: 'auto',
};

export const AiSearchParametersTitleStyles: SxProps = {
  fontFamily: 'Usual',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0em',
  textAlign: 'center',
  textTransform: 'capitalize',
  marginBottom: '20px',
};
export const AiSearchParametersTabsSwitcherStyles: SxProps = {
  color: '#15BEF0',
  ...common,
};
export const AiSearchParametersIndicatorStyles: SxProps = {
  backgroundColor: '#15BEF0',
};
export const AiSearchParametersTabsPanelsStyles: SxProps = {
  ...common,
};
export const AiSearchParametersTabPanelStyles: SxProps = {
  fontFamily: 'Usual',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
  paddingBottom: '16px',
};
